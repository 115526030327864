import React, { useEffect, useState } from "react";
import AddCategories from "../../../AdminPanel/AddCategories";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { useQuery } from "react-query";
import { useAuth } from "../../../../store/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import { APIurls } from "../../../../api/apiConstant";
import { Link, NavLink, Outlet } from "react-router-dom";
import CustomCheckbox from "../../../../helpers/CustomCheckbox";

import {
  IconButton,
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import AddNewOfferForm from "./AddNewOfferForm";

export default function CouponList() {
  const handleEdit = () => {
    window.scrollTo({ top: 0 });
  };

  const { getAccessToken } = useAuth();
  const [discountListData, setCategoryData] = useState();
  const [isRefetch, setIsRefetch] = useState(false);

  const [addCategory, setAddCategory] = useState(false);

  const showAddCategoryPopUp = () => {
    setAddCategory((prev) => !prev);
  };

  const popUpScreenState = (show) => {
    setAddCategory(show);
  };

  const getCategories = async () => {
    const token = await getAccessToken();
    const response = await fetch(`${APIurls.fetchCategories}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    console.log(result);
    return result;
  };

  const { isLoading, isError, data, error, isSuccess, refetch } = useQuery(
    "fetchCategories",
    getCategories
  );

  if (isRefetch) {
    refetch(); // when component get mounted after category is created.
    setIsRefetch(false); // refetch() only once.
  }

  useEffect(() => {
    if (isSuccess) {
      const categoryDataToShow = data?.response?.CatArray?.map(
        (item, index) => {
          return {
            mainCategory: item.categoryName,
            createdDate: item.createdAt,
            status: item.isActive,
          };
        }
      );
      setCategoryData(categoryDataToShow.reverse());
    }
  }, [data]);

  if (isError) {
    toast.error(error.message, {
      toastId: "error1",
    });
  }

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        // padding: "10px 10px",
        color: "#4D4D4D",
        fontSize: "13px",
      },
    },
    head: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        // fontSize: "15px",
      },
    },

    rows: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        color: "#565656",
        // padding: "10px 10px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    // pagination: {
    //    style: {
    //       boxShadow: "10px 5px 5px #ddd",
    //       marginBottom: "5px",
    //    },
    // },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1100px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };

  const couponsData = [
    {
      id: 1,
      club: "Club A",
      coupon: "COUPON123",
      couponType: "Percentage",
      discountType: "Membership",
      duration: "1 year",
      limit: "Single",
      status: true,
    },
    {
      id: 2,
      club: "Club B",
      coupon: "DISCOUNT456",
      couponType: "Fixed Amount",
      discountType: "Product",
      duration: "6 months",
      limit: "Unlimited",
      status: false,
    },
    {
      id: 3,
      club: "Club C",
      coupon: "SAVE10NOW",
      couponType: "Percentage",
      discountType: "Membership",
      duration: "3 months",
      limit: "Double",
      status: true,
    },
    {
      id: 4,
      club: "Club D",
      coupon: "FREEPRODUCT",
      couponType: "Free Product",
      discountType: "Product",
      duration: "Single Use",
      limit: "Limited",
      status: false,
    },
    {
      id: 5,
      club: "Club E",
      coupon: "SAVEBIG",
      couponType: "Percentage",
      discountType: "Membership",
      duration: "1 month",
      limit: "Five",
      status: true,
    },
    {
      id: 6,
      club: "Club F",
      coupon: "DEALOFTHEDAY",
      couponType: "Fixed Amount",
      discountType: "Product",
      duration: "24 hours",
      limit: "Limited",
      status: true,
    },
    {
      id: 7,
      club: "Club G",
      coupon: "WEEKENDSALE",
      couponType: "Percentage",
      discountType: "Membership",
      duration: "Weekend Only",
      limit: "Three",
      status: false,
    },
    {
      id: 8,
      club: "Club H",
      coupon: "HOLIDAY20",
      couponType: "Percentage",
      discountType: "Membership",
      duration: "Seasonal",
      limit: "Unlimited",
      status: true,
    },
    {
      id: 9,
      club: "Club I",
      coupon: "FREESHIP",
      couponType: "Free Shipping",
      discountType: "Product",
      duration: "Limited Time Offer",
      limit: "Single",
      status: false,
    },
    {
      id: 10,
      club: "Club J",
      coupon: "SPECIALOFFER",
      couponType: "Fixed Amount",
      discountType: "Membership",
      duration: "2 weeks",
      limit: "Limited",
      status: true,
    },
  ];

  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: false,
      maxWidth: "0.5rem",
      grow: 1,
    },
    {
      name: "Clubs",
      cell: (row) => <span>{row.club}</span>,
      center: false,
    },
    {
      name: "Coupon",
      cell: (row) => <span>{row.coupon}</span>,
      sortable: true,
      center: false,
    },
    {
      name: "Coupon Type",
      selector: (row) => row.couponType,
      sortable: true,
      center: false,
      grow: 3,
    },
    {
      name: "Discount Type",
      selector: (row) => row.discountType,
      sortable: true,
      center: false,
      grow: 3,
    },
    {
      name: "Duration",
      selector: (row) => row.duration,
      sortable: true,
      center: false,
      grow: 2,
    },
    {
      name: "User Limit",
      selector: (row) => row.limit,
      sortable: true,
      center: false,
      grow: 2,
    },
    {
      name: "Status",
      id: "status",
      selector: (row) => (
        <div
          className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
            py-[0.4rem] w-[4.5rem] ${
              row.status === true
                ? "text-[#25BF17] bg-[#d1f0cf91]"
                : "text-[#FF0023] bg-[#fdcad15e]"
            }`}
        >
          <span
            className={`${
              row.status === true ? "bg-[#25BF17]" : "bg-[#FF0023]"
            } h-[0.5rem] w-[0.5rem] rounded-full`}
          ></span>
          <h2>{row.status === true ? "Active" : "Inactive"}</h2>
        </div>
      ),
      center: false,
      grow: 2,
      sortable: true,
    },
    {
      name: "Actions",
      id: "action",
      cell: (row) => (
        <div className="flex space-x-2">
          <Link onClick={handleEdit} to={`edit/${row.id}`}>
            <IconButton className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]">
              <Icon
                icon="material-symbols:edit-rounded"
                className="text-[1.5rem] text-black"
              />
            </IconButton>
          </Link>
          <Link to={`delete/${row.id}`}>
            <IconButton className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]">
              <Icon
                icon="material-symbols:delete-rounded"
                className="text-[1.5rem] text-black"
              />
            </IconButton>
          </Link>
        </div>
      ),
      center: false,
      grow: 1,
    },
  ];

  return (
    <>
      <Outlet />
      <div className="flex gap-[3rem] border-b-2 border-[#989898] px-[1rem] pt-[0.5rem]">
        <NavLink to={`/offersAndBanners`} end>
          {({ isActive }) => (
            <h2
              className={`text-[1.2rem] sm:text-[1.3rem] font-bold whitespace-nowrap pb-[0.3rem] ${
                isActive
                  ? "font-bold text-primary border-b-2 border-primary"
                  : "text-[#858585]"
              }`}
            >
              Category Discount
            </h2>
          )}
        </NavLink>
        <NavLink to={`/offersAndBanners/coupons`}>
          {({ isActive }) => (
            <h2
              className={`text-[1.2rem] sm:text-[1.3rem] font-bold whitespace-nowrap pb-[0.3rem] ${
                isActive
                  ? "font-bold text-primary border-b-2 border-primary"
                  : "text-[#858585]"
              }`}
            >
              Coupons
            </h2>
          )}
        </NavLink>
      </div>

      <div className="flex flex-col gap-[0.5rem]">
        <div className="flex items-center justify-between">
          <h2 className="text-black text-[1.3rem] font-semibold">Support</h2>
          <div className="flex items-center gap-[2rem]">
            <div className="relative">
              <input
                className="text-[0.8rem] w-[300px] p-2 outline-none border-[#DFDFDF] border-[1px] rounded-[6px]"
                type="text"
                placeholder="Search by ID or name or email or phone"
              />
              <p className="absolute right-[8px] top-[8px] bg-[#FB6108] p-[3px] rounded-sm text-white">
                <Icon icon="ri:search-fill" />
              </p>
            </div>
            <div className="flex items-center gap-[0.4rem] hover:text-primary cursor-pointer">
              <Icon icon="material-symbols:delete" className="text-[1.5rem]" />
              <span className="text-[1rem] font-medium">Remove</span>
            </div>
            <div className="flex items-center gap-[0.3rem] hover:text-[#FB6108] cursor-pointer">
              <p className="text-[1rem] font-medium">Change Status</p>
              <Icon icon="mingcute:down-line" className="text-[1.5rem]" />
            </div>
          </div>
        </div>
        <div>
          <DataTable
            columns={columns}
            customStyles={customStyles}
            data={couponsData}
            pagination
            selectableRows
            paginationPerPage={10}
            progressPending={isLoading}
            selectableRowsComponent={CustomCheckbox}
          />
        </div>
        {addCategory ? (
          <AddCategories
            setPopUpScreenVisibility={popUpScreenState}
            setIsRefetch={setIsRefetch}
          />
        ) : (
          ""
        )}
        <ToastContainer />
      </div>
    </>
  );
}
