import { configureStore } from "@reduxjs/toolkit";
import createSubCategorySlice from "./createSubCategorySlice";
import createClubSlice from "./createClubSlice";
import editSubCategorySlice from "./editSubCategorySlice";
import authSlice from "./authSlice";
import { clubOwnerSlice } from "./features/clubOwner/clubOwnerSlice";
import editClubSlice from "./editClubSlice";

const store = configureStore({
  reducer: {
    createSubCategory: createSubCategorySlice,
    editSubCategory: editSubCategorySlice,
    createClub: createClubSlice,
    editClub:editClubSlice,
    auth: authSlice,
    [clubOwnerSlice.reducerPath]: clubOwnerSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(clubOwnerSlice.middleware),
});

export default store;
