import { Icon } from "@iconify/react";
import React from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import rentalImg from "../../../../assets/rentals.png";
import {
  Button,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import { APIurls } from "../../../../api/apiConstant";
import { useAuth } from "../../../../store/AuthContext";
import { useQuery } from "react-query";
import moment from "moment";

export default function ViewCOExpensesView() {
  const { id, expenseId } = useParams();

  const { getAccessToken } = useAuth();

  const fetchClubOwnerInventory = async () => {
    const token = await getAccessToken();
    const response = await fetch(
      `${APIurls.fetchSpecificExpenseData}/${expenseId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    console.log(
      "View Club Owner Specific Expense",
      result?.response?.findExpenses
    );
    return result?.response?.findExpenses;
  };
  const {
    data: specificExpense,
    isLoading,
    refetch,
  } = useQuery("SpecificExpense", fetchClubOwnerInventory);

  if (isLoading) {
    return (
      <div class="flex items-center justify-center pt-4 min-h-screen">
        <div class="w-10 h-10 border-4 border-t-orange-500 border-gray-200 rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="bg-white flex flex-col gap-[1rem] rounded-md p-[1rem]">
      <div className="flex gap-[18rem] items-center">
        <div className="flex gap-[0.2rem] items-center text-[#808080] font-semibold">
          <Link to={"/club-owners"}>
            <h2 className="text-[1rem] font-semibold">Club Owners</h2>
          </Link>
          <Icon
            icon="material-symbols:arrow-forward-ios"
            className="text-[1.1rem]"
          />
          <Link to={`/club-owners/view/${id}`}>
            <h2 className="text-[1rem]">
              Dashboard
              {/* <span className='text-[#808080] text-[0.9rem] font-normal'> (GBCLUB1) </span> */}
            </h2>
          </Link>
          <Icon
            icon="material-symbols:arrow-forward-ios"
            className="text-[1.1rem]"
          />
          <Link to={`/club-owners/view/${id}/expenses`}>
            <h2 className="text-[1rem]">Expenses</h2>
          </Link>
          <Icon
            icon="material-symbols:arrow-forward-ios"
            className="text-[1.1rem]"
          />
          <h2 className="text-[1.2rem] text-black font-bold">{expenseId}</h2>
        </div>
        <div className="flex items-center gap-[2rem] ">
          <div className="flex items-center gap-[0.4rem] hover:text-primary cursor-pointer">
            <Icon icon="material-symbols:delete" className="text-[1.5rem]" />
            <span className="text-[1rem] font-medium">Remove</span>
          </div>
          <div className="flex items-center gap-[0.3rem] hover:text-primary cursor-pointer">
            <span
              className={`bg-[#25BF17] h-[0.5rem] w-[0.5rem] rounded-full`}
            ></span>
            <p className="text-[1rem] font-medium whitespace-nowrap text-[#026039]">
              Active
            </p>
            <Icon icon="mingcute:down-line" className="text-[1.5rem]" />
          </div>
          <div className="flex flex-row gap-[0.5rem] xs:gap-[1rem] items-center justify-center border border-[#CBCBCB] rounded-[5px]">
            <div className="">
              <Menu>
                <MenuHandler>
                  <Button className="text-[0.8rem] bg-[#FB6108] sm:text-[1rem] flex w-full flex-row justify-center whitespace-nowrap capitalize text-white  px-[0.4rem] xs:px-[1rem] py-[0.4rem] rounded-md items-center gap-[0.5rem] sm:gap-[0.8rem] hover:shadow-none shadow-none">
                    <Icon icon="mdi:cloud-download" className="text-[1.5rem]" />
                    <span className="font-[500]">Export</span>
                  </Button>
                </MenuHandler>
                <MenuList>
                  <MenuItem
                    //  onClick={downloadPdf}
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                  >
                    {" "}
                    <Icon
                      icon="fa-solid:file-pdf"
                      className="text-[#BE0A23] w-[1rem] h-auto"
                    />
                    Download as PDF
                  </MenuItem>
                  <hr className="my-[0.1rem]" />
                  <MenuItem
                    //  onClick={downloadCSV}
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                  >
                    <Icon
                      icon="fa-solid:file-csv"
                      className="text-[#29672E] w-[1rem] h-auto"
                    />
                    Download as CSV
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      </div>

      <div className="flex gap-[2rem] text-[1.2rem] font-medium border-b-2 border-[#989898]">
        <NavLink to={`/club-owners/view/${id}/expenses/view/${expenseId}`} end>
          {({ isActive }) => (
            <h2
              className={`pb-[0.3rem] font-bold text-primary border-b-2 border-primary`}
            >
              Expense ID : {expenseId}
            </h2>
          )}
        </NavLink>
      </div>
      <div className="grid grid-cols-6 px-[0.6rem]">
        <div className="col-span-3">
          <h2 className="text-[1.15rem] font-bold my-[1rem]">Info</h2>
          <div className="grid grid-cols-2 gap-y-[1rem] place-content-between">
            <label className="text-[#858585] font-medium">Expense ID</label>
            <h2 className="font-semibold">{expenseId}</h2>
            <label className="text-[#858585] font-medium">Expense Type</label>
            <h2 className="font-semibold">{specificExpense?.expenseType}</h2>
            <label className="text-[#858585] font-medium">Remark</label>
            <h2 className="font-semibold">{specificExpense?.remark}</h2>
            <label className="text-[#858585] font-medium">
              Date Of Expense
            </label>
            <h2 className="font-semibold">
              {moment(specificExpense?.createdAt).format("MMM Do YY")}
            </h2>
            <label className="text-[#858585] font-medium">
              Time Of Expense
            </label>
            <h2 className="font-semibold">
              {moment(specificExpense?.createdAt).format("LTS")}
            </h2>
            <label className="text-[#858585] font-medium">Amount</label>
            <h2 className="font-semibold">₹{specificExpense?.amount}</h2>
          </div>
        </div>
        <div className="col-span-2">
          <h2 className="text-[1.15rem] font-bold my-[1rem]">Status</h2>
          <div className="grid grid-cols-2 gap-y-[1rem]">
            <label className="text-[#858585] font-medium">Payment Mode</label>
            <div
              className={`flex justify-center items-center gap-[0.5rem] text-[0.9rem] rounded-md font-semibold py-[0.2rem] w-[9rem] ${
                "xyz" !== "ACTIVE"
                  ? "text-[#25BF17] bg-[#D1F0CF]"
                  : "text-[#FF0023] bg-[#FDCAD1]"
              }`}
            >
              <span
                className={`${
                  "xyz" !== "ACTIVE" ? "bg-[#25BF17]" : "bg-[#FF0023]"
                } h-[0.5rem] w-[0.5rem] rounded-full`}
              ></span>
              <h2>{specificExpense?.payMode}</h2>
            </div>
          </div>
        </div>
        <div className="col-span-1 my-[1rem]">
          {specificExpense?.image!=="" ? <img
    src={specificExpense?.image}
    alt="sports"
    className="rounded-md w-full h-auto"
  /> : <p>No Image</p>}
</div>
      </div>
    </div>
  );
}
