import React, { useEffect, useState } from "react";
import { IconButton } from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import { PiGreaterThan } from "react-icons/pi";
import { useAuth } from "../../../store/AuthContext";
import { APIurls } from "../../../api/apiConstant";
import { toast } from "react-toastify";
import { useQuery } from "react-query";

export default function RoleEditShowModal({ setShowModal, setIsRefetch, modalData }) {
    const { getAccessToken } = useAuth();
    const [title, setTitle] = useState("")
    const [showError, setShowError] = useState(false);
    const [specificTitle,setSpecificTitle] = useState("")


    const handleSaveTitle = async () => {
        if ((title === "")) {
            setShowError(true)
            return
        }
        const token = await getAccessToken();
        try {
            const response = await fetch(`${APIurls.updatingRole}/${modalData}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type':"application/json"
                },
                body: JSON.stringify({title})
            })
            const result = await response.json();
            if (!response.ok) {
                toast.error("Title not Updated")
                throw new Error(result.message)             
            }
            toast.success("Title Updated Successfully")
            console.log("Title Updated Successfully", result)
            setIsRefetch()
        } catch (error) {
            console.log(error)
        }
        setShowModal(false)
    }

    const fetchSpecificRole = async () =>{
        const token = await getAccessToken();
        const response = await fetch(`${APIurls.fetchSpecificRoleData}/${modalData}`,{
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const result = await response.json();
        if(!response.ok){
            throw new Error(result.message)
        }
        setTitle(result?.response?.role?.roleName)
        return result?.response
    }

    useEffect(()=>{
        fetchSpecificRole();
    },[])

    return (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-[#000000E6]">
            <div className="flex flex-col gap-4 bg-white rounded-sm px-6 py-8 w-[40%] relative">
                <div className="flex justify-between items-center">
                    <h1 className="text-[20px] font-bold">Edit Title</h1>
                    <IconButton
                        onClick={() => setShowModal(false)}
                        className={"shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-sm"}
                        size="sm"
                    >
                        <Icon icon="material-symbols:close" className="text-[1.5rem]" />
                    </IconButton>
                </div>
                <div className="flex flex-col gap-[0.75rem]">
                    <input
                        className="no flex-grow text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] placeholder:text-[#7F7F7F] bg-[#EFF1F9] outline-none"
                        type="text"
                        placeholder="Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    {!title && showError && (
                        <p className="text-red-600 text-sm">This field is required*</p>
                    )}
                </div>
                <div className="w-[17%] flex items-center justify-center text-white bg-[#FB6108] px-4 py-1 cursor-pointer rounded-md gap-1">
                    <button
                        type="button"
                        className="text-[0.8rem] sm:text-[1rem] font-semibold"
                        onClick={handleSaveTitle}
                    >
                        Save
                    </button>
                    <PiGreaterThan />
                </div>
            </div>
        </div>
    );
}
