import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IconButton, Option, Select } from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { useQuery } from "react-query";
import { ToastContainer } from "react-toastify";
import CustomCheckbox from "../../../helpers/CustomCheckbox";
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";
import CustomerDisableModal from "./CustomerModal";
import { FcCancel } from "react-icons/fc";
import { SlCheck } from "react-icons/sl";
import gamebeezFinalLogo from "../../../assets/GB-Final-Logo.png";
import DownloadExcel from "../../../exportData/ExportExcel";
import pdfLogo from "../../../assets/pdfImage.png";
import { FaFileCsv } from "react-icons/fa6";
import DownloadPDF from "../../../exportData/ExportPDF";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";

export default function CustomerList() {

  const [status, setStatus] = useState("");
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const [showModal3, setShowModal3] = useState(false);
  const [modalData3, setModalData3] = useState([]);
  const [loading, setLoading] = useState(false);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const { getAccessToken, userRole, adminName } = useAuth();

  const showModalHandler = (row) => {
    setShowModal3(true);
    setModalData3(row);
  };

  const [isRefetch, setIsRefetch] = useState(false);

  const fetchCustomersData = async () => {
    const token = await getAccessToken();
    const response = await fetch(
      `${APIurls.fetchCustomers
      }?page=${currentPage}&limit=${recordsPerPage}&text=${debouncedSearch}&userStatus=${status === "CLEAR_ALL" ? "" : status
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    console.log("Customers List", result?.response);
    return result?.response;
  };
  const { data: customers, refetch } = useQuery(
    ["customersData", currentPage, recordsPerPage, status, debouncedSearch],
    fetchCustomersData
  );


  // Update debounced search after a delay
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 1000);

    // Clean up timer if the search term changes
    return () => clearTimeout(handler);
  }, [search]);

  // // Debounce function
  // function debounce(func, delay) {
  //   let timeoutId;
  //   // Return a new debounced function
  //   return function (input) {
  //     console.log("inside debounce return function", input);
  //     // If a timeout is already set, clear it to prevent the previous call
  //     clearTimeout(timeoutId);
  //     // Set a new timeout to call the function after the specified delay
  //     timeoutId = setTimeout(() => {
  //       // Execute the original function with the provided arguments
  //       func();
  //     }, delay);
  //   };
  // }

  // const debouncedSearch = debounce(refetch, 3000);
  // const handleSearch = (event) => {
  //   setSearch(event.target.value);
  //   debouncedSearch(event.target.value);
  // };

  const fetchAdminDetails = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.fetchAdminOrSubAdminDetails}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.message);
      }
      return result?.response?.name;
    } catch (error) {
      console.log("Error in fetching admin details", error);
      return "";
    }
  };

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        // padding: "10px 10px",
        color: "#4D4D4D",
        fontSize: "13px",
      },
    },
    head: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        // fontSize: "15px",
      },
    },

    rows: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        color: "#565656",
        padding: "5px 0px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    // pagination: {
    //    style: {
    //       boxShadow: "10px 5px 5px #ddd",
    //       marginBottom: "5px",
    //    },
    // },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1200px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };

  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      // maxWidth: "0.5rem",
      // grow: 0.3,
    },
    {
      name: "Image",
      selector: (row) =>
        row.imageUrl ? (
          <img
            src={row.imageUrl}
            alt="customer"
            className="h-[50px] w-[50px] object-cover"
          />
        ) : (
          <img
            src={gamebeezFinalLogo}
            alt="customer"
            className="h-[50px] w-[50px] object-cover"
          />
        ),
      center: true,
      grow: 1,
    },
    {
      name: "Customer ID",
      selector: (row) => <p className="">{row?.customId}</p>,
      center: true,
      grow: 3,
    },

    {
      name: "Customer Name",
      selector: (row) => row.FirstName + " " + row.LastName,
      center: true,
      grow: 3,
    },
    {
      name: "Contact Information",
      selector: (row) => (
        <div>
          <p>{row.email}</p>
          <p>{row.mobileNumber}</p>
        </div>
      ),
      // center: true,
      grow: 3.5,
    },
    {
      name: "Online Bookings",
      selector: (row) => <div>{row?.Bookings}</div>,
      center: true,
      width: "140px",
    },
    {
      name: "Offline Bookings",
      selector: (row) => <div>{row?.offlineBooking}</div>,
      center: true,
      width: "140px",
    },
    {
      name: "Status",
      selector: (row) => (
        <div
          className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
            py-[0.4rem] w-[7rem] ${row.status === "ACTIVE"
              ? "text-[#25BF17] bg-[#D1F0CF]"
              : "text-[#FF0023] bg-[#FDCAD1]"
            }`}
        >
          <span
            className={`${row.status === "ACTIVE" ? "bg-[#25BF17]" : "bg-[#FF0023]"
              } h-[0.5rem] w-[0.5rem] rounded-full`}
          ></span>
          <h2>{row.status}</h2>
        </div>
      ),
      center: true,
      sortable: true,
      width: "160px",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex gap-[0.5rem]">
          {(userRole?.role?.admin ||
            userRole?.role?.subAdmin?.permissions?.includes(
              "VIEW_CUSTOMERS"
            )) && (
              <Link to={`view/${row?.customerId}`}>
                <IconButton className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]">
                  <Icon icon="mdi:eye" className="text-[1.5rem] text-black" />
                </IconButton>
              </Link>
            )}

          {(userRole?.role?.admin ||
            userRole?.role?.subAdmin?.permissions?.includes(
              "UPDATE_CUSTOMER_STATUSES"
            )) && (
              <IconButton
                onClick={() => showModalHandler(row)}
                className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
              >
                {row.status === "ACTIVE" ? (
                  <FcCancel className="text-[1.3rem]" />
                ) : (
                  <SlCheck className="text-[1.2rem] text-green-500" />
                )}
              </IconButton>
            )}
        </div>
      ),
      center: true,
    },
  ];

  if (isRefetch) {
    refetch();
    setIsRefetch(false);
  }

  const handleDownloadPDF = async () => {
    setLoading(true);
    try {
      const pdfData = customers?.users?.map((ele, index) => [
        index + 1,
        ele?.customId,
        ele?.FirstName + " " + ele?.LastName,
        ele?.email,
        ele?.mobileNumber,
        ele?.Bookings,
        ele?.offlineBooking,
      ]);

      const pdfColumns = [
        "SL",
        "Customer ID",
        "Customer Name",
        "Email",
        "Mobile No",
        "Online Bookings",
        "Offline Bookings",
      ];

      await DownloadPDF(
        pdfData,
        pdfColumns,
        "Customers.pdf",
        adminName
      );
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setLoading(false);
    }
  };

  const excelData = customers?.users?.map((item, index) => [
    index + 1,
    item?.customId,
    item?.FirstName + " " + item?.LastName,
    item?.email,
    item?.mobileNumber,
    item?.Bookings,
    item?.offlineBooking,
  ]);

  const excelColumns = [
    "SL",
    "Customer ID",
    "Customer Name",
    "Email",
    "Mobile No",
    "Online Bookings",
    "Offline Bookings",
  ];

  const handleDirection = (direction) => {
    if (direction === "right") {
      const currPg = currentPage + 1;
      if (currentPage >= customers?.totalPages) {
        return;
      } else {
        setCurrentPage(currPg);
      }
    } else if (direction === "left") {
      const currPg = currentPage - 1;
      if (currentPage <= 1) {
        return;
      } else {
        setCurrentPage(currPg);
      }
    }
  };

  return (
    <>
      <div className="flex gap-5 items-center ">
        <div className="flex flex-col bg-[#FFF3EC] px-[1rem] py-[1rem] rounded-[4px] w-full">
          <h2 className="font-semibold">Total Customer</h2>
          <p className="text-[1.8rem] font-bold">{customers?.length || "NA"}</p>
        </div>
        <div className="flex flex-col bg-[#FFF3EC] px-[1rem] py-[1rem] rounded-[4px] w-full">
          <h2 className="font-semibold">Total Active</h2>
          <p className="text-[1.8rem] font-bold">{customers?.active || "NA"}</p>
        </div>
        <div className="flex flex-col bg-[#FFF3EC] px-[1rem] py-[1rem] rounded-[4px] w-full">
          <h2 className="font-semibold">Inactive</h2>
          <p className="text-[1.8rem] font-bold">
            {customers?.inActive !== undefined ? customers.inActive : "NA"}
          </p>
        </div>
      </div>

      {/* {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("VIEW_CUSTOMERS") || userRole?.role?.subAdmin?.permissions?.includes("UPDATE_CUSTOMER_STATUSES")) && */}
      <div className="flex flex-col gap-[0.5rem] bg-white rounded-md">
        <div className="flex justify-between items-center p-[1rem] flex-wrap gap-3">
          <div className="flex items-center justify-between">
            <h2 className="text-black text-[1.3rem] font-semibold whitespace-nowrap">
              Customers List
            </h2>
          </div>
          <div className="flex items-center gap-[2rem] justify-between w-full">
            <div className="flex items-center gap-4">
              <div className="flex py-[0.4rem] px-[1rem] bg-[#FFF] border border-[#DFDFDF] rounded-md">
                <input
                  type="text"
                  className="w-[18rem] placeholder-[#8F8F8F] text-black text-sm font-semibold outline-none"
                  placeholder="Search by Name or email or phone"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
                <Icon
                  icon="ri:search-2-fill"
                  className="p-[0.2rem] bg-primary text-white text-[1.5rem] rounded-[5px] cursor-pointer"
                />
              </div>
              <div className="flex items-center gap-[0.3rem] hover:text-primary cursor-pointer">
                <Select
                  label="Change Status"
                  value={status}
                  onChange={(value) => setStatus(value)}
                >
                  <Option value="ACTIVE" disabled={status === "ACTIVE"}>
                    ACTIVE
                  </Option>
                  <Option value="SUSPENDED" disabled={status === "SUSPENDED"}>
                    SUSPENDED
                  </Option>
                  <Option value="CLEAR_ALL" disabled={status === "CLEAR_ALL"}>
                    CLEAR ALL
                  </Option>
                </Select>
              </div>
            </div>
            <div className="flex items-center gap-6">
              <div className="flex gap-3 items-center rounded-md">
                {loading ? (
                  <p className="text-xs">Generating PDF...</p>
                ) : (
                  <img
                    src={pdfLogo}
                    className="w-[25px] cursor-pointer"
                    onClick={handleDownloadPDF}
                    alt="Generate PDF"
                  />
                )}
                <FaFileCsv
                  className="w-[25px] h-[30px] text-green-800 cursor-pointer"
                  onClick={() => DownloadExcel({ excelData, excelColumns })}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <DataTable
            columns={columns}
            customStyles={customStyles}
            data={customers?.users}
            selectableRows
            selectableRowsComponent={CustomCheckbox}
          />
          <div className="flex justify-center items-center">
            <div>
              <span>Total Users : </span>
              <span>{customers?.totalUsers}</span>
            </div>
          </div>
          <div className="flex justify-center items-center gap-2 py-2">
            {/* Left Arrow */}
            <button
              className={`${currentPage === 1
                ? "cursor-not-allowed text-gray-500"
                : "cursor-pointer"
                } rounded-md px-2 border-2`}
              onClick={() => handleDirection("left")}
            >
              <FaAngleDoubleLeft size={25} />
            </button>
            <span>
              {currentPage} /{" "}
              {Math.ceil(customers?.totalUsers / recordsPerPage)}
            </span>
            <button
              className={`${currentPage === customers?.totalPages
                ? "cursor-not-allowed text-gray-500"
                : "cursor-pointer"
                } rounded-md px-2 border-2`}
              onClick={() => handleDirection("right")}
            >
              <FaAngleDoubleRight size={25} />
            </button>
            <div className="flex items-center">
              <span>Per Page:</span>
              <select
                value={recordsPerPage}
                onChange={(e) => {
                  setRecordsPerPage(+e.target.value);
                  setCurrentPage(1);
                }}
                className="border outline-none ml-2"
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={customers?.totalUsers}>
                  {customers?.totalUsers}
                </option>
              </select>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
      {/* } */}
      {showModal3 && (
        <CustomerDisableModal
          setShowModal={setShowModal3}
          modalData={modalData3}
          setIsRefetch={setIsRefetch}
        />
      )}
    </>
  );
}
