import React from 'react'
import { Icon } from '@iconify/react'
import { Link, NavLink, Outlet, useOutletContext, useParams } from 'react-router-dom'
import { useAuth } from '../../../../../store/AuthContext';
import { APIurls } from '../../../../../api/apiConstant';
import { useQuery } from 'react-query';

export default function CustomersViewContainer() {
   const { id, customerId } = useParams();
   const { getAccessToken } = useAuth();
   const [clubName, clubID] = useOutletContext();

   //  const fetchSportsDataById = async () => {
   //     const token = await getAccessToken();
   //     // setLoaderBtn(true);
   //     const response = await fetch(`${APIurls.getClubSportById}?sport_id=${encodeURIComponent(sportId)}`, {
   //        method: 'GET',
   //        headers: {
   //           Authorization: `Bearer ${token}`,
   //        }
   //     })
   //     const result = await response.json();
   //     if (!response.ok) {
   //        // setLoaderBtn(false);
   //        throw new Error(result.message);
   //     }
   //     console.log("Sport By Id", result?.response?.sport);
   //     // setLoaderBtn(false);
   //     return result?.response?.sport;
   //  }
   //  const { data: sportsData, isLoading } = useQuery('sports', fetchSportsDataById);

   return (
      <div className='flex flex-col gap-[1rem]'>
         <div className='bg-white flex flex-col gap-[1rem] rounded-md p-[1rem]'>
            <div className='flex gap-[0.5rem] items-center text-[#808080] font-semibold'>
               <Link to={"/club-owners"}>
                  <h2 className='text-[1rem] font-semibold'>
                     Club List
                  </h2>
               </Link>
               <Icon icon="material-symbols:arrow-forward-ios" className="text-[1.1rem]" />
               <Link to={`/club-owners/view/${id}`}>
                  <h2 className='text-[1rem]'>
                     {clubName}
                     <span className='text-[#808080] text-[0.9rem] font-normal'> ({clubID}) </span>
                  </h2>
               </Link>
               <Icon icon="material-symbols:arrow-forward-ios" className="text-[1.1rem]" />
               <Link to={`/club-owners/view/${id}/customers`}>
                  <h2 className='text-[1rem]'>
                     Customers
                  </h2>
               </Link>
               <Icon icon="material-symbols:arrow-forward-ios" className="text-[1.1rem]" />
               <h2 className='text-[1.2rem] text-black font-bold'>
                  {/* Customer Name */}
                  <span className='text-[0.9rem]'> ({customerId}) </span>
               </h2>
            </div>
            <div className='flex flex-col gap-[1rem]'>
               <div className='flex gap-[2rem] text-[1.2rem] font-medium border-b-2 border-[#989898]'>
                  {/* <NavLink to={`/club-owners/view/${id}/customers/view/:customerId`} end> */}
                     {/* {({ isActive }) => ( */}
                        <h2 className={`pb-[0.3rem] "font-bold text-primary border-b-2 border-primary" : "text-[#808080]"`}>
                           Bookings
                        </h2>
                     {/* )} */}
                  {/* </NavLink> */}
                  {/* <NavLink to={`/club-owners/view/${id}/customers/view/:customerId/canteen`}>
                     {({ isActive }) => (
                        <h2 className={`pb-[0.3rem] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#808080]"}`}>
                           Canteen
                        </h2>
                     )}
                  </NavLink> */}
               </div>
               <Outlet />
            </div>
         </div>
      </div>
   )
}

