import { Select, Option, Input, ThemeProvider } from "@material-tailwind/react";
import DatePicker from "react-flatpickr";
import { useState } from "react";
import { Icon } from "@iconify/react";
import { selectCustomTheme } from "../../../../helpers/constants";
import { useNavigate } from "react-router-dom";
export default function EditCouponForm({ handleOfferTypeChange, offerType }) {
  const navigate = useNavigate()
  const [startDate, setStartDate] = useState(new Date());
  return (
    <ThemeProvider value={selectCustomTheme}>
      <form>
      {/*Form Header  */}
      <div className="flex items-center justify-between ml-[-10px] mb-[-15px]">
        <h1 className="text-[1.2rem] sm:text-[1.3rem] font-semibold">
          Edit Offer (Coupon)
        </h1>
        <div className="flex gap-[3rem] items-center">
          <button
            onClick={() => navigate("/offersAndBanners/coupons")}
            className={`flex items-center justify-center gap-[0.4rem] text-[1rem] font-semibold text-primary bg-white border
                border-primary py-[0.35rem] w-[6rem] rounded-[5px]`}
            type="button"
          >
            <Icon
              icon="material-symbols:arrow-back-ios"
              className="text-[1rem]"
            />
            <span>Back</span>
          </button>

          <button
            type="submit"
            className="text-[0.8rem] sm:text-[1rem] flex items-center max-xs:w-full gap-[0.3rem] xs:gap-[0.4rem] bg-primary text-white py-[0.5rem] px-[0.4rem] xs:px-[1rem] rounded-md font-medium"
          >
            <span className="whitespace-nowrap">Update</span>
            <Icon
              icon="material-symbols:add"
              className="text-[1.2rem] sm:text-[1.4rem]"
            />
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-[90%] ml-[20px] my-[2rem]">
        <div className="space-y-[1.5rem]">
          <Select
            className="h-[2.63rem] border-0 bg-[#EFF1F999] text-black"
            size="lg"
            label="Select All Clubs"
            color="blue-gray"
          >
            <Option>Material Tailwind HTMLss</Option>
            <Option>Material Tailwind React</Option>
            <Option>Material Tailwind Vue</Option>
            <Option>Material Tailwind Angular</Option>
            <Option>Material Tailwind Svelte</Option>
          </Select>

          <div>
            <input
              className="h-[2.63rem] text-[0.75rem] sm:text-[1rem] px-[1rem] font-medium bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none w-full"
              type="text"
              placeholder="Title"
            />
          </div>

          <Select
            className="h-[2.63rem] border-0 bg-[#EFF1F999] text-black"
            size="lg"
            label="Sub Category"
          >
            <Option>Material Tailwind HTML</Option>
            <Option>Material Tailwind React</Option>
            <Option>Material Tailwind Vue</Option>
            <Option>Material Tailwind Angular</Option>
            <Option>Material Tailwind Svelte</Option>
          </Select>

          <div className="relative">
            <DatePicker
              className="h-[2.63rem] outline-none placeholder-[#7F7F7F] rounded-lg pl-3 text-[1rem] font-medium text-black bg-[#EFF1F999] w-full"
              placeholder="Expiry Date"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
            <Icon className="absolute right-3 top-[0.8rem]" icon="uiw:date" />
          </div>

          <div>
            <input
              className="h-[2.63rem] text-[0.75rem] sm:text-[1rem] font-medium px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none w-full"
              type="number"
              placeholder="Discount Amount"
            />
          </div>
        </div>

        {/* column 2  */}
        <div className="space-y-[1.5rem]">
        <div>
            <input
              className="h-[2.63rem] text-[0.75rem] sm:text-[1rem] font-medium px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none w-full"
              type="text"
              value="Coupon"
              readOnly
            />
        </div>

          <Select
            className="h-[2.63rem] border-none bg-[#EFF1F999] text-black"
            size="lg"
            label="Main Category"
          >
            <Option>Material Tailwind HTML</Option>
            <Option>Material Tailwind React</Option>
            <Option>Material Tailwind Vue</Option>
            <Option>Material Tailwind Angular</Option>
            <Option>Material Tailwind Svelte</Option>
          </Select>

          <div className="relative">
            <DatePicker
              className="h-[2.63rem] outline-none placeholder-[#7F7F7F] rounded-lg pl-3 text-[1rem] font-medium text-black bg-[#EFF1F999] w-full"
              placeholder="Start Date"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
            <Icon className="absolute right-3 top-[0.8rem]" icon="uiw:date" />
          </div>

          <Select
            className="h-[2.63rem] border-none bg-[#EFF1F999] text-black"
            color="blue-gray"
            size="lg"
            label="Discount Type"
          >
            <Option>Material Tailwind HTML</Option>
            <Option>Material Tailwind React</Option>
            <Option>Material Tailwind Vue</Option>
            <Option>Material Tailwind Angular</Option>
            <Option>Material Tailwind Svelte</Option>
          </Select>

          <div>
            <input
              className="h-[2.63rem] text-[0.75rem] sm:text-[1rem] font-medium px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none w-full"
              type="number"
              placeholder="Maximum Amount"
            />
          </div>
        </div>
      </div>
      </form>
    </ThemeProvider>
  );
}
