import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import OwnerInfo from "./OwnerInfo";
import ClubInfo from "./ClubInfo";
import LocationOperating from "./LocationOperating";
import SportsAmenties from "./SportsAmenties";
import StepperController from "../../../helpers/StepperController";
import HeaderStepper from "../../../helpers/HeaderStepper";
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  updateClubInfo,
  updateClubLoaderBtn,
  updateLocationOperating,
  updateOwnersInfo,
  updateSportsAmenities,
} from "../../../store/createClubSlice";

export default function CreateClub() {
  const [currentStep, setCurrentStep] = useState(1);
  const [steps, setSteps] = useState([
    "Owner Information",
    "Club Information",
    "Location and Operating Hours",
    "Sports and Amenities",
  ]);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const [allShowError, setAllShowError] = useState([
    { name: "Owner Information", showError: false },
    { name: "Club Information", showError: false },
    { name: "Location and Operating", showError: false },
    { name: "Sports Amenities", showError: false },
  ]);

  const {
    ownerName,
    adhaarNumber,
    mobileNumber,
    panNumber,
    email,
    dob,
    ownerImage,
  } = useSelector((store) => store.createClub.ownerInfo);
  const {
    clubName,
    businessPanNumber,
    businessCategory,
    businessEmail,
    openingDate,
    clubImagesArray,
  } = useSelector((store) => store.createClub.clubInfo);
  const {
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    pincode,
    selectedTimingsData,
    timingSlotsData,
  } = useSelector((store) => store.createClub.locationOperating);
  const { facilities, categoriesData } = useSelector(
    (store) => store.createClub.sportsAmenities
  );
  const dispatch = useDispatch();
  const onlyClubImagesArray = clubImagesArray.map(
    (clubImage) => clubImage.imgFile
  );
  // console.log("ONLY IMAGES", ownerImage, onlyClubImagesArray);

  const { getAccessToken } = useAuth();

  const displayStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <OwnerInfo
            currentStep={currentStep}
            steps={steps}
            handleClick={handleClick}
            showError={showError}
            setShowError={setShowError}
            error={error}
            setError={setError}
          />
        );
      case 2:
        return (
          <ClubInfo
            currentStep={currentStep}
            steps={steps}
            handleClick={handleClick}
            showError={showError}
            setShowError={setShowError}
            error={error}
            setError={setError}
          />
        );
      case 3:
        return (
          <LocationOperating
            currentStep={currentStep}
            steps={steps}
            handleClick={handleClick}
            showError={showError}
            setShowError={setShowError}
            error={error}
            setError={setError}
          />
        );
      case 4:
        return (
          <SportsAmenties
            currentStep={currentStep}
            steps={steps}
            handleClick={handleClick}
          />
        );
      default:
        break;
    }
  };

  const handleClick = async (direction) => {
    let newStep = currentStep;
    if (direction === "next") {
      newStep++;
    } else if (direction === "back") {
      newStep--;
    } else if (direction === "create") {
      createClubOwner();
    }

    if (newStep > 0 && newStep <= steps.length) {
      setCurrentStep(newStep);
    }
  };

  async function createClubOwner() {
    console.log("CREATE CLUB OWNER");
    const token = await getAccessToken();
    const formData = new FormData();
    formData.append("Email", email);
    formData.append("Mobile", mobileNumber);
    formData.append(
      "OwnersData",
      JSON.stringify({
        Fullname: ownerName,
        dob: dob,
      })
    );
    formData.append(
      "Kyc_detailss",
      JSON.stringify({
        kyc_completed: true,
        Pan_details: { panNumber: panNumber, issuedDate: "2022-01-01" },
        Aadhar_details: {
          aadharNumber: adhaarNumber,
          issuedDate: "2022-01-01",
        },
      })
    );
    formData.append("Club_profile_img", ownerImage);

    formData.append(
      "Club_detailss",
      JSON.stringify({
        Pan_details: { panNumber: businessPanNumber, issuedDate: "2022-01-01" },
        business_category: "Sports Club",
        business_email: businessEmail,
        openingDate: openingDate,
        SizeOfClub: "Large",
      })
    );

    console.log("FORM DATA", onlyClubImagesArray);
    onlyClubImagesArray?.forEach((file, index) => {
      formData.append(`Club_slider_img`, file);
    });
    // formData.append("Club_slider_img", onlyClubImagesArray);
    formData.append("Facilitiess", JSON.stringify(facilities));
    formData.append("Categories_lists", JSON.stringify(categoriesData));
    formData.append(
      "location_operating",
      JSON.stringify({
        address_line1: addressLine1,
        address_line2: addressLine2,
        City: city,
        state: state,
        Country: country || "India",
        Pincode: pincode,
        Add_Happy: "Happy Club",
      })
    );
    formData.append("club_name", clubName);
    formData.append(
      "timingType",
      selectedTimingsData === "Every Day" ? "EVERY_DAY" : "CUSTOM"
    );
    formData.append(
      selectedTimingsData === "Every Day"
        ? "EveryDays"
        : "dailyAvailableTimingss",
      JSON.stringify(timingSlotsData)
    );
    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        // "Content-Type": "application/json"
      },
    };

    try {
      dispatch(updateClubLoaderBtn(true));
      const response = await fetch(APIurls.createClubOwner, requestOptions);
      if (!response.ok) {
        dispatch(updateClubLoaderBtn(false));
        if (response.status === 400) {
          const errorData = await response.json();
          console.log("Error Data:", errorData);
          toast.error(errorData.message);
          throw new Error(errorData.message);
        } else {
          toast.error("Failed to Create Club Owner");
          throw new Error("Failed to Create Club Owner");
        }
      }
      const result = await response.json();
      console.log("Create Club Owner Response:::", result);
      toast.success("Club created Created Successfully!");
      dispatch(updateClubLoaderBtn(false));
      setTimeout(() => navigate("/club-owners"), 2500);
      // clear all fields
      dispatch(
        updateOwnersInfo({
          ownerName: "",
          adhaarNumber: "",
          mobileNumber: "",
          panNumber: "",
          email: "",
          dob: "",
          ownerImage: "",
        })
      );
      dispatch(
        updateClubInfo({
          clubName: "",
          businessPanNumber: "",
          businessCategory: "",
          businessEmail: "",
          openingDate: "",
          clubImagesArray: [],
        })
      );
      dispatch(
        updateLocationOperating({
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          country: "",
          pincode: "",
        })
      );
      dispatch(
        updateSportsAmenities({
          facilities: [],
          categoriesData: "",
        })
      );
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <div className="bg-white rounded-md px-[1.5rem] py-[1rem] h-full">
      <div className="flex flex-col gap-[1.5rem]">
        <div className="flex items-center justify-between">
          <div className="flex gap-[0.2rem] items-center text-[#808080] font-semibold">
            <Link to={"/club-owners"}>
              <h2>Club Owner</h2>
            </Link>
            <Icon
              icon="material-symbols:arrow-forward-ios"
              className="text-[1.3rem]"
            />
            <h2 className="text-black text-[1.3rem]">Add New Club</h2>
          </div>
          <div>
            <StepperController
              steps={steps}
              currentStep={currentStep}
              handleClick={handleClick}
              setShowError={setShowError}
              error={error}
            />
          </div>
        </div>
        <div>
          <HeaderStepper steps={steps} currentStep={currentStep} />
        </div>
        <div className="my-[1rem]">{displayStep()}</div>
      </div>
      <ToastContainer />
    </div>
  );
}
