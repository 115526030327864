import { useState } from "react";
import { IconButton } from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import { PiGreaterThan } from "react-icons/pi";
import { useQuery } from "react-query";
import { useAuth } from "../../../../store/AuthContext";
import { toast } from "react-toastify";
import { APIurls } from "../../../../api/apiConstant";

export default function EditQuarterFinalModal({
  roundsModal,
  roundID,
  roundRefetch,
  setIsRefetch
}) {
  const { getAccessToken } = useAuth();
  const [showError, setShowError] = useState("");
  const [participants, setParticipants] = useState([]);
  const [matches, setMatches] = useState([]);
  const [defaultData, setDefaultData] = useState([]);
  const [dropList, setDropList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showFields, setShowFields] = useState([]);

  const getRoundAndMatchesData = async () => {
    try {
      const token = await getAccessToken();
      setLoading(true);
      let responseData = await fetch(
        `${APIurls.fetchRoundModalDataToArranged}/${roundID}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      let response = await responseData.json();
      console.log("getting response data : ", response);
      console.log("getting response code : ", response.code);
      if(response?.code > 201)
        {
          console.log('in side if condition ',response?.code,typeof response.code);
          toast.error(response?.message);
          setLoading(false);
          roundsModal(false);
          return ;
        }
      setParticipants(response.response.participants);
      setMatches(response.response.roundMatches);
      let missingTeams = [];
      const arr = response.response.roundMatches?.map((match, index) => {
        missingTeams[index] = {
          teamA: null,
          teamB: null,
        };
        return {
          index: index,
          teamA: match?.teamA,
          teamB: match?.teamB,
        };
      });
      setShowFields(missingTeams);
      const teamList = response.response.participants?.map(
        (team) => team?.name
      );
      setDropList(teamList);
      setDefaultData(arr);
      console.log("participants in model: ", participants);
      console.log("matches in model : ", matches);
      setLoading(false);
      return response.response;
    } catch (error) {
      setLoading(false);
      console.log("error in manual fixingType model " + error?.message);
      setShowError(error?.message);
    }
  };

  const {
    data: manualFixingData,
    isLoading,
    refetch,
  } = useQuery("manualFixingModel", getRoundAndMatchesData);

  const onSetData = (value, index, position, listIndex) => {
    console.log("onSet params: ", value, index, position, listIndex);
    if (value.length === 0) {
      return;
    }

    var updatedDefaultData = [];
    setDefaultData((prevState) => {
      const arr = JSON.parse(JSON.stringify(prevState));
      let missingTeams =JSON.parse(JSON.stringify(showFields));
      arr?.forEach((data,index) => {
        if (data?.teamA === value) {
          data.teamA = null;
        }
        if (data?.teamB === value) {
          data.teamB = null;
        }
      });
      if (position === "A") {
        arr[index].teamA = value;
        missingTeams[index].teamA = false
      }
      if (position === "B") {
        arr[index].teamB = value;
        missingTeams[index].teamB = false;
      }
      updatedDefaultData = [...arr];
      setShowFields(missingTeams);
      const updatedTeams = [];
      updatedDefaultData.forEach((data) => {
        if (data?.teamA) {
          updatedTeams.push(data?.teamA);
        }
        if (data?.teamB) {
          updatedTeams.push(data?.teamB);
        }
      });
      console.log("list teams : ", updatedTeams);
      console.log("default updated in on set : ", updatedDefaultData);
      setDropList([...updatedTeams]);
      return [...arr];
    });
  };

  const setArrangeTeamsIntoMatches = async () => {
    try {
      let missingTeams = [];
      let pendingFields = false;
      defaultData.forEach((team, index) => {
        missingTeams[index] = {};
        if (team.teamA === null) {
          missingTeams[index].teamA = true;
          pendingFields = true;
        }
        if (team?.teamB === null) {
          missingTeams[index].teamB = true;
          pendingFields = true;
        }
      });
      if (pendingFields) {
        console.log("missing Teams : ", missingTeams);
        setShowFields(missingTeams);
        return;
      }
      if (dropList.length !== participants.length) {
        console.log("teams are not properly arrange ", dropList);
        return;
      }
      console.log("arranged teams : ", dropList);
      let dropListIDS = dropList?.map((name) => {
        let index = 0;
        participants?.forEach((participant, ind) => {
          if (participant.name === name) {
            index = ind;
          }
        });
        return participants[index].id;
      });
      console.log("participants ids : ", participants);
      console.log("dropList ids : ", dropListIDS);
      const token = await getAccessToken();
      setLoading(true);
      const response = await fetch(
        `${APIurls.setArrangedTeamsIntoMatches}/${roundID}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ participantsArr: dropListIDS }),
        }
      );
      const responseData = await response.json();
      console.log("set response Data : ", responseData);
      if(responseData?.code > 201)
      {
        toast.error(responseData?.message);
        setLoading(false);
        return;
      }else{
        toast.success("Teams Arranged Successfully");
        setLoading(false);
        roundsModal(false);
        // roundRefetch();
        setIsRefetch(true);
      }
    } catch (error) {
      console.log("Error in fixingType : ", error?.message);
      setLoading(false);
      setShowError(error?.message);
    }
  };

  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-[#000000E6]">
      <div className="flex flex-col gap-4 bg-white rounded-sm px-6 py-8 w-[30%] h-[60%] overflow-y-auto relative max-xl:w-[380px]">
        <div className="flex justify-between items-center">
          <h1 className="text-[20px] font-bold">{manualFixingData?.name}</h1>
          <IconButton
            onClick={() => roundsModal(false)}
            className={
              "shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-sm"
            }
            size="sm"
          >
            <Icon icon="material-symbols:close" className="text-[1.5rem]" />
          </IconButton>
        </div>
        {loading && (
          <h1 className="text-center text-2xl font-bold">
            Loading...
          </h1>
        )}
        <div className="w-[100%] overflow-x-auto">
          {!loading && setDefaultData.length > 0 && (
            <div className="">
              {matches?.length > 0 &&
                matches?.map((match, index) => (
                  <div
                    key={index}
                    className="flex gap-[1.5rem] items-center justify-center"
                  >
                    <div className="flex flex-col">
                      <select
                        defaultValue={defaultData[index].teamA}
                        disabled={match?.teamA ? false : true}
                        onChange={(e) =>
                          onSetData(e.target.value, index, "A", index * 2)
                        }
                        className="w-[125px] px-1 py-2 mt-2 text-gray-700 border border-gray-300 rounded-md shadow-sm"
                      >
                        <option className={`bg-lime-200 text-slate-500`}>
                          {defaultData[index].teamA}
                        </option>
                        {participants.length > 0 &&
                          participants.map((participant) => (
                            <option
                              key={participant?.id}
                              className={` ${
                                dropList.includes(participant?.name)
                                  ? "text-gray-500 font-semibold"
                                  : "text-black font-semibold"
                              }`}
                            >
                              {participant?.name}
                            </option>
                          ))}
                      </select>
                      <div>
                        <p className="text-xs h-[20px] text-red-700">
                          {showFields[index].teamA &&
                            "*This field is required."}
                        </p>
                      </div>
                    </div>
                    <p className="">VS</p>
                    <div className="flex gap-[5px] flex-col">
                      <select
                        defaultValue={defaultData[index].teamB}
                        disabled={match?.teamB ? false : true}
                        onChange={(e) =>
                          onSetData(e.target.value, index, "B", index * 2 + 1)
                        }
                        className={`${
                          !match?.teamB && "cursor-not-allowed"
                        } w-[125px] px-1 py-2 mt-2 text-gray-700 border border-gray-300 rounded-md shadow-sm`}
                      >
                        <option className={`bg-lime-200 text-slate-500`}>
                          {defaultData[index].teamB}
                        </option>
                        {participants.length > 0 &&
                          participants.map((participant) => (
                            <option
                              key={participant?.id}
                              className={` ${
                                dropList.includes(participant?.name)
                                  ? "text-gray-500 font-semibold"
                                  : "text-black font-bold"
                              }`}
                            >
                              {participant?.name}
                            </option>
                          ))}
                      </select>
                      <div>
                        <p className="text-xs h-[20px] text-red-700">
                          {showFields[index].teamB &&
                            "*This field is required."}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
        <div
          onClick={() => setArrangeTeamsIntoMatches()}
          className="w-[100px] flex items-center justify-center text-white bg-[#FB6108] px-3 py-2 cursor-pointer rounded-md gap-1"
        >
          <button
            type="button"
            className="text-base font-semibold"
          >
            Save
          </button>
          <PiGreaterThan />
        </div>
      </div>
    </div>
  );
}