import React, { useState, useEffect } from 'react'
import { Icon } from '@iconify/react'
import { Link, NavLink, Outlet, useParams } from 'react-router-dom'
import { useAuth } from '../../../../store/AuthContext'
import { APIurls } from '../../../../api/apiConstant'
import { useQuery } from 'react-query'
import moment from 'moment'
import { RotatingLines } from 'react-loader-spinner'
import UpdateEmailModal from './UpdateEmailModal'
import { MdModeEdit } from "react-icons/md";

export default function ViewClubOwnerContainer() {
  const { id } = useParams();
  // const [loaderBtn, setLoaderBtn] = useState(false);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }, [])

  const { getAccessToken } = useAuth();
  const [updateEmailModal, setUpdateEmailModal] = useState(false);

  const fetchClubOwnerById = async () => {
    const token = await getAccessToken();
    // setLoaderBtn(true);
    const response = await fetch(`${APIurls.fetchClubById}?ClubOwnerID=${encodeURIComponent(id)}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    const result = await response.json();
    if (!response.ok) {
      // setLoaderBtn(false);
      throw new Error(result.message);
    }
    console.log("View Club Owner By ID", result?.response?.clubOwner);
    // setLoaderBtn(false);
    return result?.response?.clubOwner;
  }
  const { data: clubOwnerDetails, isLoading, refetch } = useQuery(['viewClubOwnerSportsData', id], fetchClubOwnerById);

  // if (loaderBtn) {
  //    return (
  //       <div className='flex items-center justify-center'>
  //          <RotatingLines
  //             visible={true}
  //             height="120"
  //             width="120"
  //             strokeColor='#FB6108'
  //             strokeWidth="5"
  //             animationDuration="0.75"
  //          />
  //       </div>
  //    )
  // }

  const handleUpdateEmailModal = () => {
    setUpdateEmailModal(true);
  }

  if (isLoading) {
    return (
      <div class="flex items-center justify-center pt-4 min-h-screen">
        <div class="w-10 h-10 border-4 border-t-orange-500 border-gray-200 rounded-full animate-spin"></div>
      </div>
    );
  }


  return (
    <div className='flex flex-col gap-[1rem]'>
      <div className='flex gap-[3rem]'>
        <div className='h-[250px] bg-[#FFF3EC] rounded-xl p-[1rem] overflow-auto'>
          <div className='flex flex-col gap-[0.8rem] items-center'>
            <div className='flex gap-[1.5rem]'>
              <div className='flex flex-col justify-between items-center'>
                <img src={clubOwnerDetails?.Club_profile_img} alt="" className='h-[5rem] w-[5rem] rounded-full' />
                <div>
                  <h2 className='text-[1.2rem] font-semibold'>
                    {clubOwnerDetails?.OwnerData?.Fullname || "NA"}
                  </h2>
                  <p className='text-[#808080] text-[0.9rem] font-medium leading-4'>
                    Club Owner
                  </p>
                </div>
              </div>
              <div className='flex flex-col items-start justify-between'>
                <div>
                  <label className='text-[#808080] text-[0.9rem] font-normal'>
                    Mobile Number
                  </label>
                  <h2 className='text-[1.2rem] font-normal'>
                    {clubOwnerDetails?.Mobile || "NA"}
                  </h2>
                </div>
                <div>
                  <label className='text-[#808080] text-[0.9rem] font-normal'>
                    Email
                  </label>
                  <h2 className='text-[1.2rem] font-normal'>
                    {clubOwnerDetails?.Email || "NA"}
                  </h2>
                </div>
              </div>
              <div className='flex flex-col items-start justify-between'>
                <div>
                  <label className='text-[#808080] text-[0.9rem] font-normal'>
                    Date of Birth
                  </label>
                  <h2 className='text-[1.2rem] font-normal'>
                    {moment(clubOwnerDetails?.OwnerData?.dob).format("DD/MM/YYYY") || "NA"}
                  </h2>
                </div>
                <div>
                  <label className='text-[#808080] text-[0.9rem] font-normal'>
                    Date of Onboarding
                  </label>
                  <h2 className='text-[1.2rem] font-normal'>
                    {moment(clubOwnerDetails?.createdAt).format("DD/MM/YYYY") || "NA"}
                  </h2>
                </div>
                <div>
                  <label className='text-[#808080] text-[0.9rem] font-normal'>
                    Date of Opening
                  </label>
                  <h2 className='text-[1.2rem] font-normal'>
                    {moment(clubOwnerDetails?.Club_details?.openingDate).format("DD/MM/YYYY") || "NA"}
                  </h2>
                </div>
              </div>
            </div>
            <div className='flex items-center justify-center bg-primary text-white py-[0.5rem] rounded-md w-full'>
              <button
                className='flex items-center gap-[0.6rem]'
              >
                <Icon icon="ic:round-edit" className='text-[1.4rem]' />
                <Link to={`/club-owners/edit/${clubOwnerDetails?.ClubOwnerID}`}>
                  <span className='font-medium'>
                    Edit Club Details
                  </span>
                </Link>
              </button>
            </div>
          </div>
        </div>
        <div className='h-[250px] flex flex-col justify-center items-center gap-[1.5em] bg-[#FFF3EC] rounded-xl px-5 py-[1.5rem]'>
          <div className='flex flex-col gap-2'>
            <label
              className="text-[#808080] text-[0.9rem] font-normal"
            >
              Email
            </label>
            <p>{clubOwnerDetails?.Email}</p>
          </div>
          <button
            className='w-full flex items-center justify-center gap-1 text-white bg-primary rounded-md px-3 py-2'
            onClick={handleUpdateEmailModal}
          >
            <MdModeEdit className='text-[1.3rem]' />
            Update Email
          </button>
        </div>
      </div>
      <Outlet context={[clubOwnerDetails?.ClubName, clubOwnerDetails?.ClubID, clubOwnerDetails?._id]} />
      {updateEmailModal && (
        <UpdateEmailModal
          setUpdateEmailModal={setUpdateEmailModal}
          clubOwnerMongoId={clubOwnerDetails?._id}
          refetch={refetch}
        />
      )}
    </div>
  )
}
