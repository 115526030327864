import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Button, IconButton, Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react';
import moment from 'moment';
import { Icon } from '@iconify/react';
import DataTable from 'react-data-table-component';
import CustomCheckbox from '../../../../../helpers/CustomCheckbox';
import { APIurls } from '../../../../../api/apiConstant';
import { useAuth } from '../../../../../store/AuthContext';
import { useQuery } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';

export default function CustomersViewCanteen() {
   const { id } = useParams();

   const customStyles = {
      headRow: {
         style: {
            fontWeight: 600,
            color: "#4D4D4D",
            fontSize: "13px",
         },
      },
      head: {
         style: {
            background: "#FFF",
         },
      },

      rows: {
         style: {
            background: "#FFF",
            color: "#565656",
            fontSize: "14px",
            fontWeight: 400,
            width: "auto",
            padding: "5px 0px"
         },
      },
      table: {
         style: {
            overflow: "visible",
            minWidth: "1100px",
         },
      },
      tableWrapper: {
         style: {
            overflow: "visible",
         },
      },
      responsiveWrapper: {
         style: {
            overflowX: "auto", // table scroll on x axis
         },
      },
   };
   const columns = [
      {
         name: "SL",
         id: "sl",
         selector: (row, index) => index + 1,
         center: true,
         maxWidth: "0.5rem",
      },
      {
         name: 'Order ID',
         id: "orderId",
         selector: row => row.orderId,
         center: true,
         grow: 1,
      },
      {
         name: 'Date & Time',
         id: "bookingDateTime",
         selector: row => "28/07/2023 - 05:38 PM",
         center: true,
         grow: 2,
      },
      {
         name: 'Customer Name',
         id: "customerName",
         selector: row => "Mustaq",
         center: true,
         grow: 1,
      },
      {
         name: 'Mobile Number',
         id: "mobileNumber",
         selector: row => "9876543210",
         center: true,
      },
      {
         name: 'Items',
         id: "items",
         selector: row => "05",
         center: true,
      },
      {
         name: 'Total Amount',
         id: "totalAmount",
         selector: row => "₹ 1250",
         center: true,
      },
      {
         name: "Status",
         id: "status",
         selector: (row) => (
            <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
            py-[0.3rem] w-[5.5rem] ${row.isActive !== "ACTIVE" ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"}`}>
               <h2>Paid</h2>
            </div>
         ),
         center: true,
         grow: 1,
         sortable: true,
      },
      {
         name: "Actions",
         id: "action",
         cell: (row) => (
            <div className="flex gap-[0.5rem]">
               <Link to={`view/:orderId`}>
                  <IconButton
                     className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
                  >
                     <Icon
                        icon="mdi:eye"
                        className="text-[1.5rem] text-black"
                     />
                  </IconButton>
               </Link>
               <Link to={``}>
                  <IconButton
                     className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
                  >
                     <Icon
                        icon="material-symbols:print"
                        className="text-[1.5rem] text-black"
                     />
                  </IconButton>
               </Link>
            </div>
         ),
         center: true,
         grow: 1,
      },
   ];
   const data = [
      {
         orderId: "12345",
      },
      {
         orderId: "12345",
      },
      {
         orderId: "12345",
      },
      {
         orderId: "12345",
      },
   ];

   return (
      <div className='bg-white rounded-md flex flex-col gap-[0.5rem]'>
         <div className='flex items-center justify-between'>
            <h2 className='text-[1.3rem] font-semibold'>
               Bookings
            </h2>
            <div>
               <div className='flex items-center gap-[2rem]'>
                  <div className='flex items-center gap-[0.4rem] hover:text-primary cursor-pointer'>
                     <Icon
                        icon='material-symbols:delete'
                        className='text-[1.5rem]'
                     />
                     <span className='text-[1rem] font-medium'>Remove</span>
                  </div>
                  <div className='flex items-center gap-[0.3rem] hover:text-[#FB6108] cursor-pointer'>
                     <p className='text-[1rem] font-medium'>Change Status</p>
                     <Icon
                        icon='mingcute:down-line'
                        className='text-[1.5rem]'
                     />
                  </div>
                  <div className="flex flex-row gap-[0.5rem] xs:gap-[1rem] items-center justify-center border border-[#CBCBCB] rounded-[5px]">
                     <div className="">
                        <Menu>
                           <MenuHandler>
                              <Button className="text-[0.8rem] sm:text-[1rem] flex w-full flex-row justify-center whitespace-nowrap capitalize text-black bg-white px-[0.4rem] xs:px-[1rem] py-[0.4rem] rounded-md items-center gap-[0.5rem] sm:gap-[0.8rem] hover:shadow-none shadow-none">
                                 <Icon
                                    icon="mdi:cloud-download"
                                    className="text-[1.5rem]"
                                 />
                                 <span className="font-[500]">Export</span>
                              </Button>
                           </MenuHandler>
                           <MenuList>
                              <MenuItem
                                 //  onClick={downloadPdf}
                                 className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                              >
                                 {" "}
                                 <Icon
                                    icon="fa-solid:file-pdf"
                                    className="text-[#BE0A23] w-[1rem] h-auto"
                                 />
                                 Download as PDF
                              </MenuItem>
                              <hr className="my-[0.1rem]" />
                              <MenuItem
                                 //  onClick={downloadCSV}
                                 className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                              >
                                 <Icon
                                    icon="fa-solid:file-csv"
                                    className="text-[#29672E] w-[1rem] h-auto"
                                 />
                                 Download as CSV
                              </MenuItem>
                           </MenuList>
                        </Menu>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div>
            <DataTable
               columns={columns}
               customStyles={customStyles}
               data={data}
               pagination
               selectableRows
               // progressPending={isLoading}
               selectableRowsComponent={CustomCheckbox}
            />
         </div>
         <ToastContainer />
      </div>
   )
}




