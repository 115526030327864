import React, { useState, useEffect } from "react";
import { Checkbox, Switch, ThemeProvider } from "@material-tailwind/react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import "./style.css";
import { checkboxCustomTheme } from "../../../helpers/constants";
import basketballLogo from "../../../assets/sports/basketball.svg";
import football from "../../../assets/sports/football.svg";
import cricket from "../../../assets/sports/cricket.svg";
import handball from "../../../assets/sports/Handball.svg";
import futsal from "../../../assets/sports/Futsal.svg";
import volleyball from "../../../assets/sports/Volleyball.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCatSubCatData,
  updateSportsAmenities,
} from "../../../store/createClubSlice";
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";
import { useQuery } from "react-query";
import { RotatingLines } from "react-loader-spinner";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  );
}

// const FACILITIES = {
//   "Wheelchair Accessibility": "Wheelchair Accessibility",
//   "Parking Area": "Parking Area",
//   "LGBTQ Friendly": "LGBTQ Friendly",
//   "Male Restrooms": "Male Restrooms",
//   "Female Restrooms": "Female Restrooms",
//   "Changing Rooms": "Changing Rooms",
//   "Drinking Water": "Drinking Water",
//   "Waiting Area": "Waiting Area",
//   "Canteen": "Canteen",
//   "Pantry": "Pantry",
// }

export default function SportsAmenties() {
  const { facilities, categoriesData } = useSelector(
    (store) => store.createClub.sportsAmenities
  );
  const [facilitiesArray, setFacilitiesArray] = useState(facilities);
  const [categoriesList, setCategoriesList] = useState([]);
  const [FACILITIES, setFACILITIES] = useState([]);
  const [loaderBtn, setLoaderBtn] = useState(false);
  // const [dropDownLoader, setDropDownLoader] = useState(false);
  //const { categoriesData2 } = useSelector((store) => store.createClub.sportsAmenities);
  const dispatch = useDispatch();
  const { getAccessToken } = useAuth();

  const [open, setOpen] = useState(1);

  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  // console.log(Object.keys(FACILITIES));
  const fetchActiveAmenitiesData = async () => {
    const token = await getAccessToken();
    // setLoaderBtn(true);
    const response = await fetch(`${APIurls.fetchActiveAmenities}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    // console.log("Amenities", result?.response?.amenities);
    // setLoaderBtn(false);
    setFACILITIES(result?.response?.amenities);
    return result?.response?.amenities.reverse();
  };
  const { data: activeAmenitiesData, isLoading: amenitiesLoading } = useQuery(
    "activeAmenities",
    fetchActiveAmenitiesData
  );

  console.log("ACTIVE AMENITIES", activeAmenitiesData);

  const fetchCategoriesData = async () => {
    const token = await getAccessToken();
    // setDropDownLoader(true);
    const response = await fetch(`${APIurls.fetchCategories}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    // setDropDownLoader(false);
    return result?.response?.CatArray;
  };
  const { data: categories, isLoading: categoriesLoading } = useQuery(
    "categoriesData",
    fetchCategoriesData
  );

  const switchToggleHandler = (providedFacility) => {
    if (facilitiesArray.includes(providedFacility)) {
      setFacilitiesArray((prevFacilitiesArray) => {
        const updatedFacilities = prevFacilitiesArray.filter(
          (facility) => facility !== providedFacility
        );
        dispatch(updateSportsAmenities({ facilities: updatedFacilities }));
        return updatedFacilities;
      });
    } else {
      setFacilitiesArray((prevFacilitiesArray) => {
        const updatedFacilities = [...prevFacilitiesArray, providedFacility];
        dispatch(updateSportsAmenities({ facilities: updatedFacilities }));
        return updatedFacilities;
      });
    }
  };
  console.log("Facilities Array", facilitiesArray);

  const onCheckCategoriesHandler = (e, catId, subCatId, key) => {
    // console.log("CHECK KAR", e.target.checked);
    // console.log(catId, subCatId, key);
    const categoryIndex = categoriesList.findIndex(
      (item) => item.category_id === catId
    );
    // let newData;
    if (e.target.checked) {
      if (categoryIndex !== -1) {
        setCategoriesList((prevCategoriesList) => {
          const newData = [...prevCategoriesList];
          const subCategoryIndex = newData[
            categoryIndex
          ].sub_categories.findIndex(
            (subCategory) => subCategory.sub_id === subCatId
          );
          if (subCategoryIndex === -1) {
            // Subcategory doesn't exist, add it
            newData[categoryIndex].sub_categories.push({
              key: key,
              sub_id: subCatId,
            });
          } else {
            // Subcategory already exists, update the key or handle as needed
            newData[categoryIndex].sub_categories[subCategoryIndex].key = key;
          }

          try {
            console.log("Dispatching new data:", categoriesData);
            const arr = [
              {
                category_id: "658028e536c4dd43473eed94",
                sub_categories: [
                  {
                    key: 0,
                    sub_id: "65802e3ed16be9219764910f",
                  },
                ],
              },
            ];
            console.log(Array.isArray(newData), newData);
            dispatch(updateCatSubCatData(JSON.stringify(newData)));
            console.log("Dispatching new data:", categoriesData);
          } catch (error) {
            console.error("Error dispatching new data:", error);
          }
          return newData;
        });
      } else {
        setCategoriesList((prevCategoriesList) => {
          const newData = [
            ...prevCategoriesList,
            {
              category_id: catId,
              sub_categories: [
                {
                  key: key,
                  sub_id: subCatId,
                },
              ],
            },
          ];

          try {
            console.log("Dispatching new data:", categoriesData);
            console.log(Array.isArray(newData), newData);
            const arr = [
              {
                category_id: "658028e536c4dd43473eed94",
                sub_categories: [
                  {
                    key: 0,
                    sub_id: "65802e3ed16be9219764910f",
                  },
                ],
              },
            ];
            dispatch(updateCatSubCatData(JSON.stringify(newData)));
            console.log("Dispatching new data:", categoriesData);
          } catch (error) {
            console.error("Error dispatching new data:", error);
          }
          return newData;
        });
      }
    } else if (categoryIndex !== -1) {
      setCategoriesList((prevData) => {
        const newData = [...prevData];
        const subCategoryIndex = newData[
          categoryIndex
        ].sub_categories.findIndex(
          (subCategory) => subCategory.sub_id === subCatId
        );
        if (subCategoryIndex !== -1) {
          newData[categoryIndex].sub_categories.splice(subCategoryIndex, 1);
        }
        // If it was the last subcategory, remove the entire category
        if (newData[categoryIndex].sub_categories.length === 0) {
          newData.splice(categoryIndex, 1);
        }
        try {
          const arr = [
            {
              category_id: "658028e536c4dd43473eed94",
              sub_categories: [
                {
                  key: 0,
                  sub_id: "65802e3ed16be9219764910f",
                },
              ],
            },
          ];
          console.log(Array.isArray(newData), newData);
          // dispatch(updateCatSubCatData(newData));
          dispatch(updateCatSubCatData(JSON.stringify(newData)));
        } catch (error) {
          console.error("Error dispatching new data:", error);
        }
        return newData;
      });
    }

    console.log("datas", categoriesList);
  };

  // const onCheckCategoriesHandler = (e, catId, subCatId, key) => {
  //   console.log("CHECK KAR", e.target.checked);
  //   console.log(catId, subCatId, key);
  //   const newCategoriesData = [...categoriesList];
  //   const categoryIndex = newCategoriesData.findIndex((item) => item.category_id === catId);
  //   let newData;
  //   newData = [...categoriesList];
  //   if (e.target.checked) {
  //     if (categoryIndex !== -1) {
  //       console.log('test', newData[categoryIndex].sub_categories);
  //       newData[categoryIndex]["sub_categories"] = newData[categoryIndex].sub_categories || [];
  //       const subCategoryIndex = newData[categoryIndex].sub_categories.findIndex(
  //         (subCategory) => subCategory.sub_id === subCatId
  //       );
  //       if (subCategoryIndex === -1) {
  //         newData[categoryIndex].sub_categories.push({
  //           key: key,
  //           sub_id: subCatId,
  //         });
  //       } else {
  //         newData[categoryIndex].sub_categories[subCategoryIndex].key = key;
  //       }

  //     } else {
  //       newData.push({
  //         category_id: catId,
  //         sub_categories: [
  //           {
  //             key: key,
  //             sub_id: subCatId,
  //           },
  //         ],
  //       });
  //     }
  //     // dispatch(updateCatSubCatData({
  //     //   category_id: catId,
  //     //   subcategories: newData[categoryIndex]?.sub_categories,
  //     // }));
  //   } else if (categoryIndex !== -1) {
  //     newData[categoryIndex].sub_categories = newData[categoryIndex].sub_categories || [];
  //     const subCategoryIndex = newData[categoryIndex].sub_categories.findIndex(
  //       (subCategory) => subCategory.sub_id === subCatId
  //     );
  //     if (subCategoryIndex !== -1) {
  //       newData[categoryIndex].sub_categories.splice(subCategoryIndex, 1);
  //     }
  //     if (newData[categoryIndex].sub_categories.length === 0) {
  //       newData.splice(categoryIndex, 1);
  //     }
  //     // dispatch(updateCatSubCatData({
  //     //   category_id: catId,
  //     //   subcategories: newData[categoryIndex]?.sub_categories,
  //     // }));
  //   }
  //   console.log(newData);
  //   console.log("FUNCTION WORKING");
  //   setCategoriesList(newData)
  //  //dispatch(updateCatSubCatData(newData));
  // }

  // console.log("DATA", categories);

  console.log("CATEGORIES DATA", categoriesList);

  if (categoriesLoading || amenitiesLoading) {
    return (
      <div className="flex items-center justify-center h-[60%]">
        <RotatingLines
          visible={true}
          height="150"
          width="150"
          color="gray"
          strokeColor="#FB6108"
          strokeWidth="5"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );
  }

  return (
    <ThemeProvider value={checkboxCustomTheme}>
      <div className="flex">
        <div className="flex-[1]">
          {FACILITIES.map((facility, i) => {
            return (
              <div
                key={i}
                className={`grid grid-cols-3 my-[1.2rem] gap-x-[1rem]`}
              >
                <label
                  htmlFor={facility._id}
                  className={`col-span-2 text-[#808080] text-[1rem] font-medium  ${
                    facilitiesArray.includes(facility._id) &&
                    "text-[black] font-semibold"
                  }`}
                >
                  {facility.title}
                </label>
                <Switch
                  id={facility._id}
                  ripple={false}
                  className="h-full w-full checked:bg-primary col-span-1"
                  containerProps={{
                    className: "w-11 h-6",
                  }}
                  circleProps={{
                    className: "before:hidden left-0.5 border-none",
                  }}
                  checked={facilities.includes(facility._id)}
                  onChange={() => switchToggleHandler(facility._id)}
                />
              </div>
            );
          })}
        </div>
        <div className="flex-[2]">
          <div className="grid grid-cols-2 gap-[1.5rem] items-start">
            {categories?.map((category, i) => {
              return category?.subCategoriesId?.length > 0 ? (
                <div
                  key={i}
                  className="bg-[#F6F6FB] rounded-lg px-[0.5rem] py-[0.2rem]"
                >
                  <Accordion
                    open={open === i + 1}
                    icon={<Icon id={i + 1} open={open} />}
                  >
                    <AccordionHeader onClick={() => handleOpen(i + 1)}>
                      <div className="flex items-center">
                        <Checkbox color="deep-orange" disabled={true} />
                        <label
                          htmlFor="team sport"
                          className="text-[1.1rem] text-[#808080] font-medium cursor-pointer"
                        >
                          {category.categoryName}
                        </label>
                      </div>
                    </AccordionHeader>
                    <AccordionBody className="">
                      <div className="grid grid-cols-3 gap-y-[1rem]">
                        {category?.subCategoriesId?.map((subCat, i) => {
                          return (
                            <div className="flex flex-col items-center" key={i}>
                              <div className="ml-[4rem]">
                                <Checkbox
                                  color="deep-orange"
                                  id={subCat._id}
                                  // checked={categoriesList.includes(subCat.id)}
                                  onChange={(e) =>
                                    onCheckCategoriesHandler(
                                      e,
                                      subCat.mainCategoryID,
                                      subCat._id,
                                      i
                                    )
                                  }
                                />
                              </div>
                              {/* <div className='mb-[0.3rem]'>
                                             <img src={basketballLogo} alt="" className='w-auto' />
                                          </div> */}
                              <label
                                htmlFor="Basketball"
                                className="text-[#828282] text-[1rem]"
                              >
                                {subCat.subCategoryName}
                              </label>
                            </div>
                          );
                        })}
                        {/* <div className='flex flex-col items-center'>
                                          <div className='ml-[4rem]'>
                                             <Checkbox color='deep-orange' />
                                          </div>
                                          <label htmlFor="Volleyball" className='text-[#828282] text-[1rem]'>
                                             Volleyball
                                          </label>
                                       </div> */}
                      </div>
                    </AccordionBody>
                  </Accordion>
                </div>
              ) : null;
            })}
            {/* <div className='bg-[#F6F6FB] rounded-lg px-[0.5rem] py-[0.2rem]'>
              <Accordion open={open === 4} icon={<Icon id={4} open={open} />}>
                <AccordionHeader onClick={() => handleOpen(4)}>
                  <div className='flex items-center'>
                    <Checkbox color='deep-orange' />
                    <label htmlFor="team sport" className='text-[1.1rem] text-[#808080] font-medium cursor-pointer'>
                      Team Sport
                    </label>
                  </div>
                </AccordionHeader>
                <AccordionBody className="">
                  <div className='grid grid-cols-3 gap-y-[1rem]'>
                    <div className='flex flex-col items-center'>
                      <div className='ml-[4rem]'>
                        <Checkbox color='deep-orange' />
                      </div>
                      <div className='mb-[0.3rem]'>
                        <img src={basketballLogo} alt="" className='w-auto' />
                      </div>
                      <label htmlFor="Basketball" className='text-[#828282] text-[1rem]'>
                        Basketball
                      </label>
                    </div>
                    <div className='flex flex-col items-center'>
                      <div className='ml-[4rem]'>
                        <Checkbox color='deep-orange' />
                      </div>
                      <div className='mb-[0.3rem]'>
                        <img src={football} alt="" className='w-auto' />
                      </div>
                      <label htmlFor="Football" className='text-[#828282] text-[1rem]'>
                        Football
                      </label>
                    </div>
                    <div className='flex flex-col items-center'>
                      <div className='ml-[4rem]'>
                        <Checkbox color='deep-orange' />
                      </div>
                      <div className='mb-[0.3rem]'>
                        <img src={cricket} alt="" className='w-auto' />
                      </div>
                      <label htmlFor="Cricket" className='text-[#828282] text-[1rem]'>
                        Cricket
                      </label>
                    </div>
                    <div className='flex flex-col items-center'>
                      <div className='ml-[4rem]'>
                        <Checkbox color='deep-orange' />
                      </div>
                      <div className='mb-[0.3rem]'>
                        <img src={handball} alt="" className='w-auto' />
                      </div>
                      <label htmlFor="Handball" className='text-[#828282] text-[1rem]'>
                        Handball
                      </label>
                    </div>
                    <div className='flex flex-col items-center'>
                      <div className='ml-[4rem]'>
                        <Checkbox color='deep-orange' />
                      </div>
                      <div className='mb-[0.3rem]'>
                        <img src={futsal} alt="" className='w-auto' />
                      </div>
                      <label htmlFor="Futsall" className='text-[#828282] text-[1rem]'>
                        Futsall
                      </label>
                    </div>
                    <div className='flex flex-col items-center'>
                      <div className='ml-[4rem]'>
                        <Checkbox color='deep-orange' />
                      </div>
                      <div className='mb-[0.3rem]'>
                        <img src={volleyball} alt="" className='w-auto' />
                      </div>
                      <label htmlFor="Volleyball" className='text-[#828282] text-[1rem]'>
                        Volleyball
                      </label>
                    </div>
                  </div>
                </AccordionBody>
              </Accordion>
            </div> */}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
