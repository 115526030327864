import jsPDF from "jspdf";
import "jspdf-autotable";
import { toast } from "react-toastify";
import gamebeezLogo1 from "../../../../assets/GB-Final-Logo-min.png";
import gamebeezLogo2 from "../../../../assets/gamebeez-logo-bookings.jpg";
import moment from "moment";
const convertImage1ToBase64 = async (imagePath) => {
  try {
    const response = await fetch(imagePath);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error converting image 1 to base64:", error);
    throw error;
  }
};

const convertImage2ToBase64 = async (imagePath) => {
  try {
    const response = await fetch(imagePath);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error converting image 2 to base64:", error);
    throw error;
  }
};

export const DownloadGameFixturesPDF = async (
  roundsData,
  fileName,
  base64String1,
  base64String2,
  tournamentDetails,
  roundBracket
) => {
  if (roundsData.length > 0) {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const padding = 10;
    let imgHeightTopLeft = 0;
    // Add the top-left image
    if (base64String1) {
      const imgWidthTopLeft = 20;
      imgHeightTopLeft = 20; // Set image height

      const xPosTopLeft = padding;
      const yPosTopLeft = padding;

      doc.addImage(
        base64String1,
        "PNG",
        xPosTopLeft,
        yPosTopLeft,
        imgWidthTopLeft,
        imgHeightTopLeft
      );
    }
    // Add center image (if available)
    if (base64String2) {
      const imgWidth = 120;
      const imgHeight = 120;
      const xPos = (pageWidth - imgWidth) / 2;
      const yPos = (pageHeight - imgHeight) / 2; // Position the image after the club address text with some padding
      doc.addImage(base64String2, "PNG", xPos, yPos, imgWidth, imgHeight);
    }
    // Construct the text with bookingId
    const text = "Tournament : " + tournamentDetails?.id;
    const textFontSize = 12;
    doc.setTextColor(25, 33, 61);
    doc.setFontSize(textFontSize);

    // Calculate X position for the text (aligned to the right edge with padding)
    const textWidth =
      (doc.getStringUnitWidth(text) * textFontSize) / doc.internal.scaleFactor;
    const xPosTopRight = pageWidth - textWidth - padding;
    const yPosTopLeft = padding;

    // Add Booking ID text aligned to the top right
    doc.text(text, xPosTopRight, yPosTopLeft + textFontSize / 2);
    let YPosValue = imgHeightTopLeft + yPosTopLeft + padding - 3;

    // tournament
    const tournamentName = "Tournament Name : " + tournamentDetails?.name;
    doc.setFontSize(15);
    const nameX = padding;
    const nameY = YPosValue;
    // const textWidth2 = doc.getTextWidth(tournamentName);
    // const textHeight = 15 / 2.5; // Approximate text height based on font size

    // // Define positions
    // // const nameX = padding;
    // // const nameY = YPosValue;

    // // Set background color (RGB: red, green, blue)
    // doc.setFillColor(251, 97, 8); // Gamebeez Color

    // // Draw rounded rectangle behind the text (x, y, width, height, radius)
    // const radius = 3; // You can adjust the radius for more/less rounded corners
    // doc.roundedRect(
    //   nameX -3,
    //   nameY - textHeight ,
    //   textWidth2 + 6,
    //   textHeight+3,
    //   radius,
    //   radius,
    //   "F"
    // );
    // doc.setTextColor(255, 255, 255);
    doc.text(tournamentName, nameX, nameY);

    YPosValue = YPosValue + 7;
    const formatName = "Format : " + tournamentDetails?.format;
    doc.setFontSize(12);
    // doc.setTextColor(0, 0, 0);
    const formatX = padding;
    const formatY = YPosValue;
    doc.text(formatName, formatX, formatY);

    YPosValue = YPosValue + 7;
    const sportName = "Sport : " + tournamentDetails?.sportName;
    doc.setFontSize(12);
    // doc.setTextColor(0, 0, 0);
    const sportX = padding;
    const sportY = YPosValue;
    doc.text(sportName, sportX, sportY);

    YPosValue = YPosValue + 7;
    let bracket = roundsData.length > 0 ? roundBracket : "No Bracket";
    bracket = "Bracket : " + bracket;
    doc.setFontSize(12);
    // doc.setTextColor(0, 0, 0);
    const bracketX = padding;
    const bracketY = YPosValue;
    doc.text(bracket, bracketX, bracketY);

    let lastTableY = 10;
    for (let round of roundsData) {
      // const percentage = 20; // percentage to subtract
      // const heightAfterPercentage =
      //   pageHeight - pageHeight * (percentage / 100);
      // if((lastTableY - heightAfterPercentage) > 0)
      // {
      //     console.log("new Page");
      //     // doc.addPage();
      //     // lastTableY = lastTableY + heightAfterPercentage;
      // }
      const matchPDFColumns = [
        "SL",
        "Name",
        "ParticipantA",
        "ParticipantB",
        "Winner",
        "Venue",
        "City",
        "Date & Time",
      ];
      const bookingDetailsHeading = round?.roundName;
      doc.setFontSize(14);
      doc.setTextColor(0, 0, 0);
      const headingWidth = doc.getTextWidth(bookingDetailsHeading);
      const roundNameWidth = (pageWidth - headingWidth) / 2;
      const bookingDetailsY = round?.roundNumber === 1 ? YPosValue : 23;
      const adjustedY = lastTableY + bookingDetailsY;
      doc.text(bookingDetailsHeading, roundNameWidth, adjustedY);
      lastTableY = adjustedY + 5;
      const matchData = round?.matches?.map((match, i) => {
        const winner = match?.winner ? match?.winner : "-";
        const venueName = match?.venue?.name ? match?.venue?.name : "-";
        const venueLocation = match?.venue?.city ? match?.venue?.city : "-";
        const DateObj = match?.dateOfPlay
          ? moment(match?.dateOfPlay).format("MMM Do YYYY")
          : "-";
        const Timing = match?.timing
          ? moment(match?.timing).format("hh:mm A")
          : "-";
        const dateAndTime = `${DateObj},${Timing}`;
        const matchRow = [
          i + 1,
          match?.name,
          match?.participantA,
          match?.participantB,
          winner,
          venueName,
          venueLocation,
          dateAndTime,
        ];
        return matchRow;
      });
      // Booking Details Table
      doc.autoTable({
        head: [matchPDFColumns],
        body: matchData,
        startY: lastTableY,
        headStyles: {
          fillColor: [251, 97, 8],
        },
        styles: {
          minCellHeight: 5,
          fontSize: 9,
        },
        rowPageBreak: "avoid",
      });

      // Get the last position of the booking table
      lastTableY = doc.autoTable.previous.finalY + 5; // Adjust padding dynamically
    }
    doc.save(fileName);
  } else {
    toast.error("No Data to Download");
  }
};
export const GameFixturesDownloadPDFWrapper = async (
  gameFixing,
  fileName,
  tournamentDetails,
  roundBracket
) => {
  try {
    const base64String1 = await convertImage1ToBase64(gamebeezLogo1);

    const base64String2 = await convertImage2ToBase64(gamebeezLogo2);
    console.log("Generating pdf");
    await DownloadGameFixturesPDF(
      gameFixing,
      fileName,
      base64String1,
      base64String2,
      tournamentDetails,
      roundBracket
    );
  } catch (error) {
    console.log("Error in GameFixture Download Wrapper : ", error);
  }
};
