import { Icon } from "@iconify/react";
import { Link, NavLink, useOutletContext, useParams } from "react-router-dom";
import { useGetSingleClubOwnerStaffQuery } from "../../../../store/features/clubOwner/clubOwnerApi";

export default function ViewCOStaffView() {
  const { id,staffId } = useParams();
  const {data:staffData} = useGetSingleClubOwnerStaffQuery(staffId);
  const {staffId:uniqueId, email, mobile, username, designation, createdAt, permission} = staffData?.response?.staff || {};

  const [clubName, clubID] = useOutletContext();
  
  return (
    <div className="bg-white flex flex-col gap-[1rem] rounded-md p-[1rem]">
      <div className="flex gap-[0.5rem] items-center text-[#808080] font-semibold">
        <Link to={"/club-owners"}>
          <h2 className="text-[1rem] font-semibold">Club List</h2>
        </Link>
        <Icon
          icon="material-symbols:arrow-forward-ios"
          className="text-[1.1rem]"
        />
        <Link to={`/club-owners/view/${id}`}>
          <h2 className="text-[1rem]">
            {clubName}
            <span className="text-[#808080] text-[0.9rem] font-normal">
              {" "}
              ({clubID}){" "}
            </span>
          </h2>
        </Link>
        <Icon
          icon="material-symbols:arrow-forward-ios"
          className="text-[1.1rem]"
        />
        <Link to={`/club-owners/view/${id}/staff`}>
          <h2 className="text-[1rem]">Staff</h2>
        </Link>
        <Icon
          icon="material-symbols:arrow-forward-ios"
          className="text-[1.1rem]"
        />
        <h2 className="text-[1.2rem] text-black font-bold">{uniqueId}</h2>
      </div>
      <div className="flex gap-[2rem] text-[1.2rem] font-medium border-b-2 border-[#989898]">
        {/* <NavLink to={`/club-owners/view/:id/sports/view/:bookingId`} end> */}
          {/* {({ isActive }) => ( */}
            <h2
              className={`pb-[0.3rem] font-bold text-primary border-b-2 border-primary`}
            >
              Staff ID: {uniqueId}
            </h2>
          {/* )} */}
        {/* </NavLink> */}
      </div>
      <div className="grid grid-cols-2 px-[0.6rem]">
        <div className="">
          <h2 className="text-[1.15rem] font-bold my-[1rem]">Info</h2>
          <div className="grid grid-cols-2 gap-y-[1rem] place-content-between">
            <label className="text-[#858585] font-medium">Unique ID</label>
            <h2 className="font-semibold">{uniqueId}</h2>
            <label className="text-[#858585] font-medium">Designation</label>
            <h2 className="font-semibold">{designation}</h2>
            <label className="text-[#858585] font-medium">Email Addresss</label>
            <h2 className="font-semibold">{email}</h2>
            <label className="text-[#858585] font-medium">Mobile Number</label>
            <h2 className="font-semibold">{mobile}</h2>
            <label className="text-[#858585] font-medium">
              Date of Joining
            </label>
            <h2 className="font-semibold">{createdAt?.split("T")[0]}</h2>
            <label className="text-[#858585] font-medium">Permissions</label>
            <h2 className="font-semibold">{permission?.join(", ")}</h2>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}
