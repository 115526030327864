import jsPDF from "jspdf";
import "jspdf-autotable";
import { toast } from "react-toastify";
import gamebeezLogo from '../assets/GB logo For PDF (2).jpg';

const convertImageToBase64 = async (imagePath) => {
    try {
        const response = await fetch(imagePath);
        const blob = await response.blob();

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(blob); // Correct usage
        });
    } catch (error) {
        console.error("Error converting image to base64:", error);
        throw error;
    }
};

const DownloadPDF = async (pdfData, pdfColumns, fileName, adminName) => {

    if (pdfData?.length > 0 && pdfColumns?.length > 0) {

        let DynamicOrientation;

        if (pdfColumns?.length > 7 || fileName === "Customers.pdf" || fileName === "Sub Admin.pdf") {
            DynamicOrientation = "landscape"
        } else if (pdfColumns?.length === 6) {
            DynamicOrientation = "portrait"
        }

        const doc = new jsPDF({
            orientation: DynamicOrientation,
            unit: "mm",
            format: "a4",
        });

        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        const margin = 10;
        const textX = pageWidth - margin;
        const textY = 20;

        doc.setFontSize(12);
        doc.text(`Downloaded by: ${adminName}`, textX, textY, { align: 'right' });
        doc.text(`Downloaded on: ${new Date().toLocaleDateString()}`, textX, textY + 10, { align: 'right' });

        try {
            const base64Logo = await convertImageToBase64(gamebeezLogo);
            if (base64Logo) {
                const imgWidth = 20;
                var imgHeight = 20;
                doc.addImage(base64Logo, 'PNG', 10, 10, imgWidth, imgHeight);
            }
        } catch (error) {
            console.error("Error fetching logo", error);
        }

        const startY = Math.max(textY + 20, imgHeight + 30);

        doc.autoTable({
            head: [pdfColumns],
            body: pdfData,
            startY: startY,
            headStyles: {
                fillColor: [251, 97, 8]
            },
            styles: {
                minCellHeight: 5,
                fontSize: 12,
            },
            rowPageBreak: "avoid",
        });

        const linkText = 'gamebeez.in';
        const linkURL = 'https://www.gamebeez.in';
        const providedText = "Provided By: "

        const providedTextWidth = doc.getTextWidth(providedText);
        const linkTextWidth = doc.getTextWidth(linkText);

        // Position for the text
        const linkX = (pageWidth - (providedTextWidth + linkTextWidth)) / 2; // Center horizontally
        const linkY = pageHeight - 20; // Position near the bottom

        doc.setFontSize(12);
        doc.setTextColor("#000000"); // Set color for the provided text
        doc.text(providedText, linkX, linkY, { align: 'left' });

        // Set color for the link text
        doc.setTextColor("#FB6108"); // Set color for the link text
        doc.text(linkText, linkX + providedTextWidth, linkY, { align: 'left' });

        // Add clickable link
        doc.link(linkX + providedTextWidth, linkY - 10, linkTextWidth, 10, { url: linkURL });

        doc.save(fileName);
    } else {
        toast.error("No data Available for PDF");
    }
};

export default DownloadPDF;
