import "./CustomCheckbox.module.css";
import BannerList from "./BannerList";
import { Outlet } from "react-router-dom";

export default function BannersContainer() {
  return (
    <div className="bg-white flex flex-col gap-[1rem] rounded-md">
        <Outlet/>
        <BannerList/>
    </div>
  );
}
