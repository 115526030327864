import React from 'react'
import { PiGreaterThan } from "react-icons/pi";
import { FaChevronDown } from "react-icons/fa6";
import TournamentDetailsTabs from './TournamentDetailsTabs';
import { useNavigate, useParams } from 'react-router-dom';

export default function TournamentDetails() {

    const navigate = useNavigate();
    const {tournamentId} = useParams();

    return (
        <>
            <div className='flex justify-between'>
                <div className='flex items-center gap-1'>
                    <p className='text-lg font-semibold text-[#808080] cursor-pointer max-lg:text-[15px]' 
                        onClick={()=>navigate("/tournaments")}
                    >
                            Tournament
                    </p>
                    <PiGreaterThan />
                    <p className='text-base font-semibold text-[#808080] max-lg:text-[15px]'>({tournamentId})</p>
                    <FaChevronDown className='text-lg font-semibold text-[#808080]' />
                </div>
            </div>
            <TournamentDetailsTabs/>
        </>
    )
}