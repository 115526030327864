import React, { useState } from 'react'
import { PiGreaterThan } from "react-icons/pi";
import { Icon } from "@iconify/react";
import { IconButton } from '@material-tailwind/react';
import { AiOutlinePlus } from "react-icons/ai";
import DataTable from 'react-data-table-component';
import CustomCheckbox from '../../../../helpers/CustomCheckbox';
import { useNavigate, useParams } from 'react-router-dom';
import AddParticipantModal from './AddParticipantModal';
import { toast, ToastContainer } from 'react-toastify';
import { useAuth } from '../../../../store/AuthContext';
import { APIurls } from '../../../../api/apiConstant';
import { useQuery } from 'react-query';
import moment from 'moment';
import { ThreeDots } from "react-loader-spinner";

export default function ViewTeamParticipants() {

    const navigate = useNavigate();
    const { tournamentId } = useParams();
    const [activeParticipants, setActivePartcipants] = useState("participants")
    const [participantModal, setParticipantModal] = useState(false)
    const [isTeamPlayerDelete, setIsTeamPlayerDelete] = useState({})

    const { getAccessToken } = useAuth();
    const { teamId } = useParams();

    const fetchSpecificTeamDetails = async () => {
        const token = await getAccessToken();
        const response = await fetch(`${APIurls.fetchDetailsOfSpecificTeam}/${teamId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const result = await response.json()
        if (!response.ok) {
            throw new Error(result.message)
        }
        return result?.response?.team
    }
    const { data: teamDetails, refetch, isLoading } = useQuery(["TeamDetails",teamId], fetchSpecificTeamDetails)
    console.log("Specific Team Details", teamDetails)

    const handleDeleteParticipant = async (id) => {
        try {
            setIsTeamPlayerDelete((prevState) => ({
                ...prevState,
                [id]: true,
            }))
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.deleteParticipantFromTeam}/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            const result = await response.json();
            if (!response.ok) {
                console.log("Failed to Delete Player", result)
                toast.error("Failed to Delete Player")
            }
            console.log("Player Deleted Successfully", result)
            refetch();
            toast.success("Player Deleted Successfully")
        }
        catch (error) {
            console.log("Error in deleting team player", error)
        }
        setIsTeamPlayerDelete((prevState) => ({
            ...prevState,
            [id]: false,
        }))
    }

    const columns = [
        {
            name: "SL",
            selector: (row, index) => index + 1,
            center: true,
            width: "80px"
        },
        {
            name: "Player Name",
            selector: row => (
                <div>
                    <p>{row?.name}</p>
                </div>
            ),
            center: true,
            // grow: 2.5
        },
        {
            name: "Mobile Number",
            selector: row => (
                <div>{row?.phone}</div>
            ),
            center: true
        },
        {
            name: "Email",
            selector: row => (
                <div>{row?.email}</div>
            )
        },
        {
            name: "Date Added",
            selector: row => (
                <div>{moment(row?.createdAt).format("MMM Do YY")}</div>
            ),
            // center: true
        },
        {
            name: "Action",
            selector: row => (
                <div>
                    {
                        isTeamPlayerDelete[row?._id] ? (
                            <ThreeDots
                                height="40"
                                width="40"
                                radius="9"
                                color="#FB6108"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        ) : (
                            <div onClick={() => handleDeleteParticipant(row?._id)}>
                                <IconButton
                                    className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black border border-[#D9D9D9] rounded-lg"
                                >
                                    <Icon icon="material-symbols:delete-rounded" className="text-[1.5rem]" />
                                </IconButton>
                            </div>
                        )}
                </div>
            ),
            // center: true
        }
    ]

    const customStyles = {
        headRow: {
            style: {
                fontWeight: 600,
                color: "#4D4D4D",
                fontSize: "13px",
                // background: "#EAECF0"
            },
        },
        head: {
            style: {
                background: "#EAECF0",
            },
        },

        rows: {
            style: {
                background: "#FFF",
                color: "#565656",
                padding: "5px 0px",
                fontSize: "14px",
                fontWeight: 400,
                width: "auto",
            },
        },
        table: {
            style: {
                overflow: "visible",
                minWidth: "1100px",
            },
        },
        tableWrapper: {
            style: {
                overflow: "visible",
            },
        },
        responsiveWrapper: {
            style: {
                overflowX: "auto",
            },
        },
    };

    const handleShowModal = () => {
        setParticipantModal(true)
    }

    return (
        <>
            <div className='flex items-center gap-1 p-2'>
                <p className='text-base font-semibold text-[#808080] cursor-pointer max-lg:text-[14px]'
                    onClick={() => navigate("/tournaments")}
                >
                    Tournament
                </p>
                <PiGreaterThan className='text-base font-semibold text-[#808080] cursor-pointer' />
                <div className='flex items-center cursor-pointer'
                    onClick={() => navigate(`/tournaments/tournamentDetails/${tournamentId}`)}
                >
                    {/* <p className='text-base font-semibold text-[#808080]'>Test</p> */}
                    <p className='text-base font-semibold text-[#808080]'>{tournamentId}</p>
                </div>
                <PiGreaterThan className='text-base font-semibold text-[#808080]' />
                <p className='text-base font-semibold text-[#808080] cursor-pointer max-lg:text-[14px]'
                    onClick={() => navigate(`/tournaments/tournamentDetails/${tournamentId}/team_creation`)}
                >
                    Creation of Teams
                </p>
                <PiGreaterThan className='text-base font-semibold text-[#808080]' />
                <p className='text-lg font-bold'>{teamDetails?.name}</p>
            </div>
            <div className='flex gap-[3rem] border-b-2 border-[#989898] pt-[0.5rem] ml-2'>
                <div className={`${activeParticipants === "participants" ? `font-bold text-primary border-b-2 border-primary` : "text-[#858585]"} cursor-pointer text-xl text-[1.2rem] sm:text-[1.3rem] font-bold pb-[0.3rem]`} onClick={() => setActivePartcipants("participants")}>
                    <h3>Participants</h3>
                </div>
            </div>
            <div className='flex items-center justify-between px-5 py-5'>
                <h4 className='text-[#000000] font-semibold text-xl'>Participants</h4>
                <div className='flex items-center gap-4'>
                    <button className='bg-[#FB6108] px-4 py-2 text-white flex items-center rounded-md gap-1 max-lg:px-2'
                        onClick={handleShowModal}
                    >
                        Add Participant
                        <AiOutlinePlus className="text-[1.2rem]" />
                    </button>
                </div>
            </div>
            <ToastContainer />
            <DataTable
                columns={columns}
                data={teamDetails?.players}
                customStyles={customStyles}
                selectableRows
                selectableRowsComponent={CustomCheckbox}
                progressPending={isLoading}
                pagination
                paginationPerPage={10}
            />
            {participantModal &&
                <AddParticipantModal
                    setParticipantModal={setParticipantModal}
                    teamID={teamId}
                    refetch={refetch}
                />
            }
        </>
    )
}
