import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import "react-phone-number-input/style.css";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_red.css";
import uploadImg from "../../../assets/upload-image.svg";
import { selectCustomTheme } from "../../../helpers/constants";
import { Option, Select, ThemeProvider } from "@material-tailwind/react";
import { editClubInfo } from "../../../store/editClubSlice";

export default function EditClubInfo({ showError, setError }) {
  // const [error, setError] = useState(false);
  // const [showError, setShowError] = useState(false);
  const {
    clubName,
    businessPanNumber,
    businessCategory,
    businessEmail,
    openingDate,
    clubImagesArray,
  } = useSelector((store) => store.editClub.clubInfo);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [selectedDate, setSelectDate] = useState(null);
  const [selectImg, setSelectImg] = useState(null);
  const [imgArr, setImgArr] = useState(clubImagesArray);
  const [isBusinessPanValid, setIsBusinessPanValid] = useState(true);
  const dispatch = useDispatch();

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

  useEffect(() => {
    if (
      !(
        clubName &&
        (businessPanNumber.length === 0 || businessPanNumber.length === 10) && (businessPanNumber.length === 0 || isBusinessPanValid) &&
        businessEmail &&
        isEmailValid &&
        openingDate &&
        openingDate !== "undefined" &&
        clubImagesArray.length > 0
      )
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [
    clubName,
    businessPanNumber,
    isBusinessPanValid,
    businessCategory,
    businessEmail,
    isEmailValid,
    openingDate,
    clubImagesArray,
    setError,
  ]);

  // add new image
  const imgCollectionHandler = (imgFile) => {
    setImgArr((prevImgArr) => {
      // to avoid batching dispatch() is used inside for latest value.
      const updatedImageArray = [
        ...prevImgArr,
        { imgFile: imgFile, id: Math.random().toString() },
      ];
      dispatch(editClubInfo({ clubImagesArray: updatedImageArray }));
      return updatedImageArray;
    });
  };
  // delete image by Id
  const deleteImgHandler = (delImg) => {
    setImgArr((prevImgArr) => {
      const updatedImageArray = prevImgArr.filter(
        (imgData) => imgData !== delImg
      );
      dispatch(editClubInfo({ clubImagesArray: updatedImageArray }));
      return updatedImageArray;
    });
  };

  // console.log("STATE", imgArr);
  // console.log("REDUX ARRAY", clubImagesArray);

  // const handleDateChange = (selectedDate) => {
  //    setSelectDate(selectedDate[0]);
  // };
  const dateOptions = {
    mode: "single",
    dateFormat: "d M Y",
  };

  const loadImage = (image) => {
    // Check if the provided value is a URL or a File
    if (typeof image === "string") {
      // If it's a URL, set the image source directly
      return image;
    } else if (image instanceof File) {
      // If it's a File, create an object URL and set it as the image source
      const objectUrl = URL.createObjectURL(image);
      return objectUrl;
      // Clean up the object URL when the component unmounts
      return () => URL.revokeObjectURL(objectUrl);
    }
  };

  return (
    <ThemeProvider value={selectCustomTheme}>
      <div>
        <form className="flex flex-col gap-[2rem]">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-[1.5rem] gap-y-[1.5rem]">
            <div className="flex flex-col gap-[1.5rem]">
              <div className="flex flex-col gap-[0.5rem]">
                <input
                  className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                  type="text"
                  placeholder="Club Name"
                  value={clubName}
                  onChange={(e) => {
                    dispatch(editClubInfo({ clubName: e.target.value }));
                  }}
                />
                {showError && !clubName && (
                  <div className="text-sm text-[#E92215]">
                    *This Field is Required
                  </div>
                )}
              </div>
              {/* <div className="flex flex-col gap-[0.5rem]">
                        <Select label='Select Business Category' color='blue-gray' size='lg' className='text-black'
                           value={businessCategory}
                           onChange={(value) => {
                              dispatch(updateClubInfo({ businessCategory: value }));
                           }}
                        >
                           <Option value='Sports Club'>Sports Club</Option>
                           <Option value='Sports Club1'>Sports Club1</Option>
                        </Select>
                        {showError && !businessCategory && (
                           <div className="text-sm text-[#E92215]">
                              *This Field is Required
                           </div>
                        )}
                     </div> */}
              <div className="flex flex-col gap-[0.5rem]">
                <Flatpickr
                  className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                  placeholder={"Opening Date"}
                  options={dateOptions}
                  value={openingDate}
                  onChange={(selectedDate) =>
                    dispatch(
                      editClubInfo({
                        openingDate: selectedDate[0],
                      })
                    )
                  }
                />
                {showError && (!openingDate || openingDate === "undefined") && (
                  <div className="text-sm text-[#E92215]">
                    *This Field is Required
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-[1.5rem]">
              <div className="flex flex-col gap-[0.5rem]">
                <input
                  className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                  type="text"
                  placeholder="Business PAN Number (Optional)"
                  value={businessPanNumber}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      if (panRegex.test(e.target.value.toUpperCase())) {
                        setIsBusinessPanValid(true);
                      } else {
                        setIsBusinessPanValid(false);
                      }
                      dispatch(
                        editClubInfo({
                          businessPanNumber: e.target.value.toUpperCase(),
                        })
                      );
                    }
                  }}
                />
                {(showError && (businessPanNumber.length !== 0 && !isBusinessPanValid)) && <p className="text-[#E92215] text-sm">{((!isBusinessPanValid || businessPanNumber.length !== 10) && showError) ? "Invalid PAN Number" : showError ? "" : ""}</p>}
              </div>
              <div className="flex flex-col gap-[0.5rem]">
                <input
                  className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none cursor-not-allowed"
                  type="email"
                  placeholder="Business Email Address"
                  readOnly
                  value={businessEmail}
                  onChange={(e) => {
                    if (emailRegex.test(e.target.value)) {
                      setIsEmailValid(true);
                    } else {
                      setIsEmailValid(false);
                    }
                    dispatch(editClubInfo({ businessEmail: e.target.value.toLowerCase() }));
                  }}
                />
                {((showError && !businessEmail) || !isEmailValid) && (
                  <p className="text-[#E92215] text-sm">
                    {!isEmailValid && showError
                      ? "Invalid email"
                      : showError
                      ? "*This field is required."
                      : ""}
                  </p>
                )}
              </div>
            </div>
            {/* Image Upload Start */}
            <div className="flex flex-col gap-[0.5rem]">
              <div className="flex flex-col items-center py-[1rem] px-[1rem] border border-[#E1E2E9] bg-[#F6F7FB] rounded-lg">
                <img src={uploadImg} alt="" />
                <button
                  type="button"
                  onClick={() => document.querySelector(".input-field").click()}
                >
                  <h2 className="text-primary font-[500] flex items-center gap-[0.5rem] my-[1rem]">
                    <Icon
                      icon="material-symbols:cloud-upload"
                      className="text-[1.5rem]"
                    />
                    <span>Upload Image</span>
                  </h2>
                </button>
                <input
                  type="file"
                  accept="image/*"
                  value=""
                  className="input-field"
                  hidden
                  onChange={({ target: { files } }) => {
                    if (files[0]) {
                      setSelectImg(files[0]);
                      imgCollectionHandler(files[0]);
                    }
                  }}
                />
                <p className="text-[0.9rem] text-[#808080]">
                  Upload an image of the club.
                </p>
                <p className="text-[0.8rem] text-[#808080]">
                  File Format <span className="text-black"> jpeg, png </span>{" "}
                  Recommened Size{" "}
                  <span className="text-black"> 600x600 (1:1) </span>
                </p>
              </div>
              <div className="flex flex-col gap-[0.5rem]">
                <h2 className="font-semibold">Uploaded Images</h2>
                <div className="grid grid-cols-2 gap-[1rem]">
                  {clubImagesArray.map((imgData, i) => {
                    return (
                      <div
                        key={i}
                        className="border border-[#E1E2E9] rounded-lg flex items-center justify-center relative"
                      >
                        <img
                          src={
                            imgData?.imgFile
                              ? loadImage(imgData.imgFile)
                              : loadImage(imgData)
                          }
                          alt=""
                          className="h-[150px] w-[150px]"
                        />
                        <div className="border border-[#F00] rounded-[4px] absolute right-[-10px] top-[-10px] hover:bg-[#F00]/[0.3]">
                          <Icon
                            onClick={() => deleteImgHandler(imgData)}
                            icon="material-symbols:delete-rounded"
                            className="text-[1.2rem] cursor-pointer text-[#F00]"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {showError && clubImagesArray.length === 0 && (
                <div className="text-sm text-[#E92215]">
                  *This Field is Required
                </div>
              )}
            </div>
            {/* Image Upload End */}
          </div>
        </form>
      </div>
    </ThemeProvider>
  );
}
