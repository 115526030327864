import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../store/AuthContext";
import { APIurls } from "../../../../api/apiConstant";
import { BsFillFileBarGraphFill } from "react-icons/bs";
import AddScoreGameFixturesModal from "./AddScoreGameFixturesModal";
import EditThreeButton from "./EditThreeButton";
import { IconButton } from "@material-tailwind/react";
import EditQuarterFinalModal from "./EditQuaterFinalModal";
import { toast, ToastContainer } from "react-toastify";
import { Icon } from "@iconify/react";
import EditMatchDetailsModal from "./EditMatchDetailsModal";
import moment from "moment";
import { useParams } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { GameFixturesDownloadPDFWrapper } from "./DownloadGameFixturesPDF";
import pdfLogo from "../../../../assets/pdfImage.png";

export default function LoosersBracket() {
  const [editMatchDetailsModal, setEditMatchDetailsModal] = useState(false);
  const [addScoreModal, setAddScoreModal] = useState(false);
  const [editRoundsModal, setEditRoundsModal] = useState(false);
  const { tournamentId } = useParams();
  const { getAccessToken } = useAuth();
  const [roundID, setRoundID] = useState("");
  const [matchID, setMatchID] = useState("");
  const [announceStatus, setAnnounceStatus] = useState("");
  const [tournamentType, setTournamentType] = useState("");
  const [loading, setLoading] = useState(false);
  const [gameFixing, setGameFixing] = useState([]);
  const [isRefetch, setIsRefetch] = useState(true);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [tournamentDetails, setTournamentDetails] = useState({});

  const fetchLosersBracket = async () => {
    try {
      const token = await getAccessToken();
      setLoading(true);
      const response = await fetch(
        `${APIurls.fetchGameFixations}/${tournamentId}?bracket=losers`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (!response.ok) {
        console.log(data.message);
      }
      console.log(
        "Game Fixings for Double_KnockOut Losers Bracket",
        data?.response
      );
      setGameFixing(data?.response?.rounds);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchLosersBracket();
    setIsRefetch(false);
  }, [isRefetch]);

  const fetchTournamentType = async () => {
    const token = await getAccessToken();
    try {
      const response = await fetch(
        `${APIurls.fetchOverViewTournamentData}/${tournamentId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.message);
      }
      console.log("Tournament Type", result?.response?.tournamentDetails);
      const obj = {
        id: result?.response?.tournamentDetails?.tournamentID,
        name: result?.response?.tournamentDetails?.tournamentName,
        format: result?.response?.tournamentDetails?.format,
        sportName: result?.response?.tournamentDetails?.sportName,
      };
      setTournamentDetails(obj);
      setTournamentType(result?.response?.tournamentDetails?.format);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTournamentType();
  }, []);

  const handleEditMatchDetailsModal = (matchId) => {
    setMatchID(matchId);
    setEditMatchDetailsModal(true);
  };

  const handleAddScoreModal = (matchId, status) => {
    setAddScoreModal(true);
    setMatchID(matchId);
    setAnnounceStatus(status);
  };

  const handleEditRoundModal = (id) => {
    setRoundID(id);
    setEditRoundsModal(true);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <RotatingLines
          visible={true}
          height="100"
          width="100"
          color="gray"
          strokeColor="#FB6108"
          strokeWidth="5"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );
  }
  const handleDownloadGameFixtures = async () => {
    try {
      setGenerateLoading(true);
      console.log("Generating pdf");
      console.log("game Fixing : ", gameFixing);
      await GameFixturesDownloadPDFWrapper(
        gameFixing,
        "Tournament Double Elimination Winners Bracket.pdf",
        tournamentDetails,
        "Losers"
      );
      toast.success("Generated PDF Successfully");
    } catch (error) {
      console.log("Error In Generating PDF Of Game Fixtures : ", error);
      toast.error("Not able to generate PDF," + error?.message);
    }
    setGenerateLoading(false);
  };

  return (
    <div className="overflow-x-auto w-[100%]">
      <div>
        <div className="w-[12%] px-5">
          {generateLoading ? (
            <p>Generating PDF...</p>
          ) : (
            <img
              src={pdfLogo}
              className="w-[25px] cursor-pointer"
              onClick={handleDownloadGameFixtures}
              alt="Generate PDF"
            />
          )}
        </div>
        <div className="w-full flex items-center">
          {gameFixing?.map((div, i) => (
            <div
              key={i}
              className="min-w-[45%] flex flex-col gap-[2rem] py-5 px-5"
            >
              <div className="flex items-center justify-center gap-3">
                <p className="font-semibold text-2xl text-center">
                  {div?.roundName}
                </p>
                <div onClick={() => handleEditRoundModal(div?.roundID)}>
                  <IconButton
                    className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black"
                    style={{
                      borderRadius: "5.55px",
                      border: "0.925px solid #D9D9D9",
                      background: "#FFF",
                    }}
                  >
                    <Icon
                      icon="material-symbols:edit-rounded"
                      className="text-[1.5rem]"
                    />
                  </IconButton>
                </div>
              </div>
              {div?.matches?.map((div2) => (
                <div
                  key={div2?.id}
                  className="flex flex-col gap-[1.7rem] px-2 py-[1.5rem] w-[100%] bg-white shadow-lg rounded-md"
                >
                  <div className="w-[100%] flex items-center justify-between bg-[#FB6108] px-4 py-1 rounded-md">
                    <p className="text-xl font-black text-white">{div2.name}</p>
                    <div className="flex items-center gap-2">
                      <Icon
                        icon="material-symbols:edit-rounded"
                        className="text-[1.5rem] p-1 bg-white rounded-md cursor-pointer"
                        onClick={() => handleEditMatchDetailsModal(div2?.id)}
                      />
                      <BsFillFileBarGraphFill
                        title="Stats"
                        className="text-[1.5rem] p-1 bg-white rounded-md cursor-pointer"
                        onClick={() =>
                          handleAddScoreModal(div2?.id, div2?.status)
                        }
                      />
                    </div>
                  </div>
                  <div className="flex justify-start flex-col">
                    <div className="flex items-center gap-3 px-3">
                      <div className="w-[100%] flex justify-between items-center rounded-md py-1 border border-[#808080]">
                        <p
                          className="text-[#000019] text-base font-medium px-3 whitespace-nowrap overflow-hidden text-ellipsis flex-shrink"
                          title={`${div2?.participantA}`}
                        >
                          {div2.participantA}
                        </p>
                        <EditThreeButton
                          matchID={div2?.id}
                          tournamentType={tournamentType}
                          setIsRefetch={setIsRefetch}
                          participantID={div2?.participantA_Id}
                          matchData={div2}
                        />
                      </div>
                    </div>
                    <div className="flex items-center gap-3 mt-[10px] px-3">
                      <div className="w-[100%] flex justify-between items-center rounded-md py-1 border border-[#808080]">
                        <p
                          className="text-[#000019] text-base font-medium px-3 whitespace-nowrap overflow-hidden text-ellipsis flex-shrink"
                          title={`${div2?.participantB}`}
                        >
                          {div2.participantB}
                        </p>
                        <EditThreeButton
                          matchID={div2?.id}
                          tournamentType={tournamentType}
                          setIsRefetch={setIsRefetch}
                          participantID={div2?.participantB_Id}
                          matchData={div2}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between px-3 items-center">
                    <p className="text-sm text-[#000019] font-medium whitespace-nowrap overflow-hidden text-ellipsis w-[50%]">
                      {div2?.venue?.name ? div2?.venue?.name : "Club"}
                      <br />
                      {div2?.venue?.location?.City
                        ? div2?.venue?.location?.City
                        : "City"}
                    </p>
                    <p className="text-sm text-[#000019] font-medium">
                      Date :
                      {` ${div2.dateOfPlay !== null
                        ? moment(div2.dateOfPlay).format("MMM Do YY")
                        : moment().format("MMM Do YY")
                        }`}
                      <br />
                      Time :
                      {` ${div2.timing !== null
                        ? moment(div2.timing).format("LT")
                        : moment().format("LT")
                        }`}
                    </p>
                  </div>
                  {div2?.winner && (
                    <div className="flex justify-center items-center mt-[-1rem]">
                      <div className="w-[100%] bg-green-600 text-center text-white font-bold rounded-md py-1.5">
                        WINNER - {div2?.winner?.toUpperCase()}{" "}
                      </div>
                    </div>
                  )}
                </div>
              ))}
              <ToastContainer />
            </div>
          ))}
        </div>
      </div>

      {editRoundsModal && (
        <EditQuarterFinalModal
          roundsModal={setEditRoundsModal}
          roundID={roundID}
          setIsRefetch={setIsRefetch}
        />
      )}
      {editMatchDetailsModal && (
        <EditMatchDetailsModal
          setEditMatchDetailsModal={setEditMatchDetailsModal}
          matchID={matchID}
          setIsRefetch={setIsRefetch}
        />
      )}
      {addScoreModal && (
        <AddScoreGameFixturesModal
          setAddScoreModal={setAddScoreModal}
          matchID={matchID}
          status={announceStatus}
          setIsRefetch={setIsRefetch}
          tournamentType={tournamentType}
        />
      )}
    </div>
  );
}
