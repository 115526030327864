import React from 'react'
import CustomerList from './CustomerList'

const Customer = () => {
    return (
        <div className='flex flex-col gap-[1rem] rounded-md'>
            <CustomerList/>
        </div>
    )
}

export default Customer
