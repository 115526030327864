import React, { useState, useEffect } from 'react'
import { Checkbox } from '@material-tailwind/react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../store/AuthContext';
import { APIurls } from '../../../api/apiConstant';
import { toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';
import uploadImg from '../../../assets/upload-image.svg'

export default function EditNotification() {
   const [setUpdateNotificationsList] = useOutletContext();
   const [showError, setShowError] = useState(false);
   const [sendTo, setSendTo] = useState({
      customers: false,
      clubOwners: false,
   });
   const [selectImg, setSelectImg] = useState(null);
   const [isSendToAdded, setIsSendToAdded] = useState(true);
   const [loaderBtn, setLoaderBtn] = useState(false);
   const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
   const { id } = useParams();
   const navigate = useNavigate();
   const { getAccessToken } = useAuth();

   const fetchNotificationByIdData = async () => {
      const token = await getAccessToken();
      try {
         const response = await fetch(`${APIurls.fetchNotificationById}/${id}`, {
            method: "GET",
            headers: {
               Authorization: `Bearer ${token}`,
            },
         })
         if (!response.ok) {
            throw new Error("Error");
         }
         const result = await response.json();
         console.log("Fetch Notification By Id Result =>", result?.response?.notification);
         const { title, description, sendTo: { clubOwner, customers }, notificationImg } = result?.response?.notification;
         setValue("title", title);
         setValue("description", description);
         setSendTo((prevState) => {
            return {
               ...prevState,
               customers: customers,
               clubOwners: clubOwner,
            }
         });
         setSelectImg(notificationImg);
      } catch (error) {
         toast.error("Failed to fetch FAQ by Id")
      }
   }
   useEffect(() => {
      fetchNotificationByIdData();
   }, [id])

   const onSubmit = async (data) => {
      if (!(isSendToAdded && selectImg)) {
         setShowError(true);
         return;
      } else {
         setShowError(false);
      }
      const { title, description } = data;
      const formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      formData.append("NotificationImg", selectImg);
      formData.append("sendTo.customers", sendTo.customers);
      formData.append("sendTo.clubOwner", sendTo.clubOwners);
      const token = await getAccessToken();
      const requestOptions = {
         method: "PUT",
         body: formData,
         headers: {
            Authorization: `Bearer ${token}`,
         }
      };
      try {
         setLoaderBtn(true);
         const response = await fetch(`${APIurls.updateNotification}/${id}`, requestOptions)
         if (!response.ok) {
            if (response.status === 400) {
               const errorData = await response.json();
               console.log("Error Data:", errorData);
               toast.error(errorData.message);
               throw new Error(errorData.message);
            } else {
               toast.error("Failed to Update Notification");
               throw new Error("Failed to Update Notification");
            }
         }
         const result = await response.json();
         console.log("Update Notification result", result);
         toast.success("Notification is Updated Successfully!");
         setUpdateNotificationsList(true);
         reset();
         setSelectImg("");
         setSendTo({
            customers: false,
            clubOwners: false,
         })
         setIsSendToAdded(false);
         navigate("/notifications")
      } catch (error) {
         console.error(error.message);
         //toast.error("Failed to Create Agent!")
      }
      setLoaderBtn(false);
   }

   const onChangeCheckHandler = (e, key) => {
      if (e.target.checked) {
         setSendTo((prevState) => {
            const updatedObj = {
               ...prevState,
               [key]: true,
            }
            // To avoid batching of useState hook
            setIsSendToAdded(Object.values(updatedObj).some(value => value === true));
            return updatedObj;
         })
      } else {
         setSendTo((prevState) => {
            const updatedObj = {
               ...prevState,
               [key]: false,
            }
            setIsSendToAdded(Object.values(updatedObj).some(value => value === true));
            return updatedObj;
         })
      }
   }

   return (
      <div>
         <form onSubmit={handleSubmit(onSubmit)}
            className='flex flex-col gap-[1.5rem]'>
            <div className="flex items-center justify-between">
               <h1 className="text-[1.2rem] sm:text-[1.3rem] font-semibold">
                  Edit Notification
               </h1>
               <div className='flex gap-[3rem] items-center'>
                  <button onClick={() => navigate("/notifications")}
                     className={`flex items-center justify-center gap-[0.4rem] text-[1rem] font-semibold text-primary bg-white border
                border-primary py-[0.35rem] w-[6rem] rounded-[5px]`}
                     type="button"
                  >
                     <Icon icon="material-symbols:arrow-back-ios" className="text-[1rem]" />
                     <span>Back</span>
                  </button>
                  {
                     loaderBtn ? (
                        <ThreeDots
                           height="50"
                           width="50"
                           radius="9"
                           color="#FB6108"
                           ariaLabel="three-dots-loading"
                           wrapperStyle={{}}
                           wrapperClassName=""
                           visible={true}
                        />
                     ) : (
                        <button type='submit'
                           className="text-[0.8rem] sm:text-[1rem] flex items-center max-xs:w-full gap-[0.3rem] xs:gap-[0.4rem] bg-primary text-white py-[0.5rem] px-[0.4rem] xs:px-[1rem] rounded-md font-medium">
                           <span className="whitespace-nowrap">Update Notification</span>
                           <Icon
                              icon="material-symbols:add"
                              className="text-[1.2rem] sm:text-[1.4rem]"
                           />
                        </button>
                     )
                  }
               </div>
            </div>
            <div className='flex gap-[3rem] items-start px-[2rem]'>
               <div className='flex flex-col gap-[1.5rem] flex-[2]'>
                  <div className="flex flex-col gap-[0.5rem]">
                     <input
                        className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                        type="text"
                        placeholder="Title"
                        {...register("title", {
                           required: "*This field is required.",
                        })}
                     />
                     {errors.title && (
                        <p className="text-sm text-[#E92215]">
                           {errors.title.message}
                        </p>
                     )}
                  </div>
                  <div className="flex flex-col gap-[0.5rem]">
                     <textarea rows={7}
                        className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                        type="text"
                        placeholder="Description"
                        {...register("description", {
                           required: "*This field is required.",
                        })}
                     />
                     {errors.description && (
                        <p className="text-sm text-[#E92215]">
                           {errors.description.message}
                        </p>
                     )}
                  </div>
                  <div className='flex flex-col gap-[0.5rem]'>
                     <label htmlFor="title" className="text-[0.8rem] sm:text-[1rem] text-[#858585] font-semibold">
                        Select to Send Notification
                     </label>
                     <div className="flex gap-[2rem]">
                        <div className="flex items-center gap-[0.5rem] border border-[#D9D9D9] text-[#858585] pr-[2rem] rounded-md">
                           <Checkbox color="deep-orange" id="clubOwners"
                              checked={sendTo.clubOwners}
                              onChange={(e) => onChangeCheckHandler(e, "clubOwners")}
                           />
                           <label htmlFor="clubOwners" className='cursor-pointer'>
                              Club Owners
                           </label>
                        </div>
                        <div className="flex items-center gap-[0.5rem] border border-[#D9D9D9] text-[#858585] pr-[2rem] rounded-md">
                           <Checkbox color="deep-orange" id="customers"
                              checked={sendTo.customers}
                              onChange={(e) => onChangeCheckHandler(e, "customers")}
                           />
                           <label htmlFor="customers" className='cursor-pointer'>
                              Customers
                           </label>
                        </div>
                     </div>
                     {showError && !sendTo.clubOwners && !sendTo.customers && (
                        <p className="text-sm text-[#E92215]">
                           *Select atleast one of the above
                        </p>
                     )}
                  </div>
               </div>
               <div className='flex-[1]'>
                  <div className="flex flex-col items-center py-[3rem] px-[1rem] border border-[#E1E2E9] bg-[#F6F7FB] rounded-lg">
                     {selectImg ? (
                        <>
                           {
                              selectImg?.name ? (
                                 <img src={URL.createObjectURL(selectImg)} alt="" className='h-[100px] w-[100px] ' />
                              ) : (
                                 <img src={selectImg} alt="" className='h-[100px] w-[100px] ' />
                              )
                           }
                           <div className="flex gap-[1rem] items-center mt-[0.5rem]">
                              {
                                 selectImg?.name && (
                                    <h2 className="text-[1.1rem] text-black font-[500]">
                                       {selectImg?.name?.length > 30
                                          ? selectImg.name.substring(0, 30) + "..."
                                          : selectImg.name}
                                    </h2>
                                 )
                              }
                              <div className='p-[0.1rem] border border-[#D9D9D9] rounded-md hover:bg-[#3D4A5C]/[0.3]'>
                                 <Icon
                                    onClick={() => setSelectImg("")}
                                    icon="akar-icons:cross"
                                    className="text-[1.2rem] cursor-pointer"
                                 />
                              </div>
                           </div>
                        </>
                     ) : (
                        <>
                           <img src={uploadImg} alt="" />
                           <button
                              type="button"
                              onClick={() =>
                                 document.querySelector(".input-field").click()
                              }
                           >
                              <h2 className='text-primary font-[500] flex items-center gap-[0.5rem] my-[1rem]'>
                                 <Icon
                                    icon="material-symbols:cloud-upload"
                                    className="text-[1.5rem]"
                                 />
                                 <span>
                                    Upload Icon
                                 </span>
                              </h2>
                           </button>
                           <input
                              type="file"
                              accept="image/*"
                              value=""
                              className="input-field"
                              hidden
                              onChange={({ target: { files } }) => {
                                 if (files[0]) {
                                    setSelectImg(files[0]);
                                 }
                              }}
                           />
                           <p className='text-[0.9rem] text-[#808080]'>
                              Upload an Image.
                           </p>
                           <p className="text-[0.7rem] text-[#808080]">
                              File Format <span className='text-black'> jpeg, png </span> Recommened Size <span className='text-black'> 600x600 (1:1) </span>
                           </p>
                        </>
                     )}
                  </div>
                  {showError && !selectImg && (
                     <p className="text-sm text-[#E92215] mt-[0.5rem]">
                        *This field is required.
                     </p>
                  )}
               </div>
            </div>
         </form>
      </div>
   )
}


