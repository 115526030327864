import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBnX8ZfknQXzwJ7NYRuhu38n6Yefu-wwE0",
  authDomain: "gamebeezuserapp.firebaseapp.com",
  projectId: "gamebeezuserapp",
  storageBucket: "gamebeezuserapp.appspot.com",
  messagingSenderId: "841991240689",
  appId: "1:841991240689:web:7f3d14b9a10b0cfb3f6250",
  measurementId: "G-BT2D325MHT",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
const analytics = getAnalytics(app);
export const database = getDatabase(app);

export default app;
