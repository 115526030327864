import React from "react";
import { ToastContainer } from "react-toastify";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import { IconButton } from "@material-tailwind/react";
import { useAuth } from "../../../../store/AuthContext";
import { APIurls } from "../../../../api/apiConstant";
import { useQuery } from "react-query";
import moment from "moment";

const customStyles = {
  headRow: {
    style: {
      fontWeight: 600,
      // padding: "10px 10px",
      color: "#4D4D4D",
      fontSize: "13px",
    },
  },
  head: {
    style: {
      // borderRadius: "6px 6px 0px 0px",
      // borderTop: "0.824px solid #E9E9E9",
      background: "#FFF",
      // fontSize: "15px",
    },
  },

  rows: {
    style: {
      // borderRadius: "6px 6px 0px 0px",
      // borderTop: "0.824px solid #E9E9E9",
      background: "#FFF",
      color: "#565656",
      padding: "5px 0px",
      fontSize: "14px",
      fontWeight: 600,
      width: "auto",
    },
  },

  table: {
    style: {
      overflow: "visible",
      maxWidth: "1200px",
    },
  },
  tableWrapper: {
    style: {
      overflow: "visible",
    },
  },
  responsiveWrapper: {
    style: {
      overflowX: "auto", // table scroll on x axis
    },
  },
};
const columns = [
  {
    name: "SL",
    selector: (row, index) => index + 1,
    // center: true,
    // maxWidth: "0.3rem",
    width: "5%"
  },
  {
    name: "Expenses ID",
    selector: (row) => <div className="flex flex-col">{row.expenseId}</div>,
    // center: true,
    // maxWidth: "0.5rem",
    grow: 1,
  },
  {
    name: "Date of Expense",
    selector: (row) => <div className="flex flex-col">{moment(row.createdAt).format("MMM Do YY")}</div>,
    // center: true,
    grow: 1,
  },

  {
    name: "Time of Expenses",
    selector: (row) => <div>{moment(row.createdAt).format("LTS")}</div>,
    center: true,
    grow: 1,
  },
  {
    name: "Remark",
    selector: (row) => <div>{row.remark}</div>,
    center: true,
    grow: 2,
  },
  {
    name: "Expense Type",
    selector: (row) => <div>{row.expenseType}</div>,
    center: true,
    grow: 2,
  },
  {
    name: "Amount",
    selector: (row) => <div>₹{row.amount}</div>,
    center: true,
    grow: 1,
  },
  {
    name: "Status",
    selector: (row) => (
      <div
        className={`flex justify-center items-center gap-[0.5rem]  rounded-md font-semibold
      py-[0.5rem] w-[8rem] ${row.status !== "Active"
            ? "text-[#25BF17] bg-[#D1F0CF]"
            : "text-[#FF0023] bg-[#FDCAD1]"
          }`}
      >
        <h2>{row.payMode}</h2>
      </div>
    ),
    center: true,
    grow: 2.5,
  },
  {
    name: "Image",
    selector: (row) => <div>
      {row.image !== "" ? <img src={row.image} width="50px" className="object-cover" alt="" /> : <p>No image</p>}

    </div>,
    center: true,
    grow: 2,
  },
  {
    name: "Actions",
    cell: (row) => (
      <div className="flex gap-[0.5rem]">
        <Link to={`view/${row.expenseId}`}>
          <IconButton className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]">
            <Icon icon="mdi:eye" className="text-[1.5rem] text-black" />
          </IconButton>
        </Link>
      </div>
    ),
    center: true,
    // grow: 1,
  },
];
const data1 = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];
const RADIAN = Math.PI / 180;
const colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "red", "pink"];
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
const data = [
  {
    name: "Jan",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Feb",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "March",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "April",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "May",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "June",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "July",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Aug",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Sept",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Oct",
    uv: 3490,
    pv: 5000,
    amt: 2100,
  },
  {
    name: "Nov",
    uv: 3490,
    pv: 3000,
    amt: 2100,
  },
  {
    name: "Dec",
    uv: 3490,
    pv: 4900,
    amt: 2100,
  },
];

const getPath = (x, y, width, height) => {
  return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3
    }
  ${x + width / 2}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width
    }, ${y + height}
  Z`;
};

const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

export default function ViewCOExpenses() {
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  const { id } = useParams();

  const { getAccessToken } = useAuth();

  const fetchClubOwnerExpenses = async () => {
    const token = await getAccessToken();
    const response = await fetch(
      `${APIurls.fetchClubOwnerExpensesData}/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    console.log("CLUB OWNER EXPENSES DATA", result?.response?.findExpenses);
    return result?.response?.findExpenses.reverse();
  };
  const {
    data: expensesData,
    isLoading,
    refetch,
  } = useQuery("ClubOwnerExpenses", fetchClubOwnerExpenses);

  return (
    <div className="flex flex-col gap-[2rem]">
      {/* <div className="flex gap-[12px] pl-[30rem]">
        <div className="flex gap-[16px] p-[8px] border-[1px] border-[#53545C] rounded-md">
          <div className="flex items-center gap-[8px] font-medium">
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.30998 14.1902C3.28732 15.1668 4.85798 15.1668 8.00065 15.1668C11.1433 15.1668 12.7147 15.1668 13.6906 14.1902C14.6673 13.2148 14.6673 11.6428 14.6673 8.50016C14.6673 5.3575 14.6673 3.78616 13.6906 2.8095C12.7153 1.8335 11.1433 1.8335 8.00065 1.8335C4.85798 1.8335 3.28665 1.8335 2.30998 2.8095C1.33398 3.78683 1.33398 5.3575 1.33398 8.50016C1.33398 11.6428 1.33398 13.2142 2.30998 14.1902ZM9.83398 11.1668C9.83398 11.2994 9.78131 11.4266 9.68754 11.5204C9.59377 11.6142 9.46659 11.6668 9.33398 11.6668H6.66732C6.53471 11.6668 6.40753 11.6142 6.31376 11.5204C6.22 11.4266 6.16732 11.2994 6.16732 11.1668C6.16732 11.0342 6.22 10.907 6.31376 10.8133C6.40753 10.7195 6.53471 10.6668 6.66732 10.6668H9.33398C9.46659 10.6668 9.59377 10.7195 9.68754 10.8133C9.78131 10.907 9.83398 11.0342 9.83398 11.1668ZM10.6673 9.00016C10.7999 9.00016 10.9271 8.94748 11.0209 8.85372C11.1146 8.75995 11.1673 8.63277 11.1673 8.50016C11.1673 8.36755 11.1146 8.24038 11.0209 8.14661C10.9271 8.05284 10.7999 8.00016 10.6673 8.00016H5.33398C5.20138 8.00016 5.0742 8.05284 4.98043 8.14661C4.88666 8.24038 4.83398 8.36755 4.83398 8.50016C4.83398 8.63277 4.88666 8.75995 4.98043 8.85372C5.0742 8.94748 5.20138 9.00016 5.33398 9.00016H10.6673ZM12.5007 5.8335C12.5007 5.9661 12.448 6.09328 12.3542 6.18705C12.2604 6.28082 12.1333 6.3335 12.0007 6.3335H4.00065C3.86804 6.3335 3.74087 6.28082 3.6471 6.18705C3.55333 6.09328 3.50065 5.9661 3.50065 5.8335C3.50065 5.70089 3.55333 5.57371 3.6471 5.47994C3.74087 5.38617 3.86804 5.3335 4.00065 5.3335H12.0007C12.1333 5.3335 12.2604 5.38617 12.3542 5.47994C12.448 5.57371 12.5007 5.70089 12.5007 5.8335Z"
                fill="#53545C"
              />
            </svg>
            <p>Sort</p>
          </div>
        </div>
        <div className="flex gap-[16px] p-[8px] border-[1px] border-[#53545C] rounded-md">
          <div className="flex items-center gap-[8px] font-medium">
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.3333 2.5C10.6261 2.5 9.94781 2.78095 9.44772 3.28105C8.94762 3.78115 8.66667 4.45942 8.66667 5.16667C8.66667 5.87391 8.94762 6.55219 9.44772 7.05229C9.94781 7.55238 10.6261 7.83333 11.3333 7.83333C12.0406 7.83333 12.7189 7.55238 13.219 7.05229C13.719 6.55219 14 5.87391 14 5.16667C14 4.45942 13.719 3.78115 13.219 3.28105C12.7189 2.78095 12.0406 2.5 11.3333 2.5ZM2 11.8333C2 11.1261 2.28095 10.4478 2.78105 9.94772C3.28115 9.44762 3.95942 9.16667 4.66667 9.16667C5.37391 9.16667 6.05219 9.44762 6.55229 9.94772C7.05238 10.4478 7.33333 11.1261 7.33333 11.8333C7.33333 12.5406 7.05238 13.2189 6.55229 13.719C6.05219 14.219 5.37391 14.5 4.66667 14.5C3.95942 14.5 3.28115 14.219 2.78105 13.719C2.28095 13.2189 2 12.5406 2 11.8333ZM8.66667 9.83333C8.66667 9.65652 8.73691 9.48695 8.86193 9.36193C8.98695 9.23691 9.15652 9.16667 9.33333 9.16667H13.3333C13.5101 9.16667 13.6797 9.23691 13.8047 9.36193C13.9298 9.48695 14 9.65652 14 9.83333V13.1667C14 13.5203 13.8595 13.8594 13.6095 14.1095C13.3594 14.3595 13.0203 14.5 12.6667 14.5H10C9.64638 14.5 9.30724 14.3595 9.05719 14.1095C8.80714 13.8594 8.66667 13.5203 8.66667 13.1667V9.83333ZM2 3.16667C2 2.98986 2.07024 2.82029 2.19526 2.69526C2.32029 2.57024 2.48986 2.5 2.66667 2.5H6.66667C6.84348 2.5 7.01305 2.57024 7.13807 2.69526C7.2631 2.82029 7.33333 2.98986 7.33333 3.16667V6.5C7.33333 6.85362 7.19286 7.19276 6.94281 7.44281C6.69276 7.69286 6.35362 7.83333 6 7.83333H3.33333C2.97971 7.83333 2.64057 7.69286 2.39052 7.44281C2.14048 7.19276 2 6.85362 2 6.5V3.16667Z"
                fill="#53545C"
              />
            </svg>

            <p>Categories</p>
          </div>
        </div>
        <div className="flex gap-[16px] p-[8px] border-[1px] border-[#53545C] rounded-md">
          <div className="flex items-center gap-[8px] font-medium">
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 7.5C3.73478 7.5 3.48043 7.60536 3.29289 7.79289C3.10536 7.98043 3 8.23478 3 8.5C3 8.76522 3.10536 9.01957 3.29289 9.20711C3.48043 9.39464 3.73478 9.5 4 9.5C4.26522 9.5 4.51957 9.39464 4.70711 9.20711C4.89464 9.01957 5 8.76522 5 8.5C5 8.23478 4.89464 7.98043 4.70711 7.79289C4.51957 7.60536 4.26522 7.5 4 7.5ZM7 8.5C7 8.23478 7.10536 7.98043 7.29289 7.79289C7.48043 7.60536 7.73478 7.5 8 7.5C8.26522 7.5 8.51957 7.60536 8.70711 7.79289C8.89464 7.98043 9 8.23478 9 8.5C9 8.76522 8.89464 9.01957 8.70711 9.20711C8.51957 9.39464 8.26522 9.5 8 9.5C7.73478 9.5 7.48043 9.39464 7.29289 9.20711C7.10536 9.01957 7 8.76522 7 8.5ZM11 8.5C11 8.23478 11.1054 7.98043 11.2929 7.79289C11.4804 7.60536 11.7348 7.5 12 7.5C12.2652 7.5 12.5196 7.60536 12.7071 7.79289C12.8946 7.98043 13 8.23478 13 8.5C13 8.76522 12.8946 9.01957 12.7071 9.20711C12.5196 9.39464 12.2652 9.5 12 9.5C11.7348 9.5 11.4804 9.39464 11.2929 9.20711C11.1054 9.01957 11 8.76522 11 8.5Z"
                fill="#53545C"
              />
            </svg>

            <p>Others</p>
          </div>
        </div>
      </div> */}

      {/* <div className="flex gap-[12px] items-center">
        <div>
          <PieChart width={360} height={270}>
            <Pie
              data={data1}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={110}
              fill="#8884d8"
              dataKey="value"
            >
              {data1.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </div>

        {/*Pie Chart Discription Section*/}
      {/*<div className="flex justify-start items-start gap-10">
          <div className="grow shrink flex basis-0 flex-col justify-start items-start gap-3">
            <div className="flex justify-between items-center ">
              <div className=" justify-start items-start gap-2 flex pr-[83px]">
                <div className="w-6 h-6 bg-gradient-to-l from-purple-600 to-indigo-400 rounded" />
                <div className="text-neutral-600 font-medium">Rent (35%)</div>
              </div>
              <div className="text-slate-950 text-base font-semibold ">
                ₹20,000
              </div>
            </div>
            <div className="flex justify-between items-center ">
              <div className=" justify-start items-start gap-2 flex pr-[83px]">
                <div className="w-6 h-6 bg-gradient-to-l from-blue-500 to-sky-400 rounded" />
                <div className="text-neutral-600 font-medium">Rent (35%)</div>
              </div>
              <div className="text-slate-950 text-base font-semibold ">
                ₹20,000
              </div>
            </div>
            <div className="flex justify-between items-center ">
              <div className=" justify-start items-start gap-2 flex pr-[83px]">
                <div className="w-6 h-6 bg-gradient-to-r from-orange-400 to-amber-400 rounded" />
                <div className="text-neutral-600 font-medium">Rent (35%)</div>
              </div>
              <div className="text-slate-950 text-base font-semibold ">
                ₹20,000
              </div>
            </div>
            <div className="flex justify-between items-center ">
              <div className=" justify-start items-start gap-2 flex pr-[83px]">
                <div className="w-6 h-6 bg-gradient-to-b from-red-500 to-red-500 rounded" />
                <div className="text-neutral-600 font-medium">Rent (35%)</div>
              </div>
              <div className="text-slate-950 text-base font-semibold ">
                ₹20,000
              </div>
            </div>
            <div className="flex justify-between items-center ">
              <div className=" justify-start items-start gap-2 flex pr-[83px]">
                <div className="w-6 h-6 bg-gradient-to-tr from-blue-600 to-black rounded" />
                <div className="text-neutral-600 font-medium">Rent (35%)</div>
              </div>
              <div className="text-slate-950 text-base font-semibold ">
                ₹20,000
              </div>
            </div>
            <div className="flex justify-between items-center ">
              <div className=" justify-start items-start gap-2 flex pr-[83px]">
                <div className="w-6 h-6 bg-gradient-to-tr from-pink-400 to-red-500 rounded" />
                <div className="text-neutral-600 font-medium">Rent (35%)</div>
              </div>
              <div className="text-slate-950 text-base font-semibold ">
                ₹20,000
              </div>
            </div>
          </div>
        </div>
        <div className="rounded-[5px] border-[1px] border-[#EFF0F6] p-5 ">
          <div className="border-b-2 border-[#989898]">Overall Expenses</div>
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <Bar dataKey="pv" stackId="a" fill="#FB6108CC" barSize={20} />
            <Bar dataKey="uv" stackId="a" fill="#F2F7FF" barSize={20} />
          </BarChart>
        </div>
      </div> */}

      <div className="flex flex-col gap-[0.5rem] mx-6 ">
        <div className="flex gap-8 justify-between items-center">
          <div className="flex items-center gap-[16rem]">
            <h2 className="text-black text-[16px] font-[500] whitespace-nowrap">
              Expenses
            </h2>
          </div>
          <div className="flex items-center gap-[1rem] ">
            <div className="flex p-[6px] gap-[10px] font-bold bg-[#FFF]  border-[#DFDFDF] border-[1px] rounded-[4px]">
              <Icon
                icon="circum:search"
                className=" text-3xl  text-black text-[1.5rem] rounded-[5px] cursor-pointer"
              />
              <input
                type="text"
                className="w-[176px] h-[24px]  placeholder-[#8F8F8F] text-black text-sm font-semibold outline-none"
                placeholder="Search"
              />
            </div>
            {/* <div className="flex items-center h-[29px] w-[67px] p-[8px] gap-[10px] rounded-[4px] border-[1.5px] border-[#53545C] hover:text-primary cursor-pointer">
              <Icon
                icon="mdi:filter-outline"
                className="text-[16px] font-normal h-[16px] w-[16px]"
              />
              <span className="text-[11px] font-normal leading-[13.31px] text-[#53545C]">
                Filter
              </span>
            </div>
            <div className="flex items-center h-[29px] w-[67px] p-[8px] gap-[10px] rounded-[4px] border-[1.5px] border-[#53545C] hover:text-primary cursor-pointer">
              <Icon
                icon="solar:calendar-linear"
                className="text-[16px] font-normal h-[16px] w-[16px]"
              />
              <span className="text-[11px] font-normal leading-[13.31px] text-[#53545C]">
                Filter
              </span>
            </div>
            <div className="flex items-center h-[29px] w-[67px] p-[8px] gap-[10px] rounded-[4px] border-[1.5px] border-[#53545C] hover:text-primary cursor-pointer">
              <Icon
                icon="mynaui:send"
                className="text-[16px] font-normal h-[16px] w-[16px]"
              />
              <span className="text-[11px] font-normal leading-[13.31px] text-[#53545C]">
                Share
              </span>
            </div>
            <div className="flex items-center h-[29px] w-[99px] p-[8px] gap-[10px] rounded-[4px] border-[1.5px] border-[#53545C] hover:text-primary cursor-pointer">
              <p className="text-[11px] font-normal leading-[13.31px] text-[#53545C] whitespace-nowrap">
                Bulk Action
              </p>
              <Icon
                icon="mingcute:down-line"
                className="text-[16px] font-normal h-[16px] w-[16px]"
              />
            </div> */}
          </div>
        </div>

        <div>
          <DataTable
            columns={columns}
            customStyles={customStyles}
            data={expensesData}
            progressPending={isLoading}
          />
        </div>
        <div className=" h-[48px] bg-white border-b border-gray-200" />
        <ToastContainer />
      </div>
    </div>
  );
}
