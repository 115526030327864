import React, { useState } from 'react'
import { GrNext } from "react-icons/gr";
import { useAuth } from '../../../store/AuthContext';
import { APIurls } from '../../../api/apiConstant';
import { useOutletContext } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

export default function RoleCreation() {

  const [title, setTitle] = useState("")
  const [showError, setShowError] = useState(false)
  const [setUpdateRole] = useOutletContext();

  const { getAccessToken, userRole } = useAuth();

  const handleSubmit = async (e) => {
    let data = ""
    e.preventDefault();
    if (!(title)) {
      setShowError(true)
      return;
    }
    else {
      data = title?.replace(/\s+/g, ' ').trim();
    }
    console.log("Afer removing spaces" + title)
    const token = await getAccessToken();
    try {
      const response = await fetch(`${APIurls.creationOfRole}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ title: data })
      })
      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.message)
      }
      console.log("Title Send Successfully", result)
      toast.success("Title Created Successfully")
      setTitle("")
      setShowError(false)
      setUpdateRole(true)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("CREATE_ROLES")) &&
        <form onSubmit={handleSubmit}>
          <div className='flex justify-between items-center'>
            <h1 className="text-[1.2rem] sm:text-[1.3rem] font-semibold pb-[0.5rem]">
              Create Role
            </h1>
            <button type='submit' className='flex justify-center items-center px-2 py-2 text-white bg-primary rounded-md gap-2'>
              Submit <GrNext />
            </button>
          </div>
          <div className='py-5 flex flex-col justify-center items-center'>
            <input type='text'
              className='w-[60%] bg-[#EFF1F9] px-3 py-2 rounded-md outline-none'
              placeholder='Title'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            {
              !title && showError && (
                <p className='text-sm text-red-600'>*This field is required</p>
              )}
          </div>
          <ToastContainer />
        </form>
      }
    </>
  )
}
