import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "tailwindcss/tailwind.css";
import DataTable from "react-data-table-component";
import moment from "moment";
import { XAxis, Tooltip, Rectangle, Legend } from "recharts";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_red.css";
import { BarChart, Bar } from "recharts";
import { PieChart, Pie, Cell } from "recharts";
import { Icon } from "@iconify/react";
import {
  useGetLatestIventoryReportsQuery,
  useGetPurchasedInventoryReportsQuery,
  useGetTopIventoryReportsQuery,
} from "../../../store/features/clubOwner/clubOwnerApi";
import DownloadExcel from '../../../exportData/ExportExcel';
import pdfLogo from '../../../assets/pdfImage.png';
import { FaFileCsv } from "react-icons/fa6";
import DownloadPDF from '../../../exportData/ExportPDF';
import { useAuth } from "../../../store/AuthContext";

const data1 = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const renderTooltip = ({ active, label, payload }) => {
  if (active) {
    return (
      <div className="bg-[#000019] rounded-lg px-2 py-1 font-bold text-[14px] w-[90px] h-[45px] text-white">
        <p>{label}</p>
        <div className="flex items-center gap-1">
          <Icon
            icon="material-symbols-light:circle"
            className="text-[#FB6108] w-2 h-2"
          />
          <p className="text-[14px]">{payload.length && payload[0]?.value}</p>
        </div>
      </div>
    );
  }

  return null;
};

const renderLegend = (props) => {
  const { payload } = props;

  return (
    <ul className="flex flex-wrap justify-center gap-5 mt-3">
      {payload.map((entry, index) => {
        const { color, payload } = entry;

        const { inventoryCatgeory, percentage } = payload;
        return (
          <li className="flex items-center gap-1" key={`item-${index}`}>
            <p
              style={{ backgroundColor: `${color}` }}
              className="w-6 h-6 rounded-sm"
            ></p>
            <p>{inventoryCatgeory}</p>
            <p className="font-semibold">{percentage}%</p>
          </li>
        );
      })}
    </ul>
  );
};

const Inventory = () => {
  // Local States
  const [latestInventoryActiveText, setLatestInventoryActiveText] =
    useState("MONTH");
  const [puchasedInventoryActiveText, setPuchasedInventoryActiveText] =
    useState("MONTH");
  const [topInventoryActiveText, setTopInventoryActiveText] = useState("MONTH");

  // Date States
  const [latestInventoryStartDate, setLatestInventoryStartDate] =
    useState(null);
  const [latestInventoryEndDate, setLatestInventoryEndDate] = useState(null);

  const [topInventoryStartDate, setTopInventoryStartDate] = useState(null);
  const [topInventoryEndDate, setTopInventoryEndDate] = useState(null);

  const [loading, setLoading] = useState(false);

  const { adminName } = useAuth();

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        // padding: "10px 10px",
        color: "#4D4D4D",
        fontSize: "13px",
      },
    },
    head: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        // fontSize: "15px",
      },
    },

    rows: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        color: "#565656",
        // padding: "10px 10px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "880px",
        border: "1px solid #ddd",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };
  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      // center: true,
      maxWidth: "0.1rem",
    },
    {
      name: "Item Name",
      selector: (row) => (
        <div className="flex items-center justify-center py-2">
          <img className="w-10 h-10 rounded-lg" src={row.itemImage} />
          <div className="text-slate-950 text-base font-normal  ">
            {row.itemName}
          </div>
        </div>
      ),
      minWidth: "10rem",
    },
    {
      name: "Club Name",
      selector: (row) => (
        <div className="text-slate-950 text-base font-normal ">
          {row.clubName}
        </div>
      ),
    },
    {
      name: "Item Price",
      selector: (row) => (
        <div className="text-slate-950 text-base font-normal ">
          {row.itemPrice}
        </div>
      ),
    },
    {
      name: "Quantity",
      selector: (row) => (
        <div className="text-slate-950 text-base font-normal ">
          {row.quantity}
        </div>
      ),
    },
    {
      name: "Date of Purchase",
      selector: (row) => (
        <div className="text-slate-950 text-base font-normal ">
          {moment(row.dateOfPurchase).format("DD/MM/YYYY")}
        </div>
      ),
    },
  ];

  // Redux RTK Query Hooks
  const { data: latestInventoryData } = useGetLatestIventoryReportsQuery({
    latestInventoryStartDate,
    latestInventoryEndDate,
    latestInventoryActiveText,
  });
  const {
    totalInventory,
    totalInventoryCost,
    totalBookingCancled,
    inventories,
  } = latestInventoryData?.response || {};

  const { data: purchasedInventoryData } = useGetPurchasedInventoryReportsQuery(
    { puchasedInventoryActiveText }
  );
  const { inventoryCount } = purchasedInventoryData?.response || {};

  const { data: topInventoryData } = useGetTopIventoryReportsQuery({
    topInventoryActiveText,
    topInventoryStartDate,
    topInventoryEndDate,
  });
  const { occurrences } = topInventoryData?.response || {};
  console.log("Occurences Data======> ", occurrences);

  // Event Handlers
  const handleChangeLatestInventoryStartDate = (selectedDates) => {
    setLatestInventoryStartDate(selectedDates[0]);
    setLatestInventoryActiveText(null);
  };
  const handleChangeLatestInventoryEndDate = (selectedDates) => {
    setLatestInventoryEndDate(selectedDates[0]);
    setLatestInventoryActiveText(null);
  };
  const hanldeChangeLatestInventoryActiveText = (activeText) => {
    setLatestInventoryActiveText(activeText);
    setLatestInventoryStartDate(null);
    setLatestInventoryEndDate(null);
  };
  const hanldeChangePurchasedInventoryActiveText = (activeText) => {
    setPuchasedInventoryActiveText(activeText);
  };

  const handleChangeTopInventoryStartDate = (selectedDates) => {
    setTopInventoryStartDate(selectedDates[0]);
    setTopInventoryActiveText(null);
  };
  const handleChangeTopInventoryEndDate = (selectedDates) => {
    setTopInventoryEndDate(selectedDates[0]);
    setTopInventoryActiveText(null);
  };

  const hanldeChangeTopInventoryActiveText = (activeText) => {
    setTopInventoryActiveText(activeText);
    setTopInventoryStartDate(null);
    setTopInventoryEndDate(null);
  };

  const handleDownloadPDF = async () => {
    setLoading(true);
    try {
      const pdfData = inventories?.map((ele, index) => [
        index + 1,
        ele?.itemName,
        ele?.clubName,
        ele?.itemPrice,
        ele?.quantity,
        moment(ele?.dateOfPurchase).format("DD/MM/YYYY"),
      ]);

      const pdfColumns = ["SL", "Item Name", "Club Name", "Item Price", "Quantity", "Date of Purchase"];

      DownloadPDF(pdfData, pdfColumns, "Latest Inventory.pdf", adminName);
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setLoading(false);
    }
  };

  const excelData = inventories?.map((item, index) => [
    index + 1,
    item?.itemName,
    item?.clubName,
    item?.itemPrice,
    item?.quantity,
    moment(item?.dateOfPurchase).format("DD/MM/YYYY"),
  ])

  const excelColumns = ["SL", "Item Name", "Club Name", "Item Price", "Quantity", "Date of Purchase"];

  return (
    <>
      <div className="flex gap-[2rem] pt-[1rem] pb-[2rem] max-1366px:flex-col overflow-x-auto overflow-x-scrollbar-custom">
        <div className="flex flex-col gap-[1.5rem] p-3 max-1366px:order-2">
          <div className="flex justify-between max-md:gap-2">
            <p className="text-slate-950 text-[24px] font-semibold max-lg:text-[20px]">Latest Inventory</p>
            <div className="flex flex-col items-end gap-[24px] flex-wrap">
              <div className="flex items-end">
                <div
                  className={`px-4 py-2 ${latestInventoryActiveText === "DAY"
                    ? "bg-orange-800 text-white"
                    : "bg-[#e3e1e1]"
                    } cursor-pointer rounded-sm justify-start items-center`}
                  onClick={() => hanldeChangeLatestInventoryActiveText("DAY")}
                  onDoubleClick={() => setLatestInventoryActiveText("")}
                >
                  <div className="text-base font-semibold">Day</div>
                </div>
                <div
                  className={`px-4 py-2 ${latestInventoryActiveText === "WEEK"
                    ? "bg-orange-800 text-white"
                    : "bg-[#e3e1e1]"
                    } cursor-pointer rounded-sm justify-start items-center`}
                  onClick={() => hanldeChangeLatestInventoryActiveText("WEEK")}
                  onDoubleClick={() => setLatestInventoryActiveText("")}
                >
                  <div className="text-base font-semibold ">Week</div>
                </div>
                <div
                  className={`px-4 py-2 ${latestInventoryActiveText === "MONTH"
                    ? "bg-orange-800 text-white"
                    : "bg-[#e3e1e1]"
                    } cursor-pointer rounded-sm justify-start items-center`}
                  onClick={() => hanldeChangeLatestInventoryActiveText("MONTH")}
                  onDoubleClick={() => setLatestInventoryActiveText("")}
                >
                  <div className="text-neutral-600 text-base font-semibold">
                    Month
                  </div>
                </div>
                <div
                  className={`px-4 py-2 ${latestInventoryActiveText === "YEAR"
                    ? "bg-orange-800 text-white"
                    : "bg-[#e3e1e1]"
                    } cursor-pointer rounded-sm justify-start items-center`}
                  onClick={() => hanldeChangeLatestInventoryActiveText("YEAR")}
                  onDoubleClick={() => setLatestInventoryActiveText("")}
                >
                  <div className="text-neutral-600 text-base font-semibold">
                    Year
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-2 justify-end">
                <div className='flex gap-3 items-center rounded-md pr-2'>
                  {loading ? (
                    <p className="text-xs">Generating PDF...</p>
                  ) : (
                    <img
                      src={pdfLogo}
                      className='w-[25px] cursor-pointer'
                      onClick={handleDownloadPDF}
                      alt="Generate PDF"
                    />
                  )}
                  <FaFileCsv
                    className='w-[25px] h-[30px] text-green-800 cursor-pointer'
                    onClick={() => DownloadExcel({ excelData, excelColumns })}
                  />
                </div>
                <div className="mr-2">
                  <Flatpickr
                    className="mr-2 sm:text-[1rem] w-[130px] h-[40px] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                    placeholder={"From"}
                    options={{
                      mode: "single",
                      dateFormat: "d M Y",
                      onChange: handleChangeLatestInventoryStartDate,
                    }}
                    value={latestInventoryStartDate}
                  />

                  <Flatpickr
                    className="sm:text-[1rem] w-[130px] h-[40px] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                    placeholder={"To"}
                    options={{
                      mode: "single",
                      dateFormat: "d M Y",
                      onChange: handleChangeLatestInventoryEndDate,
                    }}
                    value={latestInventoryEndDate}
                  />
                </div>
              </div>
            </div>
          </div>
          <DataTable
            columns={columns}
            customStyles={customStyles}
            data={inventories}
            pagination
            paginationPerPage={10}
          />
        </div>
        <div className="grid grid-cols-1 gap-4 max-1366px:!flex max-1366px:flex-row max-1366px:flex-wrap">
          <div className="w-[260px] p-6 bg-orange-50 rounded-2xl flex-col justify-center items-start gap-4 inline-flex">
            <div className="pr-10">
              <div className="p-2 bg-orange-100 rounded-[50px] justify-center items-center inline-flex">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_6102_42853)">
                    <path
                      d="M11.1973 17.7227H12.8036V19.3289H11.1973V17.7227Z"
                      fill="#FB6108"
                    />
                    <path
                      d="M17.5977 17.7227H19.2039V19.3289H17.5977V17.7227Z"
                      fill="#FB6108"
                    />
                    <path
                      d="M4.79492 11.6953H6.40123V13.3015H4.79492V11.6953Z"
                      fill="#FB6108"
                    />
                    <path
                      d="M4.79492 17.7227H6.40123V19.3289H4.79492V17.7227Z"
                      fill="#FB6108"
                    />
                    <path
                      d="M21.414 2.00623H20.8875V4.21564C20.8875 4.60395 20.5727 4.91877 20.1844 4.91877C19.7961 4.91877 19.4813 4.60395 19.4813 4.21564V2.00623V1.20312C19.4813 0.814812 19.1664 0.5 18.7781 0.5C18.3898 0.5 18.075 0.814812 18.075 1.20312V2.00623H16.3688V4.21564C16.3688 4.60395 16.0539 4.91877 15.6656 4.91877C15.2773 4.91877 14.9625 4.60395 14.9625 4.21564V2.00623V1.20312C14.9625 0.814812 14.6477 0.5 14.2594 0.5C13.8711 0.5 13.5563 0.814812 13.5563 1.20312V2.00623H11.85V4.21564C11.85 4.60395 11.5352 4.91877 11.1469 4.91877C10.7586 4.91877 10.4438 4.60395 10.4438 4.21564V2.00623V1.20312C10.4438 0.814812 10.1289 0.5 9.74063 0.5C9.35231 0.5 9.0375 0.814812 9.0375 1.20312V2.00623H7.33125V4.21564C7.33125 4.60395 7.01644 4.91877 6.62812 4.91877C6.23981 4.91877 5.925 4.60395 5.925 4.21564V2.00623V1.20312C5.925 0.814812 5.61019 0.5 5.22188 0.5C4.83356 0.5 4.51875 0.814812 4.51875 1.20312V2.00623H2.58591C1.16006 2.00623 0 3.1663 0 4.59219V6.52498H24V4.59219C24 3.1663 22.8399 2.00623 21.414 2.00623Z"
                      fill="#FB6108"
                    />
                    <path
                      d="M0 21.9125C0 23.3384 1.16006 24.4985 2.58591 24.4985H21.414C22.8399 24.4985 24 23.3384 24 21.9125V7.92969H0V21.9125ZM14.2094 20.0297C14.2094 20.418 13.8945 20.7328 13.5062 20.7328H10.4937C10.1054 20.7328 9.79055 20.418 9.79055 20.0297V17.0172C9.79055 16.6289 10.1054 16.3141 10.4937 16.3141H13.5062C13.8945 16.3141 14.2094 16.6289 14.2094 17.0172V20.0297ZM16.1922 10.9922C16.1922 10.6039 16.507 10.2891 16.8953 10.2891H19.9078C20.2961 10.2891 20.6109 10.6039 20.6109 10.9922V14.0047C20.6109 14.393 20.2961 14.7078 19.9078 14.7078H16.8953C16.507 14.7078 16.1922 14.393 16.1922 14.0047V10.9922ZM16.1922 17.0172C16.1922 16.6289 16.507 16.3141 16.8953 16.3141H19.9078C20.2961 16.3141 20.6109 16.6289 20.6109 17.0172V20.0297C20.6109 20.418 20.2961 20.7328 19.9078 20.7328H16.8953C16.507 20.7328 16.1922 20.418 16.1922 20.0297V17.0172ZM9.90525 12.0013C10.1798 11.7267 10.625 11.7267 10.8996 12.0013L11.4675 12.5692L13.1004 10.9362C13.375 10.6617 13.8202 10.6616 14.0948 10.9362C14.3694 11.2108 14.3694 11.656 14.0948 11.9306L11.9648 14.0607C11.8329 14.1926 11.6541 14.2667 11.4676 14.2667C11.2811 14.2667 11.1023 14.1927 10.9704 14.0607L9.9053 12.9956C9.63066 12.7211 9.63066 12.2758 9.90525 12.0013ZM3.38902 10.9922C3.38902 10.6039 3.70383 10.2891 4.09214 10.2891H7.1047C7.49302 10.2891 7.80783 10.6039 7.80783 10.9922V14.0047C7.80783 14.393 7.49302 14.7078 7.1047 14.7078H4.09214C3.70383 14.7078 3.38902 14.393 3.38902 14.0047V10.9922ZM3.38902 17.0172C3.38902 16.6289 3.70383 16.3141 4.09214 16.3141H7.1047C7.49302 16.3141 7.80783 16.6289 7.80783 17.0172V20.0297C7.80783 20.418 7.49302 20.7328 7.1047 20.7328H4.09214C3.70383 20.7328 3.38902 20.418 3.38902 20.0297V17.0172Z"
                      fill="#FB6108"
                    />
                    <path
                      d="M17.5977 11.6953H19.2039V13.3015H17.5977V11.6953Z"
                      fill="#FB6108"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6102_42853">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="flex-col justify-start items-start gap-2 flex">
                <div className="text-slate-950 text-2xl font-bold ">
                  {totalInventory}
                </div>
                <div className="flex-col justify-start items-start gap-2 flex">
                  <div className="tex t-neutral-600 text-base font-medium ">
                    Inventory{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[260px] p-6 bg-orange-50 rounded-2xl flex-col justify-center items-start gap-4 inline-flex">
            <div className="bg-[#FFE1CE] rounded-[50px] justify-center items-center flex">
              <Icon
                icon="mdi:person-group"
                className="text-[2rem] text-orange-800"
              />
            </div>
            <div className="pr-10">
              <div className="flex-col justify-start items-start gap-2 flex">
                <p className="text-slate-950 text-2xl font-bold ">
                  {totalInventoryCost}
                </p>
                <div className="flex-col justify-start items-start flex">
                  <div className="tex t-neutral-600 text-base font-medium ">
                    Total Inventory Cost
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[260px] p-6 bg-orange-50 rounded-2xl flex-col justify-center items-start gap-4 inline-flex">
            <div className="pr-10">
              <div className=" p-2 bg-orange-100 rounded-[50px] justify-center items-center inline-flex">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_6102_42853)">
                    <path
                      d="M11.1973 17.7227H12.8036V19.3289H11.1973V17.7227Z"
                      fill="#FB6108"
                    />
                    <path
                      d="M17.5977 17.7227H19.2039V19.3289H17.5977V17.7227Z"
                      fill="#FB6108"
                    />
                    <path
                      d="M4.79492 11.6953H6.40123V13.3015H4.79492V11.6953Z"
                      fill="#FB6108"
                    />
                    <path
                      d="M4.79492 17.7227H6.40123V19.3289H4.79492V17.7227Z"
                      fill="#FB6108"
                    />
                    <path
                      d="M21.414 2.00623H20.8875V4.21564C20.8875 4.60395 20.5727 4.91877 20.1844 4.91877C19.7961 4.91877 19.4813 4.60395 19.4813 4.21564V2.00623V1.20312C19.4813 0.814812 19.1664 0.5 18.7781 0.5C18.3898 0.5 18.075 0.814812 18.075 1.20312V2.00623H16.3688V4.21564C16.3688 4.60395 16.0539 4.91877 15.6656 4.91877C15.2773 4.91877 14.9625 4.60395 14.9625 4.21564V2.00623V1.20312C14.9625 0.814812 14.6477 0.5 14.2594 0.5C13.8711 0.5 13.5563 0.814812 13.5563 1.20312V2.00623H11.85V4.21564C11.85 4.60395 11.5352 4.91877 11.1469 4.91877C10.7586 4.91877 10.4438 4.60395 10.4438 4.21564V2.00623V1.20312C10.4438 0.814812 10.1289 0.5 9.74063 0.5C9.35231 0.5 9.0375 0.814812 9.0375 1.20312V2.00623H7.33125V4.21564C7.33125 4.60395 7.01644 4.91877 6.62812 4.91877C6.23981 4.91877 5.925 4.60395 5.925 4.21564V2.00623V1.20312C5.925 0.814812 5.61019 0.5 5.22188 0.5C4.83356 0.5 4.51875 0.814812 4.51875 1.20312V2.00623H2.58591C1.16006 2.00623 0 3.1663 0 4.59219V6.52498H24V4.59219C24 3.1663 22.8399 2.00623 21.414 2.00623Z"
                      fill="#FB6108"
                    />
                    <path
                      d="M0 21.9125C0 23.3384 1.16006 24.4985 2.58591 24.4985H21.414C22.8399 24.4985 24 23.3384 24 21.9125V7.92969H0V21.9125ZM14.2094 20.0297C14.2094 20.418 13.8945 20.7328 13.5062 20.7328H10.4937C10.1054 20.7328 9.79055 20.418 9.79055 20.0297V17.0172C9.79055 16.6289 10.1054 16.3141 10.4937 16.3141H13.5062C13.8945 16.3141 14.2094 16.6289 14.2094 17.0172V20.0297ZM16.1922 10.9922C16.1922 10.6039 16.507 10.2891 16.8953 10.2891H19.9078C20.2961 10.2891 20.6109 10.6039 20.6109 10.9922V14.0047C20.6109 14.393 20.2961 14.7078 19.9078 14.7078H16.8953C16.507 14.7078 16.1922 14.393 16.1922 14.0047V10.9922ZM16.1922 17.0172C16.1922 16.6289 16.507 16.3141 16.8953 16.3141H19.9078C20.2961 16.3141 20.6109 16.6289 20.6109 17.0172V20.0297C20.6109 20.418 20.2961 20.7328 19.9078 20.7328H16.8953C16.507 20.7328 16.1922 20.418 16.1922 20.0297V17.0172ZM9.90525 12.0013C10.1798 11.7267 10.625 11.7267 10.8996 12.0013L11.4675 12.5692L13.1004 10.9362C13.375 10.6617 13.8202 10.6616 14.0948 10.9362C14.3694 11.2108 14.3694 11.656 14.0948 11.9306L11.9648 14.0607C11.8329 14.1926 11.6541 14.2667 11.4676 14.2667C11.2811 14.2667 11.1023 14.1927 10.9704 14.0607L9.9053 12.9956C9.63066 12.7211 9.63066 12.2758 9.90525 12.0013ZM3.38902 10.9922C3.38902 10.6039 3.70383 10.2891 4.09214 10.2891H7.1047C7.49302 10.2891 7.80783 10.6039 7.80783 10.9922V14.0047C7.80783 14.393 7.49302 14.7078 7.1047 14.7078H4.09214C3.70383 14.7078 3.38902 14.393 3.38902 14.0047V10.9922ZM3.38902 17.0172C3.38902 16.6289 3.70383 16.3141 4.09214 16.3141H7.1047C7.49302 16.3141 7.80783 16.6289 7.80783 17.0172V20.0297C7.80783 20.418 7.49302 20.7328 7.1047 20.7328H4.09214C3.70383 20.7328 3.38902 20.418 3.38902 20.0297V17.0172Z"
                      fill="#FB6108"
                    />
                    <path
                      d="M17.5977 11.6953H19.2039V13.3015H17.5977V11.6953Z"
                      fill="#FB6108"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6102_42853">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="flex-col gap-2 flex">
                <p className="text-slate-950 text-2xl font-bold ">
                  {totalBookingCancled}
                </p>
                <p className="tex t-neutral-600 text-base font-medium">Total Bookings Cancelled</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="border border-gray-200 px-5 py-5 mb-[2rem] min-h-[500px]">
          <div className="flex justify-between items-center">
            <p className="text-slate-950 text-[24px] font-semibold max-lg:text-[20px]">Inventory Purchased</p>
            <div className="flex justify-between items-center gap-[24px] ">
              <div className="flex justify-center items-end ">
                <div
                  className={`px-4 py-2 ${puchasedInventoryActiveText === "WEEK"
                    ? "bg-orange-800 text-white"
                    : "bg-[#e3e1e1]"
                    } cursor-pointer rounded-sm justify-start items-center`}
                  onClick={() =>
                    hanldeChangePurchasedInventoryActiveText("WEEK")
                  }
                >
                  <div className="text-base font-semibold ">Week</div>
                </div>
                <div
                  className={`px-4 py-2 ${puchasedInventoryActiveText === "MONTH"
                    ? "bg-orange-800 text-white"
                    : "bg-[#e3e1e1]"
                    } cursor-pointer rounded-sm justify-start items-center`}
                  onClick={() =>
                    hanldeChangePurchasedInventoryActiveText("MONTH")
                  }
                >
                  <div className="text-base font-semibold ">Month</div>
                </div>
                <div
                  className={`px-4 py-2 ${puchasedInventoryActiveText === "YEAR"
                    ? "bg-orange-800 text-white"
                    : "bg-[#e3e1e1]"
                    } cursor-pointer rounded-sm justify-start items-center`}
                  onClick={() =>
                    hanldeChangePurchasedInventoryActiveText("YEAR")
                  }
                >
                  <div className="text-base font-semibold ">Year</div>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-auto overflow-x-scrollbar-custom">
            <BarChart
              width={1000}
              height={400}
              data={inventoryCount}
              margin={{
                top: 5,
                right: 0,
                left: 20,
                bottom: 5,
              }}
              barSize={20}
              tick={{ fontSize: 10, color: "black", fontWeight: "bold" }}
              interval={0}
            >
              <XAxis
                dataKey={
                  puchasedInventoryActiveText === "WEEK"
                    ? "day"
                    : puchasedInventoryActiveText.toLowerCase()
                }
                scale="point"
                padding={{ left: 10, right: 10 }}
              />
              <Bar
                dataKey="count"
                fill="#FB6108"
                opacity="40%"
                activeBar={<Rectangle opacity="100%" />}
              />
              <Tooltip cursor={{ fill: "transparent" }} content={renderTooltip} />
            </BarChart>
          </div>

        </div>

        <div className="border border-gray-200 mb-[1.5rem] p-[16px] overflow-auto overflow-x-scrollbar-custom">
          <div className="grid grid-cols-1 gap-[24px] items-center">
            <div className="flex justify-between">
              <p className="text-slate-950 text-[24px] font-semibold max-lg:text-[20px]">Top Inventory</p>
              <div className="flex items-end gap-6 max-lg:flex max-lg:flex-col">
                <div className="flex items-center gap-[24px]">
                  {/* From */}
                  <Flatpickr
                    className="text-[0.75rem] sm:text-[1rem] w-[130px] h-[40px] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                    placeholder={"From"}
                    options={{
                      mode: "single",
                      dateFormat: "d M Y",
                      onChange: handleChangeTopInventoryStartDate,
                    }}
                    value={topInventoryStartDate}
                  />
                  <Flatpickr
                    className="text-[0.75rem] sm:text-[1rem] w-[130px] h-[40px] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                    placeholder={"To"}
                    options={{
                      mode: "single",
                      dateFormat: "d M Y",
                      onChange: handleChangeTopInventoryEndDate,
                    }}
                    value={topInventoryEndDate}
                  />
                </div>
                <div className="flex items-center gap-[24px] ">
                  <div className="flex justify-center items-end ">
                    <div
                      className={`px-4 py-2 ${topInventoryActiveText === "DAY"
                        ? "bg-orange-800 text-white"
                        : "bg-[#e3e1e1]"
                        } cursor-pointer rounded-sm justify-start items-center`}
                      onClick={() => hanldeChangeTopInventoryActiveText("DAY")}
                      onDoubleClick={() => setTopInventoryActiveText("")}
                    >
                      <div className="text-base font-semibold ">Day</div>
                    </div>
                    <div
                      className={`px-4 py-2 ${topInventoryActiveText === "WEEK"
                        ? "bg-orange-800 text-white"
                        : "bg-[#e3e1e1]"
                        } cursor-pointer rounded-sm justify-start items-center`}
                      onClick={() => hanldeChangeTopInventoryActiveText("WEEK")}
                      onDoubleClick={() => setTopInventoryActiveText("")}
                    >
                      <div className="text-base font-semibold ">Week</div>
                    </div>
                    <div
                      className={`px-4 py-2 ${topInventoryActiveText === "MONTH"
                        ? "bg-orange-800 text-white"
                        : "bg-[#e3e1e1]"
                        } cursor-pointer rounded-sm justify-start items-center`}
                      onClick={() => hanldeChangeTopInventoryActiveText("MONTH")}
                      onDoubleClick={() => setTopInventoryActiveText("")}
                    >
                      <div className="text-base font-semibold ">Month</div>
                    </div>
                    <div
                      className={`px-4 py-2 ${topInventoryActiveText === "YEAR"
                        ? "bg-orange-800 text-white"
                        : "bg-[#e3e1e1]"
                        } cursor-pointer rounded-sm justify-start items-center`}
                      onClick={() => hanldeChangeTopInventoryActiveText("YEAR")}
                      onDoubleClick={() => setTopInventoryActiveText("")}
                    >
                      <div className="text-base font-semibold ">Year</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            {
              occurrences?.length === 0
                ?
                <p className="text-gray-500 text-center text-[1.25rem] my-[3rem]">No Data Found!</p>
                :
                <PieChart width={1000} height={300}>
                  <Pie
                    data={occurrences}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={110}
                    fill="#8884d8"
                    dataKey="percentage"
                  >
                    {data1.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Legend content={renderLegend} wrapperStyle={{ display: 'flex', justifyContent: 'start' }} />
                </PieChart>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Inventory;
