import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import HeaderStepper from '../../../../helpers/HeaderStepper';
import { useAuth } from "../../../../store/AuthContext";
import { APIurls } from "../../../../api/apiConstant";
import { useDispatch, useSelector } from 'react-redux';
import { updateHowToPlayInfo, updateLoaderBtn, updateSportInfo } from '../../../../store/createSubCategorySlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditSportsInfo from './EditSportsInfo';
import EditHowToPlayInfo from './EditHowToPlayInfo';
import EditStepperController from '../../../../helpers/EditStepperController';
import { updateEditHowToPlayInfo, updateEditLoaderBtn, updateEditSportInfo } from '../../../../store/editSubCategorySlice';


export default function EditSubCategory() {
   const [currentStep, setCurrentStep] = useState(1);
   const [steps, setSteps] = useState(["Sport Information", "How to Play Information"]);
   const [showError, setShowError] = useState(false);
   const [error, setError] = useState(false);
   const { getAccessToken } = useAuth()
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { id } = useParams();
   const {
      subCategoryName, subcat_img, minPlayers, maxPlayers
   } = useSelector((store) => store.editSubCategory.editSportInfo);

   const { qna } = useSelector((store) => store.editSubCategory.editHowToPlayInfo);

   const fetchSubCategoryDataById = async () => {
      const token = await getAccessToken()
      try {
         const response = await fetch(`${APIurls.fetchSubCategoryById}/${id}`, {
            method: "GET",
            headers: {
               Authorization: `Bearer ${token}`,
            }
         });
         if (!response.ok) {
            throw new Error("Error");
         }
         const result = await response.json();
         console.log("Edit Sub Category Result", result?.response?.subCategory);
         const {
            mainCategoryID: { categoryName }, subCategoryName, subcat_img, minPlayers, maxPlayers, howToPlay
         } = result?.response?.subCategory;
         dispatch(updateEditSportInfo({
            maincategoryName: categoryName,
            subCategoryName: subCategoryName,
            subcat_img: subcat_img,
            minPlayers: minPlayers,
            maxPlayers: maxPlayers,
         }))
         dispatch(updateEditHowToPlayInfo({
            qna: howToPlay,
         }))
      } catch (error) {
         console.warn("Error Fetching Agent Data");
         toast.error("Error Fetching Agent Data");
      }
   };
   useEffect(() => {
      fetchSubCategoryDataById();
   }, [])

   const displayStep = () => {
      switch (currentStep) {
         case 1: return <EditSportsInfo showError={showError} setError={setError} />;
         case 2: return <EditHowToPlayInfo showError={showError} setError={setError} />;
         default: break;
      }
   }

   const handleClick = async (direction) => {
      let newStep = currentStep;
      if (direction === "next") {
         newStep++;
      } else if (direction === "back") {
         newStep--;
      } else if (direction === "update") {
         updateSubCategory();
      }

      if (newStep > 0 && newStep <= steps.length) {
         setCurrentStep(newStep);
      }
   }

   async function updateSubCategory() {
      const formData = new FormData();
      formData.append('subCategoryName', subCategoryName);
      formData.append('subcat_img', subcat_img);
      formData.append('maxPlayers', maxPlayers);
      formData.append('minPlayers', minPlayers);
      formData.append("howToPlay", JSON.stringify(qna));

      const token = await getAccessToken();

      const requestOptions = {
         method: "PUT",
         body: formData,
         headers: {
            Authorization: `Bearer ${token}`,
         }
      }

      try {
         dispatch(updateEditLoaderBtn(true));
         const response = await fetch(`${APIurls.updateSubCategory}/${id}`, requestOptions);
         if (!response.ok) {
            dispatch(updateEditLoaderBtn(false));
            if (response.status === 400) {
               // Handle 400 status code error
               const errorData = await response.json();
               // console.log("Error Data:", errorData);
               // You can perform specific actions for a 400 status code here
               // For example, display a specific error message to the user
               toast.error(errorData.message);
               throw new Error(errorData.message);
            } else {
               toast.error("Failed to Update SubCategory");
               throw new Error("Error");
            }
         }
         const result = await response.json();
         console.log("SubCategory Result", result);
         dispatch(updateEditLoaderBtn(false));
         toast.success("SubCategory Updated Successfully!");
         // clear the input field data from redux store.
         dispatch(updateEditSportInfo({
            maincategoryId: "",
            subCategoryName: "",
            subcat_img: "",
            minPlayers: "",
            maxPlayers: "",
         }))
         dispatch(updateEditHowToPlayInfo({
            question: "",
            answer: "",
            qna: [],
         }))
         setTimeout(() => {
            navigate("/categories/sub-categories");
         }, 3000)
      } catch (error) {
         console.log(error.response);
         //toast.error("Failed to Create Agent!")
      }
   }

   return (
      <div className='bg-white rounded-md px-[1.5rem] py-[1rem] h-full'>
         <div className='flex flex-col gap-[1.5rem]'>
            <div className='flex items-center justify-between'>
               <div className='flex gap-[0.2rem] items-center text-[#808080] font-semibold'>
                  <Link to={"/categories"}>
                     <h2>
                        Categories
                     </h2>
                  </Link>
                  <Icon icon="material-symbols:arrow-forward-ios" className="text-[1.3rem]" />
                  <Link to={"/categories/sub-categories"}>
                     <h2>
                        Sub-Categories
                     </h2>
                  </Link>
                  <Icon icon="material-symbols:arrow-forward-ios" className="text-[1.3rem]" />
                  <h2 className='text-black text-[1.3rem]'>
                     Edit Sub-Category
                  </h2>
               </div>
               <div>
                  <EditStepperController currentStep={currentStep} steps={steps} handleClick={handleClick} setShowError={setShowError} error={error} />
                  {/* <CategoryStepperController currentStep={currentStep} steps={steps} /> */}
               </div>
            </div>
            <div>
               <HeaderStepper currentStep={currentStep} steps={steps} />
               {/* <CategoryHeaderStepper steps={steps} currentStep={currentStep} /> */}
            </div>
            <div>
               {displayStep()}
            </div>
            <ToastContainer />
         </div>
      </div>
   )
}

