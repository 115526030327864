import React, { useEffect, useState } from "react"
import { IconButton } from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import { PulseLoader } from "react-spinners";
import { AiOutlinePlus } from "react-icons/ai";
import { MdCloudUpload } from "react-icons/md";
import uploadImage from '../../../../assets/upload-image.svg'
import { RxCross2 } from "react-icons/rx";
import { APIurls } from "../../../../api/apiConstant";
import { useAuth } from "../../../../store/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";

export default function AddCoClubOwner({ setAddCoClubOwnerModal, clubMongoId, setRefetch }) {

    const [previewImage, setPreviewImage] = useState("")
    const [selectedFile, setSelectedFile] = useState({})
    const [loader, setLoader] = useState(false);
    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();

    const { getAccessToken } = useAuth();

    const imageFile = watch("image");

    useEffect(() => {
        if (imageFile && imageFile?.length > 0) {
            const file = imageFile[0];
            setSelectedFile(file);
            const previewUrl = URL.createObjectURL(file);
            setPreviewImage(previewUrl);
        } else {
            setPreviewImage("");
        }
    }, [imageFile]);

    const handleAddCoClubOwner = async (data) => {

        const { Co_Owner_Name, dateOfBirth, email, mobileNumber } = data

        const formData = new FormData();
        formData.append("name", Co_Owner_Name);
        formData.append("mobile", mobileNumber);
        formData.append("email", email);
        formData.append("dob", dateOfBirth);
        formData.append("coOwnerImg", selectedFile);

        try {
            setLoader(true);
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.addCoOwner}/${clubMongoId}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData
            })
            const result = await response.json();
            if (result.code > 201) {
                toast.error(result?.message);
                throw new Error(result.message);
            }
            console.log("Co-Club Owner Details Submitted Successfully", result?.response);
            setLoader(false);
            setRefetch(true);
            toast.success("Co-Club Owner Added Successfully");
            setTimeout(() => { setAddCoClubOwnerModal(false) }, 2000);
            return;
        } catch (error) {
            toast.error(error?.message);
            console.error("Error while submitting Co-Club Owner Details", error.message)
        }
        setLoader(false);
    }

    return (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-[#000000E6]">
            <div className="flex flex-col justify-center gap-4 bg-white rounded-sm px-6 py-8 w-[35%] max-xl:w-[30%] relative h-[80%] overflow-y-auto">
                <div className="flex items-center justify-between">
                    <h3 className="text-xl font-semibold max-lg:text-[17px]">Add Co-Club Owner</h3>
                    <IconButton
                        onClick={() => setAddCoClubOwnerModal(false)}
                        className={"shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-sm"}
                        size="sm"
                    >
                        <Icon icon="material-symbols:close" className="text-[1.5rem]" />
                    </IconButton>
                </div>
                <form onSubmit={handleSubmit(handleAddCoClubOwner)}>
                    <div className="flex flex-col gap-[1.5rem]">
                        <div className="flex items-center gap-[3rem] max-xl:flex-col max-xl:gap-[1.5rem]">
                            <div className="w-full">
                                <input
                                    placeholder="Enter Name"
                                    className="w-full font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                                    {...register("Co_Owner_Name", {
                                        required: "*This field is required"
                                    })}
                                />
                                {errors?.Co_Owner_Name && (
                                    <p className="text-red-500 text-xs">{errors?.Co_Owner_Name?.message}</p>
                                )}
                            </div>
                            <div className="w-full">
                                <input
                                    placeholder="Enter Mobile Number"
                                    type="number"
                                    className="w-full font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                                    maxLength={13}
                                    {...register("mobileNumber", {
                                        required: "*This field is required"
                                    })}
                                />
                                {errors?.mobileNumber && (
                                    <p className="text-red-500 text-xs">{errors?.mobileNumber?.message}</p>
                                )}
                            </div>
                        </div>
                        <div className="flex items-center gap-[3rem] max-xl:flex-col max-xl:gap-[1.5rem]">
                            <div className="w-[100%]">
                                <input
                                    placeholder="Enter Email"
                                    className="w-[100%] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                                    {...register("email", {
                                        required: "*This field is required"
                                    })}
                                />
                                {errors?.email && (
                                    <p className="text-red-500 text-xs">{errors?.email?.message}</p>
                                )}
                            </div>
                            <div className="w-[100%]">
                                <input
                                    type="date"
                                    className="w-[100%] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                                    {...register("dateOfBirth", {
                                        required: "*Select Date of Birth"
                                    })}
                                />
                                {errors?.dateOfBirth && (
                                    <p className="text-red-500 text-xs">{errors?.dateOfBirth?.message}</p>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col w-[100%]">
                            <div className="flex flex-col h-[225px] items-center justify-center bg-[#F4F5FA]">
                                <div className="w-[100%] flex justify-center">
                                    {previewImage !== "" ?
                                        <div className='flex flex-col items-center justify-center'>
                                            <img src={previewImage} alt="club_owner_img" className='w-[50%] pb-4' />
                                            <RxCross2
                                                className='text-[1.5rem] border border-gray-300 rounded-md text-white bg-red-400 cursor-pointer'
                                                onClick={() => setPreviewImage("")}
                                            />
                                        </div>
                                        :
                                        <div>
                                            <div className="flex justify-center items-center">
                                                <img src={uploadImage} className="w-10 " alt="upload img" />
                                            </div>
                                            <div
                                                className="text-primary flex items-center gap-3 cursor-pointer justify-center"
                                                onClick={() => document.getElementById("fileInput").click()}
                                            >
                                                <MdCloudUpload className="w-[2rem] h-[2rem]" />
                                                <p>Upload Banner</p>
                                            </div>
                                            <p className="text-[#808080] text-sm text-center max-lg:text-[12px]">
                                                Upload an image of Organizer/Sponsor
                                            </p>
                                            <p className="text-[0.8rem] text-[#808080] text-center max-lg:text-[12px]">
                                                File Format <span className="text-black"> jpeg, png </span>
                                                <br />
                                                Recommended Size{" "}
                                                <span className="text-black"> 600x600 (1:1) </span>
                                            </p>
                                            <input
                                                type="file"
                                                id="fileInput"
                                                className="hidden"
                                                accept="image/*"
                                                {...register("image", {
                                                    required: "*Image is required",
                                                })}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                            {errors?.image && (
                                <p className="text-red-500 text-xs">{errors?.image?.message}</p>
                            )}
                        </div>
                    </div>
                    <div className="">
                        {loader ? <PulseLoader color="orange" size={8} style={{marginTop:"10px"}}/>
                            :
                            <button
                                className="w-[90px] flex items-center justify-center gap-1 text-white bg-primary rounded-md px-3 py-2 mt-[1.5rem]"
                                type="submit"
                            >
                                <AiOutlinePlus />
                                Add
                            </button>
                        }
                    </div>
                </form>
            </div>
            <ToastContainer />
        </div>
    )
}