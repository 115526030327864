import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

export default function AdminClubOwners() {
   useEffect(() => {
      window.scrollTo({
         top: 0,
         behavior: "smooth"
      })
   }, [])
   return (
      <div className='bg-secondary py-[1rem] px-[1.5rem] h-full'>
         <Outlet />
      </div>
   )
}
