import React, { useState } from 'react'
import { Icon } from '@iconify/react'
import SportsInfo from './SportsInfo';
import HowToPlayInfo from './HowToPlayInfo';
import { Link, useNavigate } from 'react-router-dom';
import HeaderStepper from '../../../helpers/HeaderStepper';
import StepperController from '../../../helpers/StepperController';
import { useAuth } from "../../../store/AuthContext";
import { APIurls } from "../../../api/apiConstant";
import { useDispatch, useSelector } from 'react-redux';
import { updateHowToPlayInfo, updateLoaderBtn, updateSportInfo } from '../../../store/createSubCategorySlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function CreateSubCategory() {
   const [currentStep, setCurrentStep] = useState(1);
   const [steps, setSteps] = useState(["Sport Information", "How to Play Information"]);
   const [showError, setShowError] = useState(false);
   const [error, setError] = useState(false);
   const { getAccessToken } = useAuth()
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { maincategoryId,
      subCategoryName,
      subcat_img,
      minPlayers,
      maxPlayers } = useSelector((store) => store.createSubCategory.sportInfo);

   const { qna } = useSelector((store) => store.createSubCategory.howToPlayInfo);
   // const { loaderBtn } = useSelector((store) => store.createSubCategory);

   const displayStep = () => {
      switch (currentStep) {
         case 1: return <SportsInfo showError={showError} setError={setError} />;
         case 2: return <HowToPlayInfo showError={showError} setError={setError} />;
         default: break;
      }
   }

   const handleClick = async (direction) => {
      let newStep = currentStep;
      if (direction === "next") {
         newStep++;
      } else if (direction === "back") {
         newStep--;
      } else if (direction === "create") {
         createSubCategory();
      }

      if (newStep > 0 && newStep <= steps.length) {
         setCurrentStep(newStep);
      }
   }

   async function createSubCategory() {
      const formData = new FormData();
      formData.append('maincategoryId', maincategoryId);
      formData.append('subCategoryName', subCategoryName);
      formData.append('subcat_img', subcat_img);
      formData.append('maxPlayer', maxPlayers);
      formData.append('minPlayer', minPlayers);
      formData.append("howToPlay", JSON.stringify(qna));

      const token = await getAccessToken();

      const requestOptions = {
         method: "POST",
         body: formData,
         headers: {
            Authorization: `Bearer ${token}`,
         }
      }

      try {
         dispatch(updateLoaderBtn(true));
         const response = await fetch(APIurls.createSubCategories, requestOptions);
         if (!response.ok) {
            dispatch(updateLoaderBtn(false));
            if (response.status === 400) {
               // Handle 400 status code error
               const errorData = await response.json();
               // console.log("Error Data:", errorData);
               // You can perform specific actions for a 400 status code here
               // For example, display a specific error message to the user
               toast.error(errorData.message);
               throw new Error(errorData.message);
            } else {
               toast.error("Failed to Create Sub Category");
               throw new Error("Failed to Create Sub Category");
            }
         }
         const result = await response.json();
         console.log("Sub Category", result);
         dispatch(updateLoaderBtn(false));
         toast.success("Sub Category Created Successfully");
         // clear the input field data from redux store.
         dispatch(updateSportInfo({
            maincategoryId: "",
            subCategoryName: "",
            subcat_img: "",
            minPlayers: "",
            maxPlayers: "",
         }))
         dispatch(updateHowToPlayInfo({
            question: "",
            answer: "",
            qna: [],
         }))
         setTimeout(() => {
            navigate("/categories/sub-categories");
         }, 3000)
      } catch (error) {
         console.log(error.response);
         //toast.error("Failed to Create Agent!")
      }
   }

   return (
      <div className='bg-white rounded-md px-[1.5rem] py-[1rem] h-full'>
         <div className='flex flex-col gap-[1.5rem]'>
            <div className='flex items-center justify-between'>
               <div className='flex gap-[0.2rem] items-center text-[#808080] font-semibold'>
                  <Link to={"/categories"}>
                     <h2>
                        Categories
                     </h2>
                  </Link>
                  <Icon icon="material-symbols:arrow-forward-ios" className="text-[1.3rem]" />
                  <Link to={"/categories/sub-categories"}>
                     <h2>
                        Sub-Categories
                     </h2>
                  </Link>
                  <Icon icon="material-symbols:arrow-forward-ios" className="text-[1.3rem]" />
                  <h2 className='text-black text-[1.3rem]'>
                     Add New Sub-Category
                  </h2>
               </div>
               <div>
                  <StepperController currentStep={currentStep} steps={steps} handleClick={handleClick} setShowError={setShowError} error={error} />
                  {/* <CategoryStepperController currentStep={currentStep} steps={steps} /> */}
               </div>
            </div>
            <div>
               <HeaderStepper currentStep={currentStep} steps={steps} />
               {/* <CategoryHeaderStepper steps={steps} currentStep={currentStep} /> */}
            </div>
            <div>
               {displayStep()}
            </div>
            <ToastContainer />
         </div>
      </div>
   )
}
