import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editSportInfo: {
        maincategoryName: "",
        subCategoryName: "",
        subcat_img: "",
        minPlayers: "",
        maxPlayers: "",
    },
    editHowToPlayInfo: {
        question: "",
        answer: "",
        qna: [],
    },
    editLoaderBtn: false,
}

const editSubCategorySlice = createSlice({
    name: 'editSubCategory',
    initialState: initialState,
    reducers: {
        updateEditSportInfo(state, action) {
            state.editSportInfo = { ...state.editSportInfo, ...action.payload };
        },
        updateEditHowToPlayInfo(state, action) {
            state.editHowToPlayInfo = { ...state.editHowToPlayInfo, ...action.payload };
        },
        updateEditLoaderBtn(state, action) {
            state.loaderBtn = action.payload;
        },
    }
})

export const { updateEditSportInfo, updateEditHowToPlayInfo, updateEditLoaderBtn } = editSubCategorySlice.actions;

export default editSubCategorySlice.reducer;