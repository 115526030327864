import React, { useState } from 'react'
import { Icon } from '@iconify/react'
import { Button, IconButton, Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react'
import { Link, NavLink } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import { ThreeDots } from 'react-loader-spinner'
import CustomCheckbox from '../../../helpers/CustomCheckbox'

export default function ChatSupportList() {
   const [loadingStates, setLoadingStates] = useState({});

   const customStyles = {
      headRow: {
         style: {
            fontWeight: 600,
            // padding: "10px 10px",
            color: "#4D4D4D",
            fontSize: "13px",
         },
      },
      head: {
         style: {
            background: "#FFF",
         },
      },

      rows: {
         style: {
            background: "#FFF",
            color: "#565656",
            // padding: "10px 10px",
            fontSize: "14px",
            fontWeight: 400,
            width: "auto",
         },
      },
      table: {
         style: {
            overflow: "visible",
            minWidth: "1100px",
         },
      },
      tableWrapper: {
         style: {
            overflow: "visible",
         },
      },
      responsiveWrapper: {
         style: {
            overflowX: "auto",
         },
      },
   };
   const columns = [
      {
         name: "SL",
         selector: (row, index) => index + 1,
         center: true,
         maxWidth: "0.5rem",
      },
      {
         name: 'Support ID',
         id: "supportId",
         selector: row => (
            <Link to={`view/:supportId`}>
               <span className='underline'>12345</span>
            </Link>
         ),
         center: true,
      },
      {
         name: 'Customer Information',
         id: "customerInformation",
         selector: row => (
            <div>
               <p>Manikanta Putta</p>
               <p>manikantaputta@gmail.com</p>
               <p>9876543210</p>
            </div>
         ),
         center: true,
         grow: 2,
      },
      {
         name: 'Issue',
         id: "issue",
         selector: row => row.issue,
         center: true,
      },
      {
         name: 'Issue Date & Time',
         di: 'issueDateTime',
         selector: (row) => (
            <div>
               <p>{moment(row.createdAt).format("DD/MM/YYYY")}</p>
               <p>{moment(row.createdAt).format("hh:mm A")}</p>
            </div>
         ),
         center: true,
         grow: 1.5,
         sortable: true,
      },
      {
         name: "Status",
         id: "status",
         selector: (row) => (
            <div className={`flex justify-center items-center gap-[0.4rem] rounded-md font-semibold
            py-[0.3rem] w-[5.5rem] ${row.status === "Solved" ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"}`}>
               <span className={`${row.status === "Solved" ? "bg-[#25BF17]" : "bg-[#FF0023]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
               <h2>{row.status}</h2>
            </div>
         ),
         center: true,
         grow: 1,
         sortable: true,
      },
      {
         name: 'Solved Date & Time',
         di: 'solvedDateTime',
         selector: (row) => (
            row.status !== "Pending" ? <div>
               <p>{moment(row.createdAt).format("DD/MM/YYYY")}</p>
               <p>{moment(row.createdAt).format("hh:mm A")}</p>
            </div>
               : "---"
         ),
         sortable: true,
         center: true,
         grow: 1.5,
      },
      {
         name: "Actions",
         id: "action",
         cell: (row) => (
            <div className="flex gap-[0.5rem] items-center">
               <IconButton
                  className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
               >
                  <Icon
                     icon="ic:baseline-edit"
                     className="text-[1.5rem] text-black"
                  />
               </IconButton>
               <IconButton
                  className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
               >
                  <Icon
                     icon="material-symbols:delete-rounded"
                     className="text-[1.5rem] text-black"
                  />
               </IconButton>
            </div>
         ),
         center: true,
         grow: 1,
      },
   ];
   const data = [
      {
         issue: "Payment",
         status: "Solved",
      },
      {
         issue: "Offers",
         status: "Pending",
      },
      {
         issue: "Coupons",
         status: "Solved",
      },
      {
         issue: "Coupons",
         status: "Solved",
      },
   ]

   return (
      <div className='flex flex-col gap-[1rem]'>
         <div className='flex gap-[1rem] justify-between'>
            <div className='flex flex-col bg-[#FFF3EC] px-[1rem] py-[0.5rem] rounded-[4px] w-full'>
               <h2 className='font-semibold'>
                  Total Tickets
               </h2>
               <p className='text-[1.8rem] font-bold'>
                  500
               </p>
            </div>
            <div className='flex flex-col bg-[#FFF3EC] px-[1rem] py-[0.5rem] rounded-[4px] w-full'>
               <h2 className='font-semibold'>
                  Total Total Solved
               </h2>
               <p className='text-[1.8rem] font-bold'>
                  400
               </p>
            </div>
            <div className='flex flex-col bg-[#FFF3EC] px-[1rem] py-[0.5rem] rounded-[4px] w-full'>
               <h2 className='font-semibold'>
                  Pending
               </h2>
               <p className='text-[1.8rem] font-bold'>
                  100
               </p>
            </div>
         </div>
         <div className='bg-white px-[1rem] py-[0.8rem] rounded-md flex flex-col gap-[0.5rem]'>
            <div className='flex items-center justify-between'>
               <h2 className='text-[1.3rem] font-semibold'>
                  Chat Support
               </h2>
               <div>
                  <div className='flex items-center gap-[2rem]'>
                     <div className='flex items-center gap-[0.4rem] hover:text-primary cursor-pointer'>
                        <Icon
                           icon='material-symbols:delete'
                           className='text-[1.5rem]'
                        />
                        <span className='text-[1rem] font-medium'>Remove</span>
                     </div>
                     <div className='flex items-center gap-[0.3rem] hover:text-[#FB6108] cursor-pointer'>
                        <p className='text-[1rem] font-medium'>Change Status</p>
                        <Icon
                           icon='mingcute:down-line'
                           className='text-[1.5rem]'
                        />
                     </div>
                     <div className="flex flex-row gap-[0.5rem] xs:gap-[1rem] items-center justify-center border border-[#CBCBCB] rounded-[5px]">
                        <div className="">
                           <Menu>
                              <MenuHandler>
                                 <Button className="text-[0.8rem] sm:text-[1rem] flex w-full flex-row justify-center whitespace-nowrap capitalize text-black bg-white px-[0.4rem] xs:px-[1rem] py-[0.4rem] rounded-md items-center gap-[0.5rem] sm:gap-[0.8rem] hover:shadow-none shadow-none">
                                    <Icon
                                       icon="mdi:cloud-download"
                                       className="text-[1.5rem]"
                                    />
                                    <span className="font-[500]">Export</span>
                                 </Button>
                              </MenuHandler>
                              <MenuList>
                                 <MenuItem
                                    //  onClick={downloadPdf}
                                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                                 >
                                    {" "}
                                    <Icon
                                       icon="fa-solid:file-pdf"
                                       className="text-[#BE0A23] w-[1rem] h-auto"
                                    />
                                    Download as PDF
                                 </MenuItem>
                                 <hr className="my-[0.1rem]" />
                                 <MenuItem
                                    //  onClick={downloadCSV}
                                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                                 >
                                    <Icon
                                       icon="fa-solid:file-csv"
                                       className="text-[#29672E] w-[1rem] h-auto"
                                    />
                                    Download as CSV
                                 </MenuItem>
                              </MenuList>
                           </Menu>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div>
               <DataTable
                  columns={columns}
                  customStyles={customStyles}
                  data={data}
                  pagination
                  selectableRows
                  paginationPerPage={10}
                  // progressPending={isLoading}
                  selectableRowsComponent={CustomCheckbox}
               />
            </div>
         </div>
      </div>
   )
}
