import { useEffect, useState } from "react";
import {
  XAxis,
  Tooltip,
  Legend,
  Rectangle,
} from "recharts";
import { PieChart, Pie, Cell } from "recharts";
import { BarChart, Bar } from "recharts";
import DataTable from "react-data-table-component";
import {
  useGetDashBoardTopClubsReportsQuery,
  useGetDashBoardTopSportsReportsQuery,
  useGetDashboardCardsAndJoinReportsQuery,
} from "../../store/features/clubOwner/clubOwnerApi";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useAuth } from "../../store/AuthContext";
import { APIurls } from "../../api/apiConstant";
import Flatpickr from "react-flatpickr";
import { useQuery } from "react-query";
import pdfLogo from '../../assets/pdfImage.png'
import { FaFileCsv } from "react-icons/fa6";
import gamebeezLogo from '../../assets/GB logo For PDF.jpg'
import moment from "moment";
import DownloadPDF from "../../exportData/ExportPDF";
import DownloadExcel from "../../exportData/ExportExcel";

const columns = [
  {
    name: "SL",
    selector: (row, index) => index + 1,
    center: true,
    maxWidth: "0.5rem",
  },

  {
    name: "Name",
    selector: (row) => row?.clubName,
    center: true,
    grow: 3,
  },
  {
    name: "Total bookings",
    selector: (row) => row?.noOfBookings,
    center: true,
    grow: 1,
  },
];

const customStyles = {
  headRow: {
    style: {
      fontWeight: 600,
      padding: "10px 10px",
      color: "#212529",
    },
  },
  head: {
    style: {
      borderTop: "0.824px solid #E9E9E9",
      background: "#FFF",
      fontSize: "15px",
    },
  },

  rows: {
    style: {
      background: "#FFF",
      color: "#565656",
      padding: "10px 10px",
      fontSize: "14px",
      fontWeight: 400,
      width: "auto",
    },
  },
  pagination: {
    style: {
      boxShadow: "10px 5px 5px #ddd",
      marginBottom: "5px",
    },
  },
  table: {
    style: {
      overflow: "visible",
      maxWidth: "1100px",
    },
  },
  tableWrapper: {
    style: {
      overflow: "visible",
    },
  },
  responsiveWrapper: {
    style: {
      overflowX: "auto",
    },
  },
};

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const renderLegend = (props) => {
  const { payload } = props;

  return (
    <ul className="flex items-center justify-center flex-wrap gap-5 mt-3">
      {payload.map((entry, index) => {
        const { color, payload } = entry;

        const { categoryName, percentage } = payload;
        return (
          <li className="flex items-center gap-1" key={`item-${index}`}>
            <p
              style={{ backgroundColor: `${color}` }}
              className="w-6 h-6 rounded-sm"
            ></p>
            <p>{categoryName}</p>
            <p className="font-semibold">{percentage}%</p>
          </li>
        );
      })}
    </ul>
  );
};

const renderTooltip = ({ active, label, payload }) => {
  if (active) {
    return (
      <div className="bg-[#000019] rounded-lg px-2 py-1 font-bold text-[14px] w-[75px] h-[45px] text-white">
        <p>{label}</p>
        <div className="flex items-center gap-1">
          <Icon
            icon="material-symbols-light:circle"
            className="text-[#FB6108] w-2 h-2"
          />
          <p className="text-[14px]">{payload.length && payload[0]?.value}</p>
        </div>
      </div>
    );
  }

  return null;
};

const Dashboard = () => {


  const { getAccessToken, adminName } = useAuth();
  // local states
  const [connectionStatus, setConnectionStatus] = useState("ONLINE");
  const [customersJoinedInterval, setCustomersJoinedInterval] =
    useState("MONTH");
  const [topSportsInterval, setTopSportsInterval] = useState("MONTH");
  const [topClubsInterval, setTopClubsInterval] = useState("MONTH");
  const [dashBoardStats, setDashBoardStats] = useState("MONTH");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [date, setDate] = useState("");
  const [recordsPerPage, setRecordsPerPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }, [])

  //Redux hooks
  const { data: dashboardCardsAndJoinedData } =
    useGetDashboardCardsAndJoinReportsQuery({
      connectionStatus,
      intervalType: customersJoinedInterval,
    });
  const { customerCount } =
    dashboardCardsAndJoinedData?.response || {};

  const { data: topSportsData } = useGetDashBoardTopSportsReportsQuery({
    connectionStatus,
    intervalType: topSportsInterval,
  });
  const { occurrences: topSportsOccurrences } = topSportsData?.response || {};

  // event handlers
  const handleChangeConnectionStatus = (e) => {
    setConnectionStatus(e.target.value);
  };
  const handleChangeCustomersJoinedInterval = (inverval) => {
    setCustomersJoinedInterval(inverval);
  };
  const handleChangeTopSportsInterval = (inverval) => {
    setTopSportsInterval(inverval);
  };
  const handleChangeTopClubsInterval = (inverval) => {
    setTopClubsInterval(inverval);
  };

  const handleDashBoardStats = (interval) => {
    setDashBoardStats(interval);
  }

  const fetchDashBoardStatsData = async () => {
    const token = await getAccessToken();
    const response = await fetch(`${APIurls.fetchDashBoardStats}/${connectionStatus}?dateType=${dashBoardStats}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.message);
    }
    const result = await response.json();
    // console.log("Dashboard Stats", result?.response);
    return result?.response;
  }

  const { data: dashBoardStatsData } = useQuery(["getDashBoardStats", connectionStatus, dashBoardStats], fetchDashBoardStatsData)

  const fetchDashBoardTopClubsData = async () => {
    const token = await getAccessToken();
    const response = await fetch(`${APIurls.fetchDashBoardTopClubs}/${connectionStatus}?dateType=${topClubsInterval}&page=${String(currentPage)}&limit=${String(recordsPerPage)}&startDate=${startDate}&endDate=${endDate}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    if (!response.ok) {
      const result = await response.json();
      throw new Error(result?.message);
    }
    const result = await response.json();
    console.log("Result", result?.response);
    return result?.response;
  }

  const { data: dashBoardTopClubsData, isLoading: topClubsIsLoading } = useQuery(["getDashBoardTopClubs", connectionStatus, topClubsInterval, currentPage, recordsPerPage, startDate, endDate], fetchDashBoardTopClubsData)

  const handleDownloadPDF = async () => {
    setLoading(true);
    try {
      const pdfData = dashBoardTopClubsData?.topClubs?.map((ele, index) => [
        index + 1,
        ele?.clubName,
        ele?.noOfBookings,
      ]);

      const pdfColumns = ['SL', "Name", "Total bookings"];

      await DownloadPDF(pdfData, pdfColumns, "Top Clubs.pdf", adminName);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
    setLoading(false);
  };

  const excelData = dashBoardTopClubsData?.topClubs?.map((ele, index) => [
    index + 1,
    ele?.clubName,
    ele?.noOfBookings,
  ]);

  const excelColumns = ['SL', "Name", "Total bookings"];

  const dateOptions = {
    mode: "range",
    dateFormat: "d-m-Y",
  };

  const handleDateChange = (selectedDates) => {
    setDate(selectedDates);
    if (selectedDates.length === 1) {
      setStartDate(selectedDates[0]);
    }
    if (selectedDates.length === 2) {
      setEndDate(selectedDates[1]);
    }
  };

  const handleDirection = (direction) => {
    if (direction === "right") {
      const currPg = currentPage + 1;
      if (currentPage === dashBoardTopClubsData?.totalPages || dashBoardTopClubsData?.topClubs?.length === 0) {
        setCurrentPage(dashBoardTopClubsData?.totalPages);
      } else {
        setCurrentPage(currPg);
      }
    } else if (direction === "left") {
      const currPg = currentPage - 1;
      if (currentPage === 1) {
        setCurrentPage(1);
      } else if (dashBoardTopClubsData?.topClubs?.length === 0) {
        setCurrentPage(1);
      } else {
        setCurrentPage(currPg);
      }
    }
  };

  return (
    <div className="bg-secondary px-[1.5rem] py-[1rem] h-full">
      <div className="bg-white  p-6 rounded-md">
        <div className="rounded-l flex flex-col gap-[2rem] bg-white">
          {/* Headig Section */}
          <div className="flex items-center justify-between w-full">
            <div className="flex-col flex justify-start items-start gap-2">
              <div className="text-orange-600 text-2xl font-bold ">
                Hello
              </div>
              <div className="text-black text-base font-medium ">
                Track your business data and insights.
              </div>
            </div>
            <div className="bg-neutral-100 rounded-sm items-center flex">
              <div className="flex items-center pr-3">
                <div
                  onClick={() => handleDashBoardStats("DAY")}
                  className={`${dashBoardStats === "DAY" && "bg-orange-600 text-white"
                    } px-4 py-1 rounded-sm justify-start items-center flex cursor-pointer`}
                >
                  <div className="text-base font-semibold ">Today</div>
                </div>
                <div
                  onClick={() => handleDashBoardStats("WEEK")}
                  className={`${dashBoardStats === "WEEK" && "bg-orange-600 text-white"
                    } px-4 py-1 rounded-sm justify-start items-center flex cursor-pointer`}
                >
                  <div className="text-base font-semibold ">Week</div>
                </div>
                <div
                  onClick={() => handleDashBoardStats("MONTH")}
                  className={`${dashBoardStats === "MONTH" &&
                    "bg-orange-600 text-white"
                    } px-4 py-1 rounded-sm justify-start items-center flex cursor-pointer`}
                >
                  <div className="text-base font-semibold ">Month</div>
                </div>
                <div
                  onClick={() => handleDashBoardStats("YEAR")}
                  className={`${dashBoardStats === "YEAR" &&
                    "bg-orange-600 text-white"
                    } px-4 py-1 rounded-sm justify-start items-center flex cursor-pointer`}
                >
                  <div className="text-base font-semibold ">Year</div>
                </div>
              </div>
              <select
                onChange={handleChangeConnectionStatus}
                className="outline-none border-[1px] font-semibold text-orange-500 cursor-pointer border-orange-500 rounded-sm py-1 px-3"
              >
                <option value="ONLINE">ONLINE</option>
                <option value="OFFLINE">OFFLINE</option>
              </select>
            </div>
          </div>

          {/* Intro Section */}
          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-10">
            <div className="flex py-5 pl-6 pr-9 bg-orange-50 rounded-2xl flex-col justify-start items-start gap-4 shadow-2xl">
              <div className="w-10 h-10 bg-orange-100 rounded-[50px] flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clip-path="url(#clip0_7410_31362)">
                    <path
                      d="M11.1973 17.2227H12.8036V18.8289H11.1973V17.2227Z"
                      fill="#FB6108"
                    />
                    <path
                      d="M17.5977 17.2227H19.2039V18.8289H17.5977V17.2227Z"
                      fill="#FB6108"
                    />
                    <path
                      d="M4.79492 11.1953H6.40123V12.8015H4.79492V11.1953Z"
                      fill="#FB6108"
                    />
                    <path
                      d="M4.79492 17.2227H6.40123V18.8289H4.79492V17.2227Z"
                      fill="#FB6108"
                    />
                    <path
                      d="M21.414 1.50623H20.8875V3.71564C20.8875 4.10395 20.5727 4.41877 20.1844 4.41877C19.7961 4.41877 19.4813 4.10395 19.4813 3.71564V1.50623V0.703125C19.4813 0.314812 19.1664 0 18.7781 0C18.3898 0 18.075 0.314812 18.075 0.703125V1.50623H16.3688V3.71564C16.3688 4.10395 16.0539 4.41877 15.6656 4.41877C15.2773 4.41877 14.9625 4.10395 14.9625 3.71564V1.50623V0.703125C14.9625 0.314812 14.6477 0 14.2594 0C13.8711 0 13.5563 0.314812 13.5563 0.703125V1.50623H11.85V3.71564C11.85 4.10395 11.5352 4.41877 11.1469 4.41877C10.7586 4.41877 10.4438 4.10395 10.4438 3.71564V1.50623V0.703125C10.4438 0.314812 10.1289 0 9.74063 0C9.35231 0 9.0375 0.314812 9.0375 0.703125V1.50623H7.33125V3.71564C7.33125 4.10395 7.01644 4.41877 6.62812 4.41877C6.23981 4.41877 5.925 4.10395 5.925 3.71564V1.50623V0.703125C5.925 0.314812 5.61019 0 5.22188 0C4.83356 0 4.51875 0.314812 4.51875 0.703125V1.50623H2.58591C1.16006 1.50623 0 2.6663 0 4.09219V6.02498H24V4.09219C24 2.6663 22.8399 1.50623 21.414 1.50623Z"
                      fill="#FB6108"
                    />
                    <path
                      d="M0 21.4125C0 22.8384 1.16006 23.9985 2.58591 23.9985H21.414C22.8399 23.9985 24 22.8384 24 21.4125V7.42969H0V21.4125ZM14.2094 19.5297C14.2094 19.918 13.8945 20.2328 13.5062 20.2328H10.4937C10.1054 20.2328 9.79055 19.918 9.79055 19.5297V16.5172C9.79055 16.1289 10.1054 15.8141 10.4937 15.8141H13.5062C13.8945 15.8141 14.2094 16.1289 14.2094 16.5172V19.5297ZM16.1922 10.4922C16.1922 10.1039 16.507 9.78909 16.8953 9.78909H19.9078C20.2961 9.78909 20.6109 10.1039 20.6109 10.4922V13.5047C20.6109 13.893 20.2961 14.2078 19.9078 14.2078H16.8953C16.507 14.2078 16.1922 13.893 16.1922 13.5047V10.4922ZM16.1922 16.5172C16.1922 16.1289 16.507 15.8141 16.8953 15.8141H19.9078C20.2961 15.8141 20.6109 16.1289 20.6109 16.5172V19.5297C20.6109 19.918 20.2961 20.2328 19.9078 20.2328H16.8953C16.507 20.2328 16.1922 19.918 16.1922 19.5297V16.5172ZM9.90525 11.5013C10.1798 11.2267 10.625 11.2267 10.8996 11.5013L11.4675 12.0692L13.1004 10.4362C13.375 10.1617 13.8202 10.1616 14.0948 10.4362C14.3694 10.7108 14.3694 11.156 14.0948 11.4306L11.9648 13.5607C11.8329 13.6926 11.6541 13.7667 11.4676 13.7667C11.2811 13.7667 11.1023 13.6927 10.9704 13.5607L9.9053 12.4956C9.63066 12.2211 9.63066 11.7758 9.90525 11.5013ZM3.38902 10.4922C3.38902 10.1039 3.70383 9.78909 4.09214 9.78909H7.1047C7.49302 9.78909 7.80783 10.1039 7.80783 10.4922V13.5047C7.80783 13.893 7.49302 14.2078 7.1047 14.2078H4.09214C3.70383 14.2078 3.38902 13.893 3.38902 13.5047V10.4922ZM3.38902 16.5172C3.38902 16.1289 3.70383 15.8141 4.09214 15.8141H7.1047C7.49302 15.8141 7.80783 16.1289 7.80783 16.5172V19.5297C7.80783 19.918 7.49302 20.2328 7.1047 20.2328H4.09214C3.70383 20.2328 3.38902 19.918 3.38902 19.5297V16.5172Z"
                      fill="#FB6108"
                    />
                    <path
                      d="M17.5977 11.1953H19.2039V12.8015H17.5977V11.1953Z"
                      fill="#FB6108"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7410_31362">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="flex-col justify-start items-start gap-2 flex">
                <div className="text-slate-950 text-2xl font-bold ">
                  {dashBoardStatsData?.totalBookings ? dashBoardStatsData?.totalBookings : 0}
                </div>
                <div className="flex-col justify-start items-start gap-2 flex">
                  <div className="text-neutral-600 text-base font-medium ">
                    Total Bookings
                  </div>
                </div>
              </div>
            </div>

            <div className="flex py-5 pl-6 pr-9 bg-orange-50 rounded-2xl flex-col justify-start items-start gap-4 shadow-2xl">
              <div className="w-10 h-10 p-2 bg-orange-100 rounded-[50px] justify-center items-center inline-flex">
                <div className="w-6 h-6 rounded-[50px] relative top-[-8px] left-[-8px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="41"
                    height="40"
                    viewBox="0 0 41 40"
                    fill="none"
                  >
                    <rect
                      x="0.666504"
                      width="40"
                      height="40"
                      rx="20"
                      fill="#FFE1CE"
                    />
                    <path
                      d="M13.029 32.0189V29.297H8.6665V32.0189"
                      fill="#FB6108"
                      fill-opacity="0.41"
                    />
                    <path
                      d="M19.5778 32.0179V26.0156H15.2153V32.0179"
                      fill="#FB6108"
                      fill-opacity="0.67"
                    />
                    <path
                      d="M26.1149 32.0168V11.1602H21.7524V32.0168"
                      fill="#FB6108"
                      fill-opacity="0.41"
                    />
                    <path
                      d="M32.6667 32.0154V16.7422H28.3042V32.0154"
                      fill="#FB6108"
                    />
                    <path
                      d="M8.6665 27.6548L15.216 21.1058L17.3914 23.2927L32.6659 8.01953"
                      stroke="#FB6108"
                      stroke-width="1.14582"
                    />
                    <path
                      d="M32.6667 12.3816V8.01953H28.3042"
                      stroke="#FB6108"
                      stroke-width="1.14582"
                    />
                  </svg>
                </div>
              </div>
              <div className="flex-col justify-start items-start gap-2 flex">
                <div className="text-slate-950 text-2xl font-bold ">
                  ₹{Math.round(dashBoardStatsData?.totalRevenue ? dashBoardStatsData?.totalRevenue : 0)}
                </div>
                <div className="flex-col justify-start items-start gap-2 flex">
                  <div className="text-neutral-600 text-base font-medium ">
                    Total Revenue
                  </div>
                  {/* <div className="text-green-600 text-xs font-medium ">
                    +20% from yesterday
                  </div> */}
                </div>
              </div>
            </div>

            <div className="flex py-5 pl-6 pr-9 bg-orange-50 rounded-2xl flex-col justify-start items-start gap-4 shadow-2xl">
              <div className="w-10 h-10 flex justify-center items-center bg-orange-100 rounded-[50px]">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_7410_31392)">
                    <path
                      d="M12 9.6875C14.2523 9.6875 16.0781 7.86166 16.0781 5.60938C16.0781 3.35709 14.2523 1.53125 12 1.53125C9.74771 1.53125 7.92188 3.35709 7.92188 5.60938C7.92188 7.86166 9.74771 9.6875 12 9.6875Z"
                      fill="#FB6108"
                    />
                    <path
                      d="M20.25 9.6875C21.6739 9.6875 22.8281 8.53323 22.8281 7.10938C22.8281 5.68552 21.6739 4.53125 20.25 4.53125C18.8261 4.53125 17.6719 5.68552 17.6719 7.10938C17.6719 8.53323 18.8261 9.6875 20.25 9.6875Z"
                      fill="#FB6108"
                    />
                    <path
                      d="M3.75 9.6875C5.17386 9.6875 6.32812 8.53323 6.32812 7.10938C6.32812 5.68552 5.17386 4.53125 3.75 4.53125C2.32614 4.53125 1.17188 5.68552 1.17188 7.10938C1.17188 8.53323 2.32614 9.6875 3.75 9.6875Z"
                      fill="#FB6108"
                    />
                    <path
                      d="M6.29016 12.0036C5.27531 11.1721 4.35623 11.2822 3.18281 11.2822C1.42781 11.2822 0 12.7015 0 14.4458V19.565C0 20.3225 0.618281 20.9384 1.37859 20.9384C4.66106 20.9384 4.26562 20.9978 4.26562 20.7969C4.26562 17.1694 3.83597 14.5092 6.29016 12.0036Z"
                      fill="#FB6108"
                    />
                    <path
                      d="M13.1162 11.3014C11.0667 11.1305 9.28518 11.3034 7.74857 12.5717C5.17715 14.6314 5.67201 17.4047 5.67201 20.7973C5.67201 21.695 6.40232 22.4389 7.31357 22.4389C17.208 22.4389 17.6018 22.7581 18.1886 21.4587C18.381 21.0193 18.3283 21.159 18.3283 16.9555C18.3283 13.6167 15.4373 11.3014 13.1162 11.3014Z"
                      fill="#FB6108"
                    />
                    <path
                      d="M20.8173 11.2821C19.6375 11.2821 18.7234 11.1731 17.71 12.0035C20.1458 14.4905 19.7345 16.9692 19.7345 20.7967C19.7345 20.999 19.4062 20.9383 22.5723 20.9383C23.3598 20.9383 24.0001 20.3003 24.0001 19.5161V14.4456C24.0001 12.7014 22.5723 11.2821 20.8173 11.2821Z"
                      fill="#FB6108"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7410_31392">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="flex-col justify-start items-start gap-2 flex">
                <div className="text-slate-950 text-2xl font-bold ">
                  {dashBoardStatsData?.totalCustomers ? dashBoardStatsData?.totalCustomers : 0}
                </div>
                <div className="flex-col justify-start items-start gap-2 flex">
                  <div className="text-neutral-600 text-base font-medium ">
                    Total Customers
                  </div>
                  {/* <div className="text-green-600 text-xs font-medium ">
                    +445 from yesterday
                  </div> */}
                </div>
              </div>
            </div>

            <div className="flex py-5 pl-6 pr-9 bg-orange-50 rounded-2xl flex-col justify-start items-start gap-4 shadow-2xl">
              <div className="w-10 h-10 relative bg-orange-100 rounded-[50px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                >
                  <rect width="40" height="40" rx="20" fill="#FFE1CE" />
                  <path
                    d="M9.22441 14.3971V9.56692L14.0244 11.982L9.22441 14.3971ZM27.2244 14.3971V9.56692L32.0244 11.982L27.2244 14.3971ZM18.8244 13.1896V8.35938L23.6244 10.7745L18.8244 13.1896ZM16.4244 32.3594C15.3844 32.2587 14.3644 32.1078 13.3644 31.9065C12.3644 31.7053 11.4694 31.4587 10.6794 31.1669C9.88941 30.8751 9.24941 30.538 8.75941 30.1556C8.26941 29.7732 8.02441 29.3506 8.02441 28.8877V18.0198C8.02441 17.5166 8.33941 17.0487 8.96941 16.616C9.59941 16.1833 10.4544 15.8009 11.5344 15.4688C12.6144 15.1367 13.8844 14.8751 15.3444 14.6839C16.8044 14.4927 18.3644 14.3971 20.0244 14.3971C21.6844 14.3971 23.2444 14.4927 24.7044 14.6839C26.1644 14.8751 27.4344 15.1367 28.5144 15.4688C29.5944 15.8009 30.4494 16.1833 31.0794 16.616C31.7094 17.0487 32.0244 17.5166 32.0244 18.0198V28.8877C32.0244 29.3506 31.7794 29.7732 31.2894 30.1556C30.7994 30.538 30.1594 30.8751 29.3694 31.1669C28.5794 31.4587 27.6844 31.7053 26.6844 31.9065C25.6844 32.1078 24.6644 32.2587 23.6244 32.3594V26.4726H16.4244V32.3594ZM20.0244 19.2273C21.9644 19.2273 23.6394 19.1116 25.0494 18.8801C26.4594 18.6487 27.5844 18.382 28.4244 18.0801C28.4244 17.9795 27.6644 17.743 26.1444 17.3707C24.6244 16.9984 22.5844 16.8122 20.0244 16.8122C17.4644 16.8122 15.4244 16.9984 13.9044 17.3707C12.3844 17.743 11.6244 17.9795 11.6244 18.0801C12.4644 18.382 13.5894 18.6487 14.9994 18.8801C16.4094 19.1116 18.0844 19.2273 20.0244 19.2273Z"
                    fill="#FB6108"
                  />
                </svg>
              </div>
              <div className="flex-col justify-start items-start gap-2 flex">
                <div className="text-slate-950 text-2xl font-bold ">
                  {dashBoardStatsData?.totalClubsOnBoard ? dashBoardStatsData?.totalClubsOnBoard : 0}
                </div>
                <div className="flex-col justify-start items-start gap-2 flex">
                  <div className="text-neutral-600 text-base font-medium ">
                    Total Clubs Onboard
                  </div>
                  {/* <div className="text-green-600 text-xs font-medium ">
                    +10 from last month
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-start items-start w-full gap-7">
            {/* ==============> Peak Hours <================  */}
            {/* <div className="border border-gray-200 px-5 py-5">
              <h1 className="text-zinc-800 text-2xl font-semibold ">
                Peak Hours
              </h1>
              <div className="">
                <div className=" justify-between items-center flex">
                  <div className="text-neutral-600 text-base font-semibold ">
                    20 February 2022
                  </div>
                  <div className="bg-neutral-100 flex rounded-sm justify-start items-start ">
                    <div className="px-4 py-1 bg-orange-600 rounded-sm justify-start items-center flex">
                      <div className="text-white text-base font-semibold ">
                        Day
                      </div>
                    </div>
                    <div className="px-4 py-1 bg-gray-300 rounded-sm justify-start items-center gap-[8.63px] flex">
                      <div className="text-neutral-600 text-base font-semibold ">
                        Week
                      </div>
                    </div>
                    <div className="px-4 py-1 rounded-sm bg-gray-300 justify-start items-center gap-1.5 flex">
                      <div className="text-neutral-600 text-base font-semibold ">
                        Month
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <AreaChart
                width={650}
                height={330}
                data={data}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="10%"
                      stopColor="#FB61083D"
                      stopOpacity={0.8}
                    />
                    <stop offset="95%" stopColor="#FB610800" stopOpacity={0} />
                  </linearGradient>
                </defs>

                <XAxis dataKey="name" />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="uv"
                  stroke="orange"
                  fill="url(#colorUv)"
                />
              </AreaChart>
            </div> */}

            {/* ==============> Top Sports <================= */}
            <div className="p-5 border border-gray-200 w-full shadow-2xl min-h-[450px]">
              <div className="flex justify-between items-center ">
                <div>
                  <span className="text-slate-950 text-2xl font-semibold">
                    Top Sports
                    <br />
                  </span>
                  <span className="text-neutral-600 text-base font-normal ">
                    (Bookings)
                  </span>
                </div>
                <div className="bg-neutral-100 rounded-sm justify-end items-end flex">
                  <div
                    onClick={() => handleChangeTopSportsInterval("DAY")}
                    className={`${topSportsInterval === "DAY" && "bg-orange-600 text-white"
                      } px-4 py-1 rounded-sm justify-start items-center flex cursor-pointer`}
                  >
                    <div className="text-base font-semibold ">Today</div>
                  </div>
                  <div
                    onClick={() => handleChangeTopSportsInterval("WEEK")}
                    className={`${topSportsInterval === "WEEK" && "bg-orange-600 text-white"
                      } px-4 py-1 rounded-sm justify-start items-center flex cursor-pointer`}
                  >
                    <div className="text-base font-semibold ">Week</div>
                  </div>
                  <div
                    onClick={() => handleChangeTopSportsInterval("MONTH")}
                    className={`${topSportsInterval === "MONTH" &&
                      "bg-orange-600 text-white"
                      } px-4 py-1 rounded-sm justify-start items-center flex cursor-pointer`}
                  >
                    <div className="text-base font-semibold ">Month</div>
                  </div>
                </div>
              </div>
              {/* pie Chart */}
              {topSportsOccurrences?.length === 0 ? (
                <p className="text-gray-500  text-[1.25rem] my-[3rem] text-center">
                  No Data Found!
                </p>
              ) : (
                <div className="flex justify-center items-center overflow-x-auto">
                  <PieChart width={800} height={340}>
                    <Pie
                      data={topSportsOccurrences}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={110}
                      fill="#8884d8"
                      dataKey="percentage"
                    >
                      {topSportsOccurrences?.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Legend content={renderLegend} />
                  </PieChart>
                </div>
              )}
            </div>
          </div>

          {/* Last Row */}
          <div>
            {/* =================> Top Clubs <====================== */}
            <div className="p-5 box-border border border-gray-200 mb-10 shadow-2xl">
              <div className="flex justify-between items-center ">
                <div>
                  <span className="text-slate-950 text-2xl font-semibold ">
                    Top Clubs
                    <br />
                  </span>
                  <span className="text-neutral-600 text-base font-normal ">
                    (Bookings)
                  </span>
                </div>
                <div className="bg-neutral-100 rounded-sm items-center flex">
                  <div className='flex gap-3 items-center rounded-md pr-3'>
                    {loading ? (
                      <p className="text-xs">Generating PDF...</p>
                    ) : (
                      <img
                        src={pdfLogo}
                        className='w-[25px] cursor-pointer'
                        onClick={handleDownloadPDF}
                        alt="Generate PDF"
                      />
                    )}
                    <FaFileCsv
                      className='w-[25px] h-[30px] text-green-800 cursor-pointer'
                      onClick={() => DownloadExcel({ excelData, excelColumns })}
                    />
                  </div>
                  <div className="pr-4">
                    <Flatpickr
                      value={date}
                      className="w-full px-4 py-2 text-gray-700 border border-gray-300 rounded-md outline-none"
                      placeholder="From - To"
                      options={dateOptions}
                      onChange={handleDateChange}
                    />
                  </div>
                  <div
                    onClick={() => { handleChangeTopClubsInterval("DAY"); setCurrentPage(1) }}
                    className={`${topClubsInterval === "DAY" && "bg-orange-600 text-white"
                      } px-4 py-1 rounded-sm justify-start items-center flex cursor-pointer`}
                  >
                    <div className="text-base font-semibold ">Today</div>
                  </div>
                  <div
                    onClick={() => { handleChangeTopClubsInterval("WEEK"); setCurrentPage(1) }}
                    className={`${topClubsInterval === "WEEK" && "bg-orange-600 text-white"
                      } px-4 py-1 rounded-sm justify-start items-center flex cursor-pointer`}
                  >
                    <div className="text-base font-semibold ">Week</div>
                  </div>
                  <div
                    onClick={() => { handleChangeTopClubsInterval("MONTH"); setCurrentPage(1) }}
                    className={`${topClubsInterval === "MONTH" && "bg-orange-600 text-white"
                      } px-4 py-1 rounded-sm justify-start items-center flex cursor-pointer`}
                  >
                    <div className="text-base font-semibold ">Month</div>
                  </div>
                  <div
                    onClick={() => { handleChangeTopClubsInterval("YEAR"); setCurrentPage(1) }}
                    className={`${topClubsInterval === "YEAR" && "bg-orange-600 text-white"
                      } px-4 py-1 rounded-sm justify-start items-center flex cursor-pointer`}
                  >
                    <div className="text-base font-semibold ">Year</div>
                  </div>
                </div>
              </div>

              <section className="px-4 py-8 w-full">
                <div className="mt-6 flex flex-col">
                  <div className="mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className=" py-2 align-middle md:px-6 lg:px-8">
                      <div className="overflow-hidden border border-gray-200 md:rounded-lg">
                        <DataTable
                          columns={columns}
                          customStyles={customStyles}
                          data={dashBoardTopClubsData?.topClubs}
                          progressPending={topClubsIsLoading}
                        />
                      </div>
                      {dashBoardTopClubsData?.topClubs?.length > 0 &&
                        <div className="flex justify-center items-center gap-2 py-2">
                          <button
                            className={`${currentPage === 1 || dashBoardTopClubsData?.topClubs?.length === 0 ? "cursor-not-allowed text-gray-500" : "cursor-pointer"} rounded-md px-2 border-2`}
                            onClick={() => handleDirection("left")}
                          >Prev</button>
                          <span>
                            {currentPage} / {Math.ceil(dashBoardTopClubsData?.totalLength / recordsPerPage)}
                          </span>
                          <button
                            className={`${currentPage === dashBoardTopClubsData?.totalPages || dashBoardTopClubsData?.topClubs?.length === 0 ? "cursor-not-allowed text-gray-500" : "cursor-pointer"} rounded-md px-2 border-2`}
                            onClick={() => handleDirection("right")}
                          >Next</button>
                          <div className="flex items-center">
                            <span>Per Page:</span>
                            <select
                              value={recordsPerPage}
                              onChange={(e) => { setRecordsPerPage(+e.target.value); setCurrentPage(1); }}
                              className="border outline-none ml-2"
                            >
                              <option value={1}>1</option>
                              <option value={2}>2</option>
                              <option value={dashBoardTopClubsData?.totalLength}>{dashBoardTopClubsData?.totalLength}</option>
                            </select>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </section>
            </div>
            {/* ================> Customers Joined <=================  */}
            <div className=" border border-gray-200 px-5 py-5 shadow-2xl">
              <div className="flex pt-5 pb-11 h-8 justify-between items-center">
                <div className="text-zinc-800 text-2xl font-semibold ">
                  Customers Joined
                </div>
                <div className="bg-neutral-100 rounded-sm justify-start items-start flex">
                  <div
                    onClick={() => handleChangeCustomersJoinedInterval("WEEK")}
                    className={`${customersJoinedInterval === "WEEK" &&
                      "bg-orange-600 text-white"
                      } px-4 py-1 rounded-sm justify-start items-center flex cursor-pointer`}
                  >
                    <div className="text-base font-semibold ">Week</div>
                  </div>
                  <div
                    onClick={() => handleChangeCustomersJoinedInterval("MONTH")}
                    className={`${customersJoinedInterval === "MONTH" &&
                      "bg-orange-600 text-white"
                      } px-4 py-1 rounded-sm justify-start items-center flex cursor-pointer`}
                  >
                    <div className="text-base font-semibold ">Month</div>
                  </div>
                  <div
                    onClick={() => handleChangeCustomersJoinedInterval("YEAR")}
                    className={`${customersJoinedInterval === "YEAR" &&
                      "bg-orange-600 text-white"
                      } px-4 py-1 rounded-sm justify-start items-center flex cursor-pointer`}
                  >
                    <div className="text-base font-semibold ">Year</div>
                  </div>
                </div>
              </div>
              <div className="overflow-x-auto">
                <BarChart
                  data={customerCount}
                  width={1100}
                  height={400}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <XAxis
                    dataKey={
                      customersJoinedInterval === "WEEK"
                        ? "day"
                        : customersJoinedInterval.toLowerCase()
                    }
                    scale="point"
                    padding={{ left: 45, right: 45 }}
                    tick={{ fontSize: 16, color: "black", fontWeight: "bold" }}
                    interval={0}
                  />
                  {/* <Tooltip /> */}
                  <Bar
                    dataKey="count"
                    fill="#FB6108"
                    barSize={20}
                    opacity="40%"
                    activeBar={<Rectangle opacity="100%" />}
                  />
                  <Tooltip
                    cursor={{ fill: "transparent" }}
                    content={renderTooltip}
                  />
                </BarChart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
