import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateHowToPlayInfo } from '../../../store/createSubCategorySlice';
import { Icon } from '@iconify/react';
import { ToastContainer, toast } from 'react-toastify';
import {
   IconButton,
   Option,
   Select,
   Button,
   Menu,
   MenuHandler,
   MenuItem,
   MenuList,
} from "@material-tailwind/react";
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import moment from 'moment';
import CustomCheckbox from '../../../helpers/CustomCheckbox';


export default function HowToPlayInfo({ showError, setError }) {
   const [arrayData, setArrayData] = useState([]);
   const [question, setQuestion] = useState("");
   const [answer, setAnswer] = useState("");
   const [showHTPError, setShowHTPError] = useState(false);
   const dispatch = useDispatch();
   const { qna } = useSelector((store) => store.createSubCategory.howToPlayInfo);
   const { subCategoryName } = useSelector((store) => store.createSubCategory.sportInfo);

   useEffect(() => {
      if (!(qna.length !== 0)) {
         setError(true);
      } else {
         setError(false);
      }
   }, [qna, setError]);


   const customStyles = {
      headRow: {
         style: {
            fontWeight: 600,
            // padding: "10px 10px",
            color: "#4D4D4D",
            fontSize: "13px",
         },
      },
      head: {
         style: {
            // borderRadius: "6px 6px 0px 0px",
            // borderTop: "0.824px solid #E9E9E9",
            background: "#FFF",
            // fontSize: "15px",
         },
      },

      rows: {
         style: {
            // borderRadius: "6px 6px 0px 0px",
            // borderTop: "0.824px solid #E9E9E9",
            background: "#FFF",
            color: "#565656",
            padding: "5px 0px",
            fontSize: "14px",
            fontWeight: 400,
            width: "auto",
         },
      },
      // pagination: {
      //    style: {
      //       boxShadow: "10px 5px 5px #ddd",
      //       marginBottom: "5px",
      //    },
      // },
      table: {
         style: {
            overflow: "visible",
            minWidth: "1100px",
         },
      },
      tableWrapper: {
         style: {
            overflow: "visible",
         },
      },
      responsiveWrapper: {
         style: {
            overflowX: "auto", // table scroll on x axis
         },
      },
   };
   const data = [
      {
         question: "How to Play Cricket?",
         answer: "Make two teams, Decide number of overs match and play",
      },
      {
         question: "How to Play Cricket?",
         answer: "Make two teams, Decide number of overs match and play",
      },
      {
         question: "How to Play Cricket?",
         answer: "Make two teams, Decide number of overs match and play",
      },
      {
         question: "How to Play Cricket?",
         answer: "Make two teams, Decide number of overs match and play",
      },
      {
         question: "How to Play Cricket?",
         answer: "Make two teams, Decide number of overs match and play",
      },
      {
         question: "How to Play Cricket?",
         answer: "Make two teams, Decide number of overs match and play",
      },
      {
         question: "How to Play Cricket?",
         answer: "Make two teams, Decide number of overs match and play",
      },
   ];
   const columns = [
      {
         name: "SL",
         selector: (row, index) => index + 1,
         center: true,
         maxWidth: "0.5rem",
      },
      {
         name: 'Title',
         id: "title",
         selector: row => (
            <div className='flex flex-col whitespace-break-spaces'>
               <p><span className='text-[1rem] font-semibold'>Q - </span>{row.question}</p>
               <p><span className='text-[1rem] font-semibold'>A - </span>{row.answer}</p>
            </div>
         ),
         grow: 4,
         center: true,
      },
      {
         name: 'Sub Category',
         id: "subCategory",
         selector: row => subCategoryName,
         center: true,
      },
      // {
      //    name: "Status",
      //    id: "status",
      //    selector: (row) => (
      //       <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
      //       py-[0.4rem] w-[6rem] ${row.isActive === true ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"}`}>
      //          <span className={`${row.isActive === true ? "bg-[#25BF17]" : "bg-[#FF0023]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
      //          <h2>{row.isActive === true ? "Active" : "Inactive"}</h2>
      //       </div>
      //    ),
      //    center: true,
      //    // grow: 1,
      //    sortable: true,
      // },
      {
         name: "Actions",
         id: "action",
         cell: (row) => (
            <div className="flex gap-[0.5rem]">

               {/* <Link to={`/categories/sub-categories/create`}>
                  <IconButton
                     className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
                  >
                     <Icon
                        icon="material-symbols:edit-rounded"
                        className="text-[1.5rem] text-black"
                     />
                  </IconButton>
               </Link> */}
                  <IconButton onClick={() => deleteHandler(row.id)}
                     className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
                  >
                     <Icon
                        icon="material-symbols:delete-rounded"
                        className="text-[1.5rem] text-black"
                     />
                  </IconButton>
            </div>
         ),
         center: true,
         // grow: 1,
      },
   ];

   const deleteHandler = (id) => {
      setArrayData((prevState) => {
         const updatedArray = prevState.filter((item) => item.id !== id);
         dispatch(updateHowToPlayInfo({
            qna: updatedArray,
         }))
         return updatedArray;
      })
   }

   const addNewQnACollectionHandler = (question, answer) => {
      if (!(question && answer)) {
         setShowHTPError(true);
         return;
      } else {
         setShowHTPError(false);
      }
      setArrayData((prevState) => {
         const updatedArray = [
            ...prevState,
            { question: question, answer: answer, id: Math.random().toString() }
         ]
         dispatch(updateHowToPlayInfo({
            qna: updatedArray,
         }))
         setQuestion("");
         setAnswer("");
         return updatedArray;
      })
   }

   return (
      <div className='w-full flex flex-col gap-[3rem]'>
         <form className='flex items-center justify-center'>
            <div className='flex flex-col gap-[1.5rem] w-[60%]'>
               {showError && qna.length === 0 && (
                  <div className="text-sm text-[#E92215]">
                     *Atleast one Question Answer should be Added.
                  </div>
               )}
               <div className="flex flex-col gap-[0.5rem]">
                  <input
                     className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                     type="text"
                     placeholder="Enter Question"
                     value={question}
                     onChange={(e) => setQuestion(e.target.value)}
                  />
                  {showHTPError && !question && (
                     <div className="text-sm text-[#E92215]">
                        *This Field is Required
                     </div>
                  )}
               </div>
               <div className="flex flex-col gap-[0.5rem]">
                  <textarea rows={8}
                     className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                     type="text"
                     placeholder="Enter Answer"
                     value={answer}
                     onChange={(e) => setAnswer(e.target.value)}
                  />
                  {showHTPError && !answer && (
                     <div className="text-sm text-[#E92215]">
                        *This Field is Required
                     </div>
                  )}
               </div>
               <div className='w-full'>
                  <button onClick={() => addNewQnACollectionHandler(question, answer)}
                     type='button'
                     className='flex items-center justify-center gap-[0.5rem] py-[0.5rem] bg-primary text-white w-full font-semibold rounded-[5px]'>
                     <span>Add More</span>
                     <Icon icon="material-symbols:add" className='text-[1.3rem]' />
                  </button>
               </div>
            </div>
         </form>
         <div className='flex flex-col gap-[0.5rem]'>
            <div className='flex justify-between items-center border-t border-[#EAECF0] pt-[1.5rem]'>
               <h2 className='text-black text-[1.3rem] font-semibold'>How to Play</h2>
               <div className='flex items-center gap-[2rem]'>
                  <div className='flex items-center gap-[0.4rem] hover:text-primary cursor-pointer'>
                     <Icon
                        icon='material-symbols:delete'
                        className='text-[1.5rem]'
                     />
                     <span className='text-[1rem] font-medium'>Remove</span>
                  </div>
                  <div className='flex items-center gap-[0.3rem] hover:text-primary cursor-pointer'>
                     <p className='text-[1rem] font-medium'>Change Status</p>
                     <Icon
                        icon='mingcute:down-line'
                        className='text-[1.5rem]'
                     />
                  </div>
                  <div className="flex flex-row gap-[0.5rem] xs:gap-[1rem] items-center justify-center border border-[#CBCBCB] rounded-[5px]">
                     <div className="">
                        <Menu>
                           <MenuHandler>
                              <Button className="text-[0.8rem] sm:text-[1rem] flex w-full flex-row justify-center whitespace-nowrap capitalize text-black bg-white px-[0.4rem] xs:px-[1rem] py-[0.4rem] rounded-md items-center gap-[0.5rem] sm:gap-[0.8rem] hover:shadow-none shadow-none">
                                 <Icon
                                    icon="mdi:cloud-download"
                                    className="text-[1.5rem]"
                                 />
                                 <span className="font-[500]">Export</span>
                              </Button>
                           </MenuHandler>
                           <MenuList>
                              <MenuItem
                                 //  onClick={downloadPdf}
                                 className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                              >
                                 {" "}
                                 <Icon
                                    icon="fa-solid:file-pdf"
                                    className="text-[#BE0A23] w-[1rem] h-auto"
                                 />
                                 Download as PDF
                              </MenuItem>
                              <hr className="my-[0.1rem]" />
                              <MenuItem
                                 //  onClick={downloadCSV}
                                 className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                              >
                                 <Icon
                                    icon="fa-solid:file-csv"
                                    className="text-[#29672E] w-[1rem] h-auto"
                                 />
                                 Download as CSV
                              </MenuItem>
                           </MenuList>
                        </Menu>
                     </div>
                  </div>
               </div>
            </div>
            <div>
               <DataTable
                  columns={columns}
                  customStyles={customStyles}
                  data={arrayData}
                  pagination
                  selectableRows
                  paginationPerPage={10}
                  // progressPending={isLoading}
                  selectableRowsComponent={CustomCheckbox}
               />
            </div>
            <ToastContainer />
         </div>
      </div>
   )
}
