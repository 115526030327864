import { Link, useParams } from 'react-router-dom';
import { IconButton,} from '@material-tailwind/react';
import { Icon } from '@iconify/react';
import DataTable from 'react-data-table-component';
import CustomCheckbox from '../../../../helpers/CustomCheckbox';
import { ToastContainer } from 'react-toastify';
import { useGetAllClubOwnerRentalsQuery } from '../../../../store/features/clubOwner/clubOwnerApi';
import moment from 'moment';
import { useState } from 'react';
import Flatpickr from "react-flatpickr";
import { RxCross2 } from 'react-icons/rx';

export default function ViewCORentals() {
   const { id } = useParams();

   const [startDate, setStartDate] = useState("");
   const [endDate, setEndDate] = useState("");
   const [date, setDate] = useState("");

   const { data, isLoading } = useGetAllClubOwnerRentalsQuery(id);
   const clubOwnerRentalsData = data?.response?.rentals
   // console.log("Club Owners Rentals Data", clubOwnerRentalsData)

   const customStyles = {
      headRow: {
         style: {
            fontWeight: 600,
            color: "#4D4D4D",
            fontSize: "13px",
         },
      },
      head: {
         style: {
            background: "#FFF",
         },
      },

      rows: {
         style: {
            background: "#FFF",
            color: "#565656",
            fontSize: "14px",
            fontWeight: 400,
            width: "auto",
            padding: "5px 0px"
         },
      },
      table: {
         style: {
            overflow: "visible",
            minWidth: "1100px",
         },
      },
      tableWrapper: {
         style: {
            overflow: "visible",
         },
      },
      responsiveWrapper: {
         style: {
            overflowX: "auto", // table scroll on x axis
         },
      },
   };

   const columns = [
      {
         name: "SL",
         selector: (row, index) => index + 1,
         center: true,
         maxWidth: "0.5rem",
      },
      {
         name: 'Rental ID',
         selector: row => row.rentalId,
         center: true,
         grow: 1,
      },
      {
         name: <h2 className='text-center'>Equipment Name Quantity</h2>,
         selector: row => `${row.equipmentName}-${row.equipmentQuantity}`,
         center: true,
         grow: 2,
      },
      {
         name: 'Customer Name',
         selector: row => row.customerName,
         center: true,
      },
      {
         name: 'Start & End Date',
         selector: row => `${moment(row.start_date).format("Do MMM YY")} - ${moment(row.end_date).format("Do MMM YY")}`,
         center: true,
         grow: 2.5,
      },
      {
         name: 'Start & End Time',
         selector: row => `${moment(row.start_date).format("LTS")} - ${moment(row.end_date).format("LTS")}`,
         center: true,
         grow: 2,
      },
      {
         name: 'Total Amount',
         selector: row => row.totalAmount,
         center: true,
      },
      {
         name: "Status",
         selector: (row) => (
            <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
            py-[0.3rem] w-[6rem] ${row.payment_status === "paid" ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"}`}>
               <span className={`${row.payment_status === "paid" ? "bg-[#25BF17]" : "bg-[#FF0023]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
               {row.payment_status.toLowerCase() === 'unpaid' && <h2>Unpaid</h2>}
               {row.payment_status.toLowerCase() === 'paid' && <h2>Paid</h2>}
            </div>
         ),
         center: true,
         grow: 1.5,
         sortable: true,
      },
      {
         name: "Actions",
         cell: (row) => (
            <div className="flex gap-[0.5rem]">
               <Link to={`view/${row._id}`}>
                  <IconButton
                     className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
                  >
                     <Icon
                        icon="mdi:eye"
                        className="text-[1.5rem] text-black"
                     />
                  </IconButton>
               </Link>
            </div>
         ),
         center: true,
         grow: 1,
      },
   ];

   const handleDateChange = (selectedDates) => {
      setDate(selectedDates);
      if (selectedDates.length === 1) {
         setStartDate(selectedDates[0]);
      }
      if (selectedDates.length === 2) {
         setEndDate(selectedDates[1]);
      }
   };
   const filteredData = clubOwnerRentalsData?.filter(row => {
      const rowStartDate = moment(row?.start_date);
      const rowEndDate = moment(row?.end_date);

      // Check if both startDate and endDate are selected
      if (startDate && endDate) {
         return (rowStartDate.isSameOrAfter(startDate) && rowEndDate.isSameOrBefore(endDate)) ||
            (rowStartDate.isSameOrBefore(startDate) && rowEndDate.isSameOrAfter(startDate)) ||
            (rowStartDate.isSameOrBefore(endDate) && rowEndDate.isSameOrAfter(endDate));
      }

      if (startDate) {
         return rowEndDate.isSameOrAfter(startDate);
      }

      if (endDate) {
         return rowStartDate.isSameOrBefore(endDate);
      }

      return true;
   });


   const clearDate = () => {
      setDate("")
      setStartDate("")
      setEndDate("")
   }

   const dateOptions = {
      mode: "range",
      dateFormat: "d-m-Y",
   };

   return (
      <div className='bg-white rounded-md flex flex-col gap-[0.5rem] max-w-[1100px] overflow-auto'>
         <div className='flex items-center justify-between'>
            <h2 className='text-[1.3rem] font-semibold'>
               Rentals
            </h2>
            <div>
               <div className='flex items-center gap-[2rem]'>
                  <div className="flex w-[280px] items-center border border-gray-300 rounded-md px-2 py-2">
                     <Flatpickr
                        value={date}
                        className="custom-select flex-grow outline-none"
                        placeholder="Select Date"
                        options={dateOptions}
                        onChange={handleDateChange}
                     />
                     {date !== "" &&
                        <RxCross2
                           className="text-[1.2rem] cursor-pointer bg-red-500 text-white rounded-md"
                           onClick={clearDate}
                        />
                     }
                  </div>
                  {/* <div className='flex items-center gap-[0.3rem] hover:text-[#FB6108] cursor-pointer'>
                     <p className='text-[1rem] font-medium'>Change Status</p>
                     <Icon
                        icon='mingcute:down-line'
                        className='text-[1.5rem]'
                     />
                  </div> */}
                  {/* <div className="flex flex-row gap-[0.5rem] xs:gap-[1rem] items-center justify-center border border-[#CBCBCB] rounded-[5px]">
                     <div className="">
                        
                     </div>
                  </div> */}
               </div>
            </div>
         </div>
         <div>
            <DataTable
               columns={columns}
               customStyles={customStyles}
               data={filteredData}
               pagination
               selectableRows
               progressPending={isLoading}
               selectableRowsComponent={CustomCheckbox}
            />
         </div>
         <ToastContainer />
      </div>
   )
}




