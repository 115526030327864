import React from 'react'
import { Outlet } from 'react-router-dom'

function Customers() {
  return (
    <div className='bg-secondary px-[1.5rem] py-[1rem] h-full'>
      <Outlet />
    </div>
  )
}

export default Customers
