import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import OwnerInfo from "./OwnerInfo";
import ClubInfo from "./ClubInfo";
import LocationOperating from "./LocationOperating";
import SportsAmenties from "./SportsAmenties";
import StepperController from "../../../helpers/StepperController";
import HeaderStepper from "../../../helpers/HeaderStepper";
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  updateClubInfo,
  updateClubLoaderBtn,
  updateLocationOperating,
  updateOwnersInfo,
  updateSportsAmenities,
} from "../../../store/createClubSlice";
import {
  editClubInfo,
  editIsEnable,
  editLocationOperating,
  editOwnersInfo,
  editSportsAmenities,
} from "../../../store/editClubSlice";
import EditOwnerInfo from "./EditOwnerInfo";
import EditClubInfo from "./EditClubInfo";
import EditLocationOperating from "./EditLocationOperating";
import EditSportsAmenties from "./EditSportsAmenties";
import { useQuery } from "react-query";
import { RotatingLines } from "react-loader-spinner";
import _ from "lodash";

export default function EditClub() {
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [steps, setSteps] = useState([
    "Owner Information",
    "Club Information",
    "Location and Operating Hours",
    "Sports and Amenities",
  ]);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const pathname = window.location.href;

  const [allShowError, setAllShowError] = useState([
    { name: "Owner Information", showError: false },
    { name: "Club Information", showError: false },
    { name: "Location and Operating", showError: false },
    { name: "Sports Amenities", showError: false },
  ]);

  function capitalizeFirstLetter(string) {
    if (!string) return '';
    return string
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
  

  const fetchClubOwnerById = async () => {
    setIsLoading(true);
    const token = await getAccessToken();
    // setLoaderBtn(true);
    const response = await fetch(
      `${APIurls.fetchClubById}?ClubOwnerID=${encodeURIComponent(id)}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    if (!response.ok) {
      // setLoaderBtn(false);
      throw new Error(result.message);
    }
    console.log(
      "View Club Owner By ID For Edit Details",
      result?.response?.clubOwner
    );
    // setLoaderBtn(false);
    const data = result?.response?.clubOwner;
    dispatch(
      editOwnersInfo({
        ownerName: data?.OwnerData?.Fullname,
        adhaarNumber: data?.Kyc_details?.Aadhar_details?.aadharNumber,
        mobileNumber: data?.Mobile,
        panNumber: data?.Kyc_details?.Pan_details?.panNumber,
        email: data?.Email,
        dob: data?.OwnerData?.dob,
        ownerImage: data?.Club_profile_img,
      })
    );
    dispatch(
      editClubInfo({
        clubName: data?.ClubName,
        businessPanNumber: data?.Kyc_details?.Pan_details?.panNumber,
        businessCategory: data?.Club_details?.business_category,
        businessEmail: data?.Club_details?.business_email,
        openingDate: data?.Club_details?.openingDate,
        clubImagesArray: data?.Club_details?.Club_slider_img,
      })
    );
    dispatch(
      editLocationOperating({
        addressLine1: data?.location_operating?.address_line1,
        addressLine2: data?.location_operating?.address_line2,
        city: capitalizeFirstLetter(data?.location_operating?.City),
        state: data?.location_operating?.state ,
        country: data?.location_operating?.Country,
        pincode: data?.location_operating?.Pincode,
        selectedTimingsData:
          data?.location_operating?.timingType.toLowerCase() === "custom"
            ? "Custom"
            : "Every Day",
        timingSlotsData:
          data?.location_operating?.timingType.toLowerCase() === "custom"
            ? data?.location_operating?.dailyAvailableTimings
            : data?.location_operating?.EveryDay,
      })
    );
    dispatch(
      editSportsAmenities({
        facilities: data?.SportAmenties?.Facilities?.map((data) => data._id),
        // categoriesData: data?.SportAmenties?.Categories?.map((category) => ({
        //   category_id: category.mainCategory_id._id,
        //   sub_categories: category.subCategoryId.map((div,i) =>{ return {key:i,sub_id:div._id}})
        // }))
      })
    );
    setIsLoading(false);
    // return result?.response?.clubOwner;
  };
  // const { data: clubOwnerEditDetails, isLoading } = useQuery(
  //   "clubOwnerEditDetails",
  //   fetchClubOwnerById
  // );

  useEffect(() => {
    fetchClubOwnerById();
  }, []);

  useEffect(() => {
    const newPath = pathname.split("/");
    if (_.includes(newPath, "edit")) {
      dispatch(editIsEnable(true));
    } else {
      dispatch(editIsEnable(false));
    }
    console.log("PATH NAME", pathname);
  }, [pathname]);

  const {
    ownerName,
    adhaarNumber,
    mobileNumber,
    panNumber,
    email,
    dob,
    ownerImage,
  } = useSelector((store) => store.editClub.ownerInfo);
  const {
    clubName,
    businessPanNumber,
    businessCategory,
    businessEmail,
    openingDate,
    clubImagesArray,
  } = useSelector((store) => store.editClub.clubInfo);
  const {
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    pincode,
    selectedTimingsData,
    timingSlotsData,
  } = useSelector((store) => store.editClub.locationOperating);
  const { facilities, categoriesData } = useSelector(
    (store) => store.editClub.sportsAmenities
  );
  const { isEditEnable } = useSelector((store) => store.editClub);
  console.log("ISENABLE==========", isEditEnable);
  const dispatch = useDispatch();

  console.log("API IMAGES============", clubImagesArray);

  const newImages = [];
  const oldUrls = [];

  clubImagesArray.forEach((item) => {
    if (typeof item === "object") {
      newImages.push(item);
    } else if (typeof item === "string") {
      oldUrls.push(item);
    }
  });

  const onlyClubImagesArray = newImages.map((clubImage) => clubImage.imgFile);

  // console.log("ONLY IMAGES============", onlyClubImagesArray);

  const { getAccessToken } = useAuth();

  const displayStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <EditOwnerInfo
            currentStep={currentStep}
            steps={steps}
            handleClick={handleClick}
            showError={showError}
            setShowError={setShowError}
            error={error}
            setError={setError}
          />
        );
      case 2:
        return (
          <EditClubInfo
            currentStep={currentStep}
            steps={steps}
            handleClick={handleClick}
            showError={showError}
            setShowError={setShowError}
            error={error}
            setError={setError}
          />
        );
      case 3:
        return (
          <EditLocationOperating
            currentStep={currentStep}
            steps={steps}
            handleClick={handleClick}
            showError={showError}
            setShowError={setShowError}
            error={error}
            setError={setError}
          />
        );
      case 4:
        return (
          <EditSportsAmenties
            currentStep={currentStep}
            steps={steps}
            handleClick={handleClick}
          />
        );
      default:
        break;
    }
  };

  const handleClick = async (direction) => {
    let newStep = currentStep;
    if (direction === "next") {
      newStep++;
    } else if (direction === "back") {
      newStep--;
    } else if (direction === "create") {
      createClubOwner();
    }

    if (newStep > 0 && newStep <= steps.length) {
      setCurrentStep(newStep);
    }
  };

  async function createClubOwner() {
    console.log("UPDATE CLUB OWNER");
    const token = await getAccessToken();
    const formData = new FormData();

    // Append individual fields to formData
    formData.append("ownerName", ownerName);
    formData.append("dob", dob);
    formData.append("clubName", clubName);
    formData.append("clubOpeningDate", openingDate);
    formData.append(
      "location_operating",
      JSON.stringify({
        address_line1: addressLine1,
        address_line2: addressLine2,
        City: city,
        state: state,
        Country: country || "India",
        Pincode: pincode,
        Add_Happy: "Happy Club",
        timingType:
          selectedTimingsData === "Every Day" ? "EVERY_DAY" : "CUSTOM",
        ...(selectedTimingsData === "Every Day"
          ? { EveryDay: timingSlotsData }
          : { dailyAvailableTimings: timingSlotsData }),
      })
    );

    formData.append("oldImageUrls", JSON.stringify(oldUrls));

    formData.append(
      "sportAmenties",
      JSON.stringify({
        Facilities: facilities,
        // Categories: categoriesData
      })
    );

    // Append files to formData
    if (ownerImage) {
      formData.append("Club_profile_img", ownerImage);
    }

    onlyClubImagesArray?.forEach((file, index) => {
      formData.append(`Club_slider_img`, file);
    });

    // if (clubImagesArray && clubImagesArray.length > 0) {
    //   clubImagesArray.forEach((file) => {
    //     formData.append("Club_slider_img", file);
    //   });
    // }

    const requestOptions = {
      method: "PUT",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        // "Content-Type": "application/json" // Content-Type is automatically set to multipart/form-data for FormData
      },
    };

    try {
      dispatch(updateClubLoaderBtn(true));
      const response = await fetch(
        `${APIurls.updateClubById}/${id}`,
        requestOptions
      );
      if (!response.ok) {
        dispatch(updateClubLoaderBtn(false));
        if (response.status === 400) {
          const errorData = await response.json();
          console.log("Error Data:", errorData);
          toast.error(errorData.message);
          throw new Error(errorData.message);
        } else {
          toast.error("Failed to Update Club Owner");
          throw new Error("Failed to Update Club Owner");
        }
      }
      const result = await response.json();
      console.log("Update Club Owner Response:::", result);
      toast.success("Club Updated Successfully!");
      dispatch(updateClubLoaderBtn(false));
      setTimeout(() => navigate("/club-owners"), 2500);

      // Clear all fields
      dispatch(
        editOwnersInfo({
          ownerName: "",
          adhaarNumber: "",
          mobileNumber: "",
          panNumber: "",
          email: "",
          dob: "",
          ownerImage: "",
        })
      );
      dispatch(
        editClubInfo({
          clubName: "",
          businessPanNumber: "",
          businessCategory: "",
          businessEmail: "",
          openingDate: "",
          clubImagesArray: [],
        })
      );
      dispatch(
        editLocationOperating({
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          country: "",
          pincode: "",
        })
      );
      dispatch(
        editSportsAmenities({
          facilities: [],
          categoriesData: "",
        })
      );
    } catch (error) {
      console.log(error.message);
    }
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-[60%]">
        <RotatingLines
          visible={true}
          height="150"
          width="150"
          color="gray"
          strokeColor="#FB6108"
          strokeWidth="5"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );
  }
  return (
    <div className="bg-white rounded-md px-[1.5rem] py-[1rem] h-full">
      <div className="flex flex-col gap-[1.5rem]">
        <div className="flex items-center justify-between">
          <div className="flex gap-[0.2rem] items-center text-[#808080] font-semibold">
            <Link to={"/club-owners"}>
              <h2>Club Owner</h2>
            </Link>
            <Icon
              icon="material-symbols:arrow-forward-ios"
              className="text-[1.3rem]"
            />
            <h2 className="text-black text-[1.3rem]">Update Club</h2>
          </div>
          <div>
            <StepperController
              steps={steps}
              currentStep={currentStep}
              handleClick={handleClick}
              setShowError={setShowError}
              error={error}
              isEdit={isEditEnable}
            />
          </div>
        </div>
        <div>
          <HeaderStepper steps={steps} currentStep={currentStep} />
        </div>
        <div className="my-[1rem]">{displayStep()}</div>
      </div>
      <ToastContainer />
    </div>
  );
}
