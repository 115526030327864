import { Icon } from "@iconify/react";
import React from "react";
import { useQuery } from "react-query";
import { Link, NavLink, useOutletContext, useParams } from "react-router-dom";
import { useAuth } from "../../../../store/AuthContext";
import { APIurls } from "../../../../api/apiConstant";
import DataTable from "react-data-table-component";
import moment from "moment";

export default function ViewCOBookingView() {
  const { id, bookingId } = useParams();
  const [clubName, clubID] = useOutletContext();
  const { getAccessToken } = useAuth();

  const fetchClubOwnerBookingData = async () => {
    const token = await getAccessToken();
    const response = await fetch(
      `${APIurls.fetchSpecificCustomerBookingData}/${bookingId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    console.log("FETCH SPECIFIC BOOKING DATA", result?.response);
    return result?.response;
  };
  const {
    data: specificBookingData,
    isLoading,
    refetch,
  } = useQuery("SpecificBookingData", fetchClubOwnerBookingData);

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        // padding: "10px 10px",
        color: "#000019",
        fontSize: "13px",
        background: "#F6F8FC",
      },
    },
    head: {
      style: {
        borderRadius: "6px 6px 6px 6px",
        // border: "0.824px solid #E9E9E9",
        // background: "#FFF",
        // fontSize: "15px",
      },
    },

    rows: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        // background: "#FFF",
        color: "#00000",
        padding: "5px 0px",
        fontSize: "14px",
        fontWeight: 600,
        width: "auto",
      },
    },

    table: {
      style: {
        overflow: "visible",
        minWidth: "1100px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };

  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      maxWidth: "0.5rem",
    },
    {
      name: "Sports",
      selector: (row) => (
        <div className="flex flex-col">
          {specificBookingData?.booking?.sport}
        </div>
      ),
      center: true,
      maxWidth: "0.5rem",
    },
    {
      name: "Court Details",
      id: "dob",
      selector: (row) => (
        <div className="flex flex-col">
          <p>Name: {specificBookingData?.booking?.boardDetails?.boardName}</p>
          <p>No: {specificBookingData?.booking?.boardDetails?.boardNo}</p>
          <p>Id: {specificBookingData?.booking?.boardDetails?.boardsUID}</p>
        </div>
      ),
      center: true,
    },

    {
      name: "Date of Booking",
      id: "date_of_Booking",
      selector: (row) => (
        <div>
          {moment(specificBookingData?.booking?.dateOfBooking).format(
            "MMM Do YY"
          )}
        </div>
      ),
      center: true,
    },
    {
      name: "Time slot",
      id: "time_slot",
      selector: (row) => (
        <div>
          {specificBookingData?.booking?.timeSlots?.map((div) => {
            let newDate = new Date(div);
            // subtracting 5:30 hours
            // let subtractedDateTime = new Date(
            //   newDate.getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)
            // );
            return (
              <div>
                <p>{moment(newDate).format("LTS")}</p>
                {/* <p>{moment(div).format("LTS")}</p> */}
              </div>
            );
          })}
        </div>
      ),
      center: true,
    },
    {
      name: "Booking Type",
      id: "booking_type",
      selector: (row) => (
        <div className=" px-[16px] py-1 bg-[#94FF824D] bg-opacity-30 rounded-md flex justify-center items-center gap-2.5">
          <div className="text-[#1CC500] text-sm font-medium">
            {specificBookingData?.booking?.bookingType}
          </div>
        </div>
      ),
      center: true,
    },
  ];

  const columnsForRentals = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      maxWidth: "0.5rem",
    },
    {
      name: "RentalID",
      selector: (row) => <div className="flex flex-col">{row?.RentalID}</div>,
      center: true,
      maxWidth: "0.5rem",
    },
    {
      name: "Player Details",
      id: "dob",
      selector: (row) => (
        <div className="flex flex-col">
          {row.players?.map((div) => {
            return (
              <div>
                <p>Player Name: {div?.name}</p>
                <p>Email: {div?.name}</p>
                <p>MobileNo: {div?.phone}</p>
              </div>
            );
          })}
        </div>
      ),
      center: true,
    },
    {
      name: "Start Date",
      id: "startDate",
      selector: (row) => <div>{moment(row.startDate).format("MMM Do YY")}</div>,
      center: true,
    },
    {
      name: "End Date",
      id: "endDate",
      selector: (row) => <div>{moment(row.endDate).format("MMM Do YY")}</div>,
      center: true,
    },
    {
      name: "Total Amount",
      id: "totalAmount",
      selector: (row) => <div>{row.totalAmount}</div>,
      center: true,
    },
    {
      name: "Rental Status",
      id: "rental_status",
      selector: (row) => (
        <div className=" px-[16px] py-1 bg-[#94FF824D] bg-opacity-30 rounded-md flex justify-center items-center gap-2.5">
          <div className="text-[#1CC500] text-sm font-medium">
            {row?.isCompleted === true && "Completed"}
          </div>
        </div>
      ),
      center: true,
    },
  ];

  const data = [
    {
      question: "How to Login?",
      answer: "Go to Header section and click on login button",
    },
  ];

  return (
    <div className="bg-white flex flex-col gap-[1rem] rounded-md p-[1rem]">
      <div className="flex gap-[0.5rem] items-center text-[#808080] font-semibold">
        <Link to={"/club-owners"}>
          <h2 className="text-[1rem] font-semibold">Club List</h2>
        </Link>
        <Icon
          icon="material-symbols:arrow-forward-ios"
          className="text-[1.1rem]"
        />
        <Link to={`/club-owners/view/${id}`}>
          <h2 className="text-[1rem]">
            {clubName}
            <span className="text-[#808080] text-[0.9rem] font-normal">
              {" "}
              ({clubID}){" "}
            </span>
          </h2>
        </Link>
        <Icon
          icon="material-symbols:arrow-forward-ios"
          className="text-[1.1rem]"
        />
        <Link to={`/club-owners/view/${id}/bookings`}>
          <h2 className="text-[1rem]">Bookings</h2>
        </Link>
        <Icon
          icon="material-symbols:arrow-forward-ios"
          className="text-[1.1rem]"
        />
        <h2 className="text-[1.2rem] text-black font-bold">{bookingId}</h2>
      </div>
      <div className="flex gap-[2rem] text-[1.2rem] font-medium border-b-2 border-[#989898]">
        {/* <NavLink to={`/club-owners/view/:id/sports/view/:bookingId`} end> */}
        {/* {({ isActive }) => ( */}
        <h2
          className={`pb-[0.3rem] font-bold text-primary border-b-2 border-primary`}
        >
          Booking ID: {bookingId}
        </h2>
        {/* )} */}
        {/* </NavLink> */}
      </div>

      <div className="flex-col items-start justify-start mx-6 mt-4 ">
        <div className="text-slate-950 text-lg font-bold mx-4 mb-5">
          Booking Details
        </div>
        <DataTable columns={columns} customStyles={customStyles} data={data} />
      </div>
      <div className="flex-col items-start justify-start mx-6 mt-4 ">
        <div className="text-slate-950 text-lg font-bold mx-4 mb-5">
          Rent Details
        </div>
        <DataTable
          columns={columnsForRentals}
          customStyles={customStyles}
          data={specificBookingData?.rentals}
        />
      </div>
      <div className="flex justify-between px-[2rem]">
        <div className="">
          {specificBookingData?.customerDetails?.map((div, i) => {
            return (
              <div>
                <h2 className="text-[1.15rem] font-bold my-[1rem]">
                  Customer Details -{i + 1}
                </h2>
                <div className="grid grid-cols-2 gap-x-[2rem] gap-y-[1rem] place-content-between">
                  <label className="text-[#858585] font-medium">
                    Customer Name
                  </label>
                  <h2 className="font-semibold">{div.name}</h2>
                  <label className="text-[#858585] font-medium">
                    Mobile Number
                  </label>
                  <h2 className="font-semibold">{div.mobileNumber}</h2>
                  <label className="text-[#858585] font-medium">
                    Email Addresss
                  </label>
                  <h2 className="font-semibold">{div.email}</h2>
                </div>
              </div>
            );
          })}
        </div>
        <div className="">
          <h2 className="text-[1.15rem] font-bold my-[1rem]">
            Payment Details
          </h2>
          <div className="grid grid-cols-2 gap-y-[1rem] gap-x-[2rem]">
            <label className="text-[#858585] font-medium">Payment Mode</label>
            <h2 className="font-semibold">
              {specificBookingData?.paymentDetails?.paymentMode}
            </h2>
            <label className="text-[#858585] font-medium">Status</label>
            <div
              className={`flex justify-center items-center gap-[0.5rem] text-[0.9rem] rounded-md font-semibold py-[0.2rem] w-[6rem] ${
                specificBookingData?.paymentDetails?.status?.toLowerCase() ===
                "paid"
                  ? "text-[#25BF17] bg-[#D1F0CF]"
                  : "text-[#FF0023] bg-[#FDCAD1]"
              }`}
            >
              <h2>{specificBookingData?.paymentDetails?.status}</h2>
            </div>
            <label className="text-[#858585] font-medium">
              Payment Method{" "}
            </label>
            <h2 className="font-semibold">
              {specificBookingData?.paymentDetails?.paymentMethod}
            </h2>
            {/* <label className="text-[#858585] font-medium">Transaction ID</label>
    <h2 className="font-semibold">TRA123DSD2231</h2> */}
            <label className="text-[#858585] font-medium">Total Amount</label>
            <h2 className="font-semibold">
              ₹ {specificBookingData?.paymentDetails?.totalAmount}
            </h2>
            {/* <label className="text-[#858585] font-medium">Remarks</label> */}
          </div>
        </div>
      </div>
      {/* <div className="grid grid-cols-2 place-content-end">
        <div></div>
        <div className="col-span-1">
          <h2 className="text-[1.15rem] font-bold my-[1rem]">Taxes & Fees</h2>
          <div className="grid grid-cols-2 gap-y-[1rem] place-content-between">
            <label className="text-[#858585] font-medium">
              Rent Total Amount
            </label>
            <h2 className="font-semibold">₹ 120.00</h2>
            <label className="text-[#858585] font-medium">
              Booking Charges
            </label>
            <h2 className="font-semibold">₹ 125</h2>
            <label className="text-[#858585] font-medium">
              Convenience Fees
            </label>
            <h2 className="font-semibold">₹ 50</h2>
            <label className="text-[#858585] font-medium">Discount</label>
            <h2 className="font-semibold text-[#F00]">
              (YEVIS12345CLUB) - ₹ 240
            </h2>
            <label className="text-[#858585] font-medium">GST</label>
            <h2 className="font-semibold">₹ 50</h2>
            <label className="text-[#858585] font-medium">Total Amount</label>
            <h2 className="font-semibold">₹ 1250.00</h2>
          </div>
        </div>
      </div> */}
    </div>
  );
}
