import React, { useState } from "react";
import { IconButton } from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import { useAuth } from "../../../store/AuthContext";
import { toast } from "react-toastify";
import { APIurls } from "../../../api/apiConstant";

export default function DeleteSubCategoryModal({ refetch, setDeleteSubCategoryModal, subCategoryId }) {

    const [isLoading, setIsLoading] = useState(false)
    const [showError, setShowError] = useState(false)
    const [deleteReason, setDeleteReason] = useState("")

    const { getAccessToken } = useAuth();

    const handleDeleteSubCategory = async () => {
        if (deleteReason === "") {
            setShowError(true)
            return;
        }

        try {
            setIsLoading(true)
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.deleteSubCategoryById}/${subCategoryId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ remark: deleteReason })
            });
            const result = await response.json();
            if (!response.ok) {
                toast.error("Failed to delete Sub-Category");
                throw new Error(result);
            }
            console.log("Sub-Category Deleted Successfully", result);
            refetch();
            setDeleteSubCategoryModal(false);
            toast.success("Sub-Category Deleted Successfully");
        } catch (error) {
            console.error("Error while deleting Sub-Category", error?.message);
        }
        setIsLoading(false)
    }

    return (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-[#000000E6]">
            <div className="flex flex-col gap-4 bg-white rounded-sm px-6 py-8 w-[40%] relative">
                {isLoading && (
                    <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50 z-20">
                        <div className="loader border-t-4 border-orange-500 border-solid rounded-full w-12 h-12 animate-spin"></div>
                    </div>
                )}
                <div className="flex justify-end">
                    <IconButton
                        onClick={() => setDeleteSubCategoryModal(false)}
                        className={"shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-sm"}
                        size="sm"
                    >
                        <Icon icon="material-symbols:close" className="text-[1.5rem]" />
                    </IconButton>
                </div>
                <div>
                    <h1 className="text-[20px] font-bold">Are you sure?</h1>
                </div>
                <div>
                    <p className="text-[16px]">
                        You Are Deleting Sub-Category.
                    </p>
                </div>
                <div>
                    <h1 className="text-[20px] font-bold">Reason for Delete</h1>
                    <div className="flex flex-col gap-[0.5rem]">
                        <textarea
                            rows={5}
                            className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] placeholder:text-[#7F7F7F] border border-[#D9D9D9] outline-none"
                            type="text"
                            placeholder="Enter Description"
                            value={deleteReason}
                            onChange={(e) => setDeleteReason(e.target.value)}
                        />
                    </div>
                    {showError && (
                        <p className="text-red-600 text-sm">This field is required*</p>
                    )}
                </div>
                <div className="flex items-center justify-end">
                    <button
                        type="button"
                        className="w-full text-center justify-center text-[0.8rem] sm:text-[1rem] flex items-center max-xs:w-full gap-[0.3rem] xs:gap-[1rem] bg-red-600 text-white py-[0.6rem] px-[2rem] xs:px-[2rem] font-semibold"
                        onClick={handleDeleteSubCategory}
                    >
                        Yes, Delete
                    </button>
                </div>
            </div>

        </div>
    );
}
