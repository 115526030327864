import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

export default function CategoriesContainer() {
   return (
      <div className='bg-white flex flex-col gap-[1rem] rounded-md'>
         <div className='flex gap-[3rem] border-b-2 border-[#989898] px-[1rem] pt-[0.5rem]'>
            <NavLink to={`/categories`} end>
               {({ isActive }) => (
                  <h2 className={`text-[1.2rem] sm:text-[1.3rem] font-bold whitespace-nowrap pb-[0.3rem] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                     Main Categories
                  </h2>
               )}
            </NavLink>
            <NavLink to={`/categories/sub-categories`}>
               {({ isActive }) => (
                  <h2 className={`text-[1.2rem] sm:text-[1.3rem] font-bold whitespace-nowrap pb-[0.3rem] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                     Sub-Categories
                  </h2>
               )}
            </NavLink>
         </div>
         <div>
            <Outlet />
         </div>
         <ToastContainer />
      </div>
   )
}
