import { Icon } from "@iconify/react/dist/iconify.js";
import { Checkbox, IconButton, Option, Select, ThemeProvider } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { selectCustomTheme } from "../../../../helpers/constants";
import { useParams } from "react-router-dom";
import ThreeDotsLoading from "../../../ui/ThreeDotsLoading";
import {
  useGetAllClubOwnerStaffsQuery,
  useGetClubOwnerStaffPermissionsQuery,
  useGetClubOwnerStaffRolesQuery,
  useGetSingleClubOwnerStaffQuery,
  useUpdateClubOwnerStaffMutation,
} from "../../../../store/features/clubOwner/clubOwnerApi";
import showToastNotification from "../../../../utils/showToastNotification";

// Custom checked icon component
const CustomCheckIcon = () => (
  <svg
    className="w-5 h-5 bg-[#FB6108] rounded-[5px]" // Apply the color to the custom check icon
    viewBox="0 0 24 24"
    fill="none"
    stroke="white"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="20 6 9 17 4 12" />
  </svg>
);

export default function EditStaffModal({
  setShowEditStaffModal,
  staffId,
  setStaffId,
}) {
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [designations, setDesignations] = useState([
    "Manager",
    "Canteen Manager",
    "Booking Manager",
  ]);
  const [selectedDesignation, setSelectedDesignation] = useState("");
  const [showAddDesigInput, setShowAddDesigInput] = useState(false);
  const [newInputDesignation, setNewInputDesignation] = useState("");
  const [searchText, setSearchText] = useState("");

  // States to store input fields data
  const [permissions, setPermissions] = useState([]);
  //Track Error States
  const [showError, setShowError] = useState(false);

  const { id } = useParams();

  const { data: permissionsData } = useGetClubOwnerStaffPermissionsQuery();
  const permissionsArray = permissionsData?.permissionArray;

  const { data: staffRoles } = useGetClubOwnerStaffRolesQuery();
  console.log("Staff Roles are retrieved====> ", staffRoles?.response?.roles);

  const [
    updateClubOwnerStaffApi,
    { isLoading, isError, isSuccess, error, data },
  ] = useUpdateClubOwnerStaffMutation();
  const {
    data: clubOwnerStaffs,
    isLoading: isLoadingStaffs,
    isError: isErrorStaffs,
  } = useGetAllClubOwnerStaffsQuery(id);
  const {
    data: clubOwnerStaff,
    isLoading: isLoadingStaff,
    isError: isErrorStaff,
  } = useGetSingleClubOwnerStaffQuery(staffId);

  const hanldeChangePermission = (e, isChecked) => {
    const permission = e.target.value;
    if (isChecked) {
      // If the permission is checked, remove it
      setPermissions(
        permissions.filter(
          (item) =>
            item?.trim()?.toLowerCase() !== permission?.trim()?.toLowerCase()
        )
      );
    } else {
      // If the permission is unchecked, add it
      setPermissions([...permissions, permission]);
    }
  };

  const handleUpdateStaff = async (e) => {
    e.preventDefault();
    // Hanlde Error for empty input fields
    if (!selectedDesignation || !permissions.length > 0) {
      return setShowError(true);
    }

    const clubStaffData = {
      clubId: id,
      designation: selectedDesignation,
      permission: permissions,
    };
    try {
      const result = await updateClubOwnerStaffApi({ clubStaffData, staffId });
      if (result.error) {
        throw new Error(result?.error?.data?.message);
      }
      setShowEditStaffModal(false);
      toast.success("Updated staff succesfully !");
    } catch (error) {
      toast.error(error.message || "Failed to update staff !");
    }
  };

  //Load all designations and store in the state from API initially
  useEffect(() => {
    if (!isLoadingStaffs && !isErrorStaffs) {
      const allDesignations = clubOwnerStaffs?.response?.staffs;
      const dynamicDesignations = allDesignations?.map(
        (item) => item.designation
      );
      const uniqueDesignations = [...new Set([...dynamicDesignations])];
      setDesignations(uniqueDesignations);
    }
  }, [clubOwnerStaffs, isLoadingStaffs, isErrorStaffs]);

  //Set updatable data in the modal
  useEffect(() => {
    if (!isLoadingStaff && !isErrorStaff) {
      const { designation, permission } = clubOwnerStaff?.response?.staff;
      setSelectedDesignation(designation);
      setPermissions(permission);
    }
  }, [staffId, isLoadingStaff, isErrorStaff, clubOwnerStaff]);

  const handleToggleDropdown = () => {
    setToggleDropdown(!toggleDropdown);
  };
  const handleClickDesignation = (designation) => {
    setSelectedDesignation(designation);
    setToggleDropdown(false);
  };
  const handleShowAddDesignationInput = () => {
    setShowAddDesigInput(true);
  };
  const handleChangeNewInputDesignation = (e) => {
    setNewInputDesignation(e.target.value);
  };

  const handleAddDesignation = () => {
    if (!designations.includes(newInputDesignation)) {
      setDesignations([newInputDesignation, ...designations]);
      setShowAddDesigInput(false);
      setNewInputDesignation("");
    } else {
      toast.error("This designation is already exists!");
    }
  };

  const handleCancelDesignation = () => {
    setShowAddDesigInput(false);
    setNewInputDesignation("");
  };
  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };
  const filterBySearchText = (desig) => {
    if (searchText) {
      const regex = new RegExp("^" + searchText.trim().toLowerCase(), "i");
      return regex.test(desig.trim().toLowerCase());
    }
    return desig;
  };

  return (
    <ThemeProvider value={selectCustomTheme}>
      <div className="flex items-center justify-center bg-[#000000bb] h-full w-full absolute top-0 left-0 right-0 bottom-0 z-10">
        <div className="flex flex-col w-[90%] gap-[1.5rem] py-[1.5rem] px-[2rem] bg-white rounded-lg justify-around">
          <div className="flex justify-between items-center">
            <p className="text-[1.3rem] font-semibold">Update Staff</p>
            <IconButton
              onClick={() => setShowEditStaffModal(false)}
              className="shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8]"
              size="sm"
              style={{
                borderRadius: "5px",
                border: "1px solid #FF0023",
              }}
            >
              <Icon icon="material-symbols:close" className="text-[1.5rem]" />
            </IconButton>
          </div>

          <div className="flex">
            <form
              // onSubmit={handleSubmit(onSubmit)}
              className="w-full flex flex-col"
            >
              {/* <div className="w-full">
                <div className="relative">
                  <div
                    onClick={handleToggleDropdown}
                    className="relative cursor-pointer"
                  >
                    <input
                      className="text-[0.75rem] w-full sm:text-[1rem] cursor-pointer font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                      type="text"
                      placeholder="Designation*"
                      value={selectedDesignation}
                      readOnly
                    />
                    {toggleDropdown ? (
                      <Icon
                        className="absolute text-[1.5rem] text-gray-600 right-3 top-2"
                        icon="iconamoon:arrow-up-2-light"
                        width="1.2em"
                        height="1.2em"
                      />
                    ) : (
                      <Icon
                        className="absolute text-[1.5rem] text-gray-600 right-3 top-2"
                        icon="iconamoon:arrow-down-2"
                      />
                    )}
                  </div>

                  {toggleDropdown && (
                    <div className="absolute shadow-lg bg-white w-full px-[1rem] py-[0.6rem] space-y-3 z-50">
                      <div className="relative">
                        <input
                          className="w-full border-[1px] border-gray-300 p-[0.5rem] rounded-md outline-none pl-10"
                          placeholder="Search"
                          value={searchText}
                          onChange={handleSearchInputChange}
                        />
                        <Icon
                          className="absolute left-3 top-3 text-[#8F8F8F]"
                          icon="ri:search-fill"
                          width="1.2em"
                          height="1.2em"
                        />
                      </div>

                      {showAddDesigInput ? (
                        <div>
                          <input
                            className="border-[1px] w-full border-gray-300 p-[0.8rem] rounded-md outline-none"
                            placeholder="Add Rentals Manager"
                            onChange={handleChangeNewInputDesignation}
                          />
                          <div className="flex items-center justify-end gap-[2rem] mt-2">
                            <p
                              onClick={handleCancelDesignation}
                              className="bg-red-500 min-w-[60px] px-2 py-1 text-white font-medium rounded-sm cursor-pointer select-none"
                            >
                              Cancel
                            </p>
                            <p
                              onClick={handleAddDesignation}
                              className="bg-[#FB6108] min-w-[60px] text-center px-2 py-1 text-white font-medium rounded-sm cursor-pointer select-none"
                            >
                              Add
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div
                          onClick={handleShowAddDesignationInput}
                          className="bg-[#FB6108] w-full p-[1rem] rounded-md text-white font-medium flex items-center justify-between cursor-pointer pb-[0.5rem]"
                        >
                          <p className="mb-2">Rentals manager</p>
                          <Icon
                            className="w-[1.5rem] h-[1.5rem] mb-2"
                            icon="ic:baseline-plus"
                          />
                        </div>
                      )}


                      <ul className="">
                        {designations
                          .filter(filterBySearchText)
                          .map((designation) => (
                            <li
                              onClick={() =>
                                handleClickDesignation(designation)
                              }
                              className="border-b-[1px] border-gray-300 hover:bg-[#EFF1F999] pb-[1rem] cursor-pointer pt-[0.8rem] pl-2"
                            >
                              {designation}
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
                  {showError && !selectedDesignation && (
                    <p className="text-red-500 text-[14px]">
                      This field is required*
                    </p>
                  )}
                </div>
              </div> */}

              <div className="w-full">
                {staffRoles && (
                  <Select
                    onChange={(value) => setSelectedDesignation(value)}
                    label="Select Role"
                  >
                    {staffRoles?.response?.roles?.map((item) => (
                      <Option value={item.roleName}>{item.roleName}</Option>
                    ))}
                  </Select>
                )}
                {!selectedDesignation && showError && (
                  <p className="text-red-500 text-[14px]">
                    This field is required*
                  </p>
                )}
              </div>

              <div className="mt-[1.5rem]">
                <h3 className="font-medium mb-[0.3rem]">Permissions</h3>
                <div className="flex flex-wrap gap-3">
                  {permissionsArray?.map((permission) => {
                    const permissionLower = permission.toLowerCase();
                    const isChecked = permissions?.some(
                      (perm) => perm.toLowerCase() === permissionLower
                    );

                    return (
                      <div className="border-[#d9d9d957] border-[1px] rounded-lg pr-4 bg-[#EFF1F999]">
                        <Checkbox
                          value={permission}
                          onChange={(e) => hanldeChangePermission(e, isChecked)}
                          label={permission}
                          checked={isChecked}
                          icon={<CustomCheckIcon />}
                        />
                      </div>
                    );
                  })}
                </div>
                {showError && !permissions.length > 0 && (
                  <p className="text-red-500 text-[14px]">
                    At least one permission must be seleted*
                  </p>
                )}
              </div>

              <div className="flex justify-end mt-[1.5rem]">
                <button
                  onClick={handleUpdateStaff}
                  disabled={isLoading}
                  type="submit"
                  className="flex items-center justify-between gap-[0.5rem] text-white text-[1.1rem] h-[45px] w-[165px] rounded-md px-[1rem] py-[0.6rem] bg-[#FB6108] font-bold"
                >
                  {isLoading ? <ThreeDotsLoading /> : <span>Update Staff</span>}
                  <Icon
                    icon="material-symbols:arrow-forward-ios"
                    className="text-[1.3rem]"
                  />
                </button>
              </div>
            </form>
          </div>
        </div>
        <ToastContainer />
      </div>
    </ThemeProvider>
  );
}
