import React, { useState, useEffect, useRef } from 'react'

export default function HeaderStepper({ steps, currentStep }) {
   const stepRef = useRef();
   const [newSteps, setNewSteps] = useState([]);

   const updatedStep = (stepNumber, stepsArr) => {
      const newSteps = [...stepsArr];
      let count = 0;
      while (count < newSteps.length) {
         if (count === stepNumber) {
            newSteps[count] = {
               ...newSteps[count],
               selected: true,
            }
            count++;
         } else if (count < stepNumber) {
            newSteps[count] = {
               ...newSteps[count],
               selected: true,
               completed: true
            }
            count++;
         }
         else {
            newSteps[count] = {
               ...newSteps[count],
               selected: false,
               completed: false
            }
            count++;
         }
         if (count === stepNumber - 1) {
            newSteps[count] = {
               ...newSteps[count],
               completed: true,
            }
         }
      }
      return newSteps
   }

   useEffect(() => {
      const stepsState = steps.map((step, index) =>
         Object.assign(
            {},
            {
               description: step,
               completed: false,
               selected: index === 0 ? true : false
            }
         ));
      stepRef.current = stepsState;
      const current = updatedStep(currentStep - 1, stepRef.current);
      setNewSteps(current);
   }, [steps, currentStep]);

   return (
      <div className="flex items-center gap-[3rem] text-[0.9rem] sm:text-[1.2rem] font-semibold">
         {
            newSteps.map((step, index) => {
               return (
                  <div key={index} className={`${step.selected || step.completed ? "text-black" : "text-[#7F7F7F]"} flex items-center gap-[0.6rem]`}>
                     <span className={`flex items-center justify-center h-[40px] w-[40px] rounded-full border-2 ${step.completed ? "border-[#3CB047]" : step.selected ? "border-primary" : "border-[#7F7F7F]"}`}>
                        {index + 1}
                     </span>
                     <span
                        className={`${step.completed ? "text-[#3CB047]" : step.selected ? "text-black" : "text-[#7F7F7F]"}`}>
                        {step.description}
                     </span>
                  </div>
               )
            })
         }
      </div>
   )
}


