import React, { useState } from "react";
import { IconButton } from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";
import { toast } from "react-toastify";

export default function CustomerDisableModal({ setShowModal, modalData, setIsRefetch }) {
  const { getAccessToken } = useAuth();
  const [disableReason, setDisableReason] = useState("");
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const disableCustomer = async () => {
    if (disableReason.trim() === "") {
      setShowError(true);
      return;
    }
    setIsLoading(true);
    try {
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.updateCustomerdisableStatus}/${modalData?.customerId}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ reason: disableReason }),
      });

      if (!response.ok) {
        if (response.status === 400) {
          const errorResult = await response.json();
          toast.error(errorResult);
          throw new Error(errorResult);
        } else {
          toast.error("Failed to disable customer");
          throw new Error("Failed to disable customer");
        }
      }

      const result = await response.json();
      toast.success("Customer Status Updated Successfully");
      setIsRefetch(true);
      setShowModal(false);
    } catch (error) {
      console.warn("Disabled customer Status Error -->", error);
    }
    setIsLoading(false);
  };

  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-[#000000E6]">
      <div className="flex flex-col gap-4 bg-white rounded-sm px-6 py-8 w-[45%] relative">
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50 z-20">
            <div className="loader border-t-4 border-orange-500 border-solid rounded-full w-12 h-12 animate-spin"></div>
          </div>
        )}
        <div className="flex justify-end">
          <IconButton
            onClick={() => setShowModal(false)}
            className={"shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-sm"}
            size="sm"
          >
            <Icon icon="material-symbols:close" className="text-[1.5rem]" />
          </IconButton>
        </div>
        <div>
          <h1 className="text-[20px] font-bold">Are you sure?</h1>
        </div>
        <div>
          <p className="text-[16px]">
            You are updating Customer.
          </p>
        </div>
        <div>
          <h1 className="text-[20px] font-bold">Reason for updating</h1>
          <div className="flex flex-col gap-[0.5rem]">
            {showError && (
              <p className="text-red-600 text-sm">This field is required*</p>
            )}
            <textarea
              rows={7}
              className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] placeholder:text-[#7F7F7F] border border-[#D9D9D9] outline-none"
              type="text"
              placeholder="Enter Description"
              value={disableReason}
              onChange={(e) => setDisableReason(e.target.value)}
            />
          </div>
        </div>
        <div className="flex items-center justify-end">
          <button
            type="button"
            className="w-full text-center justify-center text-[0.8rem] sm:text-[1rem] flex items-center max-xs:w-full gap-[0.3rem] xs:gap-[1rem] bg-green-500 text-white py-[0.6rem] px-[2rem] xs:px-[2rem] font-semibold"
            onClick={disableCustomer}
          >
            Yes, Update
          </button>
        </div>
      </div>
            
    </div>
  );
}
