import { Icon } from '@iconify/react'
import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import customerOrderItem from '../../../../../assets/Rectangle 24597.png'

export default function CustomersViewCanteenView() {
   return (
      <div className='bg-white flex flex-col gap-[1rem] rounded-md p-[1rem]'>
         <div className='flex gap-[0.5rem] items-center text-[#808080] font-semibold'>
            <Link to={"/club-owners"}>
               <h2 className='text-[1rem] font-semibold'>
                  Club List
               </h2>
            </Link>
            <Icon icon="material-symbols:arrow-forward-ios" className="text-[1.1rem]" />
            <Link to={`/club-owners/view/:customerId`}>
               <h2 className='text-[1rem]'>
                  NAGESH SPORTS HUB
                  <span className='text-[#808080] text-[0.9rem] font-normal'> (GBCLUB1) </span>
               </h2>
            </Link>
            <Icon icon="material-symbols:arrow-forward-ios" className="text-[1.1rem]" />
            <Link to={`/club-owners/view/:id/customers`}>
               <h2 className='text-[1rem]'>
                  Customers
               </h2>
            </Link>
            <Icon icon="material-symbols:arrow-forward-ios" className="text-[1.1rem]" />
            <Link to={`/club-owners/view/:id/customers/view/:customerId`}>
               <h2 className='text-[1rem]'>
                  Manikanta Putta
                  <span className='text-[#808080] text-[0.9rem] font-normal'> (MA001) </span>
               </h2>
            </Link>
            <Icon icon="material-symbols:arrow-forward-ios" className="text-[1.1rem]" />
            <Link to={`/club-owners/view/:id/customers/view/:customerId/canteen`}>
               <h2 className='text-[1rem]'>
                  Canteen
               </h2>
            </Link>
            <Icon icon="material-symbols:arrow-forward-ios" className="text-[1.1rem]" />
            <h2 className='text-[1.2rem] text-black font-bold'>
               MA001
            </h2>
         </div>
         <div className='flex gap-[2rem] text-[1.2rem] font-medium border-b-2 border-[#989898]'>
            <NavLink to={`/club-owners/view/:id/sports/view/:bookingId`} end>
               {({ isActive }) => (
                  <h2 className={`pb-[0.3rem] font-bold text-primary border-b-2 border-primary`}>
                     Order ID: 12345
                  </h2>
               )}
            </NavLink>
         </div>
         <div className='grid grid-cols-2 px-[0.6rem]'>
            <div className='my-[1rem]'>
               <div className='grid grid-cols-2 gap-y-[1rem]'>
                  <label className='text-[#858585] font-medium'>Date & Time</label>
                  <h2 className='font-semibold'>10/08/2023 - 04:30 pm</h2>
                  <label className='text-[#858585] font-medium'>Total Items</label>
                  <h2 className='font-semibold'>04</h2>
               </div>
            </div>
            <div className=''>
               <h2 className='text-[1.15rem] font-bold my-[1rem]'>
                  Payment Details
               </h2>
               <div className='grid grid-cols-2 gap-y-[1rem]'>
                  <label className='text-[#858585] font-medium'>Payment Mode</label>
                  <h2 className='font-semibold'>Online Payment</h2>
                  <label className='text-[#858585] font-medium'>Payment Method</label>
                  <h2 className='font-semibold'>UPI</h2>
                  <label className='text-[#858585] font-medium'>Total Amount</label>
                  <h2 className='font-semibold'>₹ 1250.00</h2>
                  <label className='text-[#858585] font-medium'>Status</label>
                  <div className={`flex justify-center items-center gap-[0.5rem] text-[0.9rem] rounded-md font-semibold py-[0.2rem] w-[6rem] ${"ACTIVE" === "ACTIVE" ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"}`}>
                     <h2>Paid</h2>
                  </div>
                  <label className='text-[#858585] font-medium'>Transaction ID</label>
                  <h2 className='font-semibold'>TRA123DSD2231</h2>
                  <label className='text-[#858585] font-medium'>Customer Mobile Number</label>
                  <h2 className='font-semibold'>9876543210</h2>
                  <label className='text-[#858585] font-medium'>Customer Name</label>
                  <h2 className='font-semibold'>Manikanta Putta</h2>
                  <label className='text-[#858585] font-medium'>Remarks</label>
                  <h2 className='font-semibold'>-</h2>
               </div>
            </div>
         </div>
         <div>
            <h1 className='font-bold text-[1.15rem] my-4'>Items</h1>
            <table className='w-full'>
               <thead className=''>
                  <tr className='bg-[#F6F8FC]'>
                     <th className='py-3 font-semibold'>S.No</th>
                     <th className='font-semibold'>Item Image</th>
                     <th className='font-semibold'>Item Details</th>
                     <th className='font-semibold'>Price</th>
                     <th className='font-semibold'>Quantity</th>
                     <th className='font-semibold'>Total Price</th>
                  </tr>
               </thead>
               <tbody className=''>
                  <tr className='text-center'>
                     <td className='font-semibold py-6'>1</td>
                     <td className='font-semibold flex items-center justify-center'>
                        <img src={customerOrderItem} alt="" className='text-center' />
                     </td>
                     <td className='font-semibold'>Coca Cola 300ml</td>
                     <td className='font-semibold'>₹80</td>
                     <td className='font-semibold'>1</td>
                     <td className='font-semibold'>₹80</td>
                  </tr>
                  <tr className='text-center'>
                     <td className='font-semibold py-6'>1</td>
                     <td className='font-semibold flex items-center justify-center'>
                        <img src={customerOrderItem} alt="" className='text-center' />
                     </td>
                     <td className='font-semibold'>Coca Cola 300ml</td>
                     <td className='font-semibold'>₹80</td>
                     <td className='font-semibold'>2</td>
                     <td className='font-semibold'>₹160</td>
                  </tr>
                  <tr className='text-center'>
                     <td className='font-semibold py-6'>1</td>
                     <td className='font-semibold flex items-center justify-center'>
                        <img src={customerOrderItem} alt="" className='text-center' />
                     </td>
                     <td className='font-semibold'>Coca Cola 300ml</td>
                     <td className='font-semibold'>₹80</td>
                     <td className='font-semibold'>1</td>
                     <td className='font-semibold'>₹80</td>
                  </tr>
               </tbody>
            </table>
         </div>
         <div className='grid grid-cols-2 place-content-end'>
            <div></div>
            <div className='col-span-1'>
               <h2 className='text-[1.15rem] font-bold my-[1rem]'>
                  Taxes & Fees
               </h2>
               <div className='grid grid-cols-2 gap-y-[1rem] place-content-between'>
                  <label className='text-[#858585] font-medium'>GST</label>
                  <h2 className='font-semibold'>₹20.00</h2>
                  <label className='text-[#858585] font-medium'>Total Amount</label>
                  <h2 className='font-semibold'>₹350.00</h2>
               </div>
            </div>
         </div>
      </div>
   )
}


