import React, { useEffect, useState } from "react";
import { IconButton } from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import { PiGreaterThan } from "react-icons/pi";
import { useAuth } from "../../../store/AuthContext";
import { APIurls } from "../../../api/apiConstant";
import { toast } from "react-toastify";
import { PulseLoader } from 'react-spinners';

export default function SportEditModal({ setShowModal, sportName, sportId, setIsRefetch}) {

    const [sport, setSport] = useState("")
    const [showError, setShowError] = useState(false);
    const [isLoading,setIsLoading] = useState(false)
    const { getAccessToken } = useAuth(); 

    const handleSaveSport = async () => {
        if ((sport === "")) {
            setShowError(true)
            return
        }
        try{
            const token = await getAccessToken();
            setIsLoading(true)
            const response = await fetch(`${APIurls.updateSportNameForTournament}/${sportId}`,{
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({name:sport})
            })
            const result = await response.json();
            if(result.code > 201){
                toast.error(result.message);
                setIsLoading(false)
                return
            }
            console.log("Sport Edit Successfully",result)
            setIsRefetch(true)
            toast.success(result.message)
        }
        catch(error){
            console.log(error)
        }
        setIsLoading(false)
        setShowModal(false)
    }

    useEffect(() => {
        setSport(sportName);
      }, [])

    return (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-[#000000E6]">
            <div className="flex flex-col gap-4 bg-white rounded-sm px-6 py-8 w-[40%] relative">
                <div className="flex justify-between items-center">
                    <h1 className="text-[20px] font-bold">Edit Sport</h1>
                    <IconButton
                        onClick={() => setShowModal(false)}
                        className={"shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-sm"}
                        size="sm"
                    >
                        <Icon icon="material-symbols:close" className="text-[1.5rem]" />
                    </IconButton>
                </div>
                <div className="flex flex-col gap-[0.75rem]">
                    <input
                        className="flex-grow text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] placeholder:text-[#7F7F7F] bg-[#EFF1F9] outline-none"
                        type="text"
                        placeholder="Sport Name"
                        value={sport}
                        onChange={(e) => setSport(e.target.value)}
                    />
                    {!sport && showError && (
                        <p className="text-red-600 text-sm">This field is required*</p>
                    )}
                </div>
                <div className="w-[17%] flex justify-center text-white bg-[#FB6108] px-4 py-1 cursor-pointer rounded-md gap-1">
                    {isLoading ? <PulseLoader color="white" size={8}/> : 
                    
                    <button
                        type="submit"
                        className="text-[0.8rem] flex items-center justify-center sm:text-[1rem] font-semibold"
                        onClick={handleSaveSport}
                    >
                        Save
                    </button>
                    }
                </div>
            </div>
        </div>
    );
}
