import React, { useState } from 'react'
import { Icon } from "@iconify/react";
import { AiOutlinePlus } from "react-icons/ai";
import DataTable from 'react-data-table-component';
import { IconButton } from "@material-tailwind/react";
import { NavLink, useParams } from 'react-router-dom';
import OverViewAddTeamModal from './OverViewAddTeamModal';
import OverViewEditTeamModal from './OverViewEditTeamModal';
import { useAuth } from '../../../../store/AuthContext';
import { APIurls } from '../../../../api/apiConstant';
import { useQuery } from 'react-query';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import DeletePartcipantModal from './DeletePartcipantModal';
import OverViewEditPlayerModal from './OverViewEditPlayerModal';

export default function TeamsCreation() {

  const [AddTeamModal, setAddTeamModal] = useState(false);
  const [editTeamModal, setEditTeamModal] = useState(false);
  const [participantNumber, setPartcipantNumber] = useState(0);
  const [participantId, setPartcipantId] = useState("");
  const [deleteParticipantModal, setDeletPartcipantModal] = useState(false);
  const [editPlayerModal, setEditPlayerModal] = useState(false);
  const [playername, setplayername] = useState("");

  const { tournamentId } = useParams();
  const { getAccessToken } = useAuth();

  const fetchParticipants = async () => {
    const token = await getAccessToken();
    const response = await fetch(`${APIurls.fetchParticipantsList}/${tournamentId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    );
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    setPartcipantNumber(result?.response?.participants?.length);
    return result?.response;
  };

  const { data: participantsData, isLoading, refetch: participantsrefetch } = useQuery(["fetchParticipantsData", tournamentId], fetchParticipants)
  console.log("Participant Data", participantsData)

  const handleAddTeamShowModal = () => {
    setAddTeamModal(true);
  }

  const handleEditTeamModal = (id) => {
    setPartcipantId(id)
    setEditTeamModal(true)
  }

  const handleDeletePartcipantModal = (id) => {
    setPartcipantId(id)
    setDeletPartcipantModal(true)
  }

  const handleEditPlayerModal = (name) => {
    setEditPlayerModal(true)
    setplayername(name)
  }

  const columns1 = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      width: "80px",
      center: true
    },
    {
      name: "Teams List",
      selector: row => (
        <div>
          <div>{row?.name}</div>
          <div>{row?.email}</div>
          <div>{row?.phone}</div>
        </div>
      ),
      center: true
    },
    {
      name: "Date Added",
      selector: row => (
        <div>{moment(row?.createdAt).format("MMM Do YY")}</div>
      ),
      center: true
    },
    {
      name: "Actions",
      selector: row => (
        <div className="flex gap-[0.3rem]">
          <IconButton
            className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black border border-[#D9D9D9] rounded-lg"
            onClick={() => handleEditTeamModal(row?._id)}
          >
            <Icon
              icon="material-symbols:edit-rounded"
              className="text-[1.5rem]"
            />
          </IconButton>
          <NavLink to={`/tournaments/tournamentDetails/${tournamentId}/viewTeamParticipants/${row?._id}`}>
            <IconButton
              className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
            >
              <Icon icon="mdi:eye" className="text-[1.5rem] text-black" />
            </IconButton>
          </NavLink>
          <div onClick={() => handleDeletePartcipantModal(row?._id)}>
            <IconButton
              className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black border border-[#D9D9D9] rounded-lg"
            >
              <Icon
                icon="material-symbols:delete-rounded"
                className="text-[1.5rem]"
              />
            </IconButton>
          </div>
        </div>
      ),
      center: true
    }
  ]

  const columns2 = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      width: "80px"
    },
    {
      name: "Player Name",
      selector: row => (
        <div>
          <p>Name:- {row?.name}</p>
          <p>Phone:- {row?.phone === null ? "NA" : row?.phone}</p>
          <p>Email:- {row?.email === null ? "NA" : row?.email}</p>
        </div>
      ),
      center: true,
      // grow: 2.5
    },
    {
      name: "Date Added",
      selector: row => (
        <div>{moment(row?.createdAt).format("MMM Do YY")}</div>
      ),
      center: true
    },
    {
      name: "Updated Date",
      selector: row => (
        <div>{moment(row?.updatedAt).format("MMM Do YY")}</div>
      ),
      center: true
    },
    {
      name: "Action",
      selector: row => (
        <div className='flex items-center gap-2'>
          <div>
            <IconButton
              className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black border border-[#D9D9D9] rounded-lg"
              onClick={() => handleEditPlayerModal(row?._id)}
            >
              <Icon
                icon="material-symbols:edit-rounded"
                className="text-[1.5rem]"
              />
            </IconButton>
          </div>
          <div onClick={() => handleDeletePartcipantModal(row?._id)}>
            <IconButton
              className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black border border-[#D9D9D9] rounded-lg"
            >
              <Icon icon="material-symbols:delete-rounded" className="text-[1.5rem]" />
            </IconButton>
          </div>
        </div>
      ),
      center: true,
      grow: 1.5
    }
  ]

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        color: "#4D4D4D",
        fontSize: "13px",
        // background: "#EAECF0"
      },
    },
    head: {
      style: {
        background: "#EAECF0",
      },
    },

    rows: {
      style: {
        background: "#FFF",
        color: "#565656",
        padding: "5px 0px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1100px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto",
      },
    },
  };

  return (
    <div>
      <div className='border border-gray-200 p-3 rounded-md'>
        <div className='flex justify-between px-4 py-2'>
          <p className='text-xl font-semibold'>{participantsData?.selectionType === "team" ? "List of Teams" : "List of Players"}</p>
          <div className='flex gap-4'>
            <div className='flex items-center text-white bg-[#FB6108] p-2 rounded-md gap-1 cursor-pointer'
              onClick={handleAddTeamShowModal}
            >
              <button>{participantsData?.selectionType === "team" ? "Add Team" : "Add Player"}</button>
              <AiOutlinePlus className="text-[1.2rem]" />
            </div>
          </div>
        </div>
        {
          participantsData?.selectionType === "team" ?
            (<DataTable
              data={participantsData?.participants?.sort((a, b) => a?.teamNumber - b?.teamNumber)}
              columns={columns1}
              customStyles={customStyles}
              pagination
              paginationPerPage={10}
              progressPending={isLoading}
            />)
            :
            (<DataTable
              data={participantsData?.participants?.sort((a, b) => a?.playerNumber - b?.playerNumber)}
              columns={columns2}
              customStyles={customStyles}
              pagination
              paginationPerPage={10}
              progressPending={isLoading}
            />)
        }
        <ToastContainer />
      </div>
      {
        AddTeamModal && (
          <OverViewAddTeamModal
            setShowModal={setAddTeamModal}
            participantsrefetch={participantsrefetch}
            selectionType={participantsData?.selectionType}
            number={participantNumber}
            tournamentId={tournamentId}
          />
        )}
      {editTeamModal && (
        <OverViewEditTeamModal
          setEditTeamModal={setEditTeamModal}
          participantId={participantId}
          selectionType={participantsData?.selectionType}
          refetch={participantsrefetch}
        />
      )}
      {
        editPlayerModal && (
          <OverViewEditPlayerModal
            setEditPlayerModal={setEditPlayerModal}
            id={playername}
            refetch={participantsrefetch}
          />
        )}
      {deleteParticipantModal && (
        <DeletePartcipantModal
          partcipantModal={setDeletPartcipantModal}
          participantId={participantId}
          participantsrefetch={participantsrefetch}
          tournamentId={tournamentId}
        />
      )}
    </div>
  )
}
