import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ManageRoles from "../AdminModules/Roles/ManageRoles";


export default function AdminRoles() {
   const [updateRole, setUpdateRole] = useState(false);
   const { pathname } = useLocation();

   useEffect(() => {
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
   }, [pathname]);

   return (
      <div className="bg-secondary px-[1.5rem] py-[1rem] h-full">
         <div className="bg-white flex flex-col gap-[1rem] font-[500] rounded-md px-[2rem] py-[1rem]">
            <div>
               <Outlet context={[setUpdateRole]} />
            </div>
            <div className="my-[1rem]">
               <ManageRoles updateRole = {updateRole} setUpdateRole = {setUpdateRole}/>
            </div>
         </div>
      </div>
   );
}
