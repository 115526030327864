import React, { useState } from 'react'
import { Link, NavLink, useOutletContext } from 'react-router-dom';
import { Button, IconButton, Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react';
import moment from 'moment';
import { Icon } from '@iconify/react';
import DataTable from 'react-data-table-component';
import CustomCheckbox from '../../../../helpers/CustomCheckbox';
import { APIurls } from '../../../../api/apiConstant';
import { useAuth } from '../../../../store/AuthContext';
import { useQuery } from 'react-query';
import { ToastContainer } from 'react-toastify';
import photo from '../../../../assets/Rectangle 19.png'


export default function CanteenList() {
    const [customerId] = useOutletContext();
    const [totalClubs, setTotalClubs] = useState();

    const { getAccessToken } = useAuth();

    //  const fetchClubData = async () => {
    //     const token = await getAccessToken();
    //     const response = await fetch(`${APIurls.fetchClubs}`, {
    //        method: 'GET',
    //        headers: {
    //           Authorization: `Bearer ${token}`,
    //        }
    //     })
    //     const result = await response.json();
    //     if (!response.ok) {
    //        throw new Error(result.message);
    //     }
    //     console.log("Club List", result?.response);
    //     setTotalClubs(result?.response?.CatLength);
    //     return result?.response?.CatArray?.reverse();
    //  }
    //  const { data: clubs, isLoading, error, refetch } = useQuery('clubData', fetchClubData);

    const customStyles = {
        headRow: {
            style: {
                fontWeight: 600,
                // padding: "10px 10px",
                color: "#4D4D4D",
                fontSize: "13px",
            },
        },
        head: {
            style: {
                // borderRadius: "6px 6px 0px 0px",
                // borderTop: "0.824px solid #E9E9E9",
                background: "#FFF",
                // fontSize: "15px",
            },
        },

        rows: {
            style: {
                // borderRadius: "6px 6px 0px 0px",
                // borderTop: "0.824px solid #E9E9E9",
                background: "#FFF",
                color: "#00000",
                padding: "5px 0px",
                fontSize: "14px",
                fontWeight: 600,
                width: "auto",
            },
        },

        table: {
            style: {
                overflow: "visible",
                maxWidth: "1200px",
            },
        },
        tableWrapper: {
            style: {
                overflow: "visible",
            },
        },
        responsiveWrapper: {
            style: {
                overflowX: "auto", // table scroll on x axis
            },
        },
    };

    const data = [
        {
            question: "How to Login?",
            answer: "Go to Header section and click on login button",
        },
        {
            question: "How to Login?",
            answer: "Go to Header section and click on login button",
        },
        {
            question: "How to Login?",
            answer: "Go to Header section and click on login button",
        },
        {
            question: "How to Login?",
            answer: "Go to Header section and click on login button",
        },
        {
            question: "How to Login?",
            answer: "Go to Header section and click on login button",
        },
        {
            question: "How to Login?",
            answer: "Go to Header section and click on login button",
        },
        {
            question: "How to Login?",
            answer: "Go to Header section and click on login button",
        },
    ];

    const columns = [
        {
            name: "SL",
            selector: (row, index) => index + 1,
            // center: true,
            maxWidth: "0.3rem",
        },
        {
            name: "Booking ID",
            selector: row => (
                <div className='flex flex-col'>
                    MA001
                </div>),
            // center: true,
            // maxWidth: "0.5rem",
        },
        {
            name: 'Date of Booking',
            id: "dob",
            selector: row => (
                <div className='flex flex-col'>
                    20-08-2023
                </div>
            ),
            // center: true,
        },

        {
            name: 'Time Slot',
            id: "time_slot",
            selector: row => (
                <div>
                    05:00 PM - 07:00 PM
                </div>
            ),
            center: true,
        },
        {
            name: 'Sports',
            id: "sports",
            selector: row => (
                <div>
                    Box Cricket
                </div>
            ),
            center: true,
        },
        {
            name: 'Total Amount',
            id: "total_bookings",
            selector: row => (
                <div>
                    ₹1250
                </div>
            ),
            center: true,
        },
        {
            name: "Status",
            id: "status",
            selector: (row) => (
                <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
            py-[0.4rem] w-[7rem] ${row.status !== "Active" ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"}`}>
                    <h2>{"Paid Online"}</h2>
                </div>
            ),
            center: true,
            // grow: 1,
            sortable: true,
        },
        {
            name: "Actions",
            id: "action",
            cell: (row) => (
                <div className="flex gap-[0.5rem]">

                    <Link to={`booking/:bookingId`}>
                        <IconButton
                            className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
                        >
                           <Icon icon="mdi:eye" 
                                className="text-[1.5rem] text-black"
                            />
                        </IconButton>
                    </Link>
                    <Link to={``}>
                        <IconButton
                            className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
                        >
                            <Icon
                                icon="ic:round-print"
                                className="text-[1.5rem] text-black"
                            />
                        </IconButton>
                    </Link>
                </div>
            ),
            center: true,
            // grow: 1,
        },

    ];

    return (
        <>
            <div className='flex gap-[25rem] items-center border-t border-[#EAECF0] pt-[1.5rem] pb-6 px-6'>
                <div className='flex items-center'>
                <Link to="/customers"><h2 className='text-[#808080] text-[1.3rem] font-semibold whitespace-nowrap'>Customers List </h2></Link>
                    <Icon icon="f7:greaterthan" className='text-[#808080]' />
                    <div><span className="text-black text-xl font-semibold whitespace-nowrap">Manikanta Putta </span><span className="text-zinc-500 text-base font-normal font-['Inter'] leading-tight">(12345)</span></div>
                    <Icon icon="mdi:keyboard-arrow-down" />
                </div>
                <div className='flex items-center gap-[2rem] '>
                    <div className='flex items-center gap-[0.4rem] hover:text-primary cursor-pointer'>
                        <Icon
                            icon='material-symbols:delete'
                            className='text-[1.5rem]'
                        />
                        <span className='text-[1rem] font-medium'>Remove</span>
                    </div>
                    <div className='flex items-center gap-[0.3rem] hover:text-primary cursor-pointer'>
                    <span className={`bg-[#25BF17] h-[0.5rem] w-[0.5rem] rounded-full`}></span>
                        <p className='text-[1rem] font-medium whitespace-nowrap text-[#026039]'>Active</p>
                        <Icon
                            icon='mingcute:down-line'
                            className='text-[1.5rem]'
                        />
                    </div>
                    <div className="flex flex-row gap-[0.5rem] xs:gap-[1rem] items-center justify-center border border-[#CBCBCB] rounded-[5px]">
                        <div className="">
                            <Menu>
                                <MenuHandler>
                                    <Button className="text-[0.8rem] bg-[#FB6108] sm:text-[1rem] flex w-full flex-row justify-center whitespace-nowrap capitalize text-white  px-[0.4rem] xs:px-[1rem] py-[0.4rem] rounded-md items-center gap-[0.5rem] sm:gap-[0.8rem] hover:shadow-none shadow-none">
                                        <Icon
                                            icon="mdi:cloud-download"
                                            className="text-[1.5rem]"
                                        />
                                        <span className="font-[500]">Export</span>
                                    </Button>
                                </MenuHandler>
                                <MenuList>
                                    <MenuItem
                                        //  onClick={downloadPdf}
                                        className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                                    >
                                        {" "}
                                        <Icon
                                            icon="fa-solid:file-pdf"
                                            className="text-[#BE0A23] w-[1rem] h-auto"
                                        />
                                        Download as PDF
                                    </MenuItem>
                                    <hr className="my-[0.1rem]" />
                                    <MenuItem
                                        //  onClick={downloadCSV}
                                        className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                                    >
                                        <Icon
                                            icon="fa-solid:file-csv"
                                            className="text-[#29672E] w-[1rem] h-auto"
                                        />
                                        Download as CSV
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-b-[2px] border-[#c3c3c3] mx-6 flex justify-start items-start gap-[40px] text-xs">
                <NavLink to={`/customers/view/${customerId}`} end>
                    {({ isActive }) => (
                        <div className={`justify-center items-center gap-2 flex ${isActive ? "font-bold text-primary border-b-2 pb-[16px] border-primary" : "text-[#858585]"}`}>
                          
                            <div className={` text-[20px] font-bold `}>Bookings</div>
                        </div>)}
                </NavLink>
                <NavLink to={`/customers/view/${customerId}/tournaments`} end>
                    {({ isActive }) => (
                        <div className={`justify-center items-center pb-[16px] gap-2 flex ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                       
                            <div className={` text-[20px] font-bold `}>Tournaments</div>
                        </div>)}
                </NavLink>
                <NavLink to={`/customers/view/${customerId}/canteen`} end>
                    {({ isActive }) => (
                        <div className={`justify-center pb-[16px] items-center gap-2 flex ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                            <div className={` text-[20px] font-bold `}>Canteen</div>
                        </div>)}
                </NavLink>

            </div>
            <div className='flex flex-col gap-[0.5rem] mx-6 '>
                <div className='flex gap-[30rem] items-center'>
                    <div className='flex items-center gap-[16rem]'>
                        <h2 className='text-black text-[16px] font-[500] whitespace-nowrap'>Bookings</h2>
                    </div>
                    <div className='flex items-center gap-[1rem] '>
                        <div className="flex p-[6px] gap-[10px] font-bold bg-[#FFF]  border-[#DFDFDF] border-[1px] rounded-[4px]">
                            <Icon icon="circum:search" className=" text-3xl  text-black text-[1.5rem] rounded-[5px] cursor-pointer" />
                            <input
                                type="text"
                                className="w-[176px] h-[24px]  placeholder-[#8F8F8F] text-black text-sm font-semibold outline-none"
                                placeholder="Search"
                            />
                        </div>
                        <div className='flex items-center h-[29px] w-[67px] p-[8px] gap-[10px] rounded-[4px] border-[1.5px] border-[#53545C] hover:text-primary cursor-pointer'>
                            <Icon icon="mdi:filter-outline" className='text-[16px] font-normal h-[16px] w-[16px]' />
                            <span className='text-[11px] font-normal leading-[13.31px] text-[#53545C]'>Filter</span>
                        </div>
                        <div className='flex items-center h-[29px] w-[67px] p-[8px] gap-[10px] rounded-[4px] border-[1.5px] border-[#53545C] hover:text-primary cursor-pointer'>
                            <Icon icon="solar:calendar-linear" className='text-[16px] font-normal h-[16px] w-[16px]' />
                            <span className='text-[11px] font-normal leading-[13.31px] text-[#53545C]'>Filter</span>
                        </div>
                        <div className='flex items-center h-[29px] w-[67px] p-[8px] gap-[10px] rounded-[4px] border-[1.5px] border-[#53545C] hover:text-primary cursor-pointer'>
                            <Icon icon="mynaui:send" className='text-[16px] font-normal h-[16px] w-[16px]' />
                            <span className='text-[11px] font-normal leading-[13.31px] text-[#53545C]'>Share</span>
                        </div>
                        <div className='flex items-center h-[29px] w-[99px] p-[8px] gap-[10px] rounded-[4px] border-[1.5px] border-[#53545C] hover:text-primary cursor-pointer'>
                            <p className='text-[11px] font-normal leading-[13.31px] text-[#53545C] whitespace-nowrap'>Bulk Action</p>
                            <Icon
                                icon='mingcute:down-line'
                                className='text-[16px] font-normal h-[16px] w-[16px]'
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <DataTable
                        columns={columns}
                        customStyles={customStyles}
                        data={data}
                        // progressPending={isLoading}
                    />
                </div>
                <div className=" h-[48px] bg-white border-b border-gray-200" />
                <ToastContainer />
            </div>
        </>
    )
}

