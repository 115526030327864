import { Icon } from '@iconify/react'
import React from 'react'
import { Link, NavLink, useOutletContext, useParams } from 'react-router-dom'
import rentalImg from '../../../../assets/rentals.png'
import { useGetSingleClubOwnerRentalQuery } from '../../../../store/features/clubOwner/clubOwnerApi'
import moment from 'moment'

export default function ViewCORentalView() {
   const {id,rentalId} = useParams()
   const [clubName, clubID] = useOutletContext();
   
   const {data} = useGetSingleClubOwnerRentalQuery(rentalId);
   console.log('Single Rental Data===============> ', data?.response?.rental)
   const {customerName, remarks, warrantyDuration, customerMobileNo, customerEmail,equipmentImage, priceForRent, start_date, end_date, equipmentName, equipmentQuantity, totalAmount, status, rentalId:rentId, type, payment_status} = data?.response?.rental || {};
   return (
      <div className='bg-white flex flex-col gap-[1rem] rounded-md p-[1rem]'>
         <div className='flex gap-[0.5rem] items-center text-[#808080] font-semibold'>
            <Link to={"/club-owners"}>
               <h2 className='text-[1rem] font-semibold'>
                  Club List
               </h2>
            </Link>
            <Icon icon="material-symbols:arrow-forward-ios" className="text-[1.1rem]" />
            <Link to={`/club-owners/view/${id}`}>
               <h2 className='text-[1rem]'>
                  {clubName}
                  <span className='text-[#808080] text-[0.9rem] font-normal'> ({clubID}) </span>
               </h2>
            </Link>
            <Icon icon="material-symbols:arrow-forward-ios" className="text-[1.1rem]" />
            <Link to={`/club-owners/view/${id}/rentals`}>
               <h2 className='text-[1rem]'>
                  Rentals
               </h2>
            </Link>
            <Icon icon="material-symbols:arrow-forward-ios" className="text-[1.1rem]" />
            <h2 className='text-[1.2rem] text-black font-bold'>
               {rentId}
            </h2>
         </div>
         <div className='flex gap-[2rem] text-[1.2rem] font-medium border-b-2 border-[#989898]'>
            {/* <NavLink to={`/club-owners/view/${id}/rentals/view/${rentId}`} end>
               {({ isActive }) => ( */}
                  <h2 className={`pb-[0.3rem] font-bold text-primary border-b-2 border-primary`}>
                     Rental ID: {rentId}
                  </h2>
               {/* )} */}
            {/* </NavLink> */}
         </div>
         <div className='grid grid-cols-5 px-[0.6rem]'>
            <div className='col-span-2'>
               <h2 className='text-[1.15rem] font-bold my-[1rem]'>
                  Info
               </h2>
               <div className='grid grid-cols-2 gap-y-[1rem] place-content-between'>
                  <label className='text-[#858585] font-medium'>Rental ID</label>
                  <h2 className='font-semibold'>{rentId}</h2>

                  {/* <label className='text-[#858585] font-medium'>Staff ID & Name</label>
                  <h2 className='font-semibold'>MA001 - Manikanta Putta</h2> */}

                  <label className='text-[#858585] font-medium'>Equipment Name</label>
                  <h2 className='font-semibold'>{equipmentName}</h2>
                  <label className='text-[#858585] font-medium'>Rent Amount</label>
                  <h2 className='font-semibold'>₹{totalAmount}</h2>
                  <label className='text-[#858585] font-medium'>Rent Start Date</label>
                  <h2 className='font-semibold'>{moment(start_date).format('DD/MM/YYYY')}</h2>
                  <label className='text-[#858585] font-medium'>Rent End Date</label>
                  <h2 className='font-semibold'>{moment(end_date).format('DD/MM/YYYY')}</h2>
                  <label className='text-[#858585] font-medium'>Rent Start Time</label>
                  <h2 className='font-semibold'>{moment(start_date).add(3, 'days').calendar()}</h2>
                  <label className='text-[#858585] font-medium'>Rent End Time</label>
                  <h2 className='font-semibold'>{moment(end_date).add(3, 'days').calendar()}</h2>
                  <label className='text-[#858585] font-medium'>Warranty Duration</label>
                  <h2 className='font-semibold'>{warrantyDuration}</h2>

                  {/* <label className='text-[#858585] font-medium'>Payment Mode</label>
                  <h2 className='font-semibold'>{payment_status}</h2> */}

                  <label className='text-[#858585] font-medium'>Remarks</label>
                  <h2 className='font-semibold'>{remarks}</h2>
               </div>
            </div>
            <div className='col-span-2'>
               <h2 className='text-[1.15rem] font-bold my-[1rem]'>
                  Player Info
               </h2>
               <div className='grid grid-cols-2 gap-y-[1rem]'>
                  <label className='text-[#858585] font-medium'>Player Name</label>
                  <h2 className='font-semibold'>{customerName}</h2>
                  <label className='text-[#858585] font-medium'>Player's Phone Number</label>
                  <h2 className='font-semibold'>{customerMobileNo}</h2>
                  <label className='text-[#858585] font-medium'>Player's Email Address</label>
                  <h2 className='font-semibold'>{customerEmail}</h2>
               </div>
               <h2 className='text-[1.15rem] font-bold mb-[1rem] mt-[3rem]'>
                  Status
               </h2>
               <div className='grid grid-cols-2 gap-y-[1rem]'>
                  <label className='text-[#858585] font-medium'>Status</label>
                  <div className={`${payment_status === "paid" ? "bg-[#25BF17]" : "bg-[#ff002242] text-red-500"} flex justify-center items-center gap-[0.5rem] text-[0.9rem] rounded-md font-semibold py-[0.2rem] w-[6.5rem] ${"xyz" !== "ACTIVE" ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"}`}>
                     <span className={`${payment_status === "paid" ? "bg-[#25BF17]" : "bg-[#FF0023]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
                     <h2>{payment_status}</h2>
                  </div>
               </div>
            </div>
            <div className='col-span-1 my-[1rem]'>
               <img src={equipmentImage} alt="sports" className='rounded-md' />
            </div>
         </div>
      </div>
   )
}

