import { Select as MaterialTailwindSelect, Option, Input, Checkbox, ThemeProvider } from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import uploadImg from "../../../../assets/upload-image.svg";
import { useEffect, useState } from "react";
import { selectCustomTheme } from './../../../../helpers/constants';
import Select, { components } from "react-select";
import { useCreateNewClubBannerMutation, useGetAllClubLocationsQuery, useGetClubOffersByLocationsQuery, useGetClubsByLocationsQuery, useGetClubsOrOffersConditionallyByLocationsQuery, useGetSingleClubBannerQuery, useUpdateClubBannerMutation } from "../../../../store/features/clubOwner/clubOwnerApi";
import { ToastContainer, toast } from "react-toastify";
import ThreeDotsLoading from "../../../ui/ThreeDotsLoading";
import { useParams } from "react-router-dom";

// Custom checked icon component
const CustomCheckIcon = () => (
  <svg
    className="w-5 h-5 bg-[#FB6108] rounded-[5px]" // Apply the color to the custom check icon
    viewBox="0 0 24 24"
    fill="none"
    stroke="white"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="20 6 9 17 4 12" />
  </svg>
);

export default function BannerEdit() {
  const {id} = useParams();
  /*=====================> Local States <================== */
  const [imgUrl, setImgUrl] = useState("");
  const [imgFile, setImgFile] = useState("");
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [inputTitle, setInputTitle] = useState("");
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [sendToCustomers, setSendToCustomers] = useState(false);
  const [sendToClubOwners, setSendToClubOwners] = useState(false);
  const [showError, setShowError] = useState(false);
  const [invalidUrl, setInvalidUrl] = useState(false);
 


  /*=====================> RTK Queries <================== */
  const { data: clubLocationsData } = useGetAllClubLocationsQuery();
  const clubLocatoinsArray = clubLocationsData?.response?.cities || [];

  //Call Api conditionally for Offers or Clubs
  const { data: clubsOrOffers } = useGetClubsOrOffersConditionallyByLocationsQuery({ selectedLocations, selectedBanner });
  const { clubs: clubsByLocations, clubOffers: offersByLocations } = clubsOrOffers?.response || {}

  const [updateBannerApi, { isLoading }] = useUpdateClubBannerMutation();

  const {data:singleBanner} = useGetSingleClubBannerQuery({bannerId:id});
  const {bannerImg, bannerType, location, sendTo, bannerId, clubId, offerId, title, customLink, tournamentId} = singleBanner?.response?.banner || {};
  
  useEffect(()=>{
    setInputTitle(title);
    setSendToCustomers(sendTo?.customers);
    setSendToClubOwners(sendTo?.clubOwners);
    setSelectedLocations(location?.map(item=> ({value:item, label:item})));
    setSelectedBanner({value:bannerType, label:bannerType})
  }, [id, title, location, bannerType, singleBanner])


  /*=====================> Options <================== */
  const locationOptions = clubLocatoinsArray?.map(item => ({ value: item, label: item }));
  locationOptions.unshift({ value: "select-all-locations", label: "Select All Locations" });

  const bannerOptions = [
    { value: "Offer", label: "Offer" },
    { value: "Club", label: "Club" },
    { value: "Tournaments", label: "Tournaments" },
    { value: "Custom Link", label: "Custom Link" }
  ];
  const clubsOrOffersOptions = clubsByLocations?.length 
  ? clubsByLocations?.map(item => ({ value: item.ClubName, label: item.ClubName, id: item._id })) 
  : offersByLocations?.length 
  ? offersByLocations?.map(item => ({ value: item.title, label: item.title, id: item._id })) 
  : []

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#F6F7FB',
    }),
  };


  /*=====================> Event Handlers <================== */
  const CheckboxOption = (props) => {
    const { value } = props.data;

    const handleCheckboxChange = () => {
      props.selectOption(props.data);
    };

    return (
      <components.Option {...props}>
        {value === "select-all-locations" ? (
          <span className="font-bold">{props.children}</span>
        ) : (
          <>
            <input
              type="checkbox"
              checked={props.isSelected}
              onChange={handleCheckboxChange}
              className="mr-2"
            />
            {props.children}
          </>
        )}
      </components.Option>
    );
  };

  const handleLocationChange = (selected) => {
    const includesSelectAll = selected.some(option => option.value === 'select-all-locations');

    if (includesSelectAll) {
      setSelectedLocations(locationOptions.filter(option => option.value !== 'select-all-locations'));
    } else {
      setSelectedLocations(selected || []);
    }
  };

  const handleImageUpload = (e) => {
    setImgUrl(URL.createObjectURL(e.target.files[0]));
    setImgFile(e.target.files[0])
  };

  const handleDeleteImage = () => {
    setImgUrl('');
    setImgFile('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!imgFile || !selectedLocations.length || !inputTitle || !selectedBanner || !selectedOffer || !selectedOffer.value) {
      return setShowError(true)
    }


    // Form Data 
    const formData = new FormData();
    formData.append('title', inputTitle);
    formData.append('bannerImg', imgFile);
    formData.append('location', JSON.stringify(selectedLocations?.map(item => item.value)));

    // Set dynamic field 
    if (selectedBanner?.value == 'Offer') {
      formData.append('offerId', selectedOffer?.id);
      formData.append('bannerType', selectedBanner?.value);
    } else if (selectedBanner?.value == 'Club') {
      formData.append('clubId', selectedOffer?.id);
      formData.append('bannerType', selectedBanner?.value);
    } else if (selectedBanner?.value == 'Custom Link') {
      const urlRegex = /^(?:https?|ftp):\/\/(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z0-9]+(?:\/[^\s]*)?$/;
      if (!urlRegex.test(selectedOffer.value)) {
        setInvalidUrl(true);
        return setShowError(true);
      }
      formData.append('customLink', selectedOffer.value);
      formData.append('bannerType', selectedBanner?.value);
    } else if (selectedBanner?.value == 'Tournaments') {
      formData.append('tournamentId', selectedOffer.value);
      formData.append('bannerType', selectedBanner?.value);
    }

    formData.append('sendTo', JSON.stringify({ customers: sendToCustomers, clubOwners: sendToClubOwners }));

    //Call Api to create new banner
    const result = await updateBannerApi({formData, bannerId});
    if (result?.error) {
      return toast.error(`${result?.error?.data?.message}`);
    }
    toast.success("Updated banner successfully !");

    //clear form fields
    setInputTitle("");
    setImgFile(null);
    setImgUrl("");
    setSelectedBanner("");
    setSelectedOffer("");
    setSelectedLocations([]);
    setSendToCustomers(false);
    setSendToClubOwners(false);
    setShowError(false);
    setInvalidUrl(false);
  }

  return (
    <ThemeProvider value={selectCustomTheme}>
      <form onSubmit={handleSubmit}>
        <div>
          {/* Form Header */}
          <div className="justify-between items-center gap-2 flex mt-[0.3rem] ml-[-10px]">
            <div className="text-black text-xl font-semibold leading-tight">
              Banner Edit
            </div>
            <button disabled={isLoading} className="py-2 w-[130px] h-10 bg-[#FB6108] rounded-[5px] shadow justify-center items-center gap-2 flex cursor-pointer">
              <div className="text-white text-base font-medium flex items-center gap-2">
                {isLoading ? <ThreeDotsLoading /> : <p>Update Banner</p>}
              </div>
            </button>
          </div>

          {/* Input fields */}
          <div className="flex my-[2rem] ml-[20px]">
            <div className="flex w-[90%] gap-10">
              <div className="space-y-[1.5rem] flex-1">
                <div>
                  <Select
                    placeholder="Location"
                    options={locationOptions}
                    isMulti
                    components={{ Option: CheckboxOption }}
                    styles={customStyles}
                    value={selectedLocations}
                    onChange={handleLocationChange}
                  />
                  {showError && !selectedLocations.length && (
                    <p className="text-red-500 text-[14px]">
                      This field is required*
                    </p>
                  )}
                </div>

                <div>
                  <input
                    className="text-[0.75rem] sm:text-[1rem] py-[0.6rem] px-[1rem] font-medium bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none w-full"
                    type="text"
                    placeholder="Name"
                    value={inputTitle}
                    onChange={(e) => setInputTitle(e.target.value)}
                  />
                  {showError && !inputTitle && (
                    <p className="text-red-500 text-[14px]">
                      This field is required*
                    </p>
                  )}
                </div>

                <div>
                  <Select
                    placeholder="Type of Banner"
                    options={bannerOptions}
                    styles={customStyles}
                    onChange={(value) => {
                      setSelectedBanner(value);
                      setSelectedOffer({ value: '' });
                    }}
                    value={selectedBanner}
                  />
                  {showError && !selectedBanner && (
                    <p className="text-red-500 text-[14px]">
                      This field is required*
                    </p>
                  )}
                </div>


                {/* Conditional Input Fields  */}
                {
                  selectedBanner?.value == 'Custom Link'
                    ?
                    (<div>
                      <input
                        className="text-[0.75rem] sm:text-[1rem] py-[0.6rem] px-[1rem] font-medium bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none w-full"
                        type="text"
                        placeholder="Enter Custom Link"
                        value={selectedOffer?.value}
                        onChange={(e) => setSelectedOffer({ value: e.target.value })}
                      />
                      {showError && !selectedOffer.value && (
                        <p className="text-red-500 text-[14px]">
                          This field is required*
                        </p>
                      )}
                      {showError && selectedOffer.value && invalidUrl && (
                        <p className="text-red-500 text-[14px]">
                          This URL is not valid !
                        </p>
                      )}
                    </div>)
                    :
                    selectedBanner?.value == 'Tournaments'
                      ?
                      (<div>
                        <input
                          className="text-[0.75rem] sm:text-[1rem] py-[0.6rem] px-[1rem] font-medium bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none w-full"
                          type="text"
                          placeholder="Enter Tournament"
                          value={selectedOffer?.value}
                          onChange={(e) => setSelectedOffer({ value: e.target.value })}
                        />
                        {showError && !selectedOffer.value && (
                          <p className="text-red-500 text-[14px]">
                            This field is required*
                          </p>
                        )}
                      </div>)
                      :
                      (<div>
                        <Select
                          placeholder="List of Offers"
                          options={clubsOrOffersOptions}
                          styles={customStyles}
                          onChange={(value) => setSelectedOffer(value)}
                          value={selectedOffer}
                        />
                        {showError && !selectedOffer && (
                          <p className="text-red-500 text-[14px]">
                            This field is required*
                          </p>
                        )}
                      </div>)
                }

                <div>
                  <h3 className="text-[#858585] font-semibold">
                    Select to add Notification
                  </h3>
                  <div className="flex items-center gap-5 pt-1">
                    <div>
                      <div className="border-[#D9D9D9] border-[1.5px] rounded-lg pr-4">
                        <Checkbox checked={sendToClubOwners} onChange={(e) => setSendToClubOwners(e.target.checked)} label="Club Owners" icon={<CustomCheckIcon />} />
                      </div>
                      {/* {showError && !sendToClubOwners && (
                        <p className="text-red-500 text-[14px]">
                          This field is required*
                        </p>
                      )} */}
                    </div>
                    <div>
                      <div className="border-[#D9D9D9] border-[1.5px] rounded-lg pr-4">
                        <Checkbox checked={sendToCustomers} onChange={(e) => setSendToCustomers(e.target.checked)} label="Customers" icon={<CustomCheckIcon />} />
                      </div>
                      {/* {showError && !sendToCustomers && (
                        <p className="text-red-500 text-[14px]">
                          This field is required*
                        </p>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>

              {/* column 2 */}
              <div>
                <div className="min-w-[350px] h-[300px] flex flex-col items-center justify-center py-[1rem] px-[1rem] border border-[#E1E2E9] bg-[#F6F7FB] rounded-lg">
                  {imgUrl ? (
                    <div className="border border-[#E1E2E9] rounded-lg flex items-center justify-center relative">
                      <img src={imgUrl} alt="" className="h-[150px] w-[150px]" />
                      <div className="border border-[#F00] rounded-[4px] absolute right-[-10px] top-[-10px] hover:bg-[#F00]/[0.3]">
                        <Icon
                          onClick={handleDeleteImage}
                          icon="material-symbols:delete-rounded"
                          className="text-[1.2rem] cursor-pointer text-[#F00]"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center">
                      <img src={uploadImg} alt="Uploaded" />
                      <button
                        type="button"
                        onClick={() => document.getElementById("input-field").click()}
                      >
                        <h2 className="text-primary font-[500] flex items-center gap-[0.5rem] my-[1rem]">
                          <Icon
                            icon="material-symbols:cloud-upload"
                            className="text-[1.5rem]"
                          />
                          <span>Upload Image</span>
                        </h2>
                      </button>
                      <input
                        onChange={handleImageUpload}
                        type="file"
                        accept="image/*"
                        id="input-field"
                        className="hidden"
                      />
                      <p className="text-[0.9rem] text-[#808080]">
                        Upload an image of the club.
                      </p>
                      <p className="text-[0.8rem] text-[#808080]">
                        File Format <span className="text-black"> jpeg, png </span>{" "}
                        Recommended Size{" "}
                        <span className="text-black"> 600x600 (1:1) </span>
                      </p>
                    </div>
                  )}
                </div>
                {showError && !imgFile && (
                  <p className="text-red-500 text-[14px]">
                    This field is required*
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer />
    </ThemeProvider>
  );
}
