import React from "react";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";

function DownloadExcel({ excelData, excelColumns }) {
    if (excelData?.length > 0) {
        // Combine header row with data
        const dataWithHeader = [excelColumns, ...excelData];

        // Convert data to worksheet
        const worksheet = XLSX.utils.aoa_to_sheet(dataWithHeader);

        // Calculate column widths
        const colWidthsArray = excelColumns.map((_, index) => ({
            wch: Math.max(
                ...dataWithHeader.map((row) => String(row[index] || '').length),
                excelColumns[index].length
            ) + 5,
        }));

        // Adjust the width of the column containing the image URLs
        const imageColumnIndex = excelColumns.indexOf('Image'); // Find the index of the image column
        if (imageColumnIndex !== -1) {
            // Set width to 50px for the image column
            colWidthsArray[imageColumnIndex] = { wch: 50 };
        }

        worksheet["!cols"] = colWidthsArray;

        // Create workbook and save file
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
        XLSX.writeFile(workbook, `ExportedData.xlsx`);
    } else {
        toast.error("No data available for Excel");
    }
}

export default DownloadExcel;
