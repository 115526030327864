import React, { useState } from "react";
import { Icon } from "@iconify/react";
import DatePicker from "react-flatpickr";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  Button,
  Option,
  Select,
  ThemeProvider,
} from "@material-tailwind/react";
import { selectCustomTheme } from "../../helpers/constants";
import uploadImg from "../../assets/upload-image.svg";
import { useQuery } from "react-query";
import { useAuth } from "../../store/AuthContext";
import { APIurls } from "../../api/apiConstant";

const Dashboard = () => {
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [adminName, setAdminName] = useState("");
  const [loading, setLoading] = useState(false);
  const [endDate, setEndDate] = useState("");
  const handleOpen = () => setOpen(!open);
  const [state, setState] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [selectImg, setSelectImg] = useState(null);
  const { getAccessToken } = useAuth();

  const fetchAdmin = async () => {
    const token = await getAccessToken();

    try {
      const res = await fetch(`${APIurls.fetchAdminOrSubAdminDetails}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();
      console.log("admin data from profile : ", data.response);
      if (!res.ok) {
        throw new Error(data.message);
      }
      setAdminName(data?.response?.name);
      return data?.response;
    } catch (error) {
      console.error("API FAILED TO FETCH ADMIN DATA ", error.message);
    }
  };

  const {
    data: adminData,
    isLoading,
    refetch,
  } = useQuery("updateAdminDetails", fetchAdmin);

  const updateAdmin = async () => {
    if (adminName.length === 0 || adminName === adminData?.name) {
      return;
    }
    try {
      setLoading(true);
      const token = await getAccessToken();
      console.log("reqBody : ", {
        name: adminName,
      });
      let updatedDetails = await fetch(
        `${APIurls.updateAdminOrSubAdminDetails}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name: adminName }),
        }
      );
      const data = await updatedDetails.json();
      console.log("admin data from profile updated : ", data.response);
      setAdminName(data?.response?.name);
      setLoading(false);
      if (!updatedDetails.ok) {
        throw new Error(data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("API FAILED TO UPDATE ADMIN NAME DATA ", error.message);
    }
  };

  return (
    <div className="bg-secondary px-[1.5rem] py-[1rem] h-full">
      {loading && <h1 className="text-center text-xl font-bold  my-[10rem] ">L o a d i n g . . .</h1>}
      {!loading && <div className="bg-white p-6 rounded-md flex flex-col gap-[1rem] ">
        <ThemeProvider value={selectCustomTheme}>
          {/* 1st Row */}
          <div className="flex gap-5">
            <div className="flex flex-col gap-[10px] p-[16px] border-[1px] rounded-[8px] border-[#767a81]">
              <div className="flex justify-between items-center pb-[24px]">
                <div className="text-black text-xl font-semibold whitespace-nowrap">
                  Basic Info
                </div>
                <Button
                  className="text-[0.8rem] sm:text-[1rem] flex  flex-row justify-center whitespace-nowrap capitalize text-white bg-[#FB6108] px-[0.4rem] xs:px-[1rem] py-[0.4rem] rounded-md items-center gap-[0.5rem] sm:gap-[0.8rem] hover:shadow-none shadow-none"
                  onClick={updateAdmin}
                >
                  <Icon icon="mdi:cloud-download" className="text-[1.5rem]" />
                  <span className="font-[500]">Update</span>
                </Button>
              </div>

              <div className="flex gap-[24px] items-center pb-[24px]">
                {/* <div className='flex flex-col gap-[16px]'>
                  <div className='p-[40px] border-[1px] rounded-[8px]'>
                    <Icon icon="mdi:user" width="64" height="64" />
                  </div>
                  <Button className="text-[0.8rem] sm:text-[1rem] flex  flex-row justify-center whitespace-nowrap capitalize text-white bg-[#FB6108] px-[0.4rem] xs:px-[1rem] py-[0.4rem] rounded-md items-center gap-[0.5rem] sm:gap-[0.8rem] hover:shadow-none shadow-none">
                    <Icon
                      icon="mdi:cloud-download"
                      className="text-[1.5rem]"
                    />
                    <span className="font-[500]">Upload Image</span>
                  </Button>
                </div> */}
                <div className="flex flex-col">
                  {selectImg?.name ? (
                    <>
                      <div className="flex flex-col items-center py-[1.5rem] px-[1rem] border border-[#E1E2E9] bg-[#F6F7FB] rounded-lg">
                        <img
                          src={URL.createObjectURL(selectImg)}
                          alt=""
                          className="h-[100px] w-[110px] "
                        />
                        <div className="flex gap-[1rem] items-center mt-[0.5rem]">
                          <div className=" border border-[#D9D9D9] rounded-md hover:bg-[#3D4A5C]/[0.3]">
                            <Icon
                              onClick={() => setSelectImg("")}
                              icon="akar-icons:cross"
                              className="text-[1.2rem] cursor-pointer"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="p-[40px] border-[1px] rounded-[8px]">
                        <Icon icon="mdi:user" width="64" height="64" />
                      </div>
                      <input
                        type="file"
                        accept="image/*"
                        value=""
                        className="input-field"
                        hidden
                        onChange={({ target: { files } }) => {
                          if (files[0]) {
                            setSelectImg(files[0]);
                          }
                        }}
                      />
                      <Button
                        type="button"
                        onClick={() =>
                          document.querySelector(".input-field").click()
                        }
                        className="text-[0.8rem] sm:text-[1rem] flex  flex-row justify-center whitespace-nowrap capitalize text-white bg-[#FB6108] px-[0.4rem] xs:px-[1rem] py-[0.4rem] rounded-md items-center gap-[0.5rem] sm:gap-[0.8rem] hover:shadow-none shadow-none"
                      >
                        <Icon
                          icon="mdi:cloud-download"
                          className="text-[1.5rem]"
                        />
                        <span className="font-[500]">Upload Image</span>
                      </Button>
                    </>
                  )}
                </div>

                <div className="flex flex-col gap-[24px]">
                  <div className="flex flex-col gap-[0.5rem]">
                    <input
                      // {...register("email", {
                      //     required: "*This field is required.",
                      //     pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      // })}
                      type="text"
                      className="text-[0.75rem] w-[400px] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                      placeholder="Name"
                      id="name"
                      onChange={(e) => setAdminName(e.target.value)}
                      value={adminName}
                    />
                  </div>
                  <div className="flex flex-col gap-[0.5rem]">
                    <input
                      // {...register("email", {
                      //     required: "*This field is required.",
                      //     pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      // })}
                      type="email"
                      className="text-[0.75rem] sm:text-[1rem]  font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none cursor-not-allowed"
                      placeholder="Email"
                      id="email"
                      disabled
                      value={adminData?.email}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='flex flex-col gap-[10px] p-[16px] border-[1px] rounded-[8px] border-[#a5c2fc]'>
              <div className='flex justify-between items-center pb-[24px]'>
                <div className="text-black text-xl font-semibold whitespace-nowrap">Password</div>
                <Button className="text-[0.8rem] sm:text-[1rem] flex flex-row justify-center whitespace-nowrap capitalize text-white bg-[#FB6108] px-[0.4rem] xs:px-[1rem] py-[0.4rem] rounded-md items-center gap-[0.5rem] sm:gap-[0.8rem] hover:shadow-none shadow-none">
                  <Icon
                    icon="mdi:cloud-download"
                    className="text-[1.5rem]"
                  />
                  <span className="font-[500]">Update</span>
                </Button>
              </div>

              <div className='flex gap-[24px] items-center pb-[24px]'>

                <div className='flex flex-col gap-[30px]'>
                  <div className="flex flex-col gap-[0.5rem]">
                    <input
                      type="password"
                      className="text-[0.75rem] w-[495px] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                      placeholder="Current Password"
                    // {...register("password", {
                    //     required: "*This field is required.",
                    //     minLength: 6,
                    // })}
                    />

                  </div>
                  <div className="flex flex-col gap-[0.5rem]">
                    <input
                      type="password"
                      className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                      placeholder="Enter New Password"
                    // {...register("password", {
                    //     required: "*This field is required.",
                    //     minLength: 6,
                    // })}
                    />

                  </div>
                  <div className="flex flex-col gap-[0.5rem]">
                    <input
                      type="password"
                      className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                      placeholder="Re-Enter new Password"
                    // {...register("password", {
                    //     required: "*This field is required.",
                    //     minLength: 6,
                    // })}
                    />

                  </div>
                </div>

              </div>
            </div> */}
          </div>
          {/* 2nd Row */}
          {/* <div className="flex flex-col gap-[10px] p-[16px] border-[1px] rounded-[8px] border-[#EAECF0]">
            <div className="flex justify-between items-center pb-[24px]">
              <div className="text-black text-xl font-semibold whitespace-nowrap">
                Basic Info
              </div>
              <Button className="text-[0.8rem] sm:text-[1rem] flex  flex-row justify-center whitespace-nowrap capitalize text-white bg-[#FB6108] px-[0.4rem] xs:px-[1rem] py-[0.4rem] rounded-md items-center gap-[0.5rem] sm:gap-[0.8rem] hover:shadow-none shadow-none">
                <Icon icon="mdi:cloud-download" className="text-[1.5rem]" />
                <span className="font-[500]">Update</span>
              </Button>
            </div>

            <div className="flex gap-4">
              <div className="flex flex-col gap-[0.5rem] w-full">
                <div className="relative">
                  <DatePicker
                    className="h-[2.63rem] outline-none placeholder-[#7F7F7F] rounded-lg pl-3 text-[1rem] text-black bg-[#EFF1F999] font-medium w-full"
                    placeholder="End Date"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                  />
                  <Icon
                    className="absolute right-3 top-[0.8rem]"
                    icon="uiw:date"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-[0.5rem] w-full">
                <Select
                  label="Select Language "
                  color="blue-gray"
                  size="lg"
                  className="text-[0.75rem] sm:text-[1rem] text-black font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                  value={state}
                  onChange={(value) => setState(value)}
                >
                  <Option value="Manager">West Bengal</Option>
                  <Option value="Employee">Tamil Nadu</Option>
                  <Option value="Sub Admin">Jharkhand</Option>
                </Select>
              </div>
            </div>
            <div className="flex flex-col gap-[0.5rem] w-full">
              <Select
                label="Select Time Zone "
                color="blue-gray"
                size="lg"
                className="text-[0.75rem] sm:text-[1rem] text-black font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                value={state}
                onChange={(value) => setState(value)}
              >
                <Option value="Manager">West Bengal</Option>
                <Option value="Employee">Tamil Nadu</Option>
                <Option value="Sub Admin">Jharkhand</Option>
              </Select>
            </div>
          </div> */}

          <div></div>
        </ThemeProvider>
      </div>}
    </div>
  );
};

export default Dashboard;
