import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import { updateOwnersInfo } from '../../../store/createClubSlice';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import "react-phone-number-input/style.css";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import Input from 'react-phone-number-input/input'
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_red.css";
import uploadImg from '../../../assets/upload-image.svg'
import "./style.css"
import { useAuth } from '../../../store/AuthContext';
import { APIurls } from '../../../api/apiConstant';
import { toast } from 'react-toastify';

export default function OwnerInfo({ showError, setShowError, error, setError }) {
  // const [error, setError] = useState(false);
  // const [showError, setShowError] = useState(false);
  const [isMobileNumberValid, setIsMobileNumberValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isAdhaarValid, setIsAdhaarValid] = useState(true);
  const [isPanValid, setIsPanValid] = useState(true);
  // const [selectedDate, setSelectDate] = useState(null);
  const [showEmailError, setShowEmailError] = useState(false);
  const [selectImg, setSelectImg] = useState(null);
  const dispatch = useDispatch();
  const { getAccessToken } = useAuth();

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  const adhaarRegex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;

  const {
    ownerName, adhaarNumber, mobileNumber, panNumber, email, dob, ownerImage
  } = useSelector((store) => store.createClub.ownerInfo);

  useEffect(() => {
    if (!(ownerName && (adhaarNumber?.length === 0 || adhaarNumber?.length === 12) && (adhaarNumber?.length === 0 || isAdhaarValid) && mobileNumber && isMobileNumberValid && (panNumber?.length === 0 || panNumber?.length === 10) && (panNumber?.length === 0 || isPanValid) && email && isEmailValid && dob && dob !== "undefined" && ownerImage)) {
      setError(true);
    } else {
      setError(false);
    }
  }, [ownerName, adhaarNumber, isAdhaarValid, mobileNumber, isMobileNumberValid, panNumber, isPanValid, email, isEmailValid, dob, ownerImage, setError]);

  const dateOptions = {
    mode: "single",
    dateFormat: "d M Y",
  };

  // console.log("STATE IMAGE", ownerImage);
  // console.log("IS PAN VALID", isPanValid);

  const normalizeDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString(); // This keeps the date in UTC format
  };


  const fetchOwnerDetails = async () => {
    if (!isEmailValid || !email) {
      setShowEmailError(true);
      return
    }
    const token = await getAccessToken();
    try {
      const response = await fetch(`${APIurls.fetchClubOwnerDetails}/${email}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      const result = await response.json();
      if (!response) {
        throw new Error(result.message);
      }
      console.log("Onwer Data", result);
      if (result?.response?.found === false) {
        toast.error("Owner details not found", { autoClose: 1500 })
        dispatch(updateOwnersInfo({
          ownerName: "",
          adhaarNumber: "",
          mobileNumber: "",
          panNumber: "",
          dob: "",
          ownerImage: "",
        }))
      } else {
        const { name, number, pan, aadhar, dob, image } = result?.response?.ownerData;
        dispatch(updateOwnersInfo({
          ownerName: name,
          adhaarNumber: aadhar || "",
          mobileNumber: number,
          panNumber: pan || "",
          dob: dob,
          ownerImage: image,
        }))
      }

    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <form
        className="flex flex-col gap-[2rem]">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-[1.5rem] gap-y-[1.5rem]">
          <div className='flex flex-col gap-[1.5rem]'>
            <div className="flex flex-col gap-[0.5rem]">
              <div className='flex items-center justify-between bg-[#F6F7FB] py-[0.6rem] px-[1rem]  rounded-md font-[500] text-[0.75rem] sm:text-[1rem]'>
                <input
                  className="placeholder:text-[#7F7F7F] outline-none bg-[#F6F7FB] w-full"
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => {
                    if (emailRegex.test(e.target.value)) {
                      setIsEmailValid(true)
                    }
                    else {
                      setIsEmailValid(false)
                    }
                    dispatch(updateOwnersInfo({ email: e.target.value.toLowerCase() }));
                  }}
                />
                <button onClick={fetchOwnerDetails}
                  className={`border px-2 rounded-md ${!isEmailValid || !email ? "border-[#7F7F7F] text-[#7F7F7F]" : "border-primary"}`}
                  type='button'
                  disabled={!isEmailValid || !email}
                >
                  Fetch
                </button>
              </div>
              {((showError && !email) || !isEmailValid || showEmailError) && <p className="text-[#E92215] text-sm">{(!isEmailValid && showError) || (!isEmailValid && showEmailError && email) ? "Invalid email" : (showError || !email) ? "*This field is required." : ""}</p>}
            </div>
            <div className="flex flex-col gap-[0.5rem]">
              <input
                className="inputText text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                type="text"
                placeholder="Owner Name"
                value={ownerName}
                onChange={(e) => {
                  if (!(/[\d]/.test(e.target.value))) {
                    dispatch(updateOwnersInfo({ ownerName: e.target.value }));
                  }
                }}
              />
              {showError && !ownerName && (
                <div className="text-sm text-[#E92215]">
                  *This Field is Required
                </div>
              )}
            </div>
            <div className="flex flex-col gap-[0.5rem]">
              <Input
                className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                placeholder="Mobile Number"
                country='IN'
                value={formatPhoneNumberIntl(mobileNumber + '')}
                onChange={(value) => {
                  // if (value && value.length <= 10) {
                  if (isValidPhoneNumber(value + '')) {
                    dispatch(updateOwnersInfo({ mobileNumber: value }))
                    setIsMobileNumberValid(true)
                  } else {
                    setIsMobileNumberValid(false);
                  }
                  // }
                }
                }
                maxLength={11}
              />
              {((showError && !mobileNumber) || !isMobileNumberValid) && <p className="text-[#E92215] text-sm">{(!isMobileNumberValid && showError) ? "Invalid mobile number" : showError ? "*This Field is Required." : ""}</p>}
            </div>
          </div>
          <div className='flex flex-col gap-[1.5rem]'>
            <div className="flex flex-col gap-[0.5rem]">
              <input
                className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                type="text"
                placeholder="Adhaar Number (Optional)"
                value={adhaarNumber}
                onChange={(e) => {
                  if (!(/[a-zA-Z]/.test(e.target.value)) && e.target.value.length <= 12) {
                    if (adhaarRegex.test(e.target.value)) {
                      setIsAdhaarValid(true);
                    } else {
                      setIsAdhaarValid(false);
                    }
                    dispatch(updateOwnersInfo({ adhaarNumber: e.target.value }));
                  }
                }}
              />
              {(showError && (adhaarNumber?.length !== 0 && !isAdhaarValid)) && <p className="text-[#E92215] text-sm">{((!isAdhaarValid || adhaarNumber?.length !== 12) && showError) ? "Invalid Adhaar Number" : showError ? "" : ""}</p>}
            </div>
            <div className="flex flex-col gap-[0.5rem]">
              <input
                className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                type="text"
                placeholder="PAN Number (Optional)"
                value={panNumber}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    if (panRegex.test(e.target.value.toUpperCase())) {
                      setIsPanValid(true);
                    } else {
                      setIsPanValid(false);
                    }
                    dispatch(updateOwnersInfo({ panNumber: e.target.value.toUpperCase() }));
                  }
                }}
              />
              {(showError && (panNumber?.length !== 0 && !isPanValid)) && <p className="text-[#E92215] text-sm">{((!isPanValid || panNumber.length !== 10) && showError) ? "Invalid PAN Number" : showError ? "" : ""}</p>}
            </div>
            <div className="flex flex-col gap-[0.5rem]">
              <Flatpickr
                className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                placeholder={"Date of Birth"}
                options={dateOptions}
                value={dob}
                onChange={(selectedDate) => dispatch(updateOwnersInfo({
                  dob: selectedDate[0],
                }))}
              />
              {showError && (!dob || dob === "undefined") && (
                <div className="text-sm text-[#E92215]">
                  *This Field is Required
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <div className="flex flex-col items-center py-[3rem] px-[1rem] border border-[#E1E2E9] bg-[#F6F7FB] rounded-lg">
              {ownerImage ? (
                <>
                  {
                    ownerImage?.name ? (
                      <img src={URL.createObjectURL(ownerImage)} alt="" className='h-[150px] w-[150px] ' />
                    ) : (
                      <img src={ownerImage} alt="" className='h-[150px] w-[150px] ' />
                    )
                  }
                  <div className="flex gap-[1rem] items-center mt-[0.5rem]">
                    <h2 className="text-[1.1rem] text-black font-[500]">
                      {ownerImage?.name?.length > 30
                        ? ownerImage?.name?.substring(0, 30) + "..."
                        : ownerImage?.name}
                    </h2>
                    <div className='p-[0.1rem] border border-[#D9D9D9] rounded-md hover:bg-[#3D4A5C]/[0.3]'>
                      <Icon
                        onClick={() => dispatch(updateOwnersInfo({ ownerImage: "" }))}
                        icon="akar-icons:cross"
                        className="text-[1.2rem] cursor-pointer"
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <img src={uploadImg} alt="" />
                  <button
                    type="button"
                    onClick={() =>
                      document.querySelector(".input-field").click()
                    }
                  >
                    <h2 className='text-primary font-[500] flex items-center gap-[0.5rem] my-[1rem]'>
                      <Icon
                        icon="material-symbols:cloud-upload"
                        className="text-[1.5rem]"
                      />
                      <span>
                        Upload Image
                      </span>
                    </h2>
                  </button>
                  <input
                    type="file"
                    accept="image/*"
                    value=""
                    className="input-field"
                    hidden
                    onChange={({ target: { files } }) => {
                      if (files[0]) {
                        setSelectImg(files[0]);
                        dispatch(updateOwnersInfo({ ownerImage: files[0] }))
                        // console.log("IMAGE DATA", files[0]);
                      }
                    }}
                  />
                  <p className='text-[0.9rem] text-[#808080]'>
                    Upload an image of owner.
                  </p>
                  <p className="text-[0.8rem] text-[#808080]">
                    File Format <span className='text-black'> jpeg, png </span> Recommened Size <span className='text-black'> 600x600 (1:1) </span>
                  </p>
                </>
              )}
            </div>
            {showError && !ownerImage && (
              <div className="text-sm text-[#E92215]">
                *This Field is Required
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  )
}
