import React, { useState } from 'react'
import { IconButton } from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import { PulseLoader } from 'react-spinners';
import { useAuth } from '../../../../store/AuthContext';
import { APIurls } from '../../../../api/apiConstant';
import { toast, ToastContainer } from 'react-toastify';

export default function UpdateEmailModal({ setUpdateEmailModal, clubOwnerMongoId, refetch }) {

    const [Email, setEmail] = useState("");
    const [showError, setShowError] = useState(false);
    const [loader, setLoader] = useState(false);

    const { getAccessToken } = useAuth();

    const handleUpdateEmail = async () => {
        if (!Email) {
            setShowError(true);
            return;
        }
        try {
            setLoader(true);
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.updateClubOwnerEmail}/${clubOwnerMongoId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': "application/json"
                },
                body: JSON.stringify({ email: Email })
            })
            if (!response.ok) {
                const result = await response.json();
                toast.error(result?.message);
                throw new Error(result?.message);
            }
            const result = await response.json();
            console.log("Email Successfully Updated", result?.response)
            refetch();
            toast.success("Email Successfully Updated");
        } catch (error) {
            console.log("Error in Updating Club Owner Email", error)
        }
        setLoader(false);
        setEmail("");
    }

    return (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-[#000000E6]">
            <div className="flex flex-col gap-4 bg-white rounded-sm px-6 py-8 w-[25%] relative">
                <div className="flex justify-between">
                    <h1 className="text-[20px] font-bold">Are you sure?</h1>
                    <IconButton
                        onClick={() => setUpdateEmailModal(false)}
                        className={"shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-sm"}
                        size="sm"
                    >
                        <Icon icon="material-symbols:close" className="text-[1.5rem]" />
                    </IconButton>
                </div>
                <div>
                    <p className="text-[16px]">
                        You Are Updating Email.
                    </p>
                </div>
                <div>
                    <input
                        type='text'
                        placeholder='Enter Email'
                        className="w-full font-[500] py-2 px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                        value={Email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {!Email && showError && (
                        <p className="text-red-600 text-xs">*This field is required</p>
                    )}
                </div>
                <div className="flex items-center justify-center">
                    {loader ? <PulseLoader size={8} color='orange' />
                        :
                        <button
                            type="button"
                            className="w-full text-center bg-primary text-white py-[0.6rem] px-[2rem] font-semibold rounded-md"
                            onClick={handleUpdateEmail}
                        >
                            Yes, Update
                        </button>
                    }
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}
