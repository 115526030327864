import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  IconButton,
} from "@material-tailwind/react";
import moment from "moment";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import CustomCheckbox from "../../../../helpers/CustomCheckbox";
import { ToastContainer } from "react-toastify";
import { useGetAllClubOwnerCanteensQuery } from "../../../../store/features/clubOwner/clubOwnerApi";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { RxCross2 } from "react-icons/rx";

export default function ViewCOCanteen() {
  const { id } = useParams();

  const [date, setDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const { data, isLoading } = useGetAllClubOwnerCanteensQuery(id);
  const clubOwnerCanteensData = data?.response?.findCanteenTransaction;
  console.log("All canteen data========> ", clubOwnerCanteensData);
  const { OrderID, customerDetails, TotalItem, TotalAmount, status } =
    clubOwnerCanteensData || {};

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        color: "#4D4D4D",
        fontSize: "13px",
      },
    },
    head: {
      style: {
        background: "#FFF",
      },
    },

    rows: {
      style: {
        background: "#FFF",
        color: "#565656",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
        padding: "5px 0px",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1100px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };

  const columns = [
    {
      name: "SL",
      id: "sl",
      selector: (row, index) => index + 1,
      center: true,
      maxWidth: "0.5rem",
    },
    {
      name: "Order ID",
      id: "orderId",
      selector: (row) => row.OrderID,
      center: true,
      grow: 1,
    },
    {
      name: "Date & Time",
      id: "bookingDateTime",
      selector: (row) =>
        `${moment(row.createdAt).format("Do MMM YY")}-${moment(
          row.createdAt
        ).format("LT")}`,
      center: true,
      grow: 2,
    },
    {
      name: "Customer Name",
      id: "customerName",
      selector: (row) => row?.customerDetails?.Username,
      center: true,
      grow: 1,
    },
    {
      name: "Mobile Number",
      id: "mobileNumber",
      selector: (row) => row?.customerDetails?.PhoneNumber,
      center: true,
    },
    {
      name: "Items",
      id: "items",
      selector: (row) => row.TotalItem,
      center: true,
    },
    {
      name: "Total Amount",
      id: "totalAmount",
      selector: (row) => `₹ ${row.TotalAmount}`,
      center: true,
    },
    {
      name: "Status",
      id: "status",
      selector: (row) => (
        <div
          className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
            py-[0.3rem] w-[5.5rem] ${row.status === "PAID"
              ? "text-[#25BF17] bg-[#D1F0CF]"
              : "text-[#FF0023] bg-[#FDCAD1]"
            }`}
        >
          <h2>{row.status}</h2>
        </div>
      ),
      center: true,
      grow: 1,
      sortable: true,
    },
    {
      name: "Actions",
      id: "action",
      cell: (row) => (
        <div className="flex gap-[0.5rem]">
          <Link to={`view/${row._id}`}>
            <IconButton className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]">
              <Icon icon="mdi:eye" className="text-[1.5rem] text-black" />
            </IconButton>
          </Link>
          <Link to={``}>
            <IconButton className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]">
              <Icon
                icon="material-symbols:print"
                className="text-[1.5rem] text-black"
              />
            </IconButton>
          </Link>
        </div>
      ),
      center: true,
      grow: 1,
    },
  ];
  // const data = [
  //    {
  //       orderId: "12345",
  //    },
  //    {
  //       orderId: "12345",
  //    },
  //    {
  //       orderId: "12345",
  //    },
  //    {
  //       orderId: "12345",
  //    },
  // ];
  //  return (
  //     <div className='bg-white rounded-md flex flex-col gap-[0.5rem]'>
  //        <div className='flex items-center justify-between'>
  //           <h2 className='text-[1.3rem] font-semibold'>
  //              Canteen
  //           </h2>
  //           <div>
  //              <div className='flex items-center gap-[2rem]'>
  //                 <div className='flex items-center gap-[0.4rem] hover:text-primary cursor-pointer'>
  //                    <Icon
  //                       icon='material-symbols:delete'
  //                       className='text-[1.5rem]'
  //                    />
  //                    <span className='text-[1rem] font-medium'>Remove</span>
  //                 </div>
  //                 <div className='flex items-center gap-[0.3rem] hover:text-[#FB6108] cursor-pointer'>
  //                    <p className='text-[1rem] font-medium'>Change Status</p>
  //                    <Icon
  //                       icon='mingcute:down-line'
  //                       className='text-[1.5rem]'
  //                    />
  //                 </div>
  //                 <div className="flex flex-row gap-[0.5rem] xs:gap-[1rem] items-center justify-center border border-[#CBCBCB] rounded-[5px]">
  //                    <div className="">
  //                       <Menu>
  //                          <MenuHandler>
  //                             <Button className="text-[0.8rem] sm:text-[1rem] flex w-full flex-row justify-center whitespace-nowrap capitalize text-black bg-white px-[0.4rem] xs:px-[1rem] py-[0.4rem] rounded-md items-center gap-[0.5rem] sm:gap-[0.8rem] hover:shadow-none shadow-none">
  //                                <Icon
  //                                   icon="mdi:cloud-download"
  //                                   className="text-[1.5rem]"
  //                                />
  //                                <span className="font-[500]">Export</span>
  //                             </Button>
  //                          </MenuHandler>
  //                          <MenuList>
  //                             <MenuItem
  //                                //  onClick={downloadPdf}
  //                                className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
  //                             >
  //                                {" "}
  //                                <Icon
  //                                   icon="fa-solid:file-pdf"
  //                                   className="text-[#BE0A23] w-[1rem] h-auto"
  //                                />
  //                                Download as PDF
  //                             </MenuItem>
  //                             <hr className="my-[0.1rem]" />
  //                             <MenuItem
  //                                //  onClick={downloadCSV}
  //                                className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
  //                             >
  //                                <Icon
  //                                   icon="fa-solid:file-csv"
  //                                   className="text-[#29672E] w-[1rem] h-auto"
  //                                />
  //                                Download as CSV
  //                             </MenuItem>
  //                          </MenuList>
  //                       </Menu>
  //                    </div>
  //                 </div>
  //              </div>
  //           </div>
  //        </div>
  //        <div>
  //           <DataTable
  //              columns={columns}
  //              customStyles={customStyles}
  //              data={clubOwnerCanteensData}
  //              pagination
  //              selectableRows
  //              progressPending={isLoading}
  //              selectableRowsComponent={CustomCheckbox}
  //           />
  //        </div>
  //        <ToastContainer />
  //     </div>
  //  )

  // };

  useEffect(() => {
    if (clubOwnerCanteensData) {
      if (date) {
        setFilteredData(
          clubOwnerCanteensData?.filter((item) =>
            moment(item?.createdAt).isSame(moment(date), "day")
          )
        );
      } else {
        setFilteredData(clubOwnerCanteensData);
      }
    }
  }, [date, clubOwnerCanteensData]);

  const handleDateChange = (selectedDates) => {
    const newDate = selectedDates[0];
    setDate(newDate);
  };

  return (
    <div className="bg-white rounded-md flex flex-col gap-[0.5rem]">
      <div className="flex items-center justify-between">
        <h2 className="text-[1.3rem] font-semibold">Canteen</h2>
        <div>
          <div className="flex items-center gap-[2rem]">
            <div className="flex items-center border rounded px-3 py-2">
              <Flatpickr
                value={date}
                onChange={handleDateChange}
                options={{ dateFormat: "Y-m-d" }}
                className="flatpickr-input border-0 outline-none flex-grow"
                placeholder="Select Date"
              />
              {date !== "" &&
                <RxCross2
                  className="text-[1.2rem] cursor-pointer bg-red-500 text-white rounded-md"
                  onClick={() => setDate("")}
                />
              }
            </div>
            {/* <div className="flex items-center gap-[0.4rem] hover:text-primary cursor-pointer">
                     <Icon icon="material-symbols:delete" className="text-[1.5rem]" />
                     <span className="text-[1rem] font-medium">Remove</span>
                  </div> */}
            {/* <div className="flex items-center gap-[0.3rem] hover:text-[#FB6108] cursor-pointer">
              <p className="text-[1rem] font-medium">Change Status</p>
              <Icon icon="mingcute:down-line" className="text-[1.5rem]" />
            </div> */}
            {/* <div className='flex gap-3 items-center rounded-md'>
              {loading ? (
                <div className="loading-indicator">Generating PDF...</div>
              ) : (
                <img
                  src={pdfLogo}
                  className='w-[25px] cursor-pointer'
                  onClick={handleDownloadPDF}
                  alt="Generate PDF"
                />
              )}
              <FaFileCsv
                className='w-[25px] h-[30px] text-green-800 cursor-pointer'
                onClick={() => DownloadExcel({ excelData, excelColumns })}
              />
            </div> */}
          </div>
        </div>
      </div>
      <div>
        <DataTable
          columns={columns}
          customStyles={customStyles}
          data={filteredData}
          pagination
          selectableRows
          // progressPending={isLoading}
          selectableRowsComponent={CustomCheckbox}
        />
      </div>
      <ToastContainer />
    </div>
  );
}
