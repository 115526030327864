import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { BsPlusLg } from "react-icons/bs";
import TournamentCategories from "./Tournament-Categories";
import Select from "react-select";
import { useAuth } from "../../../store/AuthContext";
import { APIurls } from "../../../api/apiConstant";
import { toast, ToastContainer } from "react-toastify";

export default function Tournament() {

  const [city, setCity] = useState({ value: "" });
  const [club, setClub] = useState({ value: "" });
  const [locationData, setLocationData] = useState([]);
  const [clubsData, setClubsData] = useState([]);
  const [mainCategoryData, setMainCategoryData] = useState([]);
  const [mainCategorySport, setMainCategorySport] = useState({
    value: "",
    label: "",
  });
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [subCategorySport, setSubCategorySport] = useState({
    value: "",
    label: "",
  });
  const [sportsData, setSportsData] = useState([]);
  const [sport, setSport] = useState({ value: "", label: "" });

  const navigate = useNavigate();
  const { getAccessToken, userRole } = useAuth();

  const fetchCitiesForTournaments = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(
        `${APIurls.fetchCitiesForFilteringTournaments}?location=${city?.value ? city?.value : ""
        }`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.message);
      }
      console.log("Location Data", result?.response);
      setLocationData(result?.response?.cities);
      setClubsData(result?.response?.clubs);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCitiesForTournaments();
    setClub("");
  }, [city]);

  // useEffect(() => {
  //   setClub("");
  // }, [city]);

  const fetchMainCategories = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.fetchCategories}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.message);
      }
      setMainCategoryData(result?.response?.CatArray);
      return result?.response?.CatArray;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllSports = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.fetchAllTournamentSports}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.message);
      }
      // toast.success("Sports Fetch Successfully ")
      console.log("Sports---------->", result.response?.tournamentSports);
      setSportsData(result?.response?.tournamentSports);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchMainCategories();
    fetchAllSports();
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#F6F7FB",
    }),
  };

  const cityOptions = locationData?.map((item) => ({
    value: item,
    label: item,
  }));
  const clubsOptions = clubsData?.map((item) => ({
    value: item?.ClubOwnerID,
    label: item?.clubName,
  }));
  const sportsOptions = sportsData?.map((sport) => ({
    value: sport?._id,
    label: sport?.name,
    isDisabled: sport?.status === "DISABLED",
  }));

  const mainCategoryOptions = mainCategoryData.map((category) => ({
    value: category?._id,
    label: category?.categoryName,
  }));

  useEffect(() => {
    if (mainCategorySport) {
      const mainCat = mainCategoryData?.find(
        (div) => div?._id === mainCategorySport?.value
      );
      if (Array.isArray(mainCat?.subCategoriesId)) {
        const subCatOptions = mainCat.subCategoriesId.map((div) => ({
          value: div?._id,
          label: div?.subCategoryName,
        }));
        setSubCategoryOptions(subCatOptions);
      }
    }
    setSubCategorySport({ value: "", label: "" });
  }, [mainCategorySport, mainCategoryData]);

  return (
    <div className="flex flex-col pt-4">
      <div className="">
        {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("CREATE_TOURNAMENTS")) &&
          <div
            className="flex justify-end items-end"
            onClick={() => navigate("/tournaments/create")}
          >
            <button
              className="flex justify-center items-center text-white bg-[#FB6108] p-2 rounded-md max-sm:text-[14px]"
            >
              Create Tournament
              <BsPlusLg className="text-white cursor-pointer" />
            </button>
          </div>
        }
        <div className="flex justify-center items-center gap-[1rem] pt-[1.5rem] flex-wrap">
          <Select
            placeholder="Select City"
            className="w-[240px] max-sm:text-[13px] max-md:w-9/12"
            customStyles={customStyles}
            value={city?.value ? city : ""}
            options={cityOptions}
            isClearable={true}
            onChange={(selectedOption) => setCity(selectedOption)}
          />
          <Select
            placeholder="Select Club"
            className="w-[240px] max-sm:text-[13px] max-md:w-9/12"
            customStyles={customStyles}
            value={club}
            options={clubsOptions}
            isClearable={true}
            onChange={(selectedOption) => setClub(selectedOption)}
          />
          <Select
            placeholder="Select Main Category"
            className="w-[240px] max-sm:text-[13px] max-md:w-9/12"
            customStyles={customStyles}
            value={mainCategorySport?.value ? mainCategorySport : ""}
            options={mainCategoryOptions}
            isClearable={true}
            onChange={(selectedOption) => setMainCategorySport(selectedOption)}
          />
          <Select
            placeholder="Select Sub Category"
            className="w-[240px] max-sm:text-[13px] max-md:w-9/12"
            customStyles={customStyles}
            value={subCategorySport?.value ? subCategorySport : ""}
            options={subCategoryOptions}
            isClearable={true}
            onChange={(selectedOption) => setSubCategorySport(selectedOption)}
          />
          <Select
            placeholder="Select Sport"
            className="w-[240px] max-sm:text-[13px] max-md:w-9/12"
            customStyles={customStyles}
            value={sport?.value ? sport : ""}
            options={sportsOptions}
            isClearable={true}
            onChange={(selectedOption) => setSport(selectedOption)}
          />
        </div>
      </div>
      <TournamentCategories />
      <Outlet
        context={[
          city?.value ? city?.value : "",
          club?.value ? club?.value : "",
          mainCategorySport?.value ? mainCategorySport?.value : "",
          subCategorySport?.value ? subCategorySport?.value : "",
          sport?.value ? sport?.value : "",
        ]}
      />
      <ToastContainer />
    </div>
  );
}
