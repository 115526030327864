import React, { useState } from "react";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";
import moment from "moment";
import DownloadSpecificBooking from "./DownloadSpecificBooking";
import { RotatingLines } from "react-loader-spinner";
import gamebeezLogo1 from '../../../assets/GB-Final-Compressed-Logo.png'
import gamebeezLogo2 from '../../../assets/gamebeez-logo-bookings.jpg'

const ViewBooking = () => {

  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const { getAccessToken } = useAuth();

  const bookingId = String(id)

  const fetchClubOwnerBookingData = async () => {
    const token = await getAccessToken();
    const response = await fetch(
      `${APIurls.fetchSpecificCustomerBookingData}/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    console.log("FETCH SPECIFIC BOOKING DATA", result?.response);
    return result?.response;
  };
  const {
    data: specificBookingData,
    isLoading,
  } = useQuery("bookingSpecificDataForBookings", fetchClubOwnerBookingData);

  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      width: "80px",
    },
    {
      name: "Sports",
      selector: (row) => (
        <div className="flex flex-col">
          {specificBookingData?.booking?.sport}
        </div>
      ),
      center: true,
    },
    {
      name: "Court Details",
      selector: (row) => (
        <div className="flex flex-col">
          <p>Name: {specificBookingData?.booking?.boardDetails?.boardName}</p>
          <p>No: {specificBookingData?.booking?.boardDetails?.boardNo}</p>
          <p>Id: {specificBookingData?.booking?.boardDetails?.boardsUID}</p>
        </div>
      ),
      center: true,
    },

    {
      name: "Date of Booking",
      selector: (row) => (
        <div>
          {moment(specificBookingData?.booking?.dateOfBooking).format(
            "MMM Do YY"
          )}
        </div>
      ),
      center: true,
    },
    {
      name: "Time slot",
      selector: (row) => (
        <div>
          {specificBookingData?.booking?.timeSlots?.map((div, i) => {
            return (
              <div key={i}>
                <p>{moment(div).format("LT")}</p>
              </div>
            );
          })}
        </div>
      ),
      center: true,
    },
    {
      name: "Booking Type",
      selector: (row) => (
        <div className=" px-[16px] py-1 bg-[#94FF824D] bg-opacity-30 rounded-md flex justify-center items-center gap-2.5">
          <div className="text-[#1CC500] text-sm font-medium">
            {specificBookingData?.booking?.bookingType}
          </div>
        </div>
      ),
      center: true,
    },
  ];

  const columnsForRentals = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      width: "80px"
    },
    {
      name: "Inventory Name",
      selector: (row) => <div className="flex flex-col">{row?.inventoryName}</div>,
      center: true,
      grow: 1,
    },
    {
      name: "Court Details",
      selector: (row) => (
        <div className="flex flex-col">
          {row.players?.map((div, i) => {
            return (
              <div key={i}>
                <p>Player Name: {div?.name}</p>
                <p>Email: {div?.name}</p>
                <p>MobileNo: {div?.phone}</p>
              </div>
            );
          })}
        </div>
      ),
      center: true,
      grow: 2
    },
    {
      name: "Date & Time",
      selector: row => (
        <div className="flex flex-col gap-1">
          <p>{moment(row?.startDate).format("MMM Do YY h:mm a")}</p>
          <p>{moment(row?.endDate).format("MMM Do YY h:mm a")}</p>
        </div>
      ),
      center: true,
      grow: 1.3
    },
    {
      name: "Amount",
      selector: (row) => <div>{row.totalAmount}</div>,
      center: true,
    },
    {
      name: "Rental Status",
      selector: (row) => (
        <div className={`${row?.isCompleted === true ? "bg-[#D1F0CF] text-[#1CC500]" : "text-[#FF0023] bg-[#FDCAD1]"} px-3 py-2 rounded-md`}>
          <h2 className={`text-sm font-medium`}>
            {row?.isCompleted === true ? "Completed" : "Pending"}
          </h2>
        </div>
      ),
      center: true,
    },
  ];

  const columnsForCustomers = [
    {
      name: "SL",
      selector: (row, i) => i + 1,
      center: true,
      width: "80px"
    },
    {
      name: "Name",
      selector: row => (
        <div>{row?.name}</div>
      ),
      center: true
    },
    {
      name: "Mobile Number",
      selector: row => (
        <div>{row?.mobileNumber}</div>
      ),
      center: true
    },
    {
      name: "Email",
      selector: row => (
        <div>{row?.email}</div>
      )
    }
  ]

  const columnsForCanteen = [
    {
      name: "SL",
      selector: (row, i) => i + 1,
      center: true,
      width: "80px"
    },
    {
      name: "Item Name",
      selector: row => (
        <div>{row?.itemName}</div>
      ),
      center: true
    },
    {
      name: "Customer Name",
      selector: row => (
        <div>{row?.CustomerDetails?.name}</div>
      ),
      center: true
    },
    {
      name: "Date & Time",
      selector: row => (
        <div>{moment(row?.createdAt).format('MMMM Do YYYY, h:mm a')}</div>
      ),
      center: true
    },
    {
      name: "Payment Mode",
      selector: row => (
        <div>{row?.payMode}</div>
      ),
      center: true
    },
    {
      name: "Amount",
      selector: row => (
        <div>{row?.amount}</div>
      ),
      center: true
    }
  ]

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        color: "#000019",
        fontSize: "13px",
        background: "#F6F8FC",
      },
    },
    head: {
      style: {
        borderRadius: "6px 6px 6px 6px",
      },
    },

    rows: {
      style: {
        color: "#00000",
        padding: "5px 0px",
        fontSize: "14px",
        fontWeight: 600,
        width: "auto",
      },
    },

    table: {
      style: {
        overflow: "visible",
        minWidth: "1100px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto",
      },
    },
  };

  const data = [
    {
      question: "How to Login?",
      answer: "Go to Header section and click on login button",
    },
  ];

  const convertImage1ToBase64 = async (imagePath) => {
    try {
      const response = await fetch(imagePath);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error converting image 1 to base64:', error);
      throw error;
    }
  };

  const convertImage2ToBase64 = async (imagePath) => {
    try {
      const response = await fetch(imagePath);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error converting image 2 to base64:', error);
      throw error;
    }
  };


  const handleDownloadPDF = async () => {
    setLoading(true);
    try {

      const base64String1 = await convertImage1ToBase64(gamebeezLogo1);

      const base64String2 = await convertImage2ToBase64(gamebeezLogo2);

      const bookingData = []

      bookingData.push(specificBookingData?.booking)

      const bookingsPdfData = bookingData?.map((item, index) => [
        index + 1,
        item?.sport,
        item?.boardDetails?.boardName,
        moment(item?.dateOfBooking).format("MMM Do YY"),
        moment(item?.timeSlots[0]).format("LT") + "-" + moment(item?.timeSlots[item?.timeSlots?.length - 1]).format("LT"),
        item?.bookingType,
      ]);

      const bookingsPdfColumns = ["SL", "Sports", 'Court Name', "Date of Booking", "Time Slot", 'Booking Type'];
      const clubInformation = specificBookingData?.clubDetails?.clubName + " " + "(" + specificBookingData?.clubDetails?.ClubID + ")"
      const clubAddress1 = "ADDRESS 1:" + specificBookingData?.clubDetails?.address_line1 + " , " + "ADDRESS 2: " + specificBookingData?.clubDetails?.address_line2
      const clubFullAddress = specificBookingData?.clubDetails?.City?.toUpperCase() + " , " + specificBookingData?.clubDetails?.state?.toUpperCase() + " , " + specificBookingData?.clubDetails?.Country?.toUpperCase() + " , " + specificBookingData?.clubDetails?.Pincode
      const customerPdfData = specificBookingData?.customerDetails?.map((item, i) => [
        i + 1,
        item?.name,
        item?.mobileNumber,
        item?.email
      ])
      const customerPdfColumns = ["SL", "Name", "Mobile Number", "Email"];

      const bookingType = specificBookingData?.booking?.bookingType;

      let rentalPdfData;
      let rentalPdfColumns;
      let canteenPdfData;
      let canteenPdfColumns;

      if (specificBookingData?.booking?.bookingType === "OFFLINE") {

        rentalPdfData = specificBookingData?.rentals?.map((item, i) => [
          i + 1,
          item?.inventoryName || "-",
          item?.players?.map((div) => div?.name) || "-",
          moment(item?.startDate).format("MMM Do YY h:mm a") || "-",
          moment(item?.endDate).format("MMM Do YY h:mm a") || "-",
          Math.round(item?.totalAmount) || "-"
        ])
        rentalPdfColumns = ["SL", "Equipment Name", "Customer Name", "Start Date", "End Date", "Amount"];

        canteenPdfData = specificBookingData?.canteens?.map((item, i) => [
          i + 1,
          item?.itemName,
          item?.CustomerDetails?.name,
          moment(item?.createdAt).format('MMM Do YY, h:mm a'),
          item?.payMode,
          Math.round(item?.amount)
        ])
        canteenPdfColumns = ["SL", "Item Name", "Customer Name", "Date & Time", "Payment Mode", "Amount"]
      }

      const paymentData = specificBookingData?.paymentDetails;
      const totalAmountDetails = specificBookingData?.amountDetails

      await DownloadSpecificBooking(bookingId, bookingsPdfData, bookingsPdfColumns, "Specific Customer Booking.pdf", base64String1, base64String2, clubInformation, clubAddress1, clubFullAddress, customerPdfData, customerPdfColumns, rentalPdfData, rentalPdfColumns, canteenPdfData, canteenPdfColumns, paymentData, totalAmountDetails, bookingType);
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setLoading(false);
    }
  };

  const paymentDetails = []

  paymentDetails.push(specificBookingData?.paymentDetails)

  return (
    <>
      <div className="bg-[#E6E6E6] px-[1.5rem] py-[1rem]">
        <div className="rounded-md bg-[#FFFFFF] flex-col justify-center items-center px-[1rem] py-[0.5rem]">
          <div className="flex gap-[20rem] items-center border-[#EAECF0] pt-[1.5rem] pb-[24px] mx-6">
            <div className="flex items-center">
              <Link to={`/bookings`}>
                <h2 className="text-[#808080] text-[16px] font-semibold whitespace-nowrap">
                  Bookings
                </h2>
              </Link>
              <Icon icon="material-symbols:arrow-forward-ios" className="text-[1.3rem] text-[#808080]" />
              <Link to={``}>
                <div>
                  <span className="text-black text-[20px] font-[600] whitespace-nowrap">
                    {id}{" "}
                  </span>
                </div>
              </Link>
            </div>
          </div>
          {isLoading ? <div className="flex justify-center items-center">
            <RotatingLines
              visible={true}
              height="80"
              width="80"
              color="gray"
              strokeColor="#FB6108"
              // strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
            :
            <div>
              <div className="flex justify-between items-center mx-6 py-[13px] border-b-2">
                <div className="text-primary text-[20px] font-[700]">
                  Booking ID : {id}
                </div>
                {loading ? <p className="text-base">Generating Invoice...</p>
                  :
                  <button
                    className="bg-primary text-white rounded-[5px] flex justify-center items-center gap-[8px] py-[8px] px-[16px] cursor-pointer"
                    onClick={handleDownloadPDF}
                  >
                    <Icon icon="ic:round-print" className="" />
                    Print Invoice
                  </button>
                }
              </div>
              <div className="flex-col items-start justify-start mx-6 mt-4 ">
                <div className="text-slate-950 text-lg font-bold mx-4 mb-5">
                  Booking Details
                </div>
                <DataTable
                  columns={columns}
                  customStyles={customStyles}
                  data={data}
                />
              </div>
              <div className="flex-col items-start justify-start mx-6 mt-11 ">
                <div className="text-slate-950 text-lg font-bold mx-4 mb-5">
                  Customers Details
                </div>
                <DataTable
                  columns={columnsForCustomers}
                  customStyles={customStyles}
                  data={specificBookingData?.customerDetails}
                />
              </div>
              <div className="flex-col items-start justify-start mx-6 mt-11 ">
                <div className="text-slate-950 text-lg font-bold mx-4 mb-5">
                  Rent Details
                </div>
                <DataTable
                  columns={columnsForRentals}
                  customStyles={customStyles}
                  data={specificBookingData?.rentals}
                />
              </div>
              <div className="flex-col items-start justify-start mx-6 mt-11 ">
                <div className="text-slate-950 text-lg font-bold mx-4 mb-5">
                  Canteen Details
                </div>
                <DataTable
                  columns={columnsForCanteen}
                  customStyles={customStyles}
                  data={specificBookingData?.canteens}
                />
              </div>
              <div className="flex justify-between mx-6 py-5 px-4">
                <div className="">
                  <h2 className="text-[1.15rem] font-bold my-[1rem]">
                    Payment Details
                  </h2>
                  <div className="grid grid-cols-2 gap-y-[1rem] gap-x-[2rem]">
                    <label className="text-[#858585] font-medium">Payment Mode</label>
                    <h2 className="font-semibold">
                      {specificBookingData?.paymentDetails?.paymentMode}
                    </h2>
                    <label className="text-[#858585] font-medium">Status</label>
                    <div
                      className={`flex justify-center items-center gap-[0.5rem] text-[0.9rem] rounded-md font-semibold py-[0.2rem] w-[6rem] ${specificBookingData?.paymentDetails?.status?.toLowerCase() ===
                        "paid"
                        ? "text-[#25BF17] bg-[#D1F0CF]"
                        : "text-[#FF0023] bg-[#FDCAD1]"
                        }`}
                    >
                      <h2>{specificBookingData?.paymentDetails?.status}</h2>
                    </div>
                    <label className="text-[#858585] font-medium">
                      Payment Method{" "}
                    </label>
                    <h2 className="font-semibold">
                      {specificBookingData?.paymentDetails?.paymentMethod}
                    </h2>
                    <label className="text-[#858585] font-medium">Booking Amount</label>
                    <h2 className="font-semibold">
                      ₹ {Math.round(specificBookingData?.paymentDetails?.totalAmount)}
                    </h2>
                  </div>
                </div>
                {specificBookingData?.booking?.bookingType === "OFFLINE" &&
                  <div>
                    <h2 className="text-[1.15rem] font-bold my-[1rem]">Fees</h2>
                    <div className="grid grid-cols-1 gap-y-[1rem] gap-x-[2rem]">
                      <div className="flex items-center justify-between gap-8">
                        <label className="text-[#858585] font-medium">Booking Amount</label>
                        <p className="font-semibold">{Math.round(specificBookingData?.amountDetails?.bookingAmount)}</p>
                      </div>
                      <div className="flex justify-between items-center">
                        <label className="text-[#858585] font-medium">Rental Amount</label>
                        <p className="font-semibold">{Math.round(specificBookingData?.amountDetails?.rentalAmount)}</p>
                      </div>
                      <div className="flex justify-between items-center">
                        <label className="text-[#858585] font-medium">Canteen Amount</label>
                        <p className="font-semibold">{specificBookingData?.amountDetails?.canteenAmount}</p>
                      </div>
                      <div className="flex justify-between items-center">
                        <label className="text-[#858585] font-medium">Total</label>
                        <p className="font-semibold">{Math.round(specificBookingData?.amountDetails?.bookingAmount + specificBookingData?.amountDetails?.rentalAmount + specificBookingData?.amountDetails?.canteenAmount)}</p>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default ViewBooking;
