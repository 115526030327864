import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import { ToastContainer, toast } from 'react-toastify';
import {
  IconButton,
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import DataTable from 'react-data-table-component';
import CustomCheckbox from '../../../../helpers/CustomCheckbox';
import { updateEditHowToPlayInfo } from '../../../../store/editSubCategorySlice';
import UpdateHowToPlayModal from './UpdateHowToPlayModal';


export default function HowToPlayInfo({ showError, setError }) {
  const [arrayData, setArrayData] = useState([]);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [showHTPError, setShowHTPError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [questionForModal, setQuestionForModal] = useState("");
  const [answerForModal, setAnswerForModal] = useState("");
  const [modalId, setModalId] = useState("");
  const dispatch = useDispatch();
  const { qna } = useSelector((store) => store.editSubCategory.editHowToPlayInfo);
  const { subCategoryName } = useSelector((store) => store.editSubCategory.editSportInfo);
  const [isRefetch, setIsRefetch] = useState(false);

  useEffect(() => {
    if (!(qna.length !== 0)) {
      setError(true);
    } else {
      setError(false);
    }
  }, [qna, setError]);

  useEffect(() => {
    setArrayData(qna);
  }, [qna])

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        // padding: "10px 10px",
        color: "#4D4D4D",
        fontSize: "13px",
      },
    },
    head: {
      style: {
        background: "#FFF",
      },
    },

    rows: {
      style: {
        background: "#FFF",
        color: "#565656",
        padding: "5px 0px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1100px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };
  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      maxWidth: "0.5rem",
    },
    {
      name: 'Title',
      id: "title",
      selector: row => (
        <div className='flex flex-col'>
          <p><span className='text-[1rem] font-semibold'>Q - </span>{row?.question?.length > 30 ? `${row.question.slice(0,30)+"...."}` : row?.question}</p>
          <p><span className='text-[1rem] font-semibold'>A - </span>{row?.answer?.length > 100 ? `${row?.answer?.slice(0,35)+"..."}` : row?.answer}</p>
        </div>
      ),
      // grow: 4,
      center: true,
    },
    {
      name: 'Sub Category',
      id: "subCategory",
      selector: row => subCategoryName,
      center: true,
    },
    {
      name: "Actions",
      id: "action",
      cell: (row) => (
        <div className="flex gap-[0.5rem]">
          <IconButton onClick={() => showModalHandler(row.question, row.answer, row._id)}
           title='Edit '
            className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
          >
            <Icon
              icon="material-symbols:edit-rounded"
              className="text-[1.5rem] text-black"
            />
          </IconButton>
          <IconButton onClick={() => deleteHandler(row._id)}
            className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
          >
            <Icon
              icon="material-symbols:delete-rounded"
              className="text-[1.5rem] text-black"
            />
          </IconButton>
        </div>
      ),
      center: true,
      // grow: 1,
    },
  ];

  const showModalHandler = (questionToModal, answerToModal, mongoId) => {
    setShowModal(true);
    setQuestionForModal(questionToModal);
    setAnswerForModal(answerToModal);
    setModalId(mongoId);
  }

  const deleteHandler = (id) => {
    setArrayData((prevState) => {
      const updatedArray = prevState.filter((item) => item._id !== id);
      dispatch(updateEditHowToPlayInfo({
        qna: updatedArray,
      }))
      return updatedArray;
    })
    console.log("DELETE TRIGGERED")
  }

  const addNewQnACollectionHandler = (question, answer) => {
    if (!(question && answer)) {
      setShowHTPError(true);
      return;
    } else {
      setShowHTPError(false);
    }
    setArrayData((prevState) => {
      const updatedArray = [
        ...prevState,
        { question: question, answer: answer, _id: Math.random().toString() }
      ]
      dispatch(updateEditHowToPlayInfo({
        qna: updatedArray,
      }))
      setQuestion("");
      setAnswer("");
      return updatedArray;
    })
  }

  return (
    <div className='w-full flex flex-col gap-[3rem]'>
      <form className='flex items-center justify-center'>
        <div className='flex flex-col gap-[1.5rem] w-[60%]'>
          {showError && qna.length === 0 && (
            <div className="text-sm text-[#E92215]">
              *Atleast one Question Answer should be Added.
            </div>
          )}
          <div className="flex flex-col gap-[0.5rem]">
            <input
              className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
              type="text"
              placeholder="Enter Question"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
            {showHTPError && !question && (
              <div className="text-sm text-[#E92215]">
                *This Field is Required
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <textarea rows={8}
              className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
              type="text"
              placeholder="Enter Answer"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
            />
            {showHTPError && !answer && (
              <div className="text-sm text-[#E92215]">
                *This Field is Required
              </div>
            )}
          </div>
          <div className='w-full'>
            <button onClick={() => addNewQnACollectionHandler(question, answer)}
              type='button'
              className='flex items-center justify-center gap-[0.5rem] py-[0.5rem] bg-primary text-white w-full font-semibold rounded-[5px]'>
              <span>Add More</span>
              <Icon icon="material-symbols:add" className='text-[1.3rem]' />
            </button>
          </div>
        </div>
      </form>
      <div className='flex flex-col gap-[0.5rem]'>
        <div className='flex justify-between items-center border-t border-[#EAECF0] pt-[1.5rem]'>
          <h2 className='text-black text-[1.3rem] font-semibold'>How to Play</h2>
          <div className='flex items-center gap-[2rem]'>
            <div className='flex items-center gap-[0.4rem] hover:text-primary cursor-pointer'>
              <Icon
                icon='material-symbols:delete'
                className='text-[1.5rem]'
              />
              <span className='text-[1rem] font-medium'>Remove</span>
            </div>
            <div className='flex items-center gap-[0.3rem] hover:text-primary cursor-pointer'>
              <p className='text-[1rem] font-medium'>Change Status</p>
              <Icon
                icon='mingcute:down-line'
                className='text-[1.5rem]'
              />
            </div>
          </div>
        </div>
        <div className='flex justify-end py-2'>
          <p className='text-primary font-semibold'>Click Edit button to see full content of How to play info.</p>
        </div>
        <div>
          <DataTable
            columns={columns}
            customStyles={customStyles}
            data={arrayData}
            pagination
            selectableRows
            paginationPerPage={10}
            // progressPending={isLoading}
            selectableRowsComponent={CustomCheckbox}
          />
        </div>
      </div>
      <ToastContainer />
      {
        showModal && (
          <UpdateHowToPlayModal setShowModal={setShowModal} questionForModal={questionForModal} setQuestionForModal={setQuestionForModal} answerForModal={answerForModal} setAnswerForModal={setAnswerForModal} modalId={modalId} setArrayData={setArrayData} />
        )
      }
    </div>
  )
}

