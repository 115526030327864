import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { useAuth } from '../../../../store/AuthContext';
import { APIurls } from '../../../../api/apiConstant';
import { useOutletContext } from 'react-router-dom';
import { useQuery } from 'react-query';
import moment from 'moment';
import CustomCheckbox from '../../../../helpers/CustomCheckbox';
import DownloadExcel from '../../../../exportData/ExportExcel';
import pdfLogo from '../../../../assets/pdfImage.png';
import { FaFileCsv } from "react-icons/fa6";
import DownloadPDF from '../../../../exportData/ExportPDF';

export default function SpecificCustomerSubscription() {

  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const { getAccessToken, adminName } = useAuth();
  const [id] = useOutletContext();

  const fetchSpecificCustomerSubscriptions = async () => {
    const token = await getAccessToken();
    const response = await fetch(`${APIurls.fetchAllSpecificCustomerSubscriptions}/${id}?limit=${recordsPerPage}&page=${currentPage}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    if (!response.ok) {
      const result = await response.json();
      throw new Error(result?.message);
    }
    const result = await response.json();
    console.log("Specific Customer Subscriptions--->", result?.response);
    return result?.response
  }

  const { data: customerSubscriptionData, isLoading } = useQuery(["SpecificCustomerSubscriptionData", id, currentPage, recordsPerPage], fetchSpecificCustomerSubscriptions);

  const columns = [
    {
      name: "SL",
      selector: (row, i) => i + 1,
      center: true,
      width: "80px"
    },
    {
      name: "Name of Subscription",
      selector: row => (
        <div>{row?.arenaId?.Areana_name}</div>
      ),
      center: true
    },
    {
      name: "Mode of Subscription",
      selector: row => (
        <div>{row?.offlineSub === true ? "Offline" : "Online"}</div>
      ),
      center: true
    },
    {
      name: "Date of Payment",
      selector: row => (
        <div>{moment(row?.createdAt).format("MMM Do YY")}</div>
      ),
      center: true
    },
    {
      name: "Renewal Date",
      selector: row => (
        <div>{moment(row?.willRenewed).format("MMM Do YY")}</div>
      ),
      center: true
    },
    {
      name: "Status",
      selector: row => (
        <div className={`${row?.isActive === true ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"} flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
          py-[0.4rem] w-[7rem]`}>
          <span className={`${row?.isActive === true ? "bg-[#25BF17]" : "bg-[#FF0023]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
          {row?.isActive === true ? "Active" : "Inactive"}
        </div>
      ),
      center: true
    },
  ]

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        // padding: "10px 10px",
        color: "#4D4D4D",
        fontSize: "13px",
      },
    },
    head: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        // fontSize: "15px",
      },
    },

    rows: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        color: "#565656",
        padding: "5px 0px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    // pagination: {
    //    style: {
    //       boxShadow: "10px 5px 5px #ddd",
    //       marginBottom: "5px",
    //    },
    // },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1200px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };

  const handleDirection = (direction) => {
    if (direction === "right") {
      const currPg = currentPage + 1;
      if (currentPage === customerSubscriptionData?.totalPages) {
        setCurrentPage(customerSubscriptionData?.totalPages);
      } else {
        setCurrentPage(currPg);
      }
    } else if (direction === "left") {
      const currPg = currentPage - 1;
      if (currentPage === 1) {
        setCurrentPage(1);
      } else {
        setCurrentPage(currPg);
      }
    }
  };

  const handleDownloadPDF = async () => {
    setLoading(true);
    try {
      const pdfData = customerSubscriptionData?.subscriptions?.map((ele, index) => [
        index + 1,
        ele?.arenaId?.Areana_name,
        ele?.offlineSub === true ? "Offline" : "Online",
        moment(ele?.createdAt).format("MMM Do YY"),
        moment(ele?.willRenewed).format("MMM Do YY"),
        ele?.isActive === true ? "Active" : "Inactive",
      ]);

      const pdfColumns = ["SL", "Name of Subscription", "Mode of Subscription", "Date of Payment", "Renewal Date", "Status"];

      DownloadPDF(pdfData, pdfColumns, "Specific Customer Subscriptions.pdf", adminName);
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setLoading(false);
    }
  };

  const excelData = customerSubscriptionData?.subscriptions?.map((item, index) => [
    index + 1,
    item?.arenaId?.Areana_name,
    item?.offlineSub === true ? "Offline" : "Online",
    moment(item?.createdAt).format("MMM Do YY"),
    moment(item?.willRenewed).format("MMM Do YY"),
    item?.isActive === true ? "Active" : "Inactive",
  ])

  const excelColumns = ["SL", "Name of Subscription", "Mode of Subscription", "Date of Payment", "Renewal Date", "Status"];

  return (
    <div className='border-t border-[#EAECF0] pt-[1.5rem] pb-6 px-6'>
      <div className='flex items-center justify-between'>
        <header className='text-xl font-semibold'>Specific Customer Subscriptions</header>
        <div className='flex gap-3 items-center rounded-md'>
          {loading ? (
            <p className="text-xs">Generating PDF...</p>
          ) : (
            <img
              src={pdfLogo}
              className='w-[25px] cursor-pointer'
              onClick={handleDownloadPDF}
              alt="Generate PDF"
            />
          )}
          <FaFileCsv
            className='w-[25px] h-[30px] text-green-800 cursor-pointer'
            onClick={() => DownloadExcel({ excelData, excelColumns })}
          />
        </div>
      </div>
      <DataTable
        columns={columns}
        data={customerSubscriptionData?.subscriptions}
        customStyles={customStyles}
        selectableRows
        selectableRowsComponent={CustomCheckbox}
        progressPending={isLoading}
      />
      {customerSubscriptionData?.totalRecords > 0 &&
        <div className="flex justify-center items-center gap-2 py-2">
          {/* Left Arrow */}
          <button
            className={`${currentPage === 1 ? "cursor-not-allowed text-gray-500" : "cursor-pointer"} rounded-md px-2 border-2`}
            onClick={() => handleDirection("left")}
          >Prev</button>
          <span>
            {currentPage} / {Math.ceil(customerSubscriptionData?.totalRecords / recordsPerPage)}
          </span>
          <button
            className={`${currentPage === customerSubscriptionData?.totalPages || customerSubscriptionData?.totalPages === 0 ? "cursor-not-allowed text-gray-500" : "cursor-pointer"} rounded-md px-2 border-2`}
            onClick={() => handleDirection("right")}
          >Next</button>
          <div className="flex items-center">
            <span>Per Page:</span>
            <select
              value={recordsPerPage}
              onChange={(e) => { setRecordsPerPage(e.target.value); setCurrentPage(1); }}
              className="border outline-none ml-2"
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={customerSubscriptionData?.totalRecords}>{customerSubscriptionData?.totalRecords}</option>
            </select>
          </div>
        </div>
      }
    </div>
  )
}
