import authEmailLogo from '../../assets/auth-email-logo.svg';
import authPwdLogo from '../../assets/mdi_password.svg';
import authShowPwd from '../../assets/fluent_eye-12-filled.svg';
import authHidePwd from '../../assets/fluent_eye-off-16-filled.svg'
// import authBgImage from "../../assets/auth-bg-img.svg";
import gamebeezLogo from '../../assets/GB-Final-Logo.png'

import { useForm } from 'react-hook-form';
import { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../store/AuthContext';
import { ThreeDots } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { handleFirebaseError } from '../../utils/FirebaseHandle'



const AdminLogin = () => {

    const { register, handleSubmit, formState: { errors }, control, reset } = useForm();
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const [loaderBtn, setLoaderBtn] = useState(false);

    const { login } = useAuth()

    const onSubmit = async (data) => {
        const email = data?.email
        const password = data?.password
        setLoaderBtn(true)
        await login(email, password).then((e) => {
            setLoaderBtn(false)
            toast.success("Login Success !")
            console.log("Login", e);
            navigate("/")
        }).catch(err => {
            setLoaderBtn(false)
            console.log(err.code);
            toast.error(handleFirebaseError(err))
        })
    }

    const showUserPassword = (e) => {
        setShowPassword(!showPassword);
    };
    return (
        <div className="h-screen w-full flex items-center justify-center">
        {/* <div className="h-screen w-full flex items-center justify-center" style={{ backgroundImage: `url(${authBgImage})` }}> */}
            <div className="flex flex-col w-[32rem] bg-white shadow-3xl px-16 py-20 rounded-lg">
                <div className="flex justify-start max-w-[100px]">
                    <img src={gamebeezLogo} alt="Gamebeez-logo" />
                </div>
                <h1 className="text-[1.5rem] text-[#212529] font-bold my-4">Login here</h1>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="w-full flex flex-col gap-[1rem]"
                >
                    <div className="group flex flex-col gap-[0.5rem]">
                        <label htmlFor="email" className="text-[1rem] font-[600]">
                            Email Address
                        </label>
                        <div className={`flex flex-row text-[0.9rem] font-[600] group-focus-within:bg-white  group-focus-within:shadow-md border  ${!errors.email ? "border-[#AFBACA]" : "border-red-600 "
                            } items-center rounded-md px-[0.5rem] xs:px-[1rem] py-[0.5rem]`}>
                            <div className="">
                                <img src={authEmailLogo} alt="" />
                            </div>

                            <input
                                id="email"
                                type="text"
                                name="email"
                                placeholder="example@gmail.com"
                                className="ml-[1rem] outline-none w-1 border-none flex-grow placeholder:text-[#858585]"
                                {...register("email", {
                                    required: "*This field is required.",
                                    pattern: /^\S+@\S+$/i,
                                })}
                            />
                        </div>
                        {errors.email?.type === "required" && (
                            <p className="text-red-600 text-sm">{errors.email.message}</p>
                        )}
                        {errors.email?.type === "pattern" && (
                            <p className="text-sm text-red-600">Invalid email</p>
                        )}

                    </div>

                    <div className="group flex flex-col gap-[0.5rem]">
                        <label htmlFor="password" className="text-[1rem] font-[600]">
                            Password
                        </label>
                        <div className={`flex flex-row text-[0.9rem] font-[600] group-focus-within:bg-white  group-focus-within:shadow-md border  ${!errors.password ? "border-[#AFBACA]" : "border-red-600 "
                            } items-center rounded-md px-[0.5rem] xs:px-[1rem] py-[0.5rem]`}>
                            <div className="">
                                <img src={authPwdLogo} alt="" />
                            </div>
                            <input
                                id="password"
                                type={showPassword ? "text" : "password"}
                                name="password"
                                placeholder="*********"
                                className="mx-[1rem] outline-none border-none w-1 flex-grow placeholder:text-[#858585]"
                                {...register("password", {
                                    required: "*This field is required.",
                                    minLength: 6,
                                })}
                            />
                            <div className="text-[1.2rem] cursor-pointer ">
                                {showPassword ? (
                                    <img src={authShowPwd} alt="" onClick={showUserPassword} />
                                ) : (
                                    <img src={authHidePwd} alt="" onClick={showUserPassword} />
                                )}
                            </div>
                        </div>
                        {errors.password?.type === "required" && (
                            <p className="text-red-600 text-sm">{errors.password?.message}</p>
                        )}
                        {errors.password?.type === "minLength" && (
                            <p className="text-red-600 text-sm">*Minimum Password Length: 6</p>
                        )}
                        <div className="flex flex-row justify-end text-[#392C64] text-[0.8rem] font-[600] my-1">
                            <p
                                className=" cursor-pointer hover:text-[#FB6108]"
                                onClick={() => navigate('/forgotPassword')}
                            >
                                Forgot Password?
                            </p>

                        </div>
                        <div className="flex flex-row justify-center">
                            {loaderBtn ? (
                                <ThreeDots
                                    height="50"
                                    width="50"
                                    radius="9"
                                    color="#FB6108"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                            ) :
                                (<button
                                    type="submit"
                                    className="w-full text-white text-[1.1rem] rounded-md px-[1.5rem] py-[0.8rem] bg-[#FB6108] font-bold"
                                >
                                    Login
                                </button>)}
                        </div>
                    </div>
                </form>
                <ToastContainer />
            </div>
        </div>

    )
}

export default AdminLogin;