import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  IconButton,
} from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import CustomCheckbox from "../../../../helpers/CustomCheckbox";
import { ToastContainer } from "react-toastify";
import AddStaffModal from "./AddStaffModal";
import EditStaffModal from "./EditStaffModel";
import { useGetAllClubOwnerStaffsQuery } from "../../../../store/features/clubOwner/clubOwnerApi";
import DeleteModal from "./DeleteModal";

export default function ViewCOStaff() {
  const { id } = useParams();

  const [showAddStaffModal, setShowAddStaffModal] = useState(false);
  const [showEditStaffModal, setShowEditStaffModal] = useState(false);
  const [showDeleteStaffModal, setShowDeleteStaffModal] = useState(false);
  const [staffId, setStaffId] = useState("");

  const handleAddStaffModal = ()=>{
    setShowAddStaffModal(true)
  }
  const handleEditStaff = (currentStaffId)=>{
    setShowEditStaffModal(true)
    setStaffId(currentStaffId)
  }

  const handleDeleteStaff = (deleteStaffId)=>{
    setShowDeleteStaffModal(true);
    setStaffId(deleteStaffId)
  }

  const {data:clubOwnerStaffsData, isLoading} = useGetAllClubOwnerStaffsQuery(id);
  const staffsData = clubOwnerStaffsData?.response?.staffs;

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        color: "#4D4D4D",
        fontSize: "13px",
      },
    },
    head: {
      style: {
        background: "#FFF",
      },
    },

    rows: {
      style: {
        background: "#FFF",
        color: "#565656",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
        padding: "5px 0px",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1100px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };

  const columns = [
    {
      name: "SL",
      id: "sl",
      selector: (row, index) => index + 1,
      center: true,
      maxWidth: "0.5rem",
    },
    // {
    //   name: "Unique ID",
    //   id: "uniqueId",
    //   selector: (row) => row.staffId,
    //   center: true,
    //   grow: 2,
    // },
    {
      name: "Staff Name",
      id: "staffName",
      selector: (row) => row.username,
      center: true,
      grow: 1,
    },
    {
      name: "Designation",
      id: "designation",
      selector: (row) => row.designation,
      center: true,
      grow:1,
    },
    {
      name: "Mobile Number",
      id: "mobileNumber",
      selector: (row) => row.mobile,
      center: true,
      grow: 1,
    },
    {
      name: "Email Address",
      id: "emailAddress",
      selector: (row) => row.email,
      center: true,
      grow: 1,
    },
    {
      name: "Date of Joining",
      id: "joiningDate",
      selector: (row) => row.createdAt.split("T")[0],
      center: true,
      grow:1,
    },
    {
      name: "Permissions",
      id: "permissions",
      selector: (row) => row.permission.join(', '),
      center: true,
      grow: 2,
    },
    {
      name: "Actions",
      id: "action",
      cell: (row) => (
        <div className="flex gap-[0.5rem]">
          <IconButton onClick={()=>handleEditStaff(row.staffId)} className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black rounded-lg border border-[#D9D9D9]">
            <Icon
              icon="material-symbols:edit-rounded"
              className="text-[1.5rem] text-black"
            />
          </IconButton>

          <Link to={`view/${row.staffId}`}>
            <IconButton className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]">
              <Icon icon="mdi:eye" className="text-[1.5rem] text-black" />
            </IconButton>
          </Link>
          <Link to={``}>
            <IconButton onClick={()=>handleDeleteStaff(row.staffId)} className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]">
              <Icon
                icon="material-symbols:delete-rounded"
                className="text-[1.5rem] text-black"
              />
            </IconButton>
          </Link>
        </div>
      ),
      center: true,
      grow: 1,
    },
  ];

  return (
    <div className="bg-white rounded-md flex flex-col gap-[0.5rem] max-w-[1100px] overflow-auto">
      <div className="flex items-center justify-between">
        <h2 className="text-[1.3rem] font-semibold">Staff</h2>
        <div>
          <div className="flex items-center gap-[2rem]">
            {/* <div className="flex items-center gap-[0.4rem] hover:text-primary cursor-pointer">
              <Icon icon="material-symbols:delete" className="text-[1.5rem]" />
              <span className="text-[1rem] font-medium">Remove</span>
            </div> */}
            {/* <div className="flex items-center gap-[0.3rem] hover:text-[#FB6108] cursor-pointer">
              <p className="text-[1rem] font-medium">Change Status</p>
              <Icon icon="mingcute:down-line" className="text-[1.5rem]" />
            </div> */}
            <button onClick={handleAddStaffModal} className="text-[0.8rem] sm:text-[1rem] font-medium flex items-center justify-center max-xs:w-full gap-[0.3rem] xs:gap-[0.5rem] bg-primary text-white py-[0.5rem] px-[0.4rem] xs:px-[1rem] rounded-md">
              <span className="whitespace-nowrap">Add New Staff</span>
              <Icon
                icon="material-symbols:add"
                className="text-[1.2rem] sm:text-[1.5rem]"
              />
            </button>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto">
        <DataTable
          columns={columns}
          customStyles={customStyles}
          data={staffsData}
          pagination
          selectableRows
          progressPending={isLoading}
          selectableRowsComponent={CustomCheckbox}
        />
      </div>
      {showAddStaffModal && <AddStaffModal setShowAddStaffModal={setShowAddStaffModal}/>}
      {showEditStaffModal && <EditStaffModal staffId={staffId} setStaffId={setStaffId} setShowEditStaffModal={setShowEditStaffModal}/>}
      {showDeleteStaffModal && <DeleteModal staffId={staffId} setStaffId={setStaffId} setShowDeleteStaffModal={setShowDeleteStaffModal}/>}
      <ToastContainer />
    </div>
  );
}
