import React, { useState } from "react";
import { IconButton } from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import { PiGreaterThan } from "react-icons/pi";
import { APIurls } from "../../../../api/apiConstant";
import { useAuth } from "../../../../store/AuthContext";
import { toast } from "react-toastify";
import { PulseLoader } from 'react-spinners';

export default function OverViewAddTeamModal({ setShowModal, number, selectionType, tournamentId, participantsrefetch, tournamentRefetch }) {

  const [teamName, setTeamName] = useState(`${selectionType === 'team' ? `Team #${number + 1}` : `Player #${number + 1}`}`);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const { getAccessToken } = useAuth();

  const handleTeamAdd = async () => {
    if (teamName?.trim() === "") {
      setShowError(true);
      return;
    }
    setIsLoading(true)
    try {
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.addPartcipantBasedOnFormat}/${tournamentId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ participantName: teamName })
      })
      const result = await response.json();

      if (result?.code === 400) {
        toast.error(result?.message);
        setTimeout(() => { setIsLoading(false) }, 1000);
        return;
      }
      console.log("Partcipant Added Successfully", result)
      participantsrefetch();
      tournamentRefetch();
      setShowModal(false)
      toast.success("Partcipant Added Successfully")
    }
    catch (error) {
      console.log("Error while adding participant", error)
    }
    setIsLoading(false)
  };

  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-[#000000E6]">
      <div className="flex flex-col gap-4 bg-white rounded-sm px-6 py-8 w-[35%] relative">
        <div className="flex justify-between items-center">
          <h1 className="text-[20px] font-bold">{selectionType === "team" ? "Add Team" : "Add Player"}</h1>
          <IconButton
            onClick={() => setShowModal(false)}
            className={
              "shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-sm"
            }
            size="sm"
          >
            <Icon icon="material-symbols:close" className="text-[1.5rem]" />
          </IconButton>
        </div>
        <div className="w-[100%] h-[100%] text-red-600 bg-red-100 p-4 rounded-md">
          <p>By adding/removing participants, the game fixation will be regenerated. This means that all the existing results will be lost.
            Are you sure you want to continue?</p>
        </div>
        <div>
          <div className="flex flex-col gap-[0.5rem]">
            {showError && (
              <p className="text-red-600 text-sm">This field is required*</p>
            )}
            <input
              className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] placeholder:text-[#7F7F7F] bg-[#EFF1F9] outline-none"
              type="text"
              value={teamName}
              placeholder="Team Name"
              onChange={(e) => setTeamName(e.target.value)}
            />
          </div>
        </div>
        <div className="w-[140px] flex items-center justify-center text-white bg-[#FB6108] px-4 py-2 cursor-pointer rounded-md gap-1">
          {
            isLoading ? <PulseLoader color="white" size={8} /> :
              <div className="flex justify-center items-center gap-1">
                <button
                  type="button"
                  className="text-[0.8rem] sm:text-[1rem] font-semibold"
                  onClick={handleTeamAdd}
                >
                  {selectionType === "team" ? "Add Team" : "Add Player"}
                </button>
                <PiGreaterThan />
              </div>
          }
        </div>
      </div>
    </div>
  );
}