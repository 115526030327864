import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { PiGreaterThan } from "react-icons/pi";
import { ThemeProvider } from '@material-tailwind/react';
import { selectCustomTheme } from '../../../../helpers/constants';
import { useAuth } from '../../../../store/AuthContext';
import { APIurls } from '../../../../api/apiConstant';
import { useQuery } from 'react-query';
import { toast, ToastContainer } from 'react-toastify';
import { PulseLoader } from 'react-spinners';

export default function CreateVenue() {

    const [location, setLocation] = useState('')
    const [venue, setVenue] = useState("")
    const [court, setCourt] = useState("")
    const [arena, setArena] = useState("")
    const [showError, setShowError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const { tournamentId } = useParams();
    const navigate = useNavigate();
    const { getAccessToken } = useAuth();

    const fetchAllVenues = async () => {
        const token = await getAccessToken();
        const response = await fetch(`${APIurls.fetchAllDropDownVenues}?city=${location}&club=${venue}&arena=${arena}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const result = await response.json();
        if (!response.ok) {
            throw new Error(result.message)
        }
        console.log("Drop Down Venues", result?.response)
        return result?.response
    }

    const { data: venuesDropDown, refetch } = useQuery(['VenuesList'], fetchAllVenues)

    useEffect(() => {
        if ((location || venue || arena) !== "") {
            refetch();
        }
    }, [location, venue, arena])

    const handleSaveVenue = async (e) => {
        e.preventDefault();
        if (!(location) || !(venue)) {
            setShowError(true)
            return
        }
        setShowError(false)

        const body =
        {
            "city": location,
            "venueClubId": venue,
            "arenaId": arena,
            "boardId": court
        }

        try {
            setIsLoading(true)
            const token = await getAccessToken();
            const res = await fetch(`${APIurls.creatingVenue}/${tournamentId}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
            const result = await res.json();
            if (!res.ok) {
                toast.error("Venue Not Created");
                throw new Error("Venue Not Created", result.message);
            }
            console.log("Venue Created Successfully", result);
            toast.success("Venue Created Successfully");
        } catch (error) {
            console.log(error)
        }
        setIsLoading(false);
        setLocation("");
        setVenue("");
        setArena("");
        setCourt("");
    }

    return (
        <ThemeProvider value={selectCustomTheme}>
            <form onSubmit={handleSaveVenue}>
                <div className='flex items-center justify-between p-2'>
                    <div className='flex items-center gap-1 flex-wrap'>
                        <p className='text-[#808080] font-semibold text-base cursor-pointer'
                            onClick={() => navigate("/tournaments")}
                        >
                            Tournament
                        </p>
                        <PiGreaterThan className='text-[#808080]' />
                        <p className='text-[#808080] font-semibold text-base cursor-pointer'
                            onClick={() => navigate(`/tournaments/tournamentDetails/${tournamentId}`)}
                        >
                            Overview of the Tournament
                        </p>
                        <PiGreaterThan className='text-[#808080]' />
                        <p className='text-xl font-semibold max-lg:text-[18px]'>Venue</p>
                    </div>
                    <div className='flex justify-end rounded-md'>
                        {isLoading ?
                            <PulseLoader color='orange' size={8} />
                            :
                            <button type='submit' className='flex items-center bg-[#FB6108] text-white px-4 py-2 rounded-md gap-2 text-base max-lg:px-2 max-lg:py-1'>
                                <p>Save</p>
                                <PiGreaterThan />
                            </button>
                        }
                    </div>
                </div>
                <div>
                    <div className='flex items-center gap-[3rem] px-[2rem] py-[1rem] flex-wrap'>
                        <div className='flex flex-col gap-[1.5rem]'>
                            <div className=''>
                                <select
                                    className="w-[220px] h-[45px] px-1 py-2 text-gray-700 bg-[#EFF1F9] rounded-md border border-gray-300 outline-none"
                                    value={location}
                                    onChange={(e) => setLocation(e.target.value)}
                                >
                                    <option hidden>Select City</option>
                                    {
                                        venuesDropDown?.cities?.map((item) => (
                                            <option value={item} key={item}>{item}</option>
                                        ))}
                                </select>
                                {
                                    !location && showError && (
                                        <p className='text-sm text-red-500'>*This field is required</p>
                                    )}
                            </div>
                            {/* Arena Drop Down */}
                            <select
                                className="w-[220px] h-[45px] px-1 py-2 text-gray-700 bg-[#EFF1F9] border border-gray-300 rounded-md outline-none"
                                value={arena}
                                onChange={(e) => setArena(e.target.value)}
                            >
                                <option hidden>Select Arena</option>
                                {
                                    venuesDropDown?.arenas?.map((item) => (
                                        <option value={item?.arenaId} key={item?.arenaId}>{item?.arenaName}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='flex flex-col gap-[1.5rem]'>
                            <div>
                                <select
                                    className="w-[220px] h-[45px] px-1 py-2 text-gray-700 bg-[#EFF1F9] border border-gray-300 rounded-md outline-none"
                                    value={venue}
                                    onChange={(e) => setVenue(e.target.value)}
                                >
                                    <option hidden>Select Venue</option>
                                    {
                                        venuesDropDown?.clubs?.map((item) => (
                                            <option value={item?.clubId} key={item?.clubId}>{item?.clubName}</option>
                                        ))
                                    }
                                </select>
                                {
                                    !venue && showError && (
                                        <p className='text-sm text-red-500'>*This field is required</p>
                                    )}
                            </div>
                            <select
                                className="w-[220px] h-[45px] px-1 py-2 text-gray-700 bg-[#EFF1F9] border rounded-md outline-none"
                                value={court}
                                onChange={(e) => setCourt(e.target.value)}
                            >
                                <option hidden>Select Court</option>
                                {
                                    venuesDropDown?.court?.map((item) => (
                                        <option value={item?.courtId} key={item?.courtId}>{item?.courtName}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </form>
        </ThemeProvider>
    )
}
