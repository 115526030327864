import React from 'react'
import { Button, IconButton, Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react';
import { Icon } from '@iconify/react';
import DataTable from 'react-data-table-component';
import photo from "../../../../assets/Rectangle 24597.png";

const UpdateCanteen = () => {
    const columns = [
        {
            name: "SL",
            selector: (row, index) => index + 1,
            center: true,
            maxWidth: "0.5rem",
        },
        {
            name: "Item Image",
            selector: row => (
                <div className='flex flex-col'>
                    <img src={photo} alt="item" />
                </div>),
            center: true,
            maxWidth: "0.5rem",
        },
        {
            name: 'Item Details',
            id: "dob",
            selector: row => (
                <div className='flex flex-col'>
                    Coca Cola 300ml
                </div>
            ),
            center: true,
        },

        {
            name: 'Price',
            id: "date_of_Booking",
            selector: row => (
                <div>
                    ₹80
                </div>
            ),
            center: true,
        },
        {
            name: 'Quantity',
            id: "time_slot",
            selector: row => (
                <div>
                    1
                </div>
            ),
            center: true,
        },
        {
            name: 'Total Price',
            id: "booking_type",
            selector: row => (
                <div className=" px-2 py-1 rounded-md flex justify-center items-center ">
                    ₹80
                </div>
            ),
            center: true,
        },
    ];


    const customStyles = {
        headRow: {
          style: {
            fontWeight: 600,
            // padding: "10px 10px",
            color: "#000019",
            fontSize: "13px",
            background : "#F6F8FC"
            
          },
        },
        head: {
          style: {
            borderRadius: "6px 6px 6px 6px",
            // border: "0.824px solid #E9E9E9",
            // background: "#FFF",
            // fontSize: "15px",
          },
        },
    
        rows: {
          style: {
            // borderRadius: "6px 6px 0px 0px",
            // borderTop: "0.824px solid #E9E9E9",
            // background: "#FFF",
            // border : "none",
            color: "#00000",
            padding: "5px 0px",
            fontSize: "14px",
            fontWeight: 600,
            width: "auto",
          },
        },
    
        table: {
          style: {
            overflow: "visible",
            minWidth: "1100px",
          },
        },
        tableWrapper: {
          style: {
            overflow: "visible",
          },
        },
        responsiveWrapper: {
          style: {
            overflowX: "auto", // table scroll on x axis
          },
        },
      };

    const data = [
        {
            question: "How to Login?",
            answer: "Go to Header section and click on login button",
        },
        {
            question: "How to Login?",
            answer: "Go to Header section and click on login button",
        },
        {
            question: "How to Login?",
            answer: "Go to Header section and click on login button",
        },
    ];

    return (
        <>
            <div className='flex gap-[30rem] items-center border-t border-[#EAECF0] pt-[1.5rem] pb-[24px] mx-6'>
                <div className='flex items-center'>
                    <h2 className='text-[#808080] text-[16px] font-semibold whitespace-nowrap'>Club Owners </h2>
                    <Icon icon="f7:greaterthan" className='h-[16px] w-[16px] text-[#808080]' />
                    <div><span className="text-[#808080] text-[16px] font-semibold whitespace-nowrap">Manikanta Putta </span><span className="text-zinc-500 text-base font-normal text-[#808080]">(12345)</span></div>
                    <Icon icon="f7:greaterthan" className='h-[16px] w-[16px] text-[#808080]' />
                    <div><span className="text-[#808080] text-[16px] font-semibold whitespace-nowrap">Canteen</span></div>
                    <Icon icon="f7:greaterthan" className='h-[16px] w-[16px] text-[#808080]' />
                    <div><span className="text-black text-[20px] font-[600] whitespace-nowrap">ID: 12345 </span></div>
                    <Icon icon="ri:arrow-drop-down-line" className='h-[16px] w-[16px] cursor-pointer' />
                </div>
                <div className='flex items-center gap-[2rem] '>
                    <div className='flex items-center gap-[0.4rem] hover:text-primary cursor-pointer'>
                        <Icon
                            icon='material-symbols:delete'
                            className='text-[1.5rem]'
                        />
                        <span className='text-[1rem] font-medium'>Remove</span>
                    </div>
                    <div className='flex items-center gap-[0.3rem] hover:text-primary cursor-pointer'>
                        <span className={`bg-[#25BF17] h-[0.5rem] w-[0.5rem] rounded-full`}></span>
                        <p className='text-[1rem] font-medium whitespace-nowrap text-[#026039]'>Active</p>
                        <Icon
                            icon='mingcute:down-line'
                            className='text-[1.5rem]'
                        />
                    </div>
                    <div className="flex flex-row gap-[0.5rem] xs:gap-[1rem] items-center justify-center border border-[#CBCBCB] rounded-[5px]">
                        <div className="">
                            <Menu>
                                <MenuHandler>
                                    <Button className="text-[0.8rem] bg-[#FB6108] sm:text-[1rem] flex w-full flex-row justify-center whitespace-nowrap capitalize text-white  px-[0.4rem] xs:px-[1rem] py-[0.4rem] rounded-md items-center gap-[0.5rem] sm:gap-[0.8rem] hover:shadow-none shadow-none">
                                        <Icon
                                            icon="mdi:cloud-download"
                                            className="text-[1.5rem]"
                                        />
                                        <span className="font-[500]">Export</span>
                                    </Button>
                                </MenuHandler>
                                <MenuList>
                                    <MenuItem
                                        //  onClick={downloadPdf}
                                        className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                                    >
                                        {" "}
                                        <Icon
                                            icon="fa-solid:file-pdf"
                                            className="text-[#BE0A23] w-[1rem] h-auto"
                                        />
                                        Download as PDF
                                    </MenuItem>
                                    <hr className="my-[0.1rem]" />
                                    <MenuItem
                                        //  onClick={downloadCSV}
                                        className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                                    >
                                        <Icon
                                            icon="fa-solid:file-csv"
                                            className="text-[#29672E] w-[1rem] h-auto"
                                        />
                                        Download as CSV
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-between items-center mx-6 py-[16px] border-b-[2px] border-[#c3c3c3]'>
                <div className="text-primary text-[20px] font-[700] ">Order ID : 12345</div>
                <div className=" bg-primary rounded-[5px] flex justify-center items-center gap-[8px] py-[8px] px-[16px]">
                    <Icon icon="ic:round-print" className='text-white' />
                    <div className="text-white text-[16px] font-medium ">Print Invoice</div>
                </div>

            </div>
            {/* <div className="position absolute top-[31rem] left-[175px] w-[265px] border-2 border-orange-600"></div> */}
            <div className='flex items-start justify-between mx-6 py-5 px-4'>
                <div className='grid grid-cols-1 gap-[16px]'>
                    <div className='flex justify-between items-center gap-[8rem]'>
                        <div className="text-[#858585] text-[16px] font-medium ">Date & Time</div>
                        <div className="text-slate-950 text-[16px] font-semibold">10/08/2023 - 04:30 pm</div>
                    </div>
                    <div className='flex justify-between items-center gap-[8rem]'>
                        <div className="text-[#858585] text-[16px] font-medium ">Total Items</div>
                        <div className="  text-slate-950 text-[16px] font-medium whitespace-nowrap p-[1px] px-[20px] rounded-[6px]">04</div>
                    </div>
                </div>
                <div className='grid grid-cols-1 gap-[16px]'>
                    <div className="text-slate-950 text-[18px] font-[700] py-4">Payment Details</div>
                    <div className='flex justify-between items-center gap-[8rem]'>
                        <div className="text-[#858585] text-[16px] font-medium ">Payment Mode</div>
                        <div className="text-slate-950 text-[16px] font-semibold">Online Payment</div>
                    </div>
                    <div className='flex justify-between items-center gap-[8rem]'>
                        <div className="text-[#858585] text-[16px] font-medium ">Status</div>
                        <div className=" bg-[#94FF824D] text-[#1CC500] text-[16px] font-medium whitespace-nowrap p-[1px] px-[20px] rounded-[6px]">Paid</div>
                    </div>
                    <div className='flex justify-between items-center gap-[8rem]'>
                        <div className="text-[#858585] text-[16px] font-medium ">Payment Method</div>
                        <div className="text-slate-950 text-[16px] font-semibold ">UPI</div>
                    </div>
                    <div className='flex justify-between items-center gap-[8rem]'>
                        <div className="text-[#858585] text-[16px] font-medium ">Transaction ID</div>
                        <div className="text-slate-950 text-[16px] font-semibold ">TRA123DSD2231</div>
                    </div>
                    <div className='flex justify-between items-center gap-[8rem]'>
                        <div className="text-[#858585] text-[16px] font-medium ">Total Amount</div>
                        <div className="text-slate-950 text-[16px] font-semibold ">₹1250.00</div>
                    </div>
                    <div className='flex justify-between items-center gap-[8rem]'>
                        <div className="text-[#858585] text-[16px] font-medium ">Remarks</div>
                        <div className="text-right text-slate-950 text-[16px] font-semibold  leading-relaxed">-</div>
                    </div>
                </div>
            </div>
            <div className='flex-col items-start justify-start mx-6'>
                <div className='text-slate-950 text-lg font-bold mx-8 mb-5'>Items</div>
                <DataTable
                    columns={columns}
                    customStyles={customStyles}
                    data={data}
                />
            </div>

            <div className='flex justify-end items-end'>

                <div className='flex-col justify-center items-center px-3 py-4 mb-10'>
                    <div className="text-slate-950 text-lg font-bold py-4 px-4">Taxes & Fees</div>
                    <div className='flex  items-center gap-[17rem] p-5'>
                        <div className="text-zinc-500 text-base font-medium ">GST</div>
                        <div className="text-slate-950 text-base font-semibold ">₹20.00</div>
                    </div>
                    <div className='flex justify-between items-center gap-[8rem] p-5'>
                        <div className="text-zinc-500 text-base font-medium ">Convenience Fees</div>
                        <div className="text-slate-950 text-base font-semibold ">₹15.00</div>
                    </div>
                    <div className='flex justify-between items-center gap-[8rem] bg-[#F6F8FC] p-5'>
                        <div className="text-zinc-500 text-base font-medium ">Total Amount</div>
                        <div className="text-slate-950 text-base font-semibold ">₹350.00</div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default UpdateCanteen
