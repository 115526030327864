import React, { useEffect, useState } from 'react'
import { Icon, InlineIcon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import "react-phone-number-input/style.css";
import uploadImg from '../../../../assets/upload-image.svg'
import { Option, Select, ThemeProvider } from '@material-tailwind/react';
import { updateSportInfo } from '../../../../store/createSubCategorySlice';
import { useAuth } from '../../../../store/AuthContext';
import { APIurls } from '../../../../api/apiConstant';
import { useQuery } from 'react-query'
import { ToastContainer, toast } from 'react-toastify';
import { selectCustomTheme } from '../../../../helpers/constants';
import { updateEditSportInfo } from '../../../../store/editSubCategorySlice';

export default function EditSportsInfo({ showError, setError }) {
   const [selectImg, setSelectImg] = useState(null);
   const { getAccessToken } = useAuth()
   const dispatch = useDispatch();

   const {
      maincategoryName, subCategoryName, subcat_img, minPlayers, maxPlayers
   } = useSelector((store) => store.editSubCategory.editSportInfo);

   const fetchCategoriesData = async () => {
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.fetchCategories}`, {
         method: 'GET',
         headers: {
            Authorization: `Bearer ${token}`,
         }
      })
      const result = await response.json();
      if (!response.ok) {
         toast.error("Failed to Fetch Categories");
         throw new Error(result.message);
      }
      console.log("Categories Data", result?.response?.CatArray);
      return result?.response?.CatArray;
   }

   const { data: mainCategories, isLoading, error } = useQuery('categoriesData', fetchCategoriesData);
   // console.log("MAIN CATEGORY", mainCategories)

   useEffect(() => {
      if (!(subCategoryName && subcat_img)) {
         setError(true);
      } else {
         setError(false);
      }
   }, [subCategoryName, subcat_img, setError]);

   // console.log("SUBCATEGORY IMAGE", subcat_img);

   return (
      <ThemeProvider value={selectCustomTheme}>
         <div>
            <form
               className="flex flex-col gap-[2rem]">
               <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-[1.5rem] gap-y-[1.5rem]">
                  <div className='flex flex-col gap-[1.5rem]'>
                     <div className="flex flex-col gap-[0.5rem]">
                        <input
                           className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-gray-100 placeholder:text-[#7F7F7F] rounded-md outline-none"
                           type="text"
                           placeholder="Min Players (Optional)"
                           value={maincategoryName}
                           readOnly
                        />
                     </div>
                     <div className="flex flex-col gap-[0.5rem]">
                        <input
                           className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                           type="text"
                           placeholder="Sport Name"
                           value={subCategoryName}
                           onChange={(e) => {
                              dispatch(updateEditSportInfo({ subCategoryName: e.target.value }));
                           }}
                        />
                        {showError && !subCategoryName && (
                           <div className="text-sm text-[#E92215]">
                              *This Field is Required
                           </div>
                        )}
                     </div>
                  </div>
                  <div className='flex flex-col gap-[1.5rem]'>
                     <div className="flex flex-col gap-[0.5rem]">
                        <input
                           className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                           type="text"
                           placeholder="Min Players (Optional)"
                           value={minPlayers}
                           onChange={(e) => {
                              if (!(/[a-zA-Z]/.test(e.target.value))) {
                                 dispatch(updateEditSportInfo({ minPlayers: e.target.value }));
                              }
                           }}
                        />
                        {/* {showError && !minPlayers && (
                           <div className="text-sm text-[#E92215]">
                              *This Field is Required
                           </div>
                        )} */}
                     </div>
                     <div className="flex flex-col gap-[0.5rem]">
                        <input
                           className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                           type="text"
                           placeholder="Max Players (per game) (Optional)"
                           value={maxPlayers}
                           onChange={(e) => {
                              if (!(/[a-zA-Z]/.test(e.target.value))) {
                                 dispatch(updateEditSportInfo({ maxPlayers: e.target.value }));
                              }
                           }}
                        />
                        {/* {showError && !maxPlayers && (
                           <div className="text-sm text-[#E92215]">
                              *This Field is Required
                           </div>
                        )} */}
                     </div>
                  </div>
                  <div className="flex flex-col gap-[0.5rem]">
                     <div className="flex flex-col items-center py-[3rem] px-[1rem] border border-[#E1E2E9] bg-[#F6F7FB] rounded-lg">
                        {subcat_img ? (
                           <>
                              {
                                 subcat_img?.name ? (
                                    <img src={URL.createObjectURL(subcat_img)} alt="" className='h-[100px] w-[100px] ' />
                                 ) : (
                                    <img src={subcat_img} alt="" className='h-[100px] w-[100px] ' />
                                 )
                              }
                              <div className="flex gap-[1rem] items-center mt-[0.5rem]">
                                 {/* <h2 className="text-[1.1rem] text-black font-[500]">
                                    {subcat_img?.name.length > 30
                                       ? subcat_img.name.substring(0, 30) + "..."
                                       : subcat_img.name}
                                 </h2> */}
                                 <div className='p-[0.1rem] border border-[#D9D9D9] rounded-md hover:bg-[#3D4A5C]/[0.3] mt-[0.5rem]'>
                                    <Icon
                                       onClick={() => dispatch(updateEditSportInfo({ subcat_img: "" }))}
                                       icon="akar-icons:cross"
                                       className="text-[1.2rem] cursor-pointer"
                                    />
                                 </div>
                              </div>
                           </>
                        ) : (
                           <>
                              <img src={uploadImg} alt="" />
                              <button
                                 type="button"
                                 onClick={() =>
                                    document.querySelector(".input-field").click()
                                 }
                              >
                                 <h2 className='text-primary font-[500] flex items-center gap-[0.5rem] my-[1rem]'>
                                    <Icon
                                       icon="material-symbols:cloud-upload"
                                       className="text-[1.5rem]"
                                    />
                                    <span>
                                       Upload Icon
                                    </span>
                                 </h2>
                              </button>
                              <input
                                 type="file"
                                 accept="image/*"
                                 value=""
                                 className="input-field"
                                 hidden
                                 onChange={({ target: { files } }) => {
                                    if (files[0]) {
                                       setSelectImg(files[0]);
                                       dispatch(updateEditSportInfo({ subcat_img: files[0] }))
                                    }
                                 }}
                              />
                              <p className='text-[0.9rem] text-[#808080]'>
                                 Upload an Image of Sport.
                              </p>
                              <p className="text-[0.8rem] text-[#808080]">
                                 File Format <span className='text-black'> jpeg, png </span> Recommened Size <span className='text-black'> 600x600 (1:1) </span>
                              </p>
                           </>
                        )}
                     </div>
                     {showError && !subcat_img && (
                        <div className="text-sm text-[#E92215]">
                           *This Field is Required
                        </div>
                     )}
                  </div>
               </div>
            </form>
            <ToastContainer />
         </div>
      </ThemeProvider>
   )
}


