
import React, { useState } from 'react'
import { ThreeDots } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { isValidPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input'
import "react-phone-number-input/style.css";
import { APIurls } from '../../../api/apiConstant';
import { useAuth } from '../../../store/AuthContext';
import { ThemeProvider } from '@material-tailwind/react';
import { selectCustomTheme } from '../../../helpers/constants';
import { Option, Select } from '@material-tailwind/react';
import { useOutletContext } from 'react-router-dom';
import CustomCheckbox from '../../../helpers/CustomCheckbox';
import { useQuery } from 'react-query';
import PhoneInput from 'react-phone-number-input/input';


export default function CreateSubStaff() {
  const [mobileNumber, setMobileNumber] = useState(null);
  const [isMobileNumberValid, setIsMobileNumberValid] = useState(true);
  const [showError, setShowError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [loaderBtn, setLoaderBtn] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [designationStatus, setDesignationStatus] = useState('');
  const { register, handleSubmit, formState: { errors }, watch, reset } = useForm();
  const [setUpdateStaffList] = useOutletContext();
  const { getAccessToken, userRole } = useAuth();


  const fetchSubAdminRoles = async () => {
    const token = await getAccessToken()
    const response = await fetch(APIurls.fetchSubAdminRoles, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (!response.ok) {
      throw new Error("Failed to Fetch Roles");
    }
    const result = await response.json();
    console.log("Staff Roles...", result?.subAdminPermission)
    return result?.subAdminPermission;
  };
  const { data: rolesData } = useQuery('roles', fetchSubAdminRoles);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    if (!(designationStatus && permissions.length !== 0 && isMobileNumberValid)) {
      setShowError(true);
      return;
    } else {
      setShowError(false);
    }
    setLoaderBtn(true)
    const { name, email, password } = data;
    const token = await getAccessToken();
    try {
      const response = await fetch(APIurls.createStaff, {
        method: 'POST',
        body: JSON.stringify({
          "name": name,
          "email": email,
          "mobile": mobileNumber,
          "password": password,
          "designation": designationStatus,
          "permisions": permissions,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      if (!response.ok) {
        setLoaderBtn(false)
        if (response.status === 400) {
          const result = await response.json();
          console.error("Error creating Sub-Admin:", result.message);
          toast.error(result?.message)
        } else if (response.status === 404) {
          const result = await response.json();
          console.error("Error creating Sub-Admin:", result.message);
          toast.error(result?.message)
        } else {
          console.log(response.status)
          throw new Error("Error creating Sub-Admin");
        }
      } else {
        setLoaderBtn(false)
        const result = await response.json();
        toast.success("Successfully Created Sub-Admin!");
        setUpdateStaffList(true);
        console.log(result);
        setMobileNumber(null);
        setDesignationStatus("");
        setPermissions([]);
        reset();
      }
    } catch (error) {
      setLoaderBtn(false)

      toast.error("Error creating Sub-Admin")
      console.warn(error);
    }
  }

  const handleCheckboxChange = (role) => {
    if (permissions.includes(role)) {
      setPermissions((prevPermissions) =>
        prevPermissions.filter((permission) => permission !== role)
      );
    } else {
      setPermissions((prevPermissions) => [...prevPermissions, role]);
    }
  }

  const watchPassword = watch("password", "");
  const watchConfirmPassword = watch("confirmPassword", "");

  const formatKey = (key) => {
    return key.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
  };

  return (
    <div className="flex flex-col font-[500]">
      <div className='flex-col'>
        {/* Upper Input Section */}
        <ThemeProvider value={selectCustomTheme}>
          {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("CREATE_STAFFS")) &&
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3 py-3 bg-white rounded-tl-[5px] rounded-tr-[5px] flex justify-between items-center">
                <div className="text-black text-xl font-semibold  leading-tight ">Add New Staff</div>
                <div className="justify-start items-center gap-10 flex">
                  {loaderBtn ? (
                    <ThreeDots
                      height="50"
                      width="50"
                      radius="9"
                      color="#FB6108"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) :
                    <button type='submit' className="text-[0.8rem] sm:text-[1rem] flex items-center justify-center max-xs:w-full  gap-[0.3rem] xs:gap-[0.5rem] bg-primary text-white py-[0.5rem] px-[0.4rem] xs:px-[1rem] rounded-md">
                      Create Staff
                    </button>
                  }
                </div>
              </div>

              <div className='flex gap-[3rem] items-baseline px-[2rem]'>
                <div className='flex flex-col gap-[1.5rem] flex-[2]'>
                  <div className="flex flex-col gap-[0.5rem]">
                    <input
                      {...register("name", {
                        required: "*This field is required.",
                      })}
                      type="text"
                      className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                      placeholder="User Name"
                      id="name"
                    />
                    {errors.name && (
                      <div className="text-sm text-[#E92215]">
                        {errors.name.message}
                      </div>
                    )}

                  </div>
                  <div className="flex flex-col gap-[0.5rem]">
                    <input
                      {...register("email", {
                        required: "*This field is required.",
                        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      })}
                      type="email"
                      className="text-[0.75rem] sm:text-[1rem]  font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                      placeholder="Email Address"
                      id="email"
                    />
                    {errors.email?.type === "required" && (
                      <p className="text-red-600 text-sm">{errors.email.message}</p>
                    )}
                    {errors.email?.type === "pattern" && (
                      <p className="text-sm text-red-600">*Invalid Email</p>
                    )}

                  </div>
                  <div className="flex flex-col gap-[0.5rem]">
                    <input
                      type="password"
                      className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                      placeholder="Enter Password"
                      {...register("password", {
                        required: "*This field is required.",
                        minLength: 6,
                      })}
                    />
                    {errors.password?.type === "required" && (
                      <div className="text-sm text-[#E92215]">
                        *This field is required
                      </div>
                    )}
                    {errors.password?.type === "minLength" && (
                      <div className="text-sm text-[#E92215]">
                        Minimum Length: 6
                      </div>
                    )}

                  </div>
                </div>
                <div className='flex flex-col gap-[1.5rem] flex-[2]'>
                  <div className="flex flex-col gap-[0.5rem]">
                    <Select label='Enter Designation' color='blue-gray' size='lg'
                      className='text-[0.75rem] sm:text-[1rem] text-black font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none'
                      value={designationStatus}
                      onChange={(value) => setDesignationStatus(value)}
                    >
                      <Option value='Manager'>Manager</Option>
                      <Option value='Employee'>Employee</Option>
                      <Option value='Sub Admin'>Sub Admin</Option>
                    </Select>
                    {showError && !designationStatus && (
                      <p className="text-[#E92215] text-sm">
                        *This field is required.
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col gap-[0.5rem]">
                    <PhoneInput
                      placeholder='Enter Your Mobile Number'
                      className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                      initialValueFormat="national"
                      type="text"
                      defaultCountry="IN"
                      value={formatPhoneNumberIntl(mobileNumber + '')}
                      onChange={(value) => {
                        if (isValidPhoneNumber(value + '')) {
                          setMobileNumber(value)
                          setIsMobileNumberValid(true)
                        } else {
                          setIsMobileNumberValid(false);
                        }
                      }}
                    />
                    {((showError && !mobileNumber) || !isMobileNumberValid) && <p className="text-[#E92215] text-sm">{(!isMobileNumberValid && showError) ? "Invalid mobile number" : showError ? "*This field is required." : ""}</p>}
                    {/* {errors.mobileNumber && !mobileNumber && (<p className="text-[#E92215] text-sm">
                    *This field is required.
                  </p>)}
                  {mobileNumber && !isValidPhoneNumber('+91' + mobileNumber) && (<p className="text-[#E92215] text-sm">
                    *Invalid number
                  </p>)} */}
                  </div>
                  <div className="flex flex-col gap-[0.5rem]">
                    <input
                      type="password"
                      className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                      placeholder="Confirm Password"
                      {...register("confirmPassword", {
                        required: "*This field is required.",
                      })}
                      onBlur={() => setShowPasswordError(true)}
                    />
                    {showPasswordError && watchPassword !== watchConfirmPassword && (
                      <div className="text-sm text-[#E92215]">
                        Password Doesn't match
                      </div>
                    )}
                    {errors.confirmPassword && (
                      <div className="text-sm text-[#E92215]">
                        *This field is required
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='flex flex-col gap-[0.5rem]'>

                {/* Heading for Permissions */}
                <div className="mt-12 mx-9 text-[0.8rem] sm:text-[1rem] text-[#858585] font-semibold"> <span>  Permissions </span>
                  {showError && permissions.length === 0 && (
                    <p className="text-[#E92215] text-sm">
                      *Atleast select one permission.
                    </p>
                  )}
                </div>
                <div className='flex flex-col gap-[0.1rem]'>
                  <div className='ml-8 flex-wrap rounded-[10px] items-center flex gap-[1rem]'>
                    {Object.keys(rolesData ? rolesData : {}).map((role) => (
                      <>
                        <div className="flex items-center gap-[0.5rem] border border-[#D9D9D9] text-[#858585]  rounded-md px-4 py-2">
                          <input
                            className="relative h-4 w-4 cursor-pointer rounded-md border border-blue-gray-200 "
                            type="checkbox"
                            id={role}
                            checked={permissions.includes(rolesData[role])}
                            onChange={() => handleCheckboxChange(rolesData[role])}
                          />
                          <label htmlFor={role} className="text-zinc-500 text-[16px] font-medium cursor-pointer">
                            {formatKey(role)}
                          </label>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </form>
          }
        </ThemeProvider>
      </div >
      <ToastContainer />
    </div >
  )
}
