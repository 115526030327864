import { createBrowserRouter } from "react-router-dom";
import { useAuth } from "../store/AuthContext";
import { Navigate } from "react-router-dom";
import _, { isEmpty } from "lodash";
import AdminLogin from "../pages/login/AdminLogin";
import ForgotPassword from "../pages/login/ForgotPassword";
import AdminLayout from "../layouts/AdminLayout";
import Dashboard from "../components/AdminPanel/Dashboard";
import ClubOwners from "../pages/ClubOwners/ClubOwners";
import Categories from "../components/AdminPanel/Categories";
import Bookings from "../components/AdminPanel/Bookings";
import Customers from "../components/AdminPanel/Customers";
import Faqs from "../components/AdminPanel/Faqs";
import Notifications from "../components/AdminPanel/Notifications";
import OffersAndBanners from "../components/AdminPanel/OffersAndBanners";
import Reports from "../components/AdminPanel/Reports";
import Settings from "../components/AdminPanel/Settings";
import Staff from "../components/AdminPanel/Staff";
import Support from "../components/AdminPanel/Support";
import Tournaments from "../components/AdminPanel/Tournaments";
import SubCategories from "../components/AdminPanel/SubCategories";
import AddSubCategories from "../components/AdminPanel/AddSubCategories";
import SubCategoriesContainer from "../components/AdminModules/SubCategoriesContainer";
import HowToPlayInfo from "../components/AdminPanel/HowToPlayInfo";
import AdminPanelCategories from "../components/AdminModules/AdminPanelCategories";
import ShowClubOwners from "../pages/ClubOwners/ShowClubOwners";
import AdminCategories from "../components/AdminPanel/AdminCategories";
import MainCategoryList from "../components/AdminModules/Categories/MainCategoryList";
import SubCategoryList from "../components/AdminModules/Categories/SubCategoryList";
import CreateSubCategory from "../components/AdminModules/Categories/CreateSubCategory";
import CategoriesContainer from "../components/AdminModules/Categories/CategoriesContainer";
import AdminClubOwners from "../components/AdminPanel/AdminClubOwners";
import ClubOwnersList from "../components/AdminModules/ClubOwners/ClubOwnersList";
import CreateClub from "../components/AdminModules/ClubOwners/CreateClub";
import CreateSubStaff from "../components/AdminModules/Staff/createStaff.js";
import UpdateSubStaff from "../components/AdminModules/Staff/updateStaff.js";
import Canteen from "../components/AdminModules/ReportModules/Canteen.js";
import CustomersReport from "../components/AdminModules/ReportModules/CustomersReport.js";
import BookingsReport from "../components/AdminModules/ReportModules/BookingsReport.js";
import Expenses from "../components/AdminModules/ReportModules/Expenses.js";
import Inventory from "../components/AdminModules/ReportModules/Inventory.js";
import Rentals from "../components/AdminModules/ReportModules/Rentals.js";
import TournamentsReport from "../components/AdminModules/ReportModules/TournamentsReport.js";
import AdminFAQs from "../components/AdminPanel/AdminFAQs";
import CreateFAQ from "../components/AdminModules/FAQs/CreateFAQ";
import EditFAQ from "../components/AdminModules/FAQs/EditFAQ";

// import AdminCustomers from '../components/AdminPanel/AdminCustomers.js';
import AdminBookings from "../components/AdminPanel/AdminBookings.js";
// import AdminTournaments from '../components/AdminPanel/AdminTournaments.js';
import Customer from "../components/AdminModules/CustomerModule/Customer.js";
import UpdateCustomer from "../components/AdminModules/CustomerModule/UpdateCustomer.js";
import CanteenList from "../components/AdminModules/CustomerModule/EditLists/CanteenList.js";
import TournamentsList from "../components/AdminModules/CustomerModule/EditLists/TournamentsList.js";
import BookingsList from "../components/AdminModules/CustomerModule/EditLists/BookingsList.js";
import UpdateBooking from "../components/AdminModules/CustomerModule/EditLists/UpdateBooking.js";
import UpdateCanteen from "../components/AdminModules/CustomerModule/EditLists/UpdateCanteen.js";

import ViewClubOwner from "../components/AdminModules/ClubOwners/ViewClubOwner/ViewClubOwner.js";
import ViewCODashboard from "../components/AdminModules/ClubOwners/ViewClubOwner/ViewCODashboard.js";
import ViewCOSports from "../components/AdminModules/ClubOwners/ViewClubOwner/ViewCOSports.js";
import ViewCOBookings from "../components/AdminModules/ClubOwners/ViewClubOwner/ViewCOBookings.js";
import ViewCOStaff from "../components/AdminModules/ClubOwners/ViewClubOwner/ViewCOStaff.js";
import ViewCOCustomers from "../components/AdminModules/ClubOwners/ViewClubOwner/ViewCOCustomers.js";
import ViewCOTournaments from "../components/AdminModules/ClubOwners/ViewClubOwner/ViewCOTournaments.js";
import ViewCOInventory from "../components/AdminModules/ClubOwners/ViewClubOwner/ViewCOInventory.js";
import ViewCORentals from "../components/AdminModules/ClubOwners/ViewClubOwner/ViewCORentals.js";
import ViewCOExpenses from "../components/AdminModules/ClubOwners/ViewClubOwner/ViewCOExpenses.js";
import ViewCOCanteen from "../components/AdminModules/ClubOwners/ViewClubOwner/ViewCOCanteen.js";
import ViewClubOwnerContainer from "../components/AdminModules/ClubOwners/ViewClubOwner/ViewClubOwnerContainer.js";

import AdminTournaments from "../components/AdminPanel/AdminTournaments.js";
import AdminDashboard from "../components/AdminPanel/AdminDashboard.js";
import AdminStaff from "../components/AdminPanel/AdminStaff.js";
import ViewCOSportsViewContainer from "../components/AdminModules/ClubOwners/ViewClubOwner/ViewCOSportsViewContainer.js";
import ViewCOSportView from "../components/AdminModules/ClubOwners/ViewClubOwner/ViewCOSportView.js";
import ViewCOSportHTP from "../components/AdminModules/ClubOwners/ViewClubOwner/ViewCOSportHTP.js";
import EditSubCategory from "../components/AdminModules/Categories/UpdateSubCategory/EditSubCategory.js";
import OffersContainer from "../components/AdminModules/OffersAndBannersModule/OffersContainer/OffersContainer.js";
import BannersContainer from "../components/AdminModules/OffersAndBannersModule/BannersContainer/BannersContainer.js";
import CategoryDiscountList from "../components/AdminModules/OffersAndBannersModule/OffersContainer/CategoryDiscountList.js";
import CouponList from "../components/AdminModules/OffersAndBannersModule/OffersContainer/CouponList.js";

import BannerEdit from "../components/AdminModules/OffersAndBannersModule/BannersContainer/BannerEdit.js";
import BannerForm from "../components/AdminModules/OffersAndBannersModule/BannersContainer/BannerForm.js";
import AddNewOfferForm from "../components/AdminModules/OffersAndBannersModule/OffersContainer/AddNewOfferForm.js";

import EditCouponForm from "../components/AdminModules/OffersAndBannersModule/OffersContainer/EditCouponForm.js";

import AdminNotifications from "../components/AdminPanel/AdminNotifications.js";
import SendNotification from "../components/AdminModules/Notifications/SendNotification.js";
import EditNotification from "../components/AdminModules/Notifications/EditNotification.js";
import ViewCOBookingView from "../components/AdminModules/ClubOwners/ViewClubOwner/ViewCOBookingView.js";
import ViewCOStaffView from "../components/AdminModules/ClubOwners/ViewClubOwner/ViewCOStaffView.js";
import ViewCOInventoryView from "../components/AdminModules/ClubOwners/ViewClubOwner/ViewCOInventoryView.js";
import ViewCORentalView from "../components/AdminModules/ClubOwners/ViewClubOwner/ViewCORentalView.js";
import CustomersViewContainer from "../components/AdminModules/ClubOwners/ViewClubOwner/Customers/CustomersViewContainer.js";
import CustomersViewBookings from "../components/AdminModules/ClubOwners/ViewClubOwner/Customers/CustomersViewBookings.js";
import CustomersViewCanteen from "../components/AdminModules/ClubOwners/ViewClubOwner/Customers/CustomersViewCanteen.js";
import CustomersViewBookingView from "../components/AdminModules/ClubOwners/ViewClubOwner/Customers/CustomersViewBookingView.js";
import CustomersViewCanteenView from "../components/AdminModules/ClubOwners/ViewClubOwner/Customers/CustomersViewCanteenView.js";
import ViewCOCanteenView from "../components/AdminModules/ClubOwners/ViewClubOwner/Customers/ViewCOCanteenView.js";
import AdminChatSupport from "../components/AdminPanel/AdminChatSupport.js";
import ChatSupportList from "../components/AdminModules/ChatSupport/ChatSupportList.js";
import ViewChat from "../components/AdminModules/ChatSupport/ViewChat.js";
import AdminAmenities from "../components/AdminPanel/AdminAmenities.js";
import AddAmenity from "../components/AdminModules/Amenities/AddAmenity.js";
import EditAmenity from "../components/AdminModules/Amenities/EditAmenity.js";
import ProfilePage from "../components/AdminPanel/ProfilePage.js";
import ViewBooking from "../components/AdminModules/BookingsModule/ViewBooking.js";
import ViewCOExpensesView from "../components/AdminModules/ClubOwners/ViewClubOwner/ViewCOExpensesView.js";
import EditClub from "../components/AdminModules/ClubOwners/EditClub.js";
import EditClubOfferForm from "../components/AdminModules/OffersAndBannersModule/OffersContainer/EditClubOfferForm.js";
import Tournament from "../components/AdminModules/Tournaments/Tournament.js";
import Ongoing from "../components/AdminModules/Tournaments/Ongoing.js";
import Upcoming from "../components/AdminModules/Tournaments/Upcoming.js";
import Completed from "../components/AdminModules/Tournaments/Completed.js";
import CreateTournament from "../components/AdminModules/Tournaments/CreateTournament.js";
import TournamentDetails from "../components/AdminModules/Tournaments/TournamentDetails/TournamentDetails.js";
import OverviewOfTournament from "../components/AdminModules/Tournaments/TournamentDetails/OverviewOfTournament.js";
import TeamsCreation from "../components/AdminModules/Tournaments/TournamentDetails/TeamsCreation.js";
import GameFixtures from "../components/AdminModules/Tournaments/TournamentDetails/GameFixtures.js";
import Stats from "../components/AdminModules/Tournaments/TournamentDetails/Stats.js";
import Rewards from "../components/AdminModules/Tournaments/TournamentDetails/Rewards.js";
import EditOverViewTournament from "../components/AdminModules/Tournaments/TournamentDetails/EditOverViewTournament.js";
import CreateVenue from "../components/AdminModules/Tournaments/TournamentDetails/CreateVenue.js";
import ViewTeamParticipants from "../components/AdminModules/Tournaments/TournamentDetails/ViewTeamParticipants.js";
import RoleCreation from "../components/AdminModules/Roles/RoleCreation.js";
import AdminRoles from "../components/AdminPanel/AdminRoles.js";
import ArchivedData from "../components/AdminModules/Tournaments/ArchivedData.js";
import All from "../components/AdminModules/Tournaments/All.js";
import TournamentSports from "../components/AdminPanel/TournamentSports.js";
import SportCreation from "../components/AdminModules/TournamentSports/SportCreation.js";
import AddOrganizer from "../components/AdminModules/Tournaments/TournamentDetails/AddOrganizer.js";
import AdminPayOuts from "../components/AdminPanel/AdminPayOuts.js";
import EditOrganizerOrSponsor from "../components/AdminModules/Tournaments/TournamentDetails/EditOrganizerOrSponsor.js";
import ViewFAQ from "../components/AdminModules/FAQs/ViewFAQ.js";
import ViewCoClubOwner from "../components/AdminModules/ClubOwners/ViewClubOwner/ViewCoClubOwner.js";
import AdminSubscriptions from "../components/AdminPanel/AdminMemberShips.js";
import SpecificCustomerSubscription from "../components/AdminModules/CustomerModule/EditLists/SpecificCustomerSubscription.js";
import ViewPayOuts from "../components/AdminModules/PayOuts/ViewPayOuts.js";
import ViewSubscriptions from '../components/AdminModules/Memberships/ViewSubscriptions.js'

const PrivateRoute = ({ component: Component, Permissions: permis }) => {
  const { currentUser, userRole } = useAuth();
  return (
    <>
      {(currentUser && userRole?.role?.admin) ||
        (currentUser && userRole?.role?.subAdmin?.type) ? (
        !_.isEmpty(permis) && userRole?.role?.subAdmin?.type ? (
          userRole?.role?.subAdmin?.permissions.includes(permis) ? (
            <Component />
          ) : (
            "ACCESS DENIED"
          )
        ) : (
          <Component />
        )
      ) : (
        <Navigate to="/login" replace />
      )}
      {/* {(true && true) || (currentUser && userRole?.role?.subAdmin?.type) ? (
            !_.isEmpty(permis) && userRole?.role?.subAdmin?.type ? (userRole?.role?.subAdmin?.permissions.includes(permis) ? <Component /> : "ACCESS DENIED") : <Component />
         ) : (
            <Navigate to="/login" replace />
         )} */}
    </>
  );
};

const PublicRoute = ({ component: Component }) => {
  const { currentUser, userRole } = useAuth();

  return (
    <>
      {(currentUser && userRole?.role?.admin) ||
        (currentUser && userRole?.role?.subAdmin.type) ? (
        <Navigate to="/" replace />
      ) : (
        <Component />
      )}
    </>
  );
};

export const router = createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoute component={AdminLayout} />,
    exactPath: true,
    children: [
      {
        path: "",
        element: <PrivateRoute component={Dashboard} />,
      },
      {
        path: "profile",
        element: <PrivateRoute component={ProfilePage} />,
      },
      {
        path: "staff",
        element: <PrivateRoute component={Staff} />,
        children: [
          {
            path: "",
            element: <PrivateRoute component={CreateSubStaff} />,
          },
          {
            path: "edit/:id",
            element: <PrivateRoute component={UpdateSubStaff} />,
          },
        ],
      },
      {
        path: "club-owners",
        element: <PrivateRoute component={AdminClubOwners} />,
        children: [
          {
            path: "",
            element: <PrivateRoute component={ClubOwnersList} />,
          },
          {
            path: "create",
            element: <PrivateRoute component={CreateClub} />,
          },
          {
            path: "edit/:id",
            element: <PrivateRoute component={EditClub} />,
          },
          {
            path: "view/:id",
            element: <PrivateRoute component={ViewClubOwnerContainer} />,
            children: [
              {
                path: "",
                element: <PrivateRoute component={ViewClubOwner} />,
                children: [
                  {
                    path: "",
                    element: <PrivateRoute component={ViewCODashboard} />,
                  },
                  {
                    path: "sports",
                    element: <PrivateRoute component={ViewCOSports} />,
                  },
                  {
                    path: "bookings",
                    element: <PrivateRoute component={ViewCOBookings} />,
                  },
                  {
                    path: "staff",
                    element: <PrivateRoute component={ViewCOStaff} />,
                  },
                  {
                    path: "customers",
                    element: <PrivateRoute component={ViewCOCustomers} />,
                  },
                  {
                    path: "tournaments",
                    element: <PrivateRoute component={ViewCOTournaments} />,
                  },
                  {
                    path: "inventory",
                    element: <PrivateRoute component={ViewCOInventory} />,
                  },
                  {
                    path: "rentals",
                    element: <PrivateRoute component={ViewCORentals} />,
                  },
                  {
                    path: "expenses",
                    element: <PrivateRoute component={ViewCOExpenses} />,
                  },
                  {
                    path: "canteen",
                    element: <PrivateRoute component={ViewCOCanteen} />,
                  },
                  {
                    path: "co_ClubOwner",
                    element: <PrivateRoute component={ViewCoClubOwner} />
                  }
                ],
              },
              {
                path: "sports/view/:sportId",
                element: <PrivateRoute component={ViewCOSportsViewContainer} />,
                children: [
                  {
                    path: "",
                    element: <PrivateRoute component={ViewCOSportView} />,
                  },
                  {
                    path: "how-to-play",
                    element: <PrivateRoute component={ViewCOSportHTP} />,
                  },
                ],
              },
              {
                path: "bookings/view/:bookingId",
                element: <PrivateRoute component={ViewCOBookingView} />,
              },
              {
                path: "staff/view/:staffId",
                element: <PrivateRoute component={ViewCOStaffView} />,
              },
              {
                path: "customers/view/:customerId",
                element: <PrivateRoute component={CustomersViewContainer} />,
                children: [
                  {
                    path: "",
                    element: <PrivateRoute component={CustomersViewBookings} />,
                  },
                  {
                    path: "canteen",
                    element: <PrivateRoute component={CustomersViewCanteen} />,
                  },
                ],
              },
              {
                path: "customers/view/:customerId/view/:bookingId",
                element: <PrivateRoute component={CustomersViewBookingView} />,
              },
              {
                path: "customers/view/:customerId/canteen/view/:orderId",
                element: <PrivateRoute component={CustomersViewCanteenView} />,
              },
              {
                path: "inventory/view/:inventoryId",
                element: <PrivateRoute component={ViewCOInventoryView} />,
              },
              {
                path: "rentals/view/:rentalId",
                element: <PrivateRoute component={ViewCORentalView} />,
              },
              {
                path: "expenses/view/:expenseId",
                element: <PrivateRoute component={ViewCOExpensesView} />,
              },
              {
                path: "canteen/view/:canteenId",
                element: <PrivateRoute component={ViewCOCanteenView} />,
              },
            ],
          },
        ],
      },
      {
        path: "bookings",
        element: <PrivateRoute component={AdminBookings} />,
      },
      {
        path: "bookings/view/:id",
        element: <PrivateRoute component={ViewBooking} />,
      },
      {
        path: "categories",
        element: <PrivateRoute component={AdminCategories} />,
        children: [
          {
            path: "",
            element: <PrivateRoute component={CategoriesContainer} />,
            children: [
              {
                path: "",
                element: (
                  <PrivateRoute
                    component={MainCategoryList}
                  // Permissions="VIEW_CATEGORY"
                  />
                ),
              },
              {
                path: "sub-categories",
                element: (
                  <PrivateRoute
                    component={SubCategoryList}
                  // Permissions="VIEW_SUB_CATEGORY"
                  />
                ),
              },
            ],
          },
          {
            path: "sub-categories/create",
            element: <PrivateRoute component={CreateSubCategory} />,
          },
          {
            path: "sub-categories/edit/:id",
            element: <PrivateRoute component={EditSubCategory} />,
          },
        ],
        // children: [
        //    {
        //       path: "",
        //       element: <PrivateRoute component={CategoriesContainer} />,
        //       children: [
        //          {
        //             path: "",
        //             element: <PrivateRoute component={Categories} Permissions="VIEW_CATEGORY" />
        //          },
        //          {
        //             path: "sub-categories",
        //             element: <PrivateRoute component={SubCategories} Permissions="VIEW_SUB_CATEGORY" />
        //          },
        //       ]
        //    },
        //    {
        //       path: 'sub-categories/addSubCategories',
        //       element: <PrivateRoute component={SubCategoriesContainer} />,
        //       children: [
        //          {
        //             path: '',
        //             element: <PrivateRoute component={AddSubCategories} />
        //          },
        //          {
        //             path: 'howToPlay',
        //             element: <PrivateRoute component={HowToPlayInfo} />
        //          }
        //       ]
        //    },
        // ]
      },

      {
        path: "customers",
        element: <PrivateRoute component={Customers} />,
        children: [
          {
            path: "",
            element: <PrivateRoute component={Customer} />,
          },
          {
            path: "view/:customerId",
            element: <PrivateRoute component={UpdateCustomer} />,
            children: [
              {
                path: "",
                element: <PrivateRoute component={BookingsList} />,
              },
              {
                path: "subscription",
                element: <PrivateRoute component={SpecificCustomerSubscription} />
              },
              {
                path: "tournaments",
                element: <PrivateRoute component={TournamentsList} />,
              },
              {
                path: "canteen",
                element: <PrivateRoute component={CanteenList} />,
              },
              {
                path: "canteen/:canteenId",
                element: <PrivateRoute component={UpdateCanteen} />,
              },
            ],
          },
          {
            path: "view/:customerId/booking/:bookingId",
            element: <PrivateRoute component={UpdateBooking} />,
          },
        ],
      },
      {
        path: "faqs",
        element: <PrivateRoute component={AdminFAQs} />,
        children: [
          {
            path: "",
            element: <PrivateRoute component={CreateFAQ} />,
          },
          {
            path: "edit/:id",
            element: <PrivateRoute component={EditFAQ} />,
          },
        ],
      },
      {
        path: "faqs/view/:id",
        element: <PrivateRoute component={ViewFAQ} />
      },
      {
        path: "notifications",
        element: <PrivateRoute component={AdminNotifications} />,
        children: [
          {
            path: "",
            element: <PrivateRoute component={SendNotification} />,
          },
          {
            path: "edit/:id",
            element: <PrivateRoute component={EditNotification} />,
          },
        ],
      },
      {
        path: "offersAndBanners",
        element: <OffersAndBanners />,
        children: [
          {
            path: "",
            element: <OffersContainer />,
            children: [
              {
                path: "",
                element: <CategoryDiscountList />,
                children: [
                  {
                    path: "",
                    element: <AddNewOfferForm />,
                  },
                  {
                    path: "discount/edit/:id",
                    element: <EditClubOfferForm />,
                  },
                ],
              },
              {
                path: "coupons",
                element: <CouponList />,
                children: [
                  {
                    path: "",
                    element: <AddNewOfferForm />,
                  },
                  {
                    path: "edit/:id",
                    element: <EditCouponForm />,
                  },
                ],
              },
            ],
          },
          {
            path: "banners",
            element: <BannersContainer />,
            children: [
              {
                path: "",
                element: <BannerForm />,
              },
              {
                path: "edit/:id",
                element: <BannerEdit />,
              },
            ],
          },
        ],
      },
      {
        path: "reports",
        element: <PrivateRoute component={Reports} />,
        children: [
          {
            path: "",
            element: <PrivateRoute component={BookingsReport} />,
          },
          {
            path: "customers",
            element: <PrivateRoute component={CustomersReport} />,
          },
          {
            path: "tournaments",
            element: <PrivateRoute component={TournamentsReport} />,
          },
          {
            path: "inventory",
            element: <PrivateRoute component={Inventory} />,
          },
          {
            path: "rentals",
            element: <PrivateRoute component={Rentals} />,
          },
          {
            path: "expenses",
            element: <PrivateRoute component={Expenses} />,
          },
          {
            path: "canteen",
            element: <PrivateRoute component={Canteen} />,
          },
        ],
      },
      {
        path: "settings",
        element: <PrivateRoute component={Settings} />,
      },
      {
        path: "staff",
        element: <PrivateRoute component={Staff} />,
        children: [
          {
            path: "",
            element: <PrivateRoute component={CreateSubStaff} />,
          },
          {
            path: "edit/:id",
            element: <PrivateRoute component={UpdateSubStaff} />,
          },
        ],
      },
      {
        path: "chat-support",
        element: <PrivateRoute component={AdminChatSupport} />,
        children: [
          {
            path: "",
            element: <PrivateRoute component={ChatSupportList} />,
          },
          {
            path: "view/:supportId",
            element: <PrivateRoute component={ViewChat} />,
          },
        ],
      },
      {
        path: "amenities",
        element: <PrivateRoute component={AdminAmenities} />,
        children: [
          {
            path: "",
            element: <PrivateRoute component={AddAmenity} />,
          },
          {
            path: "edit/:id",
            element: <PrivateRoute component={EditAmenity} />,
          },
        ],
      },
      {
        path: "roles",
        element: <PrivateRoute component={AdminRoles} />,
        children: [
          {
            path: "",
            element: <PrivateRoute component={RoleCreation} />
          }
        ]
      },
      {
        path: "tournaments",
        element: <PrivateRoute component={AdminTournaments} />,
        children: [
          {
            path: "",
            element: <PrivateRoute component={Tournament} />,
            children: [
              {
                path: "",
                element: <PrivateRoute component={All} />,
              },
              {
                path: "ongoing",
                element: <PrivateRoute component={Ongoing} />,
              },
              {
                path: "upcoming",
                element: <PrivateRoute component={Upcoming} />,
              },
              {
                path: "completed",
                element: <PrivateRoute component={Completed} />,
              },
              {
                path: "archieved",
                element: <PrivateRoute component={ArchivedData} />
              }
            ]
          },
          {
            path: "tournamentDetails/:tournamentId",
            element: <PrivateRoute component={TournamentDetails} />,
            children: [
              {
                path: "",
                element: <PrivateRoute component={OverviewOfTournament} />,
              },
              {
                path: "team_creation",
                element: <PrivateRoute component={TeamsCreation} />,
              },
              {
                path: "game_fixtures",
                element: <PrivateRoute component={GameFixtures} />,
              },
              {
                path: "stats",
                element: <PrivateRoute component={Stats} />
              },
              {
                path: "rewards",
                element: <PrivateRoute component={Rewards} />
              },
            ]
          },
          {
            path: "create",
            element: <PrivateRoute component={CreateTournament} />,
          },
          {
            path: "tournamentDetails/:tournamentId/editOverViewTournament",
            element: <PrivateRoute component={EditOverViewTournament} />
          },
          {
            path: "tournamentDetails/:tournamentId/createVenue",
            element: <PrivateRoute component={CreateVenue} />
          },
          {
            path: "tournamentDetails/:tournamentId/viewTeamParticipants/:teamId",
            element: <PrivateRoute component={ViewTeamParticipants} />
          },
          {
            path: "tournamentDetails/:tournamentId/addOrganizer",
            element: <PrivateRoute component={AddOrganizer} />
          },
          {
            path: "tournamentDetails/:tournamentId/editOrganizerDetails/:sponsorID",
            element: <PrivateRoute component={EditOrganizerOrSponsor} />
          }
        ]
      },
      {
        path: "tournamentSports",
        element: <PrivateRoute component={TournamentSports} />,
        children: [
          {
            path: "",
            element: <PrivateRoute component={SportCreation} />
          }
        ]
      },
      {
        path: "payOuts",
        element: <PrivateRoute component={AdminPayOuts} />
      },
      {
        path: "payouts/view/:_id",
        element: <PrivateRoute component={ViewPayOuts} />
      },
      {
        path: "subscriptions",
        element: <PrivateRoute component={AdminSubscriptions} />
      },
      {
        path: "subscriptions/view/:_id",
        element: <PrivateRoute component={ViewSubscriptions} />
      }
    ],
  },
  {
    path: "/login",
    element: <PublicRoute component={AdminLogin} />,
  },
  {
    path: "/forgotPassword",
    element: <PublicRoute component={ForgotPassword} />,
  },
]);
