import {
  Select,
  Option,
  Input,
  Checkbox,
  Button,
  ThemeProvider,
} from "@material-tailwind/react";
import DatePicker from "react-flatpickr";
import { useState } from "react";
import { Icon } from "@iconify/react";
import { selectCustomTheme } from "../../../../helpers/constants";
const CustomCheckIcon = () => (
  <svg
    className="w-5 h-5 bg-[#FB6108] rounded-[5px]" // Apply the color to the custom check icon
    viewBox="0 0 24 24"
    fill="none"
    stroke="white"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="20 6 9 17 4 12" />
  </svg>
);

export default function CouponForm({ handleOfferTypeChange, offerType }) {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <ThemeProvider value={selectCustomTheme}>
    <form>
    <div>
     {/* Form Header  */}
     <div className="flex items-center justify-between ml-[-10px] mb-[-5px]">
          <div className="text-black text-xl font-semibold  leading-tight">
            Add New Offer
          </div>
          <div className="py-2 w-[114px] h-8 bg-[#FB6108] rounded-[5px] shadow justify-center items-center gap-2 flex">
            <div className="text-white text-base font-medium flex items-center gap-2">
              <span>Submit</span>
              <Icon icon="flowbite:angle-right-outline" />
            </div>
          </div>
      </div>

      <div className="flex mt-[2rem] mb-[1rem] ml-[20px]">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-[92%]">
          <div className="space-y-[1.5rem]">
            <Select
              className="border-0 bg-[#EFF1F999] text-black h-[2.63rem]"
              label="Select All Clubs"
              size="lg"
            >
              <Option>Material Tailwind HTML</Option>
              <Option>Material Tailwind React</Option>
              <Option>Material Tailwind Vue</Option>
              <Option>Material Tailwind Angular</Option>
              <Option>Material Tailwind Svelte</Option>
            </Select>

            <Select className="border-0 bg-[#EFF1F999] text-black h-[2.63rem]" size="lg" label="Coupon Type">
              <Option>Material Tailwind HTML</Option>
              <Option>Material Tailwind React</Option>
              <Option>Material Tailwind Vue</Option>
              <Option>Material Tailwind Angular</Option>
              <Option>Material Tailwind Svelte</Option>
            </Select>

            <div className="relative bg-[#EFF1F999] rounded-lg">
            <input
              className="h-[2.63rem] text-[0.75rem] sm:text-[1rem] px-[1rem] font-medium bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none w-full"
              type="text"
              placeholder="Coupon Code"
            />
              <button className="text-[#FB6108] text-[14px] border-[1px] border-[#FB6108] p-1 rounded-lg absolute right-[0.5rem] top-[5px]">Auto Generate Code</button>
            </div>

            <Select className="border-0 bg-[#EFF1F999] text-black h-[2.63rem]" size="lg" label="Discount Type">
              <Option>Material Tailwind HTML</Option>
              <Option>Material Tailwind React</Option>
              <Option>Material Tailwind Vue</Option>
              <Option>Material Tailwind Angular</Option>
              <Option>Material Tailwind Svelte</Option>
            </Select>

            <div className="bg-[#EFF1F999] rounded-lg">
            <input
              className="h-[2.63rem] text-[0.75rem] sm:text-[1rem] px-[1rem] font-medium bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none w-full"
              type="number"
              placeholder="Maximum Purchase"
            />
            </div>

            <div className="relative">
            <DatePicker
              className="h-[2.63rem] outline-none placeholder-[#7F7F7F] rounded-lg pl-3 text-[1rem] text-black bg-[#EFF1F999] font-medium w-full"
              placeholder="Start Date"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
            <Icon className="absolute right-3 top-[0.8rem]" icon="uiw:date" />
          </div>

          <div className="relative">
            <DatePicker
              className="h-[2.63rem] outline-none rounded-lg pl-3 text-[1rem] text-black bg-[#EFF1F999] font-medium w-full placeholder-[#7F7F7F]"
              placeholder="Expiry Date"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
            <Icon className="absolute right-3 top-[0.8rem]" icon="uiw:date" />
          </div>
          </div>

          {/* column 2  */}
          <div className="space-y-[1.5rem]">
            <Select
              onChange={handleOfferTypeChange}
              value={offerType}
              className="border-none bg-[#EFF1F999] text-black h-[2.63rem]"
              label="Offer Type"
              size="lg"
            >
              <Option value="Discount">Discount</Option>
              <Option value="Coupons">Coupons</Option>
            </Select>

            <div>
            <input
              className="h-[2.63rem] text-[0.75rem] sm:text-[1rem] px-[1rem] font-medium bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none w-full"
              type="text"
              placeholder="Coupon Titile"
            />
          </div>

          <div>
            <input
              className="h-[2.63rem] text-[0.75rem] sm:text-[1rem] px-[1rem] font-medium bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none w-full"
              type="number"
              placeholder="Limit for same user"
            />
          </div>

          <div>
            <input
              className="h-[2.63rem] text-[0.75rem] sm:text-[1rem] px-[1rem] font-medium bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none w-full"
              type="number"
              placeholder="Total No. of Coupons"
            />
          </div>

          <div>
            <input
              className="h-[2.63rem] text-[0.75rem] sm:text-[1rem] px-[1rem] font-medium bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none w-full"
              type="number"
              placeholder="Discount Amount"
            />
          </div>

          <div>
            <input
              className="h-[2.63rem] text-[0.75rem] sm:text-[1rem] px-[1rem] font-medium bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none w-full"
              type="number"
              placeholder="Maximum Discount"
            />
          </div>

            <Select className="border-0 bg-[#EFF1F999] text-black h-[2.63rem]" size="lg" label="Gender">
              <Option>Male</Option>
              <Option>Female</Option>
              <Option>Other</Option>
            </Select>
          </div>
        </div>
      </div>

      <div className="mb-[2.5rem] flex flex-col justify-center ml-[20px]">
        <div className="flex items-center justify-between w-[92%]">
        <h3 className="text-left text-[#7F7F7F] font-medium">Age Grounps</h3>
        <div className="flex items-center gap-[1rem]"><p className="text-[#7F7F7F]">All Age Groups</p><Icon icon="mingcute:toggle-left-fill" className="text-[#FB6108] text-5xl"/></div>
        </div>
        <div className="w-[92%] border-[1.5px] border-[#D9D9D9] p-5 rounded-lg space-y-6">
          <div className="flex justify-between items-center">
            <div className="flex gap-[1rem]">
              <div className="w-30">
                <Select label="From" className="text-black outline-none">
                  <Option>Material</Option>
                  <Option>Material</Option>
                  <Option>Material</Option>
                  <Option>Material</Option>
                  <Option>Material</Option>
                </Select>
              </div>

              <div className="w-30">
                <Select label="To" className="text-black outline-none">
                  <Option>Material</Option>
                  <Option>Material</Option>
                  <Option>Material</Option>
                  <Option>Material</Option>
                  <Option>Material</Option>
                </Select>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <div className="border-[1px] border-[#D9D9D9] p-1 text-2xl rounded-lg cursor-pointer"><Icon className="text-[#FF0000]" icon="material-symbols:delete-outline"/></div>
              <Button variant="outlined" className="flex items-center gap-3 bg-[#FB6108] text-white border-0 h-[40px]">
                <span>Add More</span>
                <Icon icon="ic:twotone-plus" className="text-3xl"/>
              </Button>
            </div>
          </div>
          <div className="flex gap-[1rem]">
            <div className="border-[1px] border-[#FB6108] rounded-[0.7rem] w-[8rem] flex items-center justify-center gap-[1rem]">
              {" "}
              <span className="pl-2 font-medium">18 - 24</span>{" "}
              <Checkbox icon={<CustomCheckIcon />} />
            </div>
            <div className="border-[1px] border-[#FB6108] rounded-[0.7rem] w-[8rem] flex items-center justify-center gap-[1rem]">
              {" "}
              <span className="pl-2 font-medium">25 - 32</span>{" "}
              <Checkbox icon={<CustomCheckIcon />} />
            </div>
            <div className="border-[1px] border-[#FB6108] rounded-[0.7rem] w-[8rem] flex items-center justify-center gap-[1rem]">
              {" "}
              <span className="pl-2 font-medium">32+</span>{" "}
              <Checkbox icon={<CustomCheckIcon />} />
            </div>
          </div>
        </div>
      </div>

    </div>
    </form>
    </ThemeProvider>
  );
}
