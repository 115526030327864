import React, { useEffect, useState } from 'react'
import { FaGreaterThan } from "react-icons/fa6";
import { ThemeProvider } from '@material-tailwind/react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../store/AuthContext';
import { APIurls } from '../../../api/apiConstant';
import { selectCustomTheme } from '../../../helpers/constants';
import { toast, ToastContainer } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import Select from 'react-select'

export default function CreateTournament() {

  const [showError, setShowError] = useState(false)
  const [mainCategoryData, setMainCategoryData] = useState([])
  const [mainCategorySport, setMainCategorySport] = useState({ value: "", label: "" })
  const [selectType, setSelectType] = useState({ value: "", label: "" })
  const [selectFormat, setSelectFormat] = useState({ value: "", label: "" })
  const [fixingType, setFixingType] = useState({ value: "", label: "" })
  const [subSport, setSubSport] = useState({ value: "", label: "" })
  const [participants, setPartcipants] = useState("")
  const [selectSport, setSelectSport] = useState({ value: "", label: "" })
  const [subCategory, setSubCategory] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [sportsData, setSportsData] = useState([])

  const { getAccessToken } = useAuth();
  const navigate = useNavigate();

  const fetchMainCategories = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.fetchCategories}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.message)
      }
      const filteredMainCategories = result?.response?.CatArray?.filter((item) => (item?.subCategoriesId?.length > 0))
      setMainCategoryData(filteredMainCategories);
      return result?.response?.CatArray
    }
    catch (error) {
      console.log("Error while fetching Main Categories", error)
    }
  }

  const fetchAllSports = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.fetchAllTournamentSports}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.message)
      }
      // console.log("Sports---------->",result.response?.tournamentSports)
      setSportsData(result?.response?.tournamentSports)
    }
    catch (error) {
      console.log("Error while fetching Sports", error)
    }
  }

  useEffect(() => {
    fetchMainCategories();
    fetchAllSports();
  }, [])

  const handleCreateTournament = async (e) => {
    e.preventDefault();

    if (!(mainCategorySport?.value) ||
      !(selectType?.value) ||
      !(selectFormat?.value) ||
      !(fixingType?.value) ||
      !(subSport?.value) ||
      !(participants) ||
      !(selectSport?.value)) {
      setShowError(true)
      return
    } else {
      setShowError(false)
    }

    const body = {
      mainCategoryID: mainCategorySport?.value,
      subCategoryID: subSport?.value,
      gameType: selectType?.value,
      formatType: selectFormat?.value,
      fixingType: fixingType?.value,
      sportID: selectSport?.value,
      participants: participants,
    }
    try {
      setIsLoading(true);
      const token = await getAccessToken();
      const res = await fetch(`${APIurls.tournamentCreation}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      })
      const data = await res.json();
      if (data.code > 201) {
        console.log('Error occurs while creating tournament,', data.message)
        toast.error(data.message);
        setIsLoading(false);
        return
      }
      console.log('Tournament Created Sucessfully', data)
      toast.success("Tournament Created Sucessfully");
      navigate('/tournaments/upcoming');
    } catch (error) {
      return console.log(error.message)
    }
    setIsLoading(false)
    setMainCategorySport({ value: "", label: "" })
    setSubSport({ value: "", label: "" })
    setSelectType({ value: "", label: "" })
    setSelectFormat({ value: "", label: "" })
    setFixingType({ value: "", label: "" })
    setSelectSport({ value: "", label: "" })
    setPartcipants("")
  }

  const mainCategoryOptions =
    mainCategoryData.map(category => ({
      value: category?._id,
      label: category?.categoryName
    }))

  useEffect(() => {
    if (mainCategorySport) {
      const mainCat = mainCategoryData?.find((div) => div?._id === mainCategorySport?.value);
      if (Array.isArray(mainCat?.subCategoriesId)) {
        const subCatOptions = mainCat.subCategoriesId.map((div) => ({
          value: div?._id,
          label: div?.subCategoryName
        }));
        setSubCategory(subCatOptions);
      }
    }
    setSubSport({ value: "", label: "" })
  }, [mainCategorySport]);

  const selectTypeOptions = [
    { value: "team", label: "Team" },
    { value: "individual", label: "Individual" },
  ]

  const selectFormatOptions = [
    { value: 'knockout', label: "Knockout" },
    { value: 'double_elimination_bracket', label: "Double-Elimination Bracket" },
    { value: 'round_robbin', label: "Round Robbin" }
  ]

  const fixingTypeOptions = [
    { value: 'random', label: "Random" },
    { value: 'manual', label: "Manual" },
    { value: 'sequential', label: "Sequential" },
    { value: 'top_vs_bottom', label: "Top vs bottom" }
  ]

  const selectSportOptions = sportsData?.map((item) => ({
    value: item?._id,
    label: item?.name,
    isDisabled: item?.status === 'DISABLED'
  }))

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#F6F7FB",
      boxShadow: state?.isFocused ? 'none' : provided.boxShadow,
      borderColor: state?.isFocused ? 'initial' : provided.borderColor,
      '&:hover': {
        borderColor: state?.isFocused ? 'initial' : provided.borderColor,
      },
    }),
  };

  return (
    <ThemeProvider value={selectCustomTheme}>
      <form onSubmit={handleCreateTournament}>
        <div className='flex justify-between py-[1.5rem] flex-wrap max-md:gap-[1rem]'>
          <div className='flex items-center gap-1'>
            <Link to="/tournaments">
              <p className='text-[#808080] text-base font-semibold cursor-pointer max-md:text-[15px]'>Tournaments</p>
            </Link>
            <FaGreaterThan className='text-[#808080]' />
            <p className='text-xl font-semibold max-lg:text-[18px] max-sm:!text-[15px]'>Add New Tournament</p>
          </div>
          <div className='flex justify-center items-center bg-[#FB6108] rounded-md text-white cursor-pointer px-4 py-2 max-sm:text-[13px] max-sm:px-3'>
            {isLoading ?
              <PulseLoader color="white" size={8} />
              :
              <button type='submit' className=''>
                Create Tournament
              </button>
            }
          </div>
        </div>
        <div className='w-[100%] flex gap-[3rem] items-baseline px-[2rem] py-[3rem] max-md:flex-col'>
          <div className='w-full flex flex-col gap-[1.5rem] flex-[1] md:flex-[2]'>
            <div>
              <Select
                placeholder='Main-Category'
                className=''
                styles={customStyles}
                value={mainCategorySport?.value ? mainCategorySport : ""}
                options={mainCategoryOptions}
                isClearable={true}
                onChange={(selectedOption) => setMainCategorySport(selectedOption)}
              >
              </Select>
              {
                !mainCategorySport?.value && showError && (
                  <p className='text-sm text-red-500'>*This field is required</p>
                )}
            </div>
            <div>
              <Select
                placeholder="Select Type"
                styles={customStyles}
                value={selectType?.value ? selectType : ""}
                options={selectTypeOptions}
                isClearable={true}
                onChange={(selectedOption) => setSelectType(selectedOption)}
              >
              </Select>
              {
                !selectType?.value && showError && (
                  <p className='text-sm text-red-500'>*This field is required</p>
                )}
            </div>
            <div>
              <Select
                placeholder='Select Format'
                styles={customStyles}
                value={selectFormat?.value ? selectFormat : ""}
                options={selectFormatOptions}
                isClearable={true}
                onChange={(selectedOption) => setSelectFormat(selectedOption)}
              >
              </Select>
              {
                !selectFormat?.value && showError && (
                  <p className='text-sm text-red-500'>*This field is required</p>
                )}
            </div>
            {/*Fixing Type*/}
            <div>
              <Select
                placeholder='Fixing Type'
                styles={customStyles}
                options={fixingTypeOptions}
                value={fixingType?.value ? fixingType : ""}
                isClearable={true}
                onChange={(selectedOption) => setFixingType(selectedOption)}
              >
              </Select>
              {
                !fixingType?.value && showError && (
                  <p className='text-sm text-red-500'>*This field is required</p>
                )}
            </div>
          </div>
          <div className='!w-full flex flex-col gap-[1.5rem] flex-[1] md:flex-[2]'>
            {/*Sub-Category*/}
            <div className='!w-full'>
              <Select
                placeholder="Sub-Category"
                className=''
                styles={customStyles}
                value={subSport?.value ? subSport : ""}
                options={subCategory}
                isClearable={true}
                onChange={(selectedOption) => setSubSport(selectedOption)}
              />
              {
                !subSport?.value && showError && (
                  <p className='text-sm text-red-500'>*This field is required</p>
                )}
            </div>
            {/*Participants*/}
            <div className='w-[100%]'>
              <input
                type="text"
                className="w-full font-[500] py-[0.6rem] px-[1rem] border bg-gray-50 placeholder:text-[#7F7F7F] rounded-md outline-none"
                placeholder={`${selectType?.value === "team" ? "Enter No.of Teams" : "Enter No.of Players"}`}
                value={participants}
                onChange={(e) => setPartcipants(e.target.value)}
              />
              {
                !participants && showError && (
                  <p className='text-sm text-red-500'>*This field is required</p>
                )}
            </div>
            {/*Select Sport */}
            <div>
              <Select
                placeholder='Select Sport'
                className='w-full'
                styles={customStyles}
                options={selectSportOptions}
                value={selectSport?.value ? selectSport : ""}
                isClearable={true}
                onChange={(selectedOption) => setSelectSport(selectedOption)}
              >
              </Select>
              {
                !selectSport?.value && showError && (
                  <p className='text-sm text-red-500'>*This field is required</p>
                )}
            </div>
          </div>
        </div>
      </form>
      <ToastContainer />
    </ThemeProvider>
  )
}
