import React from 'react'
import { NavLink, Link, useOutletContext } from 'react-router-dom';
import { Button, Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react';
import { Icon } from '@iconify/react';


export default function TournamentList() {
    const [customerId] = useOutletContext();

    return (
        <>
            <div className='flex gap-[25rem] items-center border-t border-[#EAECF0] pt-[1.5rem] pb-6 px-6'>
                <div className='flex items-center'>
                    <Link to="/customers"> <h2 className='text-[#808080] text-[1.3rem] font-semibold whitespace-nowrap'>Customers List </h2> </Link>
                    <Icon icon="f7:greaterthan" className='text-[#808080]' />
                    <div><span className="text-black text-xl font-semibold whitespace-nowrap">Manikanta Putta </span><span className="text-zinc-500 text-base font-normal font-['Inter'] leading-tight">(12345)</span></div>
                    <Icon icon="mdi:keyboard-arrow-down" />
                </div>
                <div className='flex items-center gap-[2rem] '>
                    <div className='flex items-center gap-[0.4rem] hover:text-primary cursor-pointer'>
                        <Icon
                            icon='material-symbols:delete'
                            className='text-[1.5rem]'
                        />
                        <span className='text-[1rem] font-medium'>Remove</span>
                    </div>
                    <div className='flex items-center gap-[0.3rem] hover:text-primary cursor-pointer'>
                        <span className={`bg-[#25BF17] h-[0.5rem] w-[0.5rem] rounded-full`}></span>
                        <p className='text-[1rem] font-medium whitespace-nowrap text-[#026039]'>Active</p>
                        <Icon
                            icon='mingcute:down-line'
                            className='text-[1.5rem]'
                        />
                    </div>
                    <div className="flex flex-row gap-[0.5rem] xs:gap-[1rem] items-center justify-center border border-[#CBCBCB] rounded-[5px]">
                        <div className="">
                            <Menu>
                                <MenuHandler>
                                    <Button className="text-[0.8rem] bg-[#FB6108] sm:text-[1rem] flex w-full flex-row justify-center whitespace-nowrap capitalize text-white  px-[0.4rem] xs:px-[1rem] py-[0.4rem] rounded-md items-center gap-[0.5rem] sm:gap-[0.8rem] hover:shadow-none shadow-none">
                                        <Icon
                                            icon="mdi:cloud-download"
                                            className="text-[1.5rem]"
                                        />
                                        <span className="font-[500]">Export</span>
                                    </Button>
                                </MenuHandler>
                                <MenuList>
                                    <MenuItem
                                        //  onClick={downloadPdf}
                                        className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                                    >
                                        {" "}
                                        <Icon
                                            icon="fa-solid:file-pdf"
                                            className="text-[#BE0A23] w-[1rem] h-auto"
                                        />
                                        Download as PDF
                                    </MenuItem>
                                    <hr className="my-[0.1rem]" />
                                    <MenuItem
                                        //  onClick={downloadCSV}
                                        className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                                    >
                                        <Icon
                                            icon="fa-solid:file-csv"
                                            className="text-[#29672E] w-[1rem] h-auto"
                                        />
                                        Download as CSV
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-b-[2px] border-[#c3c3c3] mx-6 flex justify-start items-start gap-[40px] text-xs">
                <NavLink to={`/customers/view/${customerId}`} end>
                    {({ isActive }) => (
                        <div className={`justify-center items-center gap-2 flex ${isActive ? "font-bold text-primary border-b-2 pb-[16px] border-primary" : "text-[#858585]"}`}>
                            <div className={` text-[20px] font-bold `}>Bookings</div>
                        </div>)}
                </NavLink>
                <NavLink to={`/customers/view/${customerId}/tournaments`} end>
                    {({ isActive }) => (
                        <div className={`justify-center items-center pb-[16px] gap-2 flex ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>

                            <div className={` text-[20px] font-bold `}>Tournaments</div>
                        </div>)}
                </NavLink>
                <NavLink to={`/customers/view/${customerId}/canteen`} end>
                    {({ isActive }) => (
                        <div className={`justify-center pb-[16px] items-center gap-2 flex ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                            <div className={` text-[20px] font-bold `}>Canteen</div>
                        </div>)}
                </NavLink>

            </div>
            <div className='flex flex-col gap-[0.5rem] mx-6 '>
                <div className='flex gap-[30rem] items-center'>
                    <div className='flex items-center gap-[16rem]'>
                        <h2 className='text-black text-[16px] font-[500] whitespace-nowrap'>Coming Soon ...</h2>
                    </div>
                </div>
            </div>
        </>
    )
}

