import { useState } from "react";
import CouponForm from "./CouponForm";
import DiscountForm from "./DiscountForm";

export default function AddNewOfferForm() {
    const [offerType, setOfferType] = useState(null);
  const handleOfferTypeChange = (value) => setOfferType(value);
  return (
    <div className="bg-white">
      {offerType === "Discount" || !offerType? (
        <DiscountForm
          handleOfferTypeChange={handleOfferTypeChange}
          offerType={offerType}
        />
      ) : (
        <CouponForm
          handleOfferTypeChange={handleOfferTypeChange}
          offerType={offerType}
        />
      )}
    </div>
  )
}
