import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link, NavLink, Outlet } from "react-router-dom";
import CustomCheckbox from "../../../../helpers/CustomCheckbox";
import {
  IconButton,
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import { useGetAllClubOffersQuery } from "../../../../store/features/clubOwner/clubOwnerApi";
import { useEffect, useState } from "react";
import DeleteRemarkModal from "./DeleteRemarkModal";
import CircleLoader from 'react-spinners/CircleLoader';
import DownloadExcel from '../../../../exportData/ExportExcel'
import { FaFileCsv } from "react-icons/fa6";
import { useAuth } from "../../../../store/AuthContext";
import { APIurls } from "../../../../api/apiConstant";
import moment from "moment";
import '../../../../App.css'

export default function CategoryDiscountList() {

  const [adminName, setAdminName] = useState("");

  const { getAccessToken, userRole } = useAuth();

  const handleEdit = () => {
    window.scrollTo({ top: 0 });
  };

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        color: "#4D4D4D",
        fontSize: "13px",
      },
    },
    head: {
      style: {
        background: "#FFF",
      },
    },

    rows: {
      style: {
        background: "#FFF",
        color: "#565656",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1100px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };

  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: false,
      width: "3rem",
    },
    {
      name: "Title",
      center: false,
      cell: (row) => <span>{row.title}</span>,
    },
    {
      name: "Coupon Code",
      cell: (row) => <span>{row.coupon}</span>,
      sortable: true,
      center: true,
    },
    {
      name: "Location",
      cell: (row) => (
        <div>
          {row.locations.map((item) => (
            <p>{item}</p>
          ))}
        </div>
      ),
      // sortable: true,
      center: false,
      grow: 2,
    },
    {
      name: "Clubs",
      selector: (row) => (
        <div>
          {row.clubIds.map((item) => (
            <p>{item.ClubName}</p>
          ))}
        </div>
      ),
      // sortable: true,
      center: false,
      grow: 3,
    },
    {
      name: "Category",
      selector: (row) => <div>{row?.categories?.map(item => (<p>{item.categoryName}</p>))}</div>,
      // sortable: true,
      center: false,
      grow: 2,
    },
    {
      name: "Sub-Category",
      selector: (row) => <div>{row?.subcategories?.map(item => (<p>{item.subCategoryName}</p>))}</div>,
      sortable: true,
      center: false,
      grow: 2,
    },
    {
      name: "Duration",
      selector: (row) => (
        <div>
          {row.endDate.split("T")[0].split("-")[2] -
            row.startDate.split("T")[0].split("-")[2]}{" "}
          Days
        </div>
      ),
      sortable: true,
      center: false,
    },
    {
      name: "DiscountType",
      selector: (row) => row.discountType,
    },
    {
      name: "Amount",
      selector: (row) => (
        <>
          <div>Discount: {row.discountDetails.amount}</div>
          <div>Maximum: {row.discountDetails.minPurchase}</div>
        </>
      ),
      grow: 2,
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
    },
    {
      name: "Offer Details",
      selector: (row) => (
        <>
          <div>Total Limit: {row.totalLimit}</div>
          <div>
            Limit for <br /> Each User: {row.eachUserLimit}
          </div>
        </>
      ),
      grow: 3,
    },
    {
      name: "Age Groups",
      selector: (row) => (
        <div>
          {row.age?.map(item => <p>{item.from}-{item.to}</p>)}
        </div>
      ),
    },
    {
      name: "Actions",
      id: "action",
      cell: (row) => (

        <div className="flex space-x-2">
          {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("UPDATE_OFFERS")) ?
            <NavLink onClick={handleEdit} to={`discount/edit/${row.offerId}`}>
              <IconButton className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]">
                <Icon
                  icon="material-symbols:edit-rounded"
                  className="text-[1.5rem] text-black"
                />
              </IconButton>
            </NavLink>
            :
            "-"
          }

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("DELETE_OFFERS")) &&
            <Link to={``}>
              <IconButton
                onClick={() => handleDeleteClubOffer(row?.offerId)}
                className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
              >
                <Icon
                  icon="material-symbols:delete-rounded"
                  className="text-[1.5rem] text-black"
                />
              </IconButton>
            </Link>
          }
        </div>
      ),
      center: false,
    },
  ];

  const [showDeleteClubOfferModal, setShowDeleteClubOfferModal] =
    useState(false);
  const [offerId, setOfferId] = useState(false);

  const handleDeleteClubOffer = (deleteOfferId) => {
    setShowDeleteClubOfferModal(true);
    setOfferId(deleteOfferId);
  };

  // Redux RTK Query Hooks
  const { data: clubOfferData, isLoading } = useGetAllClubOffersQuery();
  const clubOfferModifiedData = clubOfferData?.response?.offers;
  // console.log('All Data ===============> ', clubOfferModifiedData)

  const fetchAdminDetails = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.fetchAdminOrSubAdminDetails}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.message)
      }
      setAdminName(result?.response?.name)
    } catch (error) {
      console.log("Error in fetching admin details", error);
    }
  }

  useEffect(() => {
    fetchAdminDetails();
  }, [])

  const excelData = clubOfferModifiedData?.map((item, index) => [
    index + 1,
    item?.title,
    item?.coupon,
    item?.locations?.map((div) => div),
    item?.clubIds?.map((div) => div?.ClubName),
    item?.categories?.map((div) => div?.categoryName),
    item?.subcategories?.map((div) => div?.subCategoryName),
    item?.endDate.split("T")[0].split("-")[2] - item?.startDate.split("T")[0].split("-")[2] + "Days",
    item?.discountType,
    item?.discountDetails?.amount,
    item?.discountDetails?.minPurchase,
    item?.gender,
    item?.totalLimit,
    item?.eachUserLimit,
    item?.age?.map((item) => item.from + "-" + item.to),
  ])

  const excelColumns = ["SL", "Title", "Coupon Code", "Location", "Clubs", "Category", "Sub-Category", "Duration", "DiscountType", "Discount", "Maximum", "Gender", "Total Limit", "Limit for Each User", "Age Groups"]

  return (
    <>
      <Outlet />
      {(userRole?.role?.admin || userRole?.role?.subAdmin?.navbar?.includes("offers")) &&
        <div>
          <div className="flex gap-[3rem] border-b-2 border-[#989898] px-[1rem] pt-[0.5rem]">
            <NavLink to={`/offersAndBanners`}>
              {({ isActive }) => (
                <h2
                  className={`text-[1.2rem] sm:text-[1.3rem] font-bold whitespace-nowrap pb-[0.3rem] ${isActive
                    ? "font-bold text-primary border-b-2 border-primary"
                    : "text-[#858585]"
                    }`}
                >
                  Category Discount
                </h2>
              )}
            </NavLink>
          </div>

          <div className="flex flex-col gap-[0.5rem] pt-[1rem]">
            <div className="flex items-center justify-between">
              {/* <h2 className='text-black text-[1.3rem] font-semibold'>Support</h2> */}
              <div className="w-full flex justify-end items-center gap-[2rem]">
                <div className="relative">
                  <input
                    className="text-[0.8rem] w-[300px] p-2 outline-none border-[#DFDFDF] border-[1px] rounded-[6px]"
                    type="text"
                    placeholder="Search by ID or name or email or phone"
                  />
                  <p className="absolute right-[8px] top-[8px] bg-[#FB6108] p-[3px] rounded-sm text-white">
                    <Icon icon="ri:search-fill" />
                  </p>
                </div>
                <FaFileCsv
                  className='w-[25px] h-[30px] text-green-800 cursor-pointer'
                  onClick={() => DownloadExcel({ excelData, excelColumns })}
                />
              </div>
            </div>
            <div className="">
              <DataTable
                columns={columns}
                customStyles={customStyles}
                data={clubOfferModifiedData}
                pagination
                selectableRows
                paginationPerPage={10}
                selectableRowsComponent={CustomCheckbox}
                progressPending={isLoading}
              />

              {showDeleteClubOfferModal && (
                <DeleteRemarkModal
                  offerId={offerId}
                  setOfferId={setOfferId}
                  setShowDeleteClubOfferModal={setShowDeleteClubOfferModal}
                />
              )}
            </div>
          </div>
        </div>
      }
    </>
  );
}
