import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react';
import { ThreeDots } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import "react-phone-number-input/style.css";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../store/AuthContext';
import { APIurls } from '../../../api/apiConstant';
import { Link, useOutletContext } from "react-router-dom";
import { Option, Select, ThemeProvider } from '@material-tailwind/react';
import { selectCustomTheme } from '../../../helpers/constants';
import { useQuery } from 'react-query';
import { isValidPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input'
import PhoneInput from 'react-phone-number-input/input';


export default function UpdateSubStaff() {
  const [showError, setShowError] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [isMobileNumberValid, setIsMobileNumberValid] = useState(true);
  const [setUpdateStaffList] = useOutletContext();
  const [designationStatus, setDesignationStatus] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [loaderBtn, setLoaderBtn] = useState(false);
  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
  const { pathname } = useLocation();
  const { getAccessToken } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname])

  const fetchSubAdminRoles = async () => {
    const token = await getAccessToken()
    const response = await fetch(APIurls.fetchSubAdminRoles, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (!response.ok) {
      throw new Error("Failed to Fetch Roles");
    }
    const result = await response.json();
    console.log("Staff Roles...", result)
    return result?.subAdminPermission;
  };
  const { data: rolesData } = useQuery('updateRoles', fetchSubAdminRoles);

  const fetchSubadminById = async () => {
    const token = await getAccessToken()
    try {
      const response = await fetch(`${APIurls.fetchSubadminsById}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Error Fetching Private Lottery Data");
      }
      const result = await response.json();
      console.log("SUB ADMIN BY ID", result);
      const { name, email, MobileNumber, Designation, userRole } = result?.response?.subAdmin;
      setValue("name", name);
      setValue("email", email);
      setDesignationStatus(Designation);
      setMobileNumber(MobileNumber);
      const filteredPermissions = userRole?.role?.subAdmin?.permissions?.filter((item) => item !== "VIEW_SUB_ADMIN")
      setPermissions(filteredPermissions);
      console.log("Filtered Data", filteredPermissions)
    } catch (error) {
      console.warn(error.message);
      toast.error(error.message);
    }
  };
  useEffect(() => {
    fetchSubadminById();
  }, [id])

  const onSubmit = async (data, event) => {
    event.preventDefault();
    if (!(designationStatus && permissions.length !== 0 && isMobileNumberValid)) {
      setShowError(true);
      return;
    } else {
      setShowError(false);
    }
    setLoaderBtn(true)
    const { name } = data;
    const token = await getAccessToken();
    try {
      const response = await fetch(`${APIurls.editSubadmin}/${id}`, {
        method: 'PUT',
        body: JSON.stringify({
          "name": name,
          "designation": designationStatus,
          "mobile": mobileNumber,
          "permissions": permissions,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      });

      if (!response.ok) {
        setLoaderBtn(false)
        if (response.status === 400) {
          const result = await response.json();
          console.error("Error Updating Subadmin:", result.message);
          toast.error(result?.message)
        } else if (response.status === 404) {
          const result = await response.json();
          console.error("Error Updating Subadmin:", result.message);
          toast.error(result?.message)
        } else {
          console.log(response.status)
          throw new Error("Error Updating Subadmin");
        }
      } else {
        toast.success("Subadmin Successfully Updated!");
        setLoaderBtn(false)
        const result = await response.json();
        console.log(result);
        setUpdateStaffList(true);
        setDesignationStatus("");
        setMobileNumber(null);
        setPermissions([]);
        reset();
        setTimeout(() => navigate('/staff'), 2500)
      }
    } catch (error) {
      setLoaderBtn(false)
      toast.error("Error creating Lottery")
      console.warn(error);
    }
  }

  const handleCheckboxChange = (role) => {
    if (permissions.includes(role)) {
      setPermissions((prevPermissions) =>
        prevPermissions.filter((permission) => permission !== role)
      );
    } else {
      setPermissions((prevPermissions) => [...prevPermissions, role]);
    }
  }

  const formatKey = (key) => {
    return key.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
  };

  return (
    <div className="flex flex-col font-[500]">
      <div>
        <ThemeProvider value={selectCustomTheme}>
          {/* Upper Input Section */}
          <form onSubmit={handleSubmit(onSubmit)}>

            <div className="py-3 bg-white rounded-tl-[5px] rounded-tr-[5px] flex justify-between items-center mr-8">
              <div className="text-black text-xl font-semibold  leading-tight pl-9 ">Edit Staff</div>
              <div className="justify-start items-center gap-10 flex">
                {/* <Link to={"/staff"}>
                <button className="text-[0.8rem] sm:text-[1rem] border-[1.20px] border-orange-800 flex items-center justify-center max-xs:w-full  gap-[0.3rem] xs:gap-[0.5rem] text-orange-800 py-[0.5rem] px-[0.4rem] xs:px-[1rem] rounded-md">
                  <Icon icon="f7:lessthan" />
                  <span className="whitespace-nowrap">Create Staff</span>
                </button>
              </Link> */}
                {loaderBtn ? (
                  <ThreeDots
                    height="50"
                    width="50"
                    radius="9"
                    color="#FB6108"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  <button type='submit' className="text-[0.8rem] sm:text-[1rem] flex items-center justify-center max-xs:w-full gap-[0.3rem] xs:gap-[0.5rem] bg-primary text-white py-[0.5rem] px-[0.5rem] xs:px-[1rem] rounded-md">
                    Save Staff
                  </button>
                )
                }
              </div>
            </div>
            <div className='flex gap-[3rem] items-baseline px-[2rem]'>
              <div className='flex flex-col gap-[1.5rem] flex-[2]'>
                <div className="flex flex-col gap-[0.5rem]">
                  <input
                    {...register("name", {
                      required: "*This field is required.",
                    })}
                    className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                    type="text"
                    placeholder="User Name"
                    id="name"
                  />
                  {errors.name && (
                    <div className="text-sm text-[#E92215]">
                      {errors.name.message}
                    </div>
                  )}

                </div>
                <div className="flex flex-col gap-[0.5rem]">
                  <input
                    {...register("email", {
                      required: "*This field is required.",
                      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    })}
                    className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                    type="email"
                    placeholder="Email Address"
                    readOnly
                    id="email"
                  />
                </div>
              </div>
              <div className='flex flex-col gap-[1.5rem] flex-[2]'>
                <div className="flex flex-col gap-[0.5rem]">
                  <Select label='Enter Designation' color='blue-gray' size='lg' className='text-[0.75rem]  text-black sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none'
                    value={designationStatus}
                    onChange={(value) => setDesignationStatus(value)}
                  >
                    <Option value='Manager'>Manager</Option>
                    <Option value='Employee'>Employee</Option>
                    <Option value='Sub Admin'>Sub Admin</Option>
                  </Select>
                  {showError && !designationStatus && (
                    <p className="text-[#E92215] text-sm">
                      *This field is required.
                    </p>
                  )}

                </div>
                <div className="flex flex-col gap-[0.5rem]">
                  <PhoneInput
                    placeholder='Enter Your Mobile Number'
                    className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                    initialValueFormat="national"
                    type="text"
                    defaultCountry="IN"
                    value={formatPhoneNumberIntl(mobileNumber + '')}
                    onChange={(value) => {
                      if (isValidPhoneNumber(value + '')) {
                        setMobileNumber(value)
                        setIsMobileNumberValid(true)
                      } else {
                        setIsMobileNumberValid(false);
                      }
                    }}
                  />
                  {((showError && !mobileNumber) || !isMobileNumberValid) && <p className="text-[#E92215] text-sm">{(!isMobileNumberValid && showError) ? "Invalid mobile number" : showError ? "*This field is required." : ""}</p>}
                </div>
              </div>
            </div>

            <div className='flex flex-col gap-[0.5rem]'>

              {/* Heading for Permissions */}
              <div className="mt-12 ml-9  text-[0.8rem] sm:text-[1rem] text-[#858585] font-semibold flex justify-between items-center"> <span>  Permissions </span>
                {showError && permissions.length === 0 && (
                  <span className="text-[#E92215] text-sm">
                    *Atleast select one permission.
                  </span>
                )}
              </div>
              <div className='flex flex-col gap-[0.1rem]'>

                <div className='ml-8 flex-wrap rounded-[10px] items-center flex gap-[1rem]'>
                  {Object.keys(rolesData ? rolesData : {}).map((role) => (
                    <>
                      <div className="flex items-center gap-[0.5rem] border border-[#D9D9D9] text-[#858585]  rounded-md px-4 py-2">
                        <input
                          className="relative h-4 w-4 cursor-pointer rounded-md border border-blue-gray-200 "
                          type="checkbox"
                          id={role}
                          checked={permissions.includes(rolesData[role])}
                          onChange={() => handleCheckboxChange(rolesData[role])}
                        />
                        <label htmlFor={role} className="text-zinc-500 text-[16px] font-medium cursor-pointer">
                          {formatKey(role)}
                        </label>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </form>
        </ThemeProvider>
      </div>
      <ToastContainer />
    </div >
  )
}
