import React, { useEffect, useState } from "react";
import { IconButton } from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import { PiGreaterThan } from "react-icons/pi";
import { APIurls } from "../../../../api/apiConstant";
import { useAuth } from "../../../../store/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import { useQuery } from "react-query";
import { PulseLoader } from "react-spinners";

export default function EditVenueModal({
  setEditVenueModal,
  venueId,
  venueRefetch,
}) {
  const [venue, setVenue] = useState("");
  const [location, setLocation] = useState("");
  const [showError, setShowError] = useState(false);
  const { getAccessToken } = useAuth();
  const [dropDownList, setDropDownList] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  const fetchDefaultVenues = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.fetchAllDropDownVenues}?city=${location}&club=${venue}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.message);
      }
      setDropDownList(result?.response);
      return;
    } catch (error) {
      console.log("Error while fetching drop down data for venues", error?.message);
    }
  };

  const { data: defaultVenueData, refetch } = useQuery("defaultVenues", fetchDefaultVenues);

  useEffect(() => {
    refetch();
  }, [location]);

  const handleEditVenue = async (e) => {
    e.preventDefault();

    if ((venue === "") || (location === "")) {
      setShowError(true);
      return;
    }
    const body = {
      city: location,
      venueClubId: venue,
    };
    try {
      setLoader(true);
      const token = await getAccessToken();
      const res = await fetch(`${APIurls.editVenue}/${venueId}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const result = await res.json();
      if (!res.ok) {
        toast.error("Venue Not Updated");
      } else {
        venueRefetch();
        toast.success("Venue Updated Successfully");
        console.log("Updated Venue Data", result?.response)
        // setIsLoading(false);
        setTimeout(() => { setEditVenueModal(false) }, 1000)
        return;
      }
    } catch (error) {
      console.error("Error while updating venue", error?.message);
    }
    setLoader(false);
  };

  const fetchExistingVenueDetails = async () => {
    try {
      setIsLoading(true);
      const token = await getAccessToken();
      const res = await fetch(`${APIurls.specificVenue}/${venueId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await res.json();
      if (!res.ok) {
        throw new Error(result.message);
      }
      setLocation(result?.response?.venue?.city);
      setVenue(result?.response?.venue?.clubID);
      setIsLoading(false);
      // setStatusForVenue(true);
      return;
    } catch (error) {
      console.log("Error while fetching Existing Venue Details", error?.message);
    }
    // setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await fetchExistingVenueDetails();
    })()
  }, []);

  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-[#000000E6]">
      <div className="flex flex-col gap-4 bg-white rounded-sm px-6 py-8 w-[23%] relative">
        <div className="flex justify-between items-center">
          <h1 className="text-[20px] font-bold">Edit Venue</h1>
          <IconButton
            onClick={() => setEditVenueModal(false)}
            className={
              "shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-sm"
            }
            size="sm"
          >
            <Icon icon="material-symbols:close" className="text-[1.5rem]" />
          </IconButton>
        </div>
        {isLoading ? <div className="flex justify-center items-center">
          <RotatingLines
            visible={true}
            height="35"
            width="35"
            color="gray"
            strokeColor="#FB6108"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
          :
          <form onSubmit={handleEditVenue}>
            <div className="flex flex-col items-center gap-[1.5rem] mb-[2rem]">
              <select
                className="w-[100%] px-1 py-2 text-gray-700 bg-[#EFF1F9] border border-gray-300 rounded-md outline-none"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              >
                <option hidden>Select a city</option>
                {dropDownList?.cities?.map((div, i) => {
                  return <option value={div} key={i}>{div}</option>;
                })}
              </select>
              {location === "" && showError && (
                <p className="text-sm text-red-500">*This field is required</p>
              )}
              <select
                className="w-[100%] px-1 py-2 text-gray-700 bg-[#EFF1F9] border border-gray-300 rounded-md outline-none"
                value={venue}
                onChange={(e) => setVenue(e.target.value)}
              >
                <option hidden>Select Venue</option>
                {dropDownList?.clubs?.map((div) => {
                  return <option value={div?.clubId}>{div?.clubName}</option>;
                })}
              </select>
              {venue === "" && showError && (
                <p className="text-sm text-red-500">*This field is required</p>
              )}
              {/* </div> */}
            </div>
            <div className="w-[90px]">
              {loader ? <PulseLoader color="orange" size={8} />
                :
                <button
                  type="submit"
                  className="flex items-center justify-center text-white bg-[#FB6108] px-4 py-2 cursor-pointer rounded-md gap-1"
                >
                  Save
                  <PiGreaterThan />
                </button>
              }
            </div>
          </form>
        }
      </div>
      <ToastContainer />
    </div>
  );
}
