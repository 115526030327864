import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
export default function OffersContainer() {
  return (
    <div className="bg-white flex flex-col gap-[1rem] rounded-md">
        <Outlet />
      <ToastContainer />
    </div>
  );
}
