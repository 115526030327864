import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link, NavLink, Outlet } from 'react-router-dom';
import CustomCheckbox from '../../../../helpers/CustomCheckbox';

import {
   IconButton,
   Button,
   Menu,
   MenuHandler,
   MenuItem,
   MenuList,
   select,
} from "@material-tailwind/react";
import { useDeleteClubBannerMutation, useGetAllClubBannersQuery } from "../../../../store/features/clubOwner/clubOwnerApi";
import moment from "moment";
import { toast } from "react-toastify";
import { useAuth } from "../../../../store/AuthContext";
import { baseUrl } from "../../../../api/apiConstant";
import { useState } from "react";

export default function BannerList() {
   const { getAccessToken, userRole } = useAuth();
   const [isDeleted, setIsDeleted] = useState(false)
   /*==================> Reduxt RTk Query Hooks <=====================*/
   const { data: bannerDynamicData, isLoading, refetch } = useGetAllClubBannersQuery();
   const bannersArray = bannerDynamicData?.response?.banner || [];
   const [deleteBannerApi] = useDeleteClubBannerMutation();

   /*==================> Event Handlers <=====================*/
   const handleEdit = () => {
      window.scrollTo({ top: 0 })
   }

   const handleDelete = async (bannerId) => {
      const token = await getAccessToken();
      const result = await deleteBannerApi(bannerId);
      if (result.error) {
         return toast.error("Failed to delete banner.");
      }
      toast.success('Deleted banner successfully.');
   }


   const customStyles = {
      headRow: {
         style: {
            fontWeight: 600,
            // padding: "10px 10px",
            color: "#4D4D4D",
            fontSize: "13px",
         },
      },
      headRow: {
         style: {
            // borderRadius: "6px 6px 0px 0px",
            // borderTop: "0.824px solid #E9E9E9",
            // background: "#EFF1F999",
            // fontSize: "15px",
         },
      },

      rows: {
         style: {
            // borderRadius: "6px 6px 0px 0px",
            // borderTop: "0.824px solid #E9E9E9",
            // background: "#FFF",
            color: "#565656",
            // padding: "10px 10px",
            fontSize: "14px",
            fontWeight: 400,
            width: "auto",
         },
      },
      // pagination: {
      //    style: {
      //       boxShadow: "10px 5px 5px #ddd",
      //       marginBottom: "5px",
      //    },
      // },
      table: {
         style: {
            overflow: "visible",
            minWidth: "1100px",
         },
      },
      tableWrapper: {
         style: {
            overflow: "visible",
         },
      },
      responsiveWrapper: {
         style: {
            overflowX: "auto", // table scroll on x axis
         },
      },
   };


   const columns = [
      {
         name: "SL",
         selector: (row, index) => index + 1,
         center: false,
         maxWidth: "0.5rem",
         grow: 1,
      },
      {
         name: 'Image',
         cell: (row) => (
            <img className="w-[60px] h-[40px]" src={row.bannerImg} />
         ),
         center: false,
      },
      {
         name: 'Locations',
         selector: (row) => <div>{row.location.map(location => (<p>{location}</p>))}</div>,
         // sortable: true,
         center: false,
      },
      {
         name: "Name",
         selector: (row) => row.title,
         center: false
      },
      {
         name: 'Type of Banners',
         selector: (row) => row.bannerType,
         sortable: true,
         center: false,
      },
      // {
      //    name: "Offer",
      //    selector: (row)=>row.Offer,
      //    center: false
      // },
      // {
      //    name: 'Category',
      //    selector: (row) => row.category,
      //    sortable: true,
      //    center: false,
      // },
      // {
      //    name: "Banners to",
      //    selector: (row)=>row.Bannersto
      // },
      {
         name: 'Date',
         selector: (row) => moment(row.createdAt).format('DD/MM/YYYY'),
         sortable: true,
         center: false,
      },
      {
         name: "Actions",
         id: "action",
         cell: (row) => (
            <div className="flex space-x-2">

               {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("UPDATE_BANNERS")) ?
                  <Link onClick={handleEdit} to={`edit/${row.bannerId}`}>
                     <IconButton
                        className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
                     >
                        <Icon
                           icon="material-symbols:edit-rounded"
                           className="text-[1.5rem] text-black"
                        />
                     </IconButton>
                  </Link>
                  :
                  "-"
               }

               {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("DELETE_BANNERS")) ?
                  <IconButton
                     onClick={() => handleDelete(row.bannerId)}
                     className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
                  >
                     <Icon
                        icon="material-symbols:delete-rounded"
                        className="text-[1.5rem] text-black"
                     />
                  </IconButton>
                  :
                  "-"
               }
            </div>
         ),
         center: false,
         grow: 1,
      },
   ];


   return (
      <div className='flex flex-col gap-[0.5rem] border-t-[2px] pt-3 border-[#EFF1F999]'>
         <div className='flex items-center justify-between'>
            <h2 className='text-black text-[1.3rem] font-semibold'>Manage Banner</h2>
            <div className='flex items-center gap-[2rem]'>
               <div className='flex items-center gap-[0.4rem] hover:text-primary cursor-pointer'>
                  <Icon
                     icon='material-symbols:delete'
                     className='text-[1.5rem]'
                  />
                  <span className='text-[1rem] font-medium'>Remove</span>
               </div>
               <div className='flex items-center gap-[0.3rem] hover:text-[#FB6108] cursor-pointer'>
                  <p className='text-[1rem] font-medium'>Change Status</p>
                  <Icon
                     icon='mingcute:down-line'
                     className='text-[1.5rem]'
                  />
               </div>
            </div>
         </div>
         <div>
            <DataTable
               columns={columns}
               customStyles={customStyles}
               data={bannersArray}
               pagination
               selectableRows
               paginationPerPage={10}
               selectableRowsComponent={CustomCheckbox}
               progressPending={isLoading}
            />
         </div>
      </div>
   )
}


