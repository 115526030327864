import { useEffect, useState } from "react";
import Select, { components } from "react-select";
import DatePicker from "react-flatpickr";
import {
  Checkbox,
  IconButton,
  Select as MaterialTailwindSelect,
  ThemeProvider,
} from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import { selectCustomTheme } from "../../../../helpers/constants";
import { Switch } from "@material-tailwind/react";
import { LuPlus } from "react-icons/lu";

import moment from "moment";
import { toast } from "react-toastify";
import ThreeDotsLoading from "../../../ui/ThreeDotsLoading";
import {
  useGetSingleClubOfferQuery,
  useUpdateClubOfferMutation,
} from "../../../../store/features/clubOwner/clubOwnerApi";
import { Link, useNavigate, useParams } from "react-router-dom";

export default function EditClubOfferForm({
  handleOfferTypeChange,
  offerType,
}) {
  const { id } = useParams();
  const navigate = useNavigate();
  /*========================== Local States =====================*/
  const [title, setTitle] = useState("");
  const [coupon, setCoupon] = useState("");

  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedClubs, setSelectedClubs] = useState([]);
  const [selectedMainCats, setSelectedMainCats] = useState([]);
  const [selectedSubCats, setSelectedSubCats] = useState([]);

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [discountType, setDiscountType] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState("");
  const [gender, setGender] = useState("");
  const [totalLimit, setTotalLimit] = useState("");
  const [eachUserLimit, setEachUserLimit] = useState("");
  const [showError, setShowError] = useState(false);


  const [ageGroups, setAgeGroups] = useState([]);
  const [selectedAgeGroups, setSelectedAgeGroups] = useState([]);
  const [showAgeGroupError, setShowAgeGroupError] = useState(false);
  const [ageGroupErrorMsg, setAgeGroupErrorMsg] = useState("");
  const [checkAllAgeGroups, setCheckAllAgeGroups] = useState(false)


  /*========================== Redux RTK Query Hooks =====================*/
  const { data: singleOfferData } = useGetSingleClubOfferQuery(id);

  const {
    clubIds,
    categories,
    subcategories,
    title: offerTitle,
    startDate: offerStartDate,
    endDate: offerEndDate,
    discountType: offerDiscountType,
    discountDetails: {
      maxDiscount: offerMaxDiscount,
      amount: offerAmount,
    } = {},
    age,
    coupon: offerCoupon,
    totalLimit: offerTotalLimit,
    eachUserLimit: offerEachUserLimit,
    gender: offerGender,
    discountDetails: { amount, minPurchase } = {},
    locations,
  } = singleOfferData?.response?.offer || {};

  const allClubs = clubIds?.map((item) => ({
    clubId: item._id,
    clubName: item.ClubName,
  }));

  const mainCategoriesData = categories?.map((item) => ({
    categoryId: item._id,
    categoryName: item.categoryName,
  }));
  const subCategoriesData = subcategories?.map((item) => ({
    categoryId: item._id,
    categoryName: item.subCategoryName,
  }));

  //Create Dynamic Options
  const dynamicLocationsOptions = locations?.map((item) => ({
    value: item,
    label: item,
  }));
  const dynamicClubsOptions = allClubs?.map((item) => ({
    value: item.clubName,
    label: item.clubName,
    clubId: item.clubId,
  }));
  const dynamicMainCategoriesOptions = mainCategoriesData?.map((item) => ({
    value: item.categoryName,
    label: item.categoryName,
    categoryId: item.categoryId,
  }));
  const dynamicSubCategoriesOptions = subCategoriesData?.map((item) => ({
    value: item.categoryName,
    label: item.categoryName,
    categoryId: item.categoryId,
  }));

  const [updateClubOfferApi, { isLoading }] = useUpdateClubOfferMutation();

  //Initially populate the input fields
  useEffect(() => {
    setTitle(offerTitle);
    setStartDate(new Date(offerStartDate));
    setEndDate(new Date(offerEndDate));
    setDiscountAmount(offerAmount);
    setDiscountType({ value: offerDiscountType, label: offerDiscountType });
    setAgeGroups(age)
    setMaxAmount(amount);
    setTotalLimit(offerTotalLimit);
    setEachUserLimit(offerEachUserLimit);
    setCoupon(offerCoupon);
    setGender({ value: offerGender, label: offerGender });
    setSelectedLocations(dynamicLocationsOptions || []);
    setSelectedClubs(dynamicClubsOptions || []);
    setSelectedMainCats(dynamicMainCategoriesOptions || []);
    setSelectedSubCats(dynamicSubCategoriesOptions || []);
  }, [id, singleOfferData]);

  /*========================== Event Handlers =====================*/
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !title ||
      !coupon ||
      !startDate ||
      !endDate ||
      !discountType ||
      !discountAmount ||
      !maxAmount ||
      !totalLimit ||
      !eachUserLimit ||
      !selectedLocations.length ||
      !selectedMainCats.length ||
      !selectedClubs.length ||
      !selectedSubCats.length ||
      !selectedAgeGroups.length
    ) {
      return setShowError(true);
    }
    const updateData = {
      title,
      coupon,
      locations: selectedLocations?.map((item) => item.value),
      clubIds: selectedClubs?.map((item) => item.clubId),
      categories: selectedMainCats?.map((item) => item.categoryId),
      subcategories: selectedSubCats?.map((item) => item.categoryId),
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(endDate).toISOString(),
      discountType: discountType?.value?.toUpperCase(),
      discountDetails: {
        amount: discountAmount,
        minPurchase: 50,
      },
      gender: gender?.value?.toLowerCase(),
      totalLimit,
      eachUserLimit,
      age: selectedAgeGroups
    };
    // console.log("Form Data to update ====> ", updateData);
    const result = await updateClubOfferApi({ updateData, clubOfferId: id });
    console.log("Update Club Offer Api Response ========> ", result);
    if (result?.error) {
      return toast.error(`${result.error.data.message}`);
    }
    toast.success("Updated offer successfully !");

    /*==================> Clear the form fields <================*/
    setTitle("");
    setStartDate(null);
    setEndDate(null);
    setDiscountAmount("");
    setDiscountType(null);
    setFrom(null);
    setTo(null);
    setMaxAmount("");
    setTotalLimit("");
    setEachUserLimit("");
    setCoupon("");
    setGender(null);
    setSelectedLocations([]);
    setSelectedClubs([]);
    setSelectedMainCats([]);
    setSelectedSubCats([]);
    setSelectedAgeGroups([]);
    setAgeGroups([]);

    //Navigate to the back
    navigate("/offersAndBanners");

    // Scroll to the bottom after update
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = window.innerHeight;
    window.scroll({
      top: scrollHeight - clientHeight,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleGenerateCouponCode = () => {
    const uniqueId =
      Date.now().toString(36) + Math.random().toString(36).substr(2);
    setCoupon(uniqueId);
  };

  // Age Groups Handlers 
  const handleAddMoreAgeGroup = () => {
    //check if both from and to age range selected
    if (!from?.value || !to?.value) {
      setShowAgeGroupError(true);
      return setAgeGroupErrorMsg(
        "Thes fields are required !"
      );
    }

    // check if from age is greater than to age
    if (from?.value >= to?.value) {
      setShowAgeGroupError(true);
      return setAgeGroupErrorMsg(
        "To age must be greater than from age !"
      );
    }

    // check if ageGroup already exists
    const isAgeGroupExist = ageGroups.find(
      (item) => item.from === from.value && item.to === to.value
    );
    if (isAgeGroupExist) {
      setShowAgeGroupError(true);
      return setAgeGroupErrorMsg("This age group is already added !");
    }

    setAgeGroups([...ageGroups, { from: from?.value, to: to?.value }]);
    setFrom(null);
    setTo(null);
    setShowAgeGroupError(false);
  };

  const handleSelectAgeGroup = (e, currentAgeGroup) => {
    const isSelected = e.target.checked;

    // push if the selectedAgeGroup is empty 
    if (selectedAgeGroups.length === 0 && isSelected) {
      setSelectedAgeGroups([currentAgeGroup])
    }

    // check if age group is already exists 
    const isExistSelectedAgeGroup = selectedAgeGroups?.find(item => item?.from === currentAgeGroup?.from && item?.to === currentAgeGroup?.to);
    if (isExistSelectedAgeGroup && !isSelected) {
      const filteredGroups = selectedAgeGroups?.filter(item => item?.from !== currentAgeGroup?.from && item?.to !== currentAgeGroup?.to);
      setSelectedAgeGroups(filteredGroups)
    } else {
      setSelectedAgeGroups([...selectedAgeGroups, currentAgeGroup])
    }
    setShowAgeGroupError(false)
  };

  const handleCheckAllAgeGroups = (e) => {
    const isSelectedAllAgeGroups = e.target.checked;
    setCheckAllAgeGroups(isSelectedAllAgeGroups)
    isSelectedAllAgeGroups ? setSelectedAgeGroups(ageGroups) : setSelectedAgeGroups([]);
    setShowAgeGroupError(false)
  }

  const handleDeleteAgeGroups = () => {
    setSelectedAgeGroups([]);
    setAgeGroups([]);
    setShowAgeGroupError(false)
  }


  const fromAgesOptions = [
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
    { value: "15", label: "15" },
    { value: "16", label: "16" },
    { value: "17", label: "17" },
    { value: "18", label: "18" },
    { value: "19", label: "19" },
    { value: "20", label: "20" },
    { value: "21", label: "21" },
    { value: "22", label: "22" },
    { value: "23", label: "23" },
    { value: "24", label: "24" },
    { value: "25", label: "25" },
    { value: "26", label: "26" },
    { value: "27", label: "27" },
    { value: "28", label: "28" },
    { value: "29", label: "29" },
    { value: "30", label: "30" },
    { value: "31", label: "31" },
    { value: "32", label: "32" },
    { value: "33", label: "33" },
    { value: "34", label: "34" },
    { value: "35", label: "35" },
    { value: "36", label: "36" },
    { value: "37", label: "37" },
    { value: "38", label: "38" },
    { value: "39", label: "39" },
    { value: "40", label: "40" },
    { value: "41", label: "41" },
    { value: "42", label: "42" },
    { value: "43", label: "43" },
    { value: "44", label: "44" },
    { value: "45", label: "45" },
    { value: "46", label: "46" },
    { value: "47", label: "47" },
    { value: "48", label: "48" },
    { value: "49", label: "49" },
    { value: "50", label: "50" },
    { value: "51", label: "51" },
    { value: "52", label: "52" },
    { value: "53", label: "53" },
    { value: "54", label: "54" },
    { value: "55", label: "55" },
    { value: "56", label: "56" },
    { value: "57", label: "57" },
    { value: "58", label: "58" },
    { value: "59", label: "59" },
    { value: "60", label: "60" },
    { value: "61", label: "61" },
    { value: "62", label: "62" },
    { value: "63", label: "63" },
    { value: "64", label: "64" },
    { value: "65", label: "65" },
    { value: "66", label: "66" },
    { value: "67", label: "67" },
    { value: "68", label: "68" },
    { value: "69", label: "69" },
    { value: "70", label: "70" },
    { value: "71", label: "71" },
    { value: "72", label: "72" },
    { value: "73", label: "73" },
    { value: "74", label: "74" },
    { value: "75", label: "75" },
    { value: "76", label: "76" },
    { value: "77", label: "77" },
    { value: "78", label: "78" },
    { value: "79", label: "79" },
    { value: "80", label: "80" },
    { value: "81", label: "81" },
    { value: "82", label: "82" },
    { value: "83", label: "83" },
    { value: "84", label: "84" },
    { value: "85", label: "85" },
    { value: "86", label: "86" },
    { value: "87", label: "87" },
    { value: "88", label: "88" },
    { value: "89", label: "89" },
    { value: "90", label: "90" },
    { value: "91", label: "91" },
    { value: "92", label: "92" },
    { value: "93", label: "93" },
    { value: "94", label: "94" },
    { value: "95", label: "95" },
    { value: "96", label: "96" },
    { value: "97", label: "97" },
    { value: "98", label: "98" },
    { value: "99", label: "99" },
    { value: "100", label: "100" },
  ];

  const toAgesOptions = [
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
    { value: "15", label: "15" },
    { value: "16", label: "16" },
    { value: "17", label: "17" },
    { value: "18", label: "18" },
    { value: "19", label: "19" },
    { value: "20", label: "20" },
    { value: "21", label: "21" },
    { value: "22", label: "22" },
    { value: "23", label: "23" },
    { value: "24", label: "24" },
    { value: "25", label: "25" },
    { value: "26", label: "26" },
    { value: "27", label: "27" },
    { value: "28", label: "28" },
    { value: "29", label: "29" },
    { value: "30", label: "30" },
    { value: "31", label: "31" },
    { value: "32", label: "32" },
    { value: "33", label: "33" },
    { value: "34", label: "34" },
    { value: "35", label: "35" },
    { value: "36", label: "36" },
    { value: "37", label: "37" },
    { value: "38", label: "38" },
    { value: "39", label: "39" },
    { value: "40", label: "40" },
    { value: "41", label: "41" },
    { value: "42", label: "42" },
    { value: "43", label: "43" },
    { value: "44", label: "44" },
    { value: "45", label: "45" },
    { value: "46", label: "46" },
    { value: "47", label: "47" },
    { value: "48", label: "48" },
    { value: "49", label: "49" },
    { value: "50", label: "50" },
    { value: "51", label: "51" },
    { value: "52", label: "52" },
    { value: "53", label: "53" },
    { value: "54", label: "54" },
    { value: "55", label: "55" },
    { value: "56", label: "56" },
    { value: "57", label: "57" },
    { value: "58", label: "58" },
    { value: "59", label: "59" },
    { value: "60", label: "60" },
    { value: "61", label: "61" },
    { value: "62", label: "62" },
    { value: "63", label: "63" },
    { value: "64", label: "64" },
    { value: "65", label: "65" },
    { value: "66", label: "66" },
    { value: "67", label: "67" },
    { value: "68", label: "68" },
    { value: "69", label: "69" },
    { value: "70", label: "70" },
    { value: "71", label: "71" },
    { value: "72", label: "72" },
    { value: "73", label: "73" },
    { value: "74", label: "74" },
    { value: "75", label: "75" },
    { value: "76", label: "76" },
    { value: "77", label: "77" },
    { value: "78", label: "78" },
    { value: "79", label: "79" },
    { value: "80", label: "80" },
    { value: "81", label: "81" },
    { value: "82", label: "82" },
    { value: "83", label: "83" },
    { value: "84", label: "84" },
    { value: "85", label: "85" },
    { value: "86", label: "86" },
    { value: "87", label: "87" },
    { value: "88", label: "88" },
    { value: "89", label: "89" },
    { value: "90", label: "90" },
    { value: "91", label: "91" },
    { value: "92", label: "92" },
    { value: "93", label: "93" },
    { value: "94", label: "94" },
    { value: "95", label: "95" },
    { value: "96", label: "96" },
    { value: "97", label: "97" },
    { value: "98", label: "98" },
    { value: "99", label: "99" },
    { value: "100", label: "100" },
  ];




  const locationOptions = [
    { value: "select-all-locations", label: "Select All Locations" },
    { value: "Hyderabad", label: "Hyderabad" },
    { value: "Bangalore", label: "Bangalore" },
    { value: "Chennai", label: "Chennai" },
    { value: "Noida", label: "Noida" },
  ];

  function getCategories(selectedClubs) {
    const mainCategories = [];
    const subCategories = [];

    return { mainCategories, subCategories };
  }

  let clubOptions =
    allClubs?.map((club) => {
      return {
        value: club.clubName,
        label: club.clubName,
        clubId: club.clubId,
      };
    }) || [];

  const mainCategoryOptions =
    mainCategoriesData?.map((item) => ({
      value: item.categoryName,
      label: item.categoryName,
      categoryId: item.categoryId,
    })) || [];
  const subCategoryOptions =
    subCategoriesData?.map((item) => ({
      value: item.categoryName,
      label: item.categoryName,
      categoryId: item.categoryId,
    })) || [];

  const discountOptions = [
    { value: "Percentage", label: "Percentage" },
    { value: "Amount", label: "Amount" },
  ];

  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Others", label: "Others" },
  ];

  const CheckboxOption = ({ children, ...props }) => {
    const { value } = props.data;

    const handleCheckboxChange = (event) => {
      console.log(event, "popin2");
      if (value?.startsWith("select-all-")) {
        props.selectProps.onSelectAll(value, event.target.checked);
      } else {
        props.selectOption(props.data);
      }
    };

    if (value?.startsWith("select-all-")) {
      return (
        <components.Option {...props}>
          <button
            type="button"
            onClick={() =>
              handleCheckboxChange({ target: { checked: !props.isSelected } })
            }
          >
            {children}
          </button>
        </components.Option>
      );
    }

    return (
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={handleCheckboxChange}
        />{" "}
        {children}
      </components.Option>
    );
  };

  const handleChange = (selected, optionType) => {
    const includesSelectAll = selected.some(
      (option) => option.value === `select-all-${optionType}`
    );

    if (includesSelectAll) {
      if (optionType === "locations") {
        setSelectedLocations(
          locationOptions.filter(
            (option) => option.value !== "select-all-locations"
          )
        );
      } else if (optionType === "clubs") {
        setSelectedClubs(
          clubOptions.filter((option) => option.value !== "select-all-clubs")
        );
      } else if (optionType === "main-categories") {
        setSelectedMainCats(
          mainCategoryOptions.filter(
            (option) => option.value !== "select-all-main-categories"
          )
        );
      } else if (optionType === "sub-categories") {
        setSelectedSubCats(
          subCategoryOptions.filter(
            (option) => option.value !== "select-all-sub-categories"
          )
        );
      }
    } else {
      if (optionType === "locations") {
        setSelectedLocations(selected || []);
        console.log("Selected Checkbox", optionType);
      } else if (optionType === "clubs") {
        setSelectedClubs(selected || []);
      } else if (optionType === "main-categories") {
        setSelectedMainCats(selected || []);
      } else if (optionType === "sub-categories") {
        setSelectedSubCats(selected || []);
      }
    }
  };

  const handleSelectAll = (optionType, isChecked) => {
    console.log(optionType, isChecked, "Checkbox Selected or not");
    if (isChecked) {
      if (optionType === "locations") {
        setSelectedLocations(
          locationOptions.filter(
            (option) => option.value !== "select-all-locations"
          )
        );
      } else if (optionType === "clubs") {
        setSelectedClubs(
          clubOptions.filter((option) => option.value !== "select-all-clubs")
        );
      } else if (optionType === "main-categories") {
        setSelectedMainCats(
          mainCategoryOptions.filter(
            (option) => option.value !== "select-all-main-categories"
          )
        );
      } else if (optionType === "sub-categories") {
        setSelectedSubCats(
          subCategoryOptions.filter(
            (option) => option.value !== "select-all-sub-categories"
          )
        );
      }
    } else {
      if (optionType === "locations") {
        setSelectedLocations([]);
      } else if (optionType === "clubs") {
        setSelectedClubs([]);
      } else if (optionType === "main-categories") {
        setSelectedMainCats([]);
      } else if (optionType === "sub-categories") {
        setSelectedSubCats([]);
      }
    }
  };

  const isAllLocationsSelected =
    selectedLocations.length === locationOptions.length - 1;
  const isAllClubsSelected = selectedClubs.length === clubOptions.length - 1;
  const isAllMainCatSelected =
    selectedMainCats.length === mainCategoryOptions.length - 1;
  const isAllSubCatsSelected =
    selectedSubCats.length === subCategoryOptions.length - 1;

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#F6F7FB",
    }),
  };

  return (
    <ThemeProvider value={selectCustomTheme}>
      <form>
        {/* Form Header */}
        <div className="flex items-center justify-between mt-[0.1rem] mb-[-5px] ml-[-10px]">
          <div className="text-black text-xl font-semibold leading-tight">
            Edit Offer
          </div>
          <div className="flex items-center gap-10">
            <Link to="/offersAndBanners">
              <button className="bg-[#FB6108] flex items-center justify-center gap-3 w-[120px] h-[40px] rounded-md text-white">
                <Icon icon="weui:back-filled" width="1.2em" height="1.2em" />
                <span>Back</span>
              </button>
            </Link>

            <button
              disabled={isLoading}
              className="flex items-center justify-between bg-[#FB6108] w-[120px] h-[40px] text-white px-5 py-2 rounded-md"
              onClick={handleSubmit}
            >
              {isLoading ? (
                <ThreeDotsLoading />
              ) : (
                <p className="flex items-center justify-between w-full h-full">
                  <span>Update</span>
                  <Icon icon="flowbite:angle-right-outline" />
                </p>
              )}
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-[90%] ml-[20px] my-[2rem]">
          <div className="flex flex-col space-x-1">
            <input
              className="h-[2.63rem] text-[0.75rem] sm:text-[1rem] px-[1rem] font-medium bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none w-full"
              type="text"
              placeholder="Title of the Offer *"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            {showError && !title && (
              <p className="text-red-500 text-[14px]">
                This field is required*
              </p>
            )}
          </div>

          <div>
            <div className="flex items-center relative">
              <input
                className="w-full h-[2.63rem] text-[0.75rem] sm:text-[1rem] px-[1rem] font-medium bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                type="text"
                placeholder="Coupon Code *"
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
              <div
                onClick={handleGenerateCouponCode}
                className="text-[13px] cursor-pointer text-orange-800 border-2 border-orange-800 rounded-md absolute right-0 top-0 mt-[0.4rem] p-1"
              >
                Auto Generate Code
              </div>
            </div>
            {showError && !coupon && (
              <p className="text-red-500 text-[14px]">
                This field is required*
              </p>
            )}
          </div>

          <div>
            <Select
              placeholder="Location"
              options={locationOptions}
              isMulti
              components={{
                Option: (props) => (
                  <CheckboxOption
                    {...props}
                    selectProps={{
                      onSelectAll: (isChecked) =>
                        handleSelectAll("locations", isChecked),
                    }}
                  />
                ),
              }}
              value={selectedLocations}
              onChange={(selected) => handleChange(selected, "locations")}
              isAllSelected={isAllLocationsSelected}
              styles={customStyles}
            />
            {showError && !selectedLocations.length && (
              <p className="text-red-500 text-[14px]">
                This field is required*
              </p>
            )}
          </div>

          <div>
            <Select
              placeholder="Select All Clubs"
              options={clubOptions}
              isMulti
              components={{
                Option: (props) => (
                  <CheckboxOption
                    {...props}
                    selectProps={{
                      onSelectAll: (isChecked) =>
                        handleSelectAll("clubs", isChecked),
                    }}
                  />
                ),
              }}
              value={selectedClubs}
              onChange={(selected) => handleChange(selected, "clubs")}
              isAllSelected={isAllClubsSelected}
              styles={customStyles}
            />
            {showError && !selectedClubs.length && (
              <p className="text-red-500 text-[14px]">
                This field is required*
              </p>
            )}
          </div>

          <div>
            <Select
              placeholder="Main Category"
              options={mainCategoryOptions}
              isMulti
              components={{
                Option: (props) => (
                  <CheckboxOption
                    {...props}
                    selectProps={{
                      onSelectAll: (isChecked) =>
                        handleSelectAll("main-categories", isChecked),
                    }}
                  />
                ),
              }}
              value={selectedMainCats}
              onChange={(selected) => handleChange(selected, "main-categories")}
              isAllSelected={isAllMainCatSelected}
              styles={customStyles}
            />
            {showError && !selectedMainCats.length && (
              <p className="text-red-500 text-[14px]">
                This field is required*
              </p>
            )}
          </div>

          <div>
            <Select
              placeholder="Sub Category"
              options={subCategoryOptions}
              isMulti
              // closeMenuOnSelect={false}
              // hideSelectedOptions={false}
              components={{
                Option: (props) => (
                  <CheckboxOption
                    {...props}
                    selectProps={{
                      onSelectAll: (isChecked) =>
                        handleSelectAll("sub-categories", isChecked),
                    }}
                  />
                ),
              }}
              value={selectedSubCats}
              onChange={(selected) => handleChange(selected, "sub-categories")}
              isAllSelected={isAllSubCatsSelected}
              styles={customStyles}
            />
            {showError && !selectedSubCats.length && (
              <p className="text-red-500 text-[14px]">
                This field is required*
              </p>
            )}
          </div>

          <div className="space-y-[1.5rem]">
            <div>
              <div className="relative">
                <DatePicker
                  className="h-[2.63rem] outline-none placeholder-[#7F7F7F] rounded-lg pl-3 text-[1rem] font-medium text-black bg-[#EFF1F999] w-full"
                  placeholder="Start Date"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  value={startDate}
                />
                <Icon
                  className="absolute right-3 top-[0.8rem]"
                  icon="uiw:date"
                />
              </div>
              {showError && !startDate && (
                <p className="text-red-500 text-[14px]">
                  This field is required*
                </p>
              )}
            </div>
            <div>
              <Select
                placeholder="Discount Type"
                options={discountOptions}
                styles={customStyles}
                onChange={(value) => setDiscountType(value)}
                value={discountType}
              />
              {showError && !discountType && (
                <p className="text-red-500 text-[14px]">
                  This field is required*
                </p>
              )}
            </div>

            <div>
              <input
                className="h-[2.63rem] text-[0.75rem] sm:text-[1rem] font-medium px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none w-full"
                type="number"
                placeholder="Maximum Amount"
                value={maxAmount}
                onChange={(e) => setMaxAmount(e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
              {showError && !maxAmount && (
                <p className="text-red-500 text-[14px]">
                  This field is required*
                </p>
              )}
            </div>

            <div>
              <input
                className="h-[2.63rem] text-[0.75rem] sm:text-[1rem] font-medium px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none w-full"
                type="number"
                placeholder="Total limit for the Offer"
                value={eachUserLimit}
                onChange={(e) => setEachUserLimit(e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
              {showError && !eachUserLimit && (
                <p className="text-red-500 text-[14px]">
                  This field is required*
                </p>
              )}
            </div>
          </div>

          <div className="space-y-[1.5rem]">
            <div>
              <div className="relative">
                <DatePicker
                  className="h-[2.63rem] outline-none placeholder-[#7F7F7F] rounded-lg pl-3 text-[1rem] font-medium text-black bg-[#EFF1F999] w-full"
                  placeholder="Expiry Date"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  value={endDate}
                />
                <Icon
                  className="absolute right-3 top-[0.8rem]"
                  icon="uiw:date"
                />
              </div>
              {showError && !endDate && (
                <p className="text-red-500 text-[14px]">
                  This field is required*
                </p>
              )}
            </div>

            <div>
              <input
                className="h-[2.63rem] text-[0.75rem] sm:text-[1rem] font-medium px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none w-full"
                type="number"
                placeholder="Discount Amount"
                value={discountAmount}
                onChange={(e) => setDiscountAmount(e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
              {showError && !discountAmount && (
                <p className="text-red-500 text-[14px]">
                  This field is required*
                </p>
              )}
            </div>

            <div className="space-y-[1.5rem]">
              <Select
                placeholder="Gender"
                options={genderOptions}
                styles={customStyles}
                value={gender}
                onChange={(value) => setGender(value)}
              />
              <div>
                <input
                  className="h-[2.63rem] text-[0.75rem] sm:text-[1rem] font-medium px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none w-full"
                  type="number"
                  placeholder="Limit for each user"
                  value={totalLimit}
                  onChange={(e) => setTotalLimit(e.target.value)}
                  onWheel={(e) => e.target.blur()}
                />
                {showError && !totalLimit && (
                  <p className="text-red-500 text-[14px]">
                    This field is required*
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Age Groups  */}
        {/* <div>
          <div className="flex justify-between pb-2">
            <p className="text-[#7F7F7F] font-semibold">Age Groups</p>
            <div className="flex gap-6">
              <p className="text-[#7F7F7F] font-normal">All Age Groups</p>
              <Switch color="orange" />
            </div>
          </div>
          <div className="flex justify-between gap-4 border border-[#D9D9D9]">
            <div className="flex flex-col gap-1 w-full">
              <div className="flex gap-4 p-3">
                <Select
                  onChange={(value) => setFrom(value)}
                  placeholder="From"
                  options={fromAgesOptions}
                  value={from}
                />
                <Select
                  onChange={(value) => setTo(value)}
                  placeholder="To"
                  options={toAgesOptions}
                  value={to}
                />
              </div>
              <div className="w-[6rem] flex justify-center items-center m-3 border border-[#D9D9D9] rounded-md">
                <p>18-24</p>
                <Checkbox color="orange" />
              </div>
            </div>
            <div className="flex gap-2 p-3">
              <IconButton className="shadow-none hover:shadow-none focus:shadow-none bg-white text-orange-800 border border-[#D9D9D9] rounded-lg">
                <Icon
                  icon="material-symbols:delete-rounded"
                  className="text-[1.5rem]"
                />
              </IconButton>
              <div>
                <button
                  disabled
                  className="w-[7rem] p-2 flex items-center justify-center text-white rounded-md bg-orange-800"
                >
                  Add More <LuPlus />
                </button>
              </div>
            </div>
          </div>
        </div> */}


         <div>
          <div className="flex justify-between pb-2">
            <p className="text-[#7F7F7F] font-semibold">Age Groups</p>
            <div className="flex gap-6">
              <p className="text-[#7F7F7F] font-normal">All Age Groups</p>
              <Switch checked color="orange" />
            </div>
          </div>
          <div className="flex justify-between gap-4 border border-[#D9D9D9]">
            <div className="flex flex-col gap-1 w-full">
              <div>
                <div className="flex gap-4 p-3">
                  <Select
                    onChange={(value) => setFrom(value)}
                    placeholder="From"
                    options={fromAgesOptions}
                    value={from}
                  />
                  <Select
                    onChange={(value) => setTo(value)}
                    placeholder="To"
                    options={toAgesOptions}
                    value={to}
                  />
                </div>
                {showAgeGroupError &&
                  (!from?.value || !to?.value) &&
                  ageGroupErrorMsg && (
                    <p className="text-red-500 text-[14px] pl-3 pb-3">
                      {ageGroupErrorMsg}
                    </p>
                  )}
                {showAgeGroupError &&
                  from?.value &&
                  to?.value &&
                  ageGroupErrorMsg && (
                    <p className="text-red-500 text-[14px] pl-3 pb-3">
                      {ageGroupErrorMsg}
                    </p>
                  )}
              </div>
              {/* Age Groups  */}
              <div className="flex flex-wrap">
                {ageGroups?.map((item) => (
                  <div className="w-[6rem] flex justify-center items-center m-3 border border-[#D9D9D9] rounded-md">
                    <p>
                      {item?.from}-{item?.to}
                    </p>
                    <Checkbox onChange={(e) => handleSelectAgeGroup(e, item)} color="orange" />
                  </div>
                ))}
              </div>
            </div>
            <div className="flex gap-2 p-3">
              <IconButton onClick={handleDeleteAgeGroups} className="shadow-none hover:shadow-none focus:shadow-none bg-white text-orange-800 border border-[#D9D9D9] rounded-lg">
                <Icon
                  icon="material-symbols:delete-rounded"
                  className="text-[1.5rem]"
                />
              </IconButton>
              <div>
                <button
                  type="button"
                  className="w-[7rem] cursor-pointer p-2 flex items-center justify-center text-white rounded-md bg-orange-800"
                  onClick={handleAddMoreAgeGroup}
                >
                  Add More <LuPlus />
                </button>
              </div>
            </div>
          </div>
          {/* Error Message  */}
          {showError && !selectedAgeGroups.length && (
            <p className="text-red-500 text-[14px]">
              Please select age group!
            </p>
          )}

        </div>
      </form>
    </ThemeProvider>
  );
}
