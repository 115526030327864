import { useState } from "react";
import { Outlet } from "react-router-dom";
import AdminSidebar from "../components/Sidebar/AdminSidebar";
import Navbar from "../components/Navbar";

const AdminLayout = () => {

  const [sidebarWidth, setSidebarWidth] = useState(false)

  return (
    <div className="flex">
      <div className={`sticky top-0 h-screen ${sidebarWidth ? "w-[8rem]" : "w-[15rem]"} flex-shrink-0`}>
        <AdminSidebar sidebarWidth={sidebarWidth} setSidebarWidth={setSidebarWidth} />
      </div>
      <div className="flex flex-col flex-1 min-w-0">
        <Navbar />
        <div className="flex-grow w-full overflow-auto">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default AdminLayout