import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react';
import { useForm } from 'react-hook-form';
import { ThreeDots } from 'react-loader-spinner';
import uploadImg from '../../../assets/upload-image.svg'
import { useNavigate, useParams } from 'react-router-dom';
import { APIurls } from '../../../api/apiConstant';
import { useAuth } from '../../../store/AuthContext';
import { toast } from 'react-toastify';
import { useOutletContext } from 'react-router-dom';

export default function EditAmenity() {
   const [setUpdateAmenitiesList] = useOutletContext();
   const [loaderBtn, setLoaderBtn] = useState(false);
   const [selectIcon, setSelectIcon] = useState(null);
   const [showError, setShowError] = useState(false);
   const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
   const navigate = useNavigate();
   const { id } = useParams();
   const { getAccessToken } = useAuth();

   const fetchNotificationByIdData = async () => {
      const token = await getAccessToken();
      try {
         const response = await fetch(`${APIurls.fetchAmenityById}/${id}`, {
            method: "GET",
            headers: {
               Authorization: `Bearer ${token}`,
            },
         })
         if (!response.ok) {
            throw new Error("Error");
         }
         const result = await response.json();
         console.log("Amenity By Id", result?.response?.amenities);
         const { title, icon } = result?.response?.amenities;
         setValue("title", title);
         setSelectIcon(icon);
         // console.log("ICON", icon);
      } catch (error) {
         toast.error("Failed to fetch Amenity by Id")
      }
   }
   useEffect(() => {
      fetchNotificationByIdData();
   }, [id])

   const onSubmit = async (data) => {
      if (!selectIcon) {
         setShowError(true);
         return;
      } else {
         setShowError(false);
      }
      const { title } = data;
      const formData = new FormData();
      formData.append("title", title);
      formData.append("amenities_icon", selectIcon);
      const token = await getAccessToken();
      const requestOptions = {
         method: "PUT",
         body: formData,
         headers: {
            Authorization: `Bearer ${token}`,
         }
      };
      try {
         setLoaderBtn(true);
         const response = await fetch(`${APIurls.updateAmenity}/${id}`, requestOptions)
         if (!response.ok) {
            if (response.status === 400) {
               const errorData = await response.json();
               console.log("Error Data:", errorData);
               toast.error(errorData.message);
               throw new Error(errorData.message);
            } else {
               toast.error("Failed to Update Amenity");
               throw new Error("Failed to Update Amenity");
            }
         }
         const result = await response.json();
         console.log("Update Amenity result", result);
         toast.success("Amenity is Updated Successfully!");
         setUpdateAmenitiesList(true);
         reset();
         setSelectIcon("");
         navigate("/amenities");
      } catch (error) {
         console.error(error.message);
         //toast.error("Failed to Create Agent!")
      }
      setLoaderBtn(false);
   }

   return (
      <div>
         <form onSubmit={handleSubmit(onSubmit)}
            className='flex flex-col gap-[1.5rem]'
         >
            <div className="flex items-center justify-between">
               <h1 className="text-[1.2rem] sm:text-[1.3rem] font-semibold">
                  Edit Amenity
               </h1>
               <div className='flex gap-[3rem] items-center'>
                  <button onClick={() => navigate("/amenities")}
                     className={`flex items-center justify-center gap-[0.4rem] text-[1rem] font-semibold text-primary bg-white border
                border-primary py-[0.35rem] w-[6rem] rounded-[5px]`}
                     type="button"
                  >
                     <Icon icon="material-symbols:arrow-back-ios" className="text-[1rem]" />
                     <span>Back</span>
                  </button>
                  {
                     loaderBtn ? (
                        <ThreeDots
                           height="50"
                           width="50"
                           radius="9"
                           color="#FB6108"
                           ariaLabel="three-dots-loading"
                           wrapperStyle={{}}
                           wrapperClassName=""
                           visible={true}
                        />
                     ) : (
                        <button type='submit'
                           className="text-[0.8rem] sm:text-[1rem] flex items-center max-xs:w-full gap-[0.3rem] xs:gap-[0.4rem] bg-primary text-white py-[0.5rem] px-[0.4rem] xs:px-[1rem] rounded-md font-medium">
                           <span className="whitespace-nowrap">Update</span>
                           <Icon
                              icon="material-symbols:add"
                              className="text-[1.2rem] sm:text-[1.4rem]"
                           />
                        </button>
                     )
                  }
               </div>
            </div>
            <div className='px-8 flex flex-col gap-6'>
               <div className="flex flex-col gap-[0.5rem]">
                  <input
                     className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                     type="text"
                     placeholder="Title"
                     {...register("title", {
                        required: "*This field is required.",
                     })}
                  />
                  {errors.title && (
                     <p className="text-sm text-[#E92215]">
                        {errors.title.message}
                     </p>
                  )}
               </div>
               <div className="flex flex-col items-center py-[3rem] px-[1rem] bg-[#F6F7FB] rounded-lg">
                  {selectIcon ? (
                     <>
                        {
                           selectIcon?.name ? (
                              <img src={URL.createObjectURL(selectIcon)} alt="" className='h-[100px] w-[100px] ' />
                           ) : (
                              <img src={selectIcon} alt="" className='h-[100px] w-[100px] ' />
                           )
                        }
                        <div className="flex gap-[1rem] items-center mt-[0.5rem]">
                           <h2 className="text-[1.1rem] text-black font-[500]">
                              {selectIcon?.name?.length > 30
                                 ? selectIcon.name.substring(0, 30) + "..."
                                 : selectIcon.name}
                           </h2>
                           <div className='p-[0.1rem] border border-[#D9D9D9] rounded-md hover:bg-[#3D4A5C]/[0.3]'>
                              <Icon
                                 onClick={() => setSelectIcon("")}
                                 icon="akar-icons:cross"
                                 className="text-[1.2rem] cursor-pointer"
                              />
                           </div>
                        </div>
                     </>
                  ) : (
                     <>
                        <img src={uploadImg} alt="" />
                        <button
                           type="button"
                           onClick={() =>
                              document.querySelector(".input-field").click()
                           }
                        >
                           <h2 className='text-primary font-[500] flex items-center gap-[0.5rem] my-[1rem]'>
                              <Icon
                                 icon="material-symbols:cloud-upload"
                                 className="text-[1.5rem]"
                              />
                              <span>
                                 Upload Icon
                              </span>
                           </h2>
                        </button>
                        <input
                           type="file"
                           accept="image/*"
                           value=""
                           className="input-field"
                           hidden
                           onChange={({ target: { files } }) => {
                              if (files[0]) {
                                 setSelectIcon(files[0]);
                              }
                           }}
                        />
                        <p className='text-[0.9rem] text-[#808080]'>
                           Upload an Icon of Amenity.
                        </p>
                        <p className="text-[0.75rem] text-[#808080]">
                           File Format <span className='text-black'> svg, png </span> Recommened Size <span className='text-black'> 24X24 (1:1) </span>
                        </p>
                     </>
                  )}
               </div>
            </div>
         </form>
      </div>
   )
}

