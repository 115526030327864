import React, { useState } from 'react'
import { GrNext } from "react-icons/gr";
import { useOutletContext } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useAuth } from '../../../store/AuthContext';
import { APIurls } from '../../../api/apiConstant';

export default function SportCreation() {

  const [sportName, setSportName] = useState("")
  const [showError, setShowError] = useState(false)
  const [setUpdateSport] = useOutletContext();
  const { getAccessToken} = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!(sportName)) {
      setShowError(true)
      return
    }
    const token = await getAccessToken();
    try{
      const response = await fetch(`${APIurls.createSportForTournament}`,{
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({sportName})
      })
      const result = await response.json();
      if(result.code > 201){
        toast.error(result.message)
        return;
      };
      console.log("Sport Added successfully",result)
      toast.success(result.message)
      setUpdateSport(true)
    }
    catch(error){
      console.log(error)
    }
    setSportName("")
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='flex justify-between items-center'>
        <h1 className="text-[1.2rem] sm:text-[1.3rem] font-semibold pb-[0.5rem]">
          Create Sport
        </h1>
        <button type='submit' className='flex justify-center items-center px-2 py-2 text-white bg-primary rounded-md gap-1'>
          Submit <GrNext/>
        </button>
      </div>
      <div className='py-5 flex flex-col justify-center items-center'>
        <input type='text'
          className='w-[60%] bg-[#EFF1F9] px-3 py-2 rounded-md outline-none'
          placeholder='Enter Your Sport'
          value={sportName}
          onChange={(e) => setSportName(e.target.value)}
        />
        {
          !sportName && showError && (
            <p className='text-sm text-red-600'>*This field is required</p>
          )}
      </div>
      <ToastContainer />
    </form>
  )
}
