import React from 'react'
import { Icon } from '@iconify/react'
import { Link, NavLink, Outlet, useOutletContext, useParams } from 'react-router-dom'


export default function ViewClubOwner() {
   const [ClubName, ClubID, mongoId] = useOutletContext();
   const { id } = useParams();

   return (
      <div className='flex flex-col gap-[1rem]'>
         <div className='bg-white flex flex-col gap-[1rem] rounded-md p-[1rem]'>
            <div className='flex gap-[0.5rem] items-center text-[#808080] font-semibold'>
               <Link to={"/club-owners"}>
                  <h2 className='text-[1rem] font-semibold'>
                     Club List
                  </h2>
               </Link>
               <Icon icon="material-symbols:arrow-forward-ios" className="text-[1.3rem]" />
               <h2 className='text-black text-[1.2rem]'>
                  {ClubName}
                  <span className='text-[#808080] text-[1rem] font-normal'> ({ClubID}) </span>
               </h2>
            </div>
            <div className='flex flex-col gap-[1rem]'>
               <div className='flex items-center gap-[2rem] text-[1.2rem] font-medium border-b-2 border-[#989898] overflow-auto'>
                  <NavLink to={`/club-owners/view/${id}`} end>
                     {({ isActive }) => (
                        <h2 className={`pb-[0.3rem] text-[18px] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#808080]"}`}>
                           Dashboard
                        </h2>
                     )}
                  </NavLink>
                  <NavLink to={`/club-owners/view/${id}/sports`}>
                     {({ isActive }) => (
                        <h2 className={`pb-[0.3rem] text-[18px] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#808080]"}`}>
                           Sports
                        </h2>
                     )}
                  </NavLink>
                  <NavLink to={`/club-owners/view/${id}/bookings`}>
                     {({ isActive }) => (
                        <h2 className={`pb-[0.3rem] text-[18px] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#808080]"}`}>
                           Bookings
                        </h2>
                     )}
                  </NavLink>
                  <NavLink to={`/club-owners/view/${id}/staff`}>
                     {({ isActive }) => (
                        <h2 className={`pb-[0.3rem] text-[18px] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#808080]"}`}>
                           Staff
                        </h2>
                     )}
                  </NavLink>
                  <NavLink to={`/club-owners/view/${id}/customers`}>
                     {({ isActive }) => (
                        <h2 className={`pb-[0.3rem] text-[18px] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#808080]"}`}>
                           Customers
                        </h2>
                     )}
                  </NavLink>
                  <NavLink to={`/club-owners/view/${id}/tournaments`}>
                     {({ isActive }) => (
                        <h2 className={`pb-[0.3rem] text-[18px] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#808080]"}`}>
                           Tournaments
                        </h2>
                     )}
                  </NavLink>
                  <NavLink to={`/club-owners/view/${id}/inventory`}>
                     {({ isActive }) => (
                        <h2 className={`pb-[0.3rem] text-[18px] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#808080]"}`}>
                           Inventory
                        </h2>
                     )}
                  </NavLink>
                  <NavLink to={`/club-owners/view/${id}/rentals`}>
                     {({ isActive }) => (
                        <h2 className={`pb-[0.3rem] text-[18px] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#808080]"}`}>
                           Rentals
                        </h2>
                     )}
                  </NavLink>
                  <NavLink to={`/club-owners/view/${id}/expenses`}>
                     {({ isActive }) => (
                        <h2 className={`pb-[0.3rem] text-[18px] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#808080]"}`}>
                           Expenses
                        </h2>
                     )}
                  </NavLink>
                  <NavLink to={`/club-owners/view/${id}/canteen`}>
                     {({ isActive }) => (
                        <h2 className={`pb-[0.3rem] text-[18px] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#808080]"}`}>
                           Canteen
                        </h2>
                     )}
                  </NavLink>
                  <NavLink to={`/club-owners/view/${id}/co_ClubOwner`}>
                     {({ isActive }) => (
                        <h2 className={`pb-[0.3rem] text-[18px] whitespace-nowrap break-keep ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#808080]"}`}>
                           Co-Club Owner
                        </h2>
                     )}
                  </NavLink>
               </div>
               <Outlet context={[mongoId]} />
            </div>
         </div>
      </div>
   )
}
