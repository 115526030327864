import React from 'react'
import { IconButton } from "@material-tailwind/react";
import { Icon } from "@iconify/react";

export default function AboutTournamentModal({ setAboutTournamentModal, aboutTournament }) {

  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-[#000000E6]">
      <div className="flex flex-col gap-4 bg-white rounded-sm px-6 py-8 w-[30%] h-[40%] relative max-lg:w-[350px]">
        <div className="flex justify-between items-center">
          <h1 className="text-[20px] font-bold">About Tournament</h1>
          <IconButton
            onClick={() => setAboutTournamentModal(false)}
            className={
              "shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-sm"
            }
            size="sm"
          >
            <Icon icon="material-symbols:close" className="text-[1.5rem]" />
          </IconButton>
        </div>
        <div className='w-[100%] overflow-y-auto px-2 mb-4'>
          <p className='break-words'>{aboutTournament}</p>
        </div>
      </div>
    </div>
  );
}

