import React, { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom';
import ManageSports from '../AdminModules/TournamentSports/ManageSports';

export default function TournamentSports() {
    const [updateSport, setUpdateSport] = useState(false);
    const { pathname } = useLocation();
 
    useEffect(() => {
       window.scrollTo({
          top: 0,
          behavior: "smooth",
       });
    }, [pathname]);
 
    return (
       <div className="bg-secondary px-[1.5rem] py-[1rem] h-full">
          <div className="bg-white flex flex-col gap-[1rem] font-[500] rounded-md px-[2rem] py-[1rem]">
             <div>
                <Outlet context={[setUpdateSport]} />
             </div>
             <div className="my-[1rem]">
                <ManageSports updateSport = {updateSport} setUpdateSport = {setUpdateSport}/>
             </div>
          </div>
       </div>
    );
}
