import { Icon } from '@iconify/react'
import React from 'react'
import { Link, NavLink, useOutletContext, useParams } from 'react-router-dom'
import customerOrderItem from '../../../../../assets/Rectangle 24597.png'
import { useGetSingleClubOwnerCanteenQuery } from '../../../../../store/features/clubOwner/clubOwnerApi';
import moment from 'moment';

export default function ViewCOCanteenView() {
   const {id,canteenId} = useParams();
   const {data, isLoading} = useGetSingleClubOwnerCanteenQuery(canteenId);
   const {customerDetails: { Username, PhoneNumber } = {}, createdAt, TotalItem, PaymentMode, TotalAmount, status, archive, OrderID, itemCartID:{itemIDlist}={}} = data?.response?.findCanteenCartItems || {};
   console.log("Item List =======> ", itemIDlist)
   const [clubName, clubID] = useOutletContext();


// "itemCartID": {
//                 "_id": "665dc8dc269cbcf2181f35ef",
//                 "ClubID": "665dbc6a269cbcf2181f0f71",
//                 "totalProfit": 4,
//                 "itemIDlist": [
//                     {
//                         "itemID": {
//                             "priceDetails": {
//                                 "wholePrice": 18,
//                                 "sellPrice": 20
//                             },
//                             "_id": "665dc88c269cbcf2181f3494",
//                             "ProductID": "GAROAME1PN1AWE",
//                             "ProductName": "cake",
//                             "category": "Desserts",
//                             "clubId": "665dbc6a269cbcf2181f0f71",
//                             "transactions": [
//                                 "665dccc9269cbcf2181f3a26",
//                                 "665dccdd269cbcf2181f3a5c"
//                             ],
//                             "threshold": 20,
//                             "canteenSlug": "cake",
//                             "productStock": 96,
//                             "productImg": "https://firebasestorage.googleapis.com/v0/b/gamebeezuserapp.appspot.com/o/YVSRVTEMXJW9N5MWM3%2Fcanteen%2FfbEAibPBk_wfLX.jpg?alt=media&token=dbcaf96c-c27e-445f-831d-3f53e0043f4a",
//                             "Remarks": "cales on aprol",
//                             "status": "IN-STOCK",
//                             "History": [],
//                             "createdAt": "2024-06-03T13:43:40.502Z",
//                             "updatedAt": "2024-06-03T14:02:05.510Z",
//                             "__v": 2
//                         },
//                         "itemQty": 2,
//                         "itemTotal": 40,
//                         "_id": "665dc8dc269cbcf2181f35f0"
//                     }
//                 ],
//                 "checkoutID": "665dc8dc269cbcf2181f35f1",
//                 "createdAt": "2024-06-03T13:45:00.596Z",
//                 "updatedAt": "2024-06-03T13:45:00.596Z",
//                 "__v": 0
//             },

   return (
      <div className='bg-white flex flex-col gap-[1rem] rounded-md p-[1rem]'>
         <div className='flex gap-[0.5rem] items-center text-[#808080] font-semibold'>
            <Link to={"/club-owners"}>
               <h2 className='text-[1rem] font-semibold'>
                  Club List
               </h2>
            </Link>
            <Icon icon="material-symbols:arrow-forward-ios" className="text-[1.1rem]" />
            <Link to={`/club-owners/view/${id}`}>
               <h2 className='text-[1rem]'>
                  {clubName}
                  <span className='text-[#808080] text-[0.9rem] font-normal'> ({clubID}) </span>
               </h2>
            </Link>
            <Icon icon="material-symbols:arrow-forward-ios" className="text-[1.1rem]" />
            <Link to={`/club-owners/view/${id}/canteen`}>
               <h2 className='text-[1rem]'>
                  Canteen
               </h2>
            </Link>
            <Icon icon="material-symbols:arrow-forward-ios" className="text-[1.1rem]" />
            <h2 className='text-[1.2rem] text-black font-bold'>
               {OrderID}
            </h2>
         </div>
         <div className='flex gap-[2rem] text-[1.2rem] font-medium border-b-2 border-[#989898]'>
            {/* <NavLink to={`/club-owners/view/${id}/canteen/view/${canteenId}`} end>
               {({ isActive }) => ( */}
                  <h2 className={`pb-[0.3rem] font-bold text-primary border-b-2 border-primary`}>
                     Order ID: {OrderID}
                  </h2>
               {/* )} */}
            {/* </NavLink> */}
         </div>
         <div className='grid grid-cols-2 px-[0.6rem]'>
            <div className='my-[1rem]'>
               <div className='grid grid-cols-2 gap-y-[1rem]'>
                  <label className='text-[#858585] font-medium'>Customer Name</label>
                  <h2 className='font-semibold'>{Username}</h2>
                  <label className='text-[#858585] font-medium'>Customer Mobile Number</label>
                  <h2 className='font-semibold'>{PhoneNumber}</h2>
                  <label className='text-[#858585] font-medium'>Date & Time</label>
                  <h2 className='font-semibold'>{moment(createdAt).format('DD/MM/YYYY')} - {moment(createdAt).format('LT')}</h2>
                  <label className='text-[#858585] font-medium'>Total Items</label>
                  <h2 className='font-semibold'>{TotalItem}</h2>
               </div>
            </div>
            <div className=''>
               <h2 className='text-[1.15rem] font-bold my-[1rem]'>
                  Payment Details
               </h2>
               <div className='grid grid-cols-2 gap-y-[1rem]'>
                  <label className='text-[#858585] font-medium'>Payment Mode</label>
                  <h2 className='font-semibold'>{PaymentMode}</h2>

                  {/* <label className='text-[#858585] font-medium'>Payment Method</label>
                  <h2 className='font-semibold'>UPI</h2> */}

                  <label className='text-[#858585] font-medium'>Total Amount</label>
                  <h2 className='font-semibold'>₹ {TotalAmount}</h2>
                  <label className='text-[#858585] font-medium'>Status</label>
                  <div className={`flex justify-center items-center gap-[0.5rem] text-[0.9rem] rounded-md font-semibold py-[0.2rem] w-[6rem] ${status === "PAID" ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"}`}>
                     <h2>{status}</h2>
                  </div>

                  {/* <label className='text-[#858585] font-medium'>Transaction ID</label>
                  <h2 className='font-semibold'>TRA123DSD2231</h2> */}

                  <label className='text-[#858585] font-medium'>Customer Mobile Number</label>
                  <h2 className='font-semibold'>{PhoneNumber}</h2>
                  <label className='text-[#858585] font-medium'>Customer Name</label>
                  <h2 className='font-semibold'>{Username}</h2>
                  <label className='text-[#858585] font-medium'>Remarks</label>
                  <h2 className='font-semibold'>{archive?.remark}</h2>
               </div>
            </div>
         </div>
         <div>
            <h1 className='font-bold text-[1.15rem] my-4'>Items</h1>
            <table className='w-full'>
               <thead className=''>
                  <tr className='bg-[#F6F8FC]'>
                     <th className='py-3 font-semibold'>S.No</th>
                     <th className='font-semibold'>Item Image</th>
                     <th className='font-semibold'>Item Details</th>
                     <th className='font-semibold'>Price</th>
                     <th className='font-semibold'>Quantity</th>
                     <th className='font-semibold'>Total Price</th>
                  </tr>
               </thead>
               <tbody className=''>
                  {
                     itemIDlist?.map((item=> (<tr className='text-center'>
                        <td className='font-semibold py-6'>1</td>
                        <td className='font-semibold flex items-center justify-center'>
                           <img className="w-[60px] h-[50px]" src={item?.itemID?.productImg} alt="Product-item" />
                        </td>
                        <td className='font-semibold'>{item?.itemID?.ProductName}</td>
                        <td className='font-semibold'>₹{item?.itemID?.priceDetails?.sellPrice}</td>
                        <td className='font-semibold'>{item?.itemQty}</td>
                        <td className='font-semibold'>₹{item?.itemTotal}</td>
                     </tr>)))
                  }
               </tbody>
            </table>
         </div>
         <div className='grid grid-cols-2 place-content-end'>
            <div></div>
            <div className='col-span-1'>
               <h2 className='text-[1.15rem] font-bold my-[1rem]'>
                  Taxes & Fees
               </h2>
               <div className='grid grid-cols-2 gap-y-[1rem] place-content-between'>

                  {/* <label className='text-[#858585] font-medium'>GST</label>
                  <h2 className='font-semibold'>₹20.00</h2> */}

                  <label className='text-[#858585] font-medium'>Total Amount</label>
                  <h2 className='font-semibold'>₹{TotalAmount}</h2>
               </div>
            </div>
         </div>
      </div>
   )
}



