import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ManageAmenities from "../AdminModules/Amenities/ManageAmenities";


export default function AdminAmenities() {
   const [updateAmenitiesList, setUpdateAmenitiesList] = useState(false);
   const { pathname } = useLocation();

   useEffect(() => {
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
   }, [pathname]);

   return (
      <div className="bg-secondary px-[1.5rem] py-[1rem] h-full">
         <div className="bg-white flex flex-col gap-[1rem] font-[500] rounded-md px-[1rem] py-[0.5rem]">
            <h1 className="border-b border-[#EAECF0] text-[1.2rem] sm:text-[1.3rem] font-semibold pb-[0.5rem]">
               Amenities
            </h1>
            <div>
               <Outlet context={[setUpdateAmenitiesList]} />
            </div>
            <div className="my-[1rem]">
               <ManageAmenities updateAmenitiesList={updateAmenitiesList} setUpdateAmenitiesList={setUpdateAmenitiesList} />
            </div>
         </div>
      </div>
   );
}
