import { useEffect, useRef, useState } from "react";
import userDisplayIcon from "../assets/Rectangle 19.png";
import { Icon } from "@iconify/react";
import { useQuery } from "react-query";
import {
  Button,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import ProfilePage from "./AdminPanel/ProfilePage";
import { Link } from "react-router-dom";
import img from "./../assets/Rectangle 19.png";
import { useAuth } from "../store/AuthContext";
import { APIurls } from "../api/apiConstant";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const handleOpen = () => setOpen(!open);
  const [state, setState] = useState("");
  const [adminData, setAdminData] = useState({});
  const [openMenu, setOpenMenu] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const { getAccessToken ,logout} = useAuth();
  const notList = [
    {
      imgSrc: img,
      message:
        "Manikanta Putta has booked cricket ground for 12PM - 2PM. Online payment has successfully done.",
      time: "8 min ago",
      btn: false,
    },
    {
      imgSrc: img,
      message:
        "Manikanta Putta has booked cricket ground for 12PM - 2PM. Online payment has successfully done.",
      time: "8 min ago",
      btn: true,
    },
    {
      imgSrc: img,
      message:
        "Manikanta Putta has booked cricket ground for 12PM - 2PM. Online payment has successfully done.",
      time: "8 min ago",
      btn: false,
    },
    {
      imgSrc: img,
      message:
        "Manikanta Putta has booked cricket ground for 12PM - 2PM. Online payment has successfully done.",
      time: "8 min ago",
      btn: false,
    },
  ];

  const fetchAdminDetails = async () => {
    const token = await getAccessToken();

    try {
      const res = await fetch(`${APIurls.fetchAdminOrSubAdminDetails}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();
      console.log("admin data for navbar : ", data.response);
      // setAdminData(data.response);
      if (!res.ok) {
        throw new Error(data.message);
      }
      return data?.response;
    } catch (error) {
      console.error("API FAILED TO FETCH ADMIN DATA ", error.message);
    }
  };

  const {
    data: mainAdminData,
    isLoading,
    refetch,
  } = useQuery("AdminDetails", fetchAdminDetails);
//   useEffect(() => {
//     refetch();
//   }, []);

  return (
    <>
      <nav className="bg-white h-[4rem] flex justify-end">
        <div className="flex justify-between items-center gap-[2rem] px-[2rem]">
          <Menu open={openMenu} handler={setOpenMenu}>
            <MenuHandler>
              <div className="flex justify-between items-center gap-[0.5rem] cursor-pointer">
                <img src={userDisplayIcon} alt="" />
                <div className="flex flex-col">
                  <span className="text-xs font-semibold text-[#000019]">
                    {mainAdminData?.name}
                  </span>
                  <span className="text-[#545454] text-xs font-medium">
                    {mainAdminData?.Designation}
                  </span>
                </div>
              </div>
            </MenuHandler>
            <MenuList>
              <Link to="/profile">
                <MenuItem
                  className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                >
                  Profile
                </MenuItem>
              </Link>
              <hr className="my-[0.1rem]" />
              <MenuItem
                className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                onClick={() => logout()}
              >
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
