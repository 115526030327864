import { Icon } from '@iconify/react';
import React from 'react'
import { ThreeDots } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function EditStepperController({ currentStep, error, setShowError, handleClick, steps }) {
    const navigate = useNavigate();

    const { editLoaderBtn } = useSelector((store) => store.editSubCategory);

    const nextButtonHandler = (e) => {
        e.preventDefault();
        if (error) {
            setShowError(true);
            return;
        }
        // console.log("Form Error: " + error);
        handleClick(steps.length === currentStep ? "update" : "next");
    }

    const backButtonHandler = (e) => {
        if (currentStep === 1) {
            navigate(-1);
            return;
        }
        e.preventDefault();
        handleClick("back");
    }

    return (
        <div>
            <div className="flex gap-[2rem] items-center justify-end">
                <button onClick={backButtonHandler}
                    className={`flex items-center justify-center gap-[0.4rem] text-[1rem] font-semibold text-primary bg-white border
                border-primary py-[0.35rem] w-[6rem] rounded-[5px]`}
                    type="button"
                >
                    <Icon icon="material-symbols:arrow-back-ios" className="text-[1rem]" />
                    <span>Back</span>
                </button>

                {editLoaderBtn ? (
                    <ThreeDots
                        height="50"
                        width="50"
                        radius="9"
                        color="#FB6108"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                ) :
                    (<button onClick={nextButtonHandler}
                        className="flex items-center justify-center gap-[0.5rem] text-[1rem] font-semibold text-white bg-primary py-[0.4rem] w-[7rem] rounded-[5px]"
                        type="submit"
                    >
                        <span>{steps.length === currentStep ? "Update" : "Next"}</span>
                        {steps.length !== currentStep ? <Icon icon="material-symbols:arrow-forward-ios" className="text-[1rem] text-white" /> : <Icon icon="material-symbols:add"
                            className='text-[1.3rem]' />}
                    </button>)}
            </div>
        </div>
    )
}

