import { useEffect } from "react"
import { NavLink, Outlet } from "react-router-dom"
import { useAuth } from "../../store/AuthContext"

function OffersAndBanners() {

   const { userRole } = useAuth();

   useEffect(() => {
      window.scrollTo({
         top: 0,
         behavior: "smooth"
      })
   }, [])
   return (
      <div className='bg-[#E6E6E6] py-[1rem] px-[1.5rem] h-full'>
         <div className='bg-white flex gap-[3rem] px-[0.8rem] pt-[0.7rem] border-b-[1px] border-[#d3d0d0] rounded-t-lg'>
            {(userRole?.role?.admin || userRole?.role?.subAdmin?.navbar?.includes('offers')) &&
               <NavLink to={`/offersAndBanners`} end>
                  {({ isActive }) => (
                     <h2 className={`text-[1.2rem] sm:text-[1.3rem] font-bold whitespace-nowrap pb-[0.3rem] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                        Offers
                     </h2>
                  )}
               </NavLink>
            }

            {(userRole?.role?.admin || userRole?.role?.subAdmin?.navbar?.includes('banners')) &&
               <NavLink to={`/offersAndBanners/banners`}>
                  {({ isActive }) => (
                     <h2 className={`text-[1.2rem] sm:text-[1.3rem] font-bold whitespace-nowrap pb-[0.3rem] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                        Banners
                     </h2>
                  )}
               </NavLink>
            }
         </div>
         <div className="bg-white min-h-screen p-[1.5rem] rounded-b-lg"><Outlet /></div>
      </div>
   )
}

export default OffersAndBanners
