import React, { useEffect, useState } from "react";
import ThreeDotsLoading from "./../../../ui/ThreeDotsLoading";
import { Icon } from "@iconify/react/dist/iconify.js";
import { IconButton, ThemeProvider } from "@material-tailwind/react";
import { selectCustomTheme } from "./../../../../helpers/constants";
import { ToastContainer, toast } from "react-toastify";
import { useDeleteClubOwnerStaffMutation } from "../../../../store/features/clubOwner/clubOwnerApi";

export default function DeleteModal({ setShowDeleteStaffModal, staffId }) {
  const [remark, setRemark] = useState("");
  const [showError, setShowError] = useState(false);
  const [deleteClubOwnerStaffApi, { isLoading, isError, isSuccess }] =
    useDeleteClubOwnerStaffMutation();
  const handleDeleteStaff = async (e) => {
    e.preventDefault();
    if (!remark) {
      return setShowError(true);
    }
    try {
      const result = await deleteClubOwnerStaffApi({
        remark: { remark },
        staffId,
        });
      if (result.error) {
        throw new Error(result?.error?.data?.message);
      }
      setShowDeleteStaffModal(false);
      toast.success("Deleted staff successfully!");
    } catch (error) {
      toast.error(error.message || "Failed to delete the staff !");
    }
  };

  // useEffect(() => {
  //   if (isSuccess) {
  //     toast.success("Deleted staff successfully.");
  //   }
  //   if (isError) {
  //     toast.error('Failed to delete staff!')
  //   }
  // }, [isSuccess, isError]);

  return (
    <ThemeProvider value={selectCustomTheme}>
      <div className="flex items-center justify-center bg-[#000000bb] h-full w-full absolute top-0 left-0 right-0 bottom-0 z-10">
        <div className="flex flex-col w-[50%] gap-[1.5rem] py-[1.5rem] px-[2rem] bg-white rounded-lg justify-around">
          <div className="flex justify-between items-center">
            <p className="text-[1.3rem] font-semibold">
              Are you sure to delete?
            </p>
            <IconButton
              onClick={() => setShowDeleteStaffModal(false)}
              className="shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8]"
              size="sm"
              style={{
                borderRadius: "5px",
                border: "1px solid #FF0023",
              }}
            >
              <Icon icon="material-symbols:close" className="text-[1.5rem]" />
            </IconButton>
          </div>

          <div className="flex">
            <form
              // onSubmit={handleSubmit(onSubmit)}
              className="w-full flex flex-col"
            >
              <div className="w-full">
                <div>
                  <p className="mb-1">
                    Please provide your remarks why you want to delete.
                  </p>
                  <input
                    className="text-[0.75rem] w-full sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                    type="text"
                    placeholder="Enter your remarks*"
                    onChange={(e) => setRemark(e.target.value)}
                  />
                  {showError && !remark && (
                    <p className="text-red-500 text-[14px]">
                      Remark is required*
                    </p>
                  )}
                </div>
              </div>

              <div className="flex justify-end mt-[1.5rem]">
                <button
                  onClick={handleDeleteStaff}
                  disabled={isLoading}
                  type="submit"
                  className="flex items-center justify-between gap-[0.5rem] text-white text-[1.1rem] h-[45px] w-[165px] rounded-md px-[1rem] py-[0.6rem] bg-[#FB6108] font-bold"
                >
                  {isLoading ? <ThreeDotsLoading /> : <span>Delete Staff</span>}
                  <Icon
                    icon="material-symbols:arrow-forward-ios"
                    className="text-[1.3rem]"
                  />
                </button>
              </div>
            </form>
          </div>
        </div>
        <ToastContainer />
      </div>
    </ThemeProvider>
  );
}
