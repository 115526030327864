import React, { useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { Button, IconButton, Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react';
import { Icon } from '@iconify/react';
import DataTable from 'react-data-table-component';
import CustomCheckbox from '../../../helpers/CustomCheckbox';
import { APIurls } from '../../../api/apiConstant';
import { useAuth } from '../../../store/AuthContext';
import { useQuery } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';
import ViewFAQ from './ViewFAQ';
import { RxCross2 } from "react-icons/rx";

export default function FAQsList({ updateFAQList, setUpdateFAQList }) {
  const [loadingStates, setLoadingStates] = useState({});
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const { getAccessToken, userRole } = useAuth();
  const [faqIndex, setFaqIndex] = useState("");

  const fetchFAQData = async () => {
    const token = await getAccessToken();
    const response = await fetch(`${APIurls.fetchFAQs}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    console.log("FAQ List", result?.response?.allFaq);
    return result?.response?.allFaq.reverse();
  }
  const { data: faqData, isLoading, refetch } = useQuery('faqs', fetchFAQData);

  useEffect(() => {
    let mapData = faqData?.map((item) => {
      return { ...item };
    });
    if (searchInput !== "") {
      mapData = mapData.filter((item) => {
        return item?.question?.toLowerCase().includes(searchInput.toLowerCase());
      });
    }
    setFilteredData(mapData);
  }, [faqData, searchInput]);

  const fetchAdminDetails = async () => {
    const token = await getAccessToken();
    const response = await fetch(`${APIurls.fetchAdminOrSubAdminDetails}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message)
    }
    return result?.response
  }

  const { data: adminDetails, refetchAdmin } = useQuery('AdminDetails', fetchAdminDetails)

  const onMoveToFAQ = (id) => {
    setFaqIndex(id);
  }

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        // padding: "10px 10px",
        color: "#4D4D4D",
        fontSize: "13px",
      },
    },
    head: {
      style: {
        background: "#FFF",
      },
    },

    rows: {
      style: {
        background: "#FFF",
        color: "#565656",
        padding: "5px 0px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1100px",
        maxWidth: "1400px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };

  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      maxWidth: "0.5rem",
    },
    {
      name: 'Title',
      id: "title",
      selector: row => (
        <div className='flex flex-col justify-start '>
          <p><span className='text-[1rem] font-semibold'>Q - </span>{row?.question?.length > 50 ? `${row?.question?.slice(0, 60) + "..."}` : row?.question}</p>
          <p><span className='text-[1rem] w-[100px] font-semibold'>A - </span>{row?.answer?.length > 50 ? `${row?.answer?.slice(0, 60) + "..."}` : row?.answer}</p>
        </div>
      ),
      grow: 4,
      // center: true,
      // maxWidth: "1200px"
    },
    {
      name: 'Category',
      id: "category",
      selector: row => row.category,
      center: true,
    },
    {
      name: 'Send To',
      id: "sendTo",
      selector: row => (
        <div className='text-center'>
          <p>{row?.sendTo?.customers && "Customers"}</p>
          <p>{row?.sendTo?.clubOwners && "Club Owners"}</p>
          <p>{row?.sendTo?.all && "All"}</p>
        </div>
      ),
      center: true,
    },
    {
      name: "Status",
      id: "status",
      selector: (row) => (
        <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
          py-[0.4rem] w-[6rem] ${row.status === "active" ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"}`}>
          <span className={`${row.status === "active" ? "bg-[#25BF17]" : "bg-[#FF0023]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
          <h2>{row.status}</h2>
        </div>
      ),
      center: true,
      grow: 1,
      sortable: true,
    },
    {
      name: "Actions",
      id: "action",
      cell: (row) => (
        <div className="flex gap-[0.5rem]">

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("VIEW_FAQS")) ?
            <NavLink to={`view/${row?.faq_id}`} onClick={() => onMoveToFAQ(row?.faq_id)}>
              <IconButton className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]">
                <Icon icon="mdi:eye" className="text-[1.5rem] text-black" />
              </IconButton>
            </NavLink>
            :
            "-"
          }

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("UPDATE_FAQS")) ?
            <Link to={`edit/${row.faq_id}`}>
              <IconButton
                className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black rounded-lg border border-[#D9D9D9]"
              >
                <Icon
                  icon="material-symbols:edit-rounded"
                  className="text-[1.5rem] text-black"
                />
              </IconButton>
            </Link>
            :
            "-"
          }

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("DELETE_FAQS")) ?
            (loadingStates[row.faq_id] ? (
              <ThreeDots
                height="50"
                width="50"
                radius="9"
                color="#FB6108"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <IconButton onClick={() => deleteFAQHandler(row.faq_id)}
                className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black rounded-lg border border-[#D9D9D9]"
              >
                <Icon
                  icon="material-symbols:delete-rounded"
                  className="text-[1.5rem] text-black"
                />
              </IconButton>
            ))
            :
            "-"
          }
        </div>
      ),
      center: true,
      grow: 1,
    },
  ];

  const deleteFAQHandler = async (id) => {
    // eslint-disable-next-line no-restricted-globals
    let isConfirm = confirm("Are you sure you want to Delete this FAQ?");
    if (isConfirm) {
      const token = await getAccessToken();
      try {
        setLoadingStates((prevState) => ({
          ...prevState,
          [id]: true,
        }))
        const response = await fetch(`${APIurls.deleteFAQ}/${id}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        if (!response.ok) {
          toast.error("Failed to Delete FAQ");
          const result = await response.json();
          throw new Error(result);
        }
        const result = await response.json();
        console.log("FAQ Deleted Response", result);
        toast.success("FAQ Deleted Successfully");
        refetch();
      } catch (error) {
        console.error(error.message);
      }
      setLoadingStates((prevState) => (
        {
          ...prevState,
          [id]: false,
        }))
    }
  }

  if (updateFAQList) {
    refetch();
    setUpdateFAQList(false);
  }

  return (
    <div className='flex flex-col gap-[0.5rem]'>
      <div className='flex justify-between items-center border-t border-[#EAECF0] pt-[1.5rem]'>
        <div className='flex items-center gap-[3rem]'>
          <h2 className='text-black text-[1.3rem] font-semibold'>Manage FAQs</h2>
          <div className="flex py-[0.4rem] px-[1rem] bg-[#FFF] border border-[#DFDFDF] rounded-md">
            <input
              type="text"
              className="w-[18rem] placeholder-[#8F8F8F] text-black text-sm font-semibold outline-none"
              placeholder="Search by Question"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            {searchInput !== "" ?
              <RxCross2
                className='text-[1.4rem] bg-primary text-white rounded-md cursor-pointer'
                onClick={() => setSearchInput("")}
              />
              :
              <Icon
                icon="ri:search-2-fill"
                className="p-[0.2rem] bg-primary text-white text-[1.5rem] rounded-[5px] cursor-pointer"
              />
            }
          </div>
        </div>
        <div className='flex items-center gap-[2rem]'>
          <div className='flex items-center gap-[0.4rem] hover:text-primary cursor-pointer'>
            <Icon
              icon='material-symbols:delete'
              className='text-[1.5rem]'
            />
            <span className='text-[1rem] font-medium'>Remove</span>
          </div>
          <div className='flex items-center gap-[0.3rem] hover:text-primary cursor-pointer'>
            <p className='text-[1rem] font-medium'>Change Status</p>
            <Icon
              icon='mingcute:down-line'
              className='text-[1.5rem]'
            />
          </div>
        </div>
      </div>
      <div>
        <DataTable
          columns={columns}
          customStyles={customStyles}
          data={filteredData}
          pagination
          selectableRows
          paginationPerPage={10}
          progressPending={isLoading}
          selectableRowsComponent={CustomCheckbox}
        />
      </div>
      <ToastContainer />
    </div>
  )
}

