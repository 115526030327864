import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { Link, NavLink, useOutletContext, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useQuery } from "react-query";
import { useAuth } from "../../../../../store/AuthContext";
import { APIurls } from "../../../../../api/apiConstant";
import moment from "moment";

export default function CustomersViewBookingView() {
  const { bookingId, id, customerId } = useParams();
  const { getAccessToken } = useAuth();
  const [clubName, clubID] = useOutletContext();

  console.log("BOOKING ID ", bookingId);

  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      maxWidth: "0.5rem",
    },
    {
      name: "Sports",
      selector: (row) => (
        <div className="flex flex-col">
          {specificBookingData?.booking?.sport}
        </div>
      ),
      center: true,
      maxWidth: "0.5rem",
    },
    {
      name: "Court Details",
      id: "dob",
      selector: (row) => (
        <div className="flex flex-col">
          <p>Name: {specificBookingData?.booking?.boardDetails?.boardName}</p>
          <p>No: {specificBookingData?.booking?.boardDetails?.boardNo}</p>
          <p>Id: {specificBookingData?.booking?.boardDetails?.boardsUID}</p>
        </div>
      ),
      center: true,
    },

    {
      name: "Date of Booking",
      id: "date_of_Booking",
      selector: (row) => (
        <div>
          {moment(specificBookingData?.booking?.dateOfBooking).format(
            "MMM Do YY"
          )}
        </div>
      ),
      center: true,
    },
    {
      name: "Time slot",
      id: "time_slot",
      selector: (row) => (
        <div>
          {specificBookingData?.booking?.timeSlots?.map((div) => {
            // const startDate = new Date(div);
            const startDate = div === null ? "NA" : new Date(div) 
            // subtracting 5:30 hours
            // const subtractedDateTime = new Date(newDate.getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000));
            return (
              <div>
                <p>{startDate==="NA" ? startDate : moment(startDate).format("LTS")}</p>
                {/* <p>{moment(startDate === "NA" ? "NA" : startDate).format("LTS")}</p> */}
              </div>
            );
          })}
        </div>
      ),
      center: true,
    },
    {
      name: "Booking Type",
      id: "booking_type",
      selector: (row) => (
        <div className=" px-[16px] py-1 bg-[#94FF824D] bg-opacity-30 rounded-md flex justify-center items-center gap-2.5">
          <div className="text-[#1CC500] text-sm font-medium">
            {specificBookingData?.booking?.bookingType}
          </div>
        </div>
      ),
      center: true,
    },
  ];

  const fetchClubOwnerCustomerBookingData = async () => {
    const token = await getAccessToken();
    const response = await fetch(
      `${APIurls.fetchSpecificCustomerBookingData}/${bookingId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    console.log("FETCH CUSTOMER SPECIFIC BOOKING DATA", result?.response);
    return result?.response;
  };
  const {
    data: specificBookingData,
    isLoading,
    refetch,
  } = useQuery(
    "customerSpecificBookingData",
    fetchClubOwnerCustomerBookingData
  );

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        // padding: "10px 10px",
        color: "#000019",
        fontSize: "13px",
        background: "#F6F8FC",
      },
    },
    head: {
      style: {
        borderRadius: "6px 6px 6px 6px",
        // border: "0.824px solid #E9E9E9",
        // background: "#FFF",
        // fontSize: "15px",
      },
    },

    rows: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        // background: "#FFF",
        color: "#00000",
        padding: "5px 0px",
        fontSize: "14px",
        fontWeight: 600,
        width: "auto",
      },
    },

    table: {
      style: {
        overflow: "visible",
        minWidth: "1100px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };

  const data = [
    {
      question: "How to Login?",
      answer: "Go to Header section and click on login button",
    },
  ];

  return (
    <div className="bg-white flex flex-col gap-[1rem] rounded-md p-[1rem]">
      <div className="flex gap-[0.5rem] items-center text-[#808080] font-semibold">
        <Link to={"/club-owners"}>
          <h2 className="text-[1rem] font-semibold">Club List</h2>
        </Link>
        <Icon
          icon="material-symbols:arrow-forward-ios"
          className="text-[1.1rem]"
        />
        <Link to={`/club-owners/view/${id}`}>
          <h2 className="text-[1rem]">
            {clubName}
            <span className="text-[#808080] text-[0.9rem] font-normal">
              {" "}
              ({clubID}){" "}
            </span>
          </h2>
        </Link>
        <Icon
          icon="material-symbols:arrow-forward-ios"
          className="text-[1.1rem]"
        />
        <Link to={`/club-owners/view/${id}/customers`}>
          <h2 className="text-[1rem]">Customers</h2>
        </Link>
        <Icon
          icon="material-symbols:arrow-forward-ios"
          className="text-[1.1rem]"
        />
        <Link to={`/club-owners/view/${id}/customers/view/${customerId}`}>
          <h2 className="text-[1rem]">
            Customer
            <span className="text-[#808080] text-[0.9rem] font-normal">
              {" "}
              ({customerId}){" "}
            </span>
          </h2>
        </Link>
        <Icon
          icon="material-symbols:arrow-forward-ios"
          className="text-[1.1rem]"
        />
        <Link to={`/club-owners/view/${id}/customers/view/${customerId}`}>
          <h2 className="text-[1rem]">Bookings</h2>
        </Link>
        <Icon
          icon="material-symbols:arrow-forward-ios"
          className="text-[1.1rem]"
        />
        <h2 className="text-[1.2rem] text-black font-bold">{bookingId}</h2>
      </div>
      <div className="flex gap-[2rem] text-[1.2rem] font-medium border-b-2 border-[#989898]">
        {/* <NavLink to={`/club-owners/view/${id}/sports/view/:bookingId`} end>
               {({ isActive }) => ( */}
        <h2
          className={`pb-[0.3rem] font-bold text-primary border-b-2 border-primary`}
        >
          Booking ID: {bookingId}
        </h2>
        {/* )} */}
        {/* </NavLink> */}
      </div>

      <div className="flex-col items-start justify-start mx-6 mt-4 ">
        <div className="text-slate-950 text-lg font-bold mx-4 mb-5">
          Booking Details
        </div>
        <DataTable columns={columns} customStyles={customStyles} data={data} />
      </div>
      {/* <div className="flex-col items-start justify-start mx-6 mt-4 ">
        <div className="text-slate-950 text-lg font-bold mx-4 mb-5">
          Booking
        </div>
        <DataTable columns={columns} customStyles={customStyles} data={data} />
      </div> */}
      {/* <div className="grid grid-cols-2 place-content-end"> */}
      <div></div>
      <div className="flex justify-between px-[2rem]">
        <div className="">
          <h2 className="text-[1.15rem] font-bold my-[1rem]">
            Payment Details
          </h2>
          <div className="grid grid-cols-2 gap-y-[1rem] gap-x-[2rem]">
            <label className="text-[#858585] font-medium">Payment Mode</label>
            <h2 className="font-semibold">
              {specificBookingData?.paymentDetails?.paymentMode}
            </h2>
            <label className="text-[#858585] font-medium">Status</label>
            <div
              className={`flex justify-center items-center gap-[0.5rem] text-[0.9rem] rounded-md font-semibold py-[0.2rem] w-[6rem] ${specificBookingData?.paymentDetails?.status?.toLowerCase() ===
                  "paid"
                  ? "text-[#25BF17] bg-[#D1F0CF]"
                  : "text-[#FF0023] bg-[#FDCAD1]"
                }`}
            >
              <h2>{specificBookingData?.paymentDetails?.status}</h2>
            </div>
            <label className="text-[#858585] font-medium">
              Payment Method{" "}
            </label>
            <h2 className="font-semibold">
              {specificBookingData?.paymentDetails?.paymentMethod}
            </h2>
            {/* <label className="text-[#858585] font-medium">Transaction ID</label>
    <h2 className="font-semibold">TRA123DSD2231</h2> */}
            <label className="text-[#858585] font-medium">Total Amount</label>
            <h2 className="font-semibold">
              ₹ {specificBookingData?.paymentDetails?.totalAmount}
            </h2>
            {/* <label className="text-[#858585] font-medium">Remarks</label> */}
          </div>
        </div>

        {/* <div className="col-span-1">
          <h2 className="text-[1.15rem] font-bold my-[1rem]">Taxes & Fees</h2>
          <div className="grid grid-cols-2 gap-y-[1rem] place-content-between">
            <label className="text-[#858585] font-medium">
              Rent Total Amount
            </label>
            <h2 className="font-semibold">₹ 120.00</h2>
            <label className="text-[#858585] font-medium">
              Booking Charges
            </label>
            <h2 className="font-semibold">₹ 125</h2>
            <label className="text-[#858585] font-medium">
              Convenience Fees
            </label>
            <h2 className="font-semibold">₹ 50</h2>
            <label className="text-[#858585] font-medium">Discount</label>
            <h2 className="font-semibold text-[#F00]">
              (YEVIS12345CLUB) - ₹ 240
            </h2>
            <label className="text-[#858585] font-medium">GST</label>
            <h2 className="font-semibold">₹ 50</h2>
            <label className="text-[#858585] font-medium">Total Amount</label>
            <h2 className="font-semibold">₹ 1250.00</h2>
          </div>
        </div> */}
      </div>
    </div>
    // </div>
  );
}
