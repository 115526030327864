import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

export default function AdminTournaments() {
  useEffect(() => {
    window.scrollTo({
       top: 0,
       behavior: "smooth"
    })
 }, [])
  return (
    <div className='bg-[#E6E6E6] px-[1.5rem] py-[1rem]'>
      <div className=' rounded-md bg-[#FFFFFF] px-[1rem] py-[0.5rem] min-h-screen'>
        <Outlet />
      </div>
    </div>
  )
}
