import React, { useState } from "react";
import { IconButton } from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import { useAuth } from "../../../../store/AuthContext";
import { toast } from "react-toastify";
import { APIurls } from "../../../../api/apiConstant";
import { PulseLoader } from 'react-spinners';

export default function DeletePartcipantModal({ partcipantModal, participantId, participantsrefetch, tournamentRefetch, tournamentId }) {

    const { getAccessToken } = useAuth();
    const [isLoading, setIsLoading] = useState(false)

    const handleDeletePartcipant = async () => {
        setIsLoading(true);
        try {
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.deletePartcipantBasedOnFormat}/${tournamentId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ participantId })
            });
            const result = await response.json();
            if (!response.ok) {
                toast.error("Failed to delete a Partcipant");
                throw new Error(result);
            }
            console.log("Deleting a Partcipant", result);
            participantsrefetch();
            tournamentRefetch();
            partcipantModal(false)
            toast.success("Partcipant Deleted Successfully");
        } catch (error) {
            console.error("Error while deleting participant", error?.message);
        }
        setIsLoading(false)
    }

    return (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-[#000000E6]">
            <div className="flex flex-col gap-4 bg-white rounded-sm px-6 py-8 w-[35%] relative">
                <div className="flex justify-between items-center">
                    <h1 className="text-[20px] font-bold">Delete Partcipant</h1>
                    <IconButton
                        onClick={() => partcipantModal(false)}
                        className={"shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-sm"}
                        size="sm"
                    >
                        <Icon icon="material-symbols:close" className="text-[1.5rem]" />
                    </IconButton>
                </div>
                <div className="w-[100%] h-[100%] text-red-600 bg-red-100 p-4 rounded-md">
                    <p>By adding/removing participants, the game fixation will be regenerated. This means that all the existing results will be lost.
                        Are you sure you want to continue?</p>
                </div>
                <div className="w-full flex items-center justify-start text-white cursor-pointer gap-[2rem]">
                    {isLoading ?
                        <PulseLoader color="red" size={8} />
                        :
                        <div className="">
                            <button
                                type="button"
                                className="text-[0.8rem] sm:text-[1rem] font-semibold bg-red-500 px-6 py-2 rounded-md"
                                onClick={handleDeletePartcipant}
                            >
                                Yes
                            </button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
