import jsPDF from "jspdf";
import "jspdf-autotable";
import { toast } from "react-toastify";
import moment from "moment";

const DownloadSpecificBooking = (bookingId,
    bookingsPdfData,
    bookingsPdfColumns,
    fileName,
    base64String1,
    base64String2,
    clubInformation,
    clubAddress1,
    clubFullAddress,
    customerPdfData,
    customerPdfColumns,
    rentalPdfData,
    rentalPdfColumns,
    canteenPdfData,
    canteenPdfColumns,
    paymentData,
    totalAmountDetails,
    bookingType) => {

    if (bookingsPdfData?.length > 0 && bookingsPdfColumns?.length > 0) {

        const doc = new jsPDF({
            orientation: "portrait",
            unit: "mm",
            format: "a4",
        });

        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const padding = 10;
        let noDataAvailableText = "No Data Available";
        let noDataAvailableTextWidth = (pageWidth / 2 - doc.getTextWidth(noDataAvailableText) / 2);

        let imgHeightTopLeft = 0;

        // Add the top-left image
        if (base64String1) {
            const imgWidthTopLeft = 20;
            imgHeightTopLeft = 20;  // Set image height

            const xPosTopLeft = padding;
            const yPosTopLeft = padding;

            doc.addImage(base64String1, 'PNG', xPosTopLeft, yPosTopLeft, imgWidthTopLeft, imgHeightTopLeft);
        }

        // Construct the text with bookingId
        const text = "BOOKING ID: " + bookingId;
        const textFontSize = 12;
        doc.setTextColor(25, 33, 61);
        doc.setFontSize(textFontSize);

        // Calculate X position for the text (aligned to the right edge with padding)
        const textWidth = doc.getStringUnitWidth(text) * textFontSize / doc.internal.scaleFactor;
        const xPosTopRight = pageWidth - textWidth - padding;
        const yPosTopLeft = padding;

        // Add Booking ID text aligned to the top right
        doc.text(text, xPosTopRight, yPosTopLeft + textFontSize / 2);

        // Set fill and text colors for the "Club Information" text
        const clubInformationHeading = "Club Information: ";
        doc.setFontSize(14);

        // Position the Club Information heading below the top-left image with proper padding
        const clubInfoX = padding;
        const clubInfoY = imgHeightTopLeft + yPosTopLeft + padding;  // Positioned below the image with padding

        doc.text(clubInformationHeading, clubInfoX, clubInfoY);

        // Add the actual club information below the heading
        const clubInformationY = clubInfoY + 7;  // Adjust Y position to add the club information text below the heading

        doc.setFontSize(12);
        doc.text(clubInformation, clubInfoX, clubInformationY);  // Add club information text

        const clubInfoAddressY1 = clubInformationY + 8;  // Add padding of 10 below clubInformation
        doc.setFontSize(10);
        doc.setTextColor(54, 60, 69)
        const multiLineAddress1 = doc.splitTextToSize(clubAddress1, doc.internal.pageSize.width - 20);
        doc.text(multiLineAddress1, clubInfoX, clubInfoAddressY1);

        const clubFullLocationY2 = clubInfoAddressY1 + 8;
        doc.setFontSize(11);
        doc.setTextColor(54, 60, 69)
        const clubFullLocation = doc.splitTextToSize(clubFullAddress, doc.internal.pageSize.width - 20);
        doc.text(clubFullLocation, clubInfoX, clubFullLocationY2);

        const bookingDetailsHeading = "Booking Details: ";
        doc.setFontSize(14);
        doc.setTextColor(0, 0, 0);
        const bookingDetailsY = clubInfoAddressY1 + 18;
        doc.text(bookingDetailsHeading, clubInfoX, bookingDetailsY);

        // Dynamic padding after the booking details table
        let lastTableY = bookingDetailsY + 5;

        // Add center image (if available)

        const totalPages = doc.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
            doc.setPage(i); // Set focus to the current page
            if (base64String2) {
                const imgWidth = 120;
                const imgHeight = 120;
                const xPos = (pageWidth - imgWidth) / 2; //Place the Image at center by calculating Page Width
                const yPos = (pageHeight - imgHeight) / 2;  // Place the Image at center by calculating Page Height

                doc.addImage(base64String2, 'PNG', xPos, yPos, imgWidth, imgHeight);
            }
        }

        // Booking Details Table
        doc.autoTable({
            head: [bookingsPdfColumns],
            body: bookingsPdfData,
            startY: lastTableY,
            headStyles: {
                fillColor: [251, 97, 8],
                fontSize: 10
            },
            styles: {
                minCellHeight: 5,
                fontSize: 10,
            },
            rowPageBreak: "avoid",
        });

        // Get the last position of the booking table
        lastTableY = doc.autoTable.previous.finalY + 10; // Adjust padding dynamically

        // Customer Details Heading
        const customerDetailsHeading = "Customer Details: ";
        doc.setFontSize(14);
        doc.text(customerDetailsHeading, clubInfoX, lastTableY);

        // Customer Details Table
        doc.autoTable({
            head: [customerPdfColumns],
            body: customerPdfData,
            startY: lastTableY + 4,
            headStyles: {
                fillColor: [251, 97, 8],
                fontSize: 10
            },
            styles: {
                minCellHeight: 5,
                fontSize: 10,
            },
            rowPageBreak: "avoid",
        });

        // Update last position after customer details table
        lastTableY = doc.autoTable.previous.finalY + 10;

        const rentDetailsHeading = "Rent Details:";
        doc.setFontSize(14);

        // Draw Rent Details heading
        const rentDetailsHeadingY = lastTableY;
        doc.text(rentDetailsHeading, clubInfoX, rentDetailsHeadingY);

        // Define Y-position for "No data available" text
        let noDataAvailableTextYForRent = rentDetailsHeadingY + 7;  // Update based on heading

        // If there is rental data, render the autoTable
        if (rentalPdfData?.length > 0) {
            doc.autoTable({
                head: [rentalPdfColumns],
                body: rentalPdfData,
                startY: rentDetailsHeadingY + 4,  // Render the table just below the Rent Details heading
                headStyles: {
                    fillColor: [251, 97, 8],
                    fontSize: 10
                },
                styles: {
                    minCellHeight: 5,
                    fontSize: 10,
                },
                rowPageBreak: "avoid",
            });
            // After the table is drawn, update the Y-position to where the table ends
            lastTableY = doc.autoTable.previous.finalY + 10;
        } else {
            // If no data, display "No data available" text and update the Y-position accordingly
            doc.setFontSize(12);
            doc.text(noDataAvailableText, noDataAvailableTextWidth, noDataAvailableTextYForRent);
            // Update lastTableY to account for "No data available" text
            lastTableY = noDataAvailableTextYForRent + 10; // Add some padding after the text
        }

        const canteenDetailsHeading = "Canteen Details:"
        doc.setFontSize(14);
        const canteenDetailsHeadingY = lastTableY;
        doc.text(canteenDetailsHeading, clubInfoX, canteenDetailsHeadingY);

        let noDataAvailableTextYForCanteen = canteenDetailsHeadingY + 8

        if (canteenPdfData?.length > 0) {
            doc.autoTable({
                head: [canteenPdfColumns],
                body: canteenPdfData,
                startY: lastTableY + 5,
                headStyles: {
                    fillColor: [251, 97, 8],
                    fontSize: 10
                },
                styles: {
                    minCellHeight: 5,
                    fontSize: 10,
                },
                rowPageBreak: "avoid",
            });
            // Update last Y-position after Canteen Details table
            lastTableY = doc.autoTable.previous.finalY + 8;
        } else {
            doc.setFontSize(12);
            doc.text(noDataAvailableText, noDataAvailableTextWidth, noDataAvailableTextYForCanteen);
            // Update lastTableY to account for "No data available" text
            lastTableY = noDataAvailableTextYForCanteen + 10; // Add some padding after the text
        }

        const bottomThreshold = 40;  // Minimum height needed before adding a new page

        if ((pageHeight - canteenDetailsHeadingY) < bottomThreshold) {
            doc.addPage();
        }

        const paymentInformationHeading = "Payment Information:";
        const paymentDetailsHeadingY = lastTableY + 5;  // Add some padding from the last section
        doc.setFontSize(14);
        doc.text(paymentInformationHeading, clubInfoX, paymentDetailsHeadingY);

        const paymentDate = paymentData?.paymentDate === undefined
            ? "No Date Available"
            : moment(paymentData?.paymentDate).format("MMM Do YY, h:mm a");
        const paymentDateX = 10;
        const paymentDateY = paymentDetailsHeadingY + 6;
        doc.setFontSize(11);
        doc.text(paymentDate, paymentDateX, paymentDateY);

        let paymentMethodLabel;
        let paymentMethodLabelValue;
        let paymentModeLabel;
        let paymentModeLabelValue;
        let totalAmountLabel;
        let totalAmountValue;
        let rightSectionX;
        let orderIDY;
        let paymentModeLabelY;
        let paymentStatusLabelY;

        if (bookingType === "OFFLINE") {

            //Payment Method Starts//
            paymentMethodLabel = "Payment Method: ";
            const paymentMethodY = paymentDateY + 6;
            doc.setFontSize(12);
            doc.setTextColor(93, 100, 129);
            doc.text(paymentMethodLabel, 10, paymentMethodY);
            const paymentMethodLabelWidth = doc.getTextDimensions(paymentMethodLabel).w;
            paymentMethodLabelValue = paymentData?.paymentMethod
            doc.setFontSize(12);
            doc.setTextColor(0, 0, 0);
            const paymentMethodXPosition = 12 + paymentMethodLabelWidth; // Adjust the offset (30) as needed for spacing
            doc.text(paymentMethodLabelValue, paymentMethodXPosition, paymentMethodY);
            //Payment Method Ends//

            //Payment Mode Starts//
            paymentModeLabel = "Payment Mode: ";
            paymentModeLabelY = paymentMethodY + 6;
            doc.setFontSize(12);
            doc.setTextColor(93, 100, 129);
            doc.text(paymentModeLabel, 10, paymentModeLabelY);
            const paymentModeLabelWidth = doc.getTextDimensions(paymentModeLabel).w;
            doc.setFontSize(12);
            doc.setTextColor(0, 0, 0);
            paymentModeLabelValue = paymentData?.paymentMode;
            const paymentModeXPosition = 15 + paymentModeLabelWidth;
            doc.text(paymentModeLabelValue, paymentModeXPosition, paymentMethodY + 6);
            //Payment Mode Ends//

            // Right section Starts
            rightSectionX = pageWidth - 80; // Adjust this value based on how far from the right edge you want it

            // Booking Amount Section on Right Starts
            const bookingAmountLabel = "Booking Amount: ";
            const bookingAmountValue = Math.round(totalAmountDetails?.bookingAmount)?.toString();
            doc.setFontSize(12);
            doc.setTextColor(93, 100, 129);
            doc.text(bookingAmountLabel, rightSectionX, paymentDetailsHeadingY);
            const bookingAmountLabelWidth = doc.getTextDimensions(bookingAmountLabel).w;
            const bookingValueXPosition = rightSectionX + bookingAmountLabelWidth + 23; // Add spacing between label and value
            doc.setFontSize(12);
            doc.setTextColor(0, 0, 0);
            doc.text(bookingAmountValue, bookingValueXPosition, paymentDetailsHeadingY);
            // Booking Amount Section on Right Ends

            // Rent Amount Section on Right Starts
            const rentAmountLabel = "Rent Amount: ";
            const rentAmountValue = Math.round(totalAmountDetails?.rentalAmount)?.toString();
            doc.setFontSize(12);
            doc.setTextColor(93, 100, 129);
            doc.text(rentAmountLabel, rightSectionX, paymentDetailsHeadingY + 6);
            const rentAmountLabelWidth = doc.getTextDimensions(rentAmountLabel).w;
            const rentValueXPosition = rightSectionX + rentAmountLabelWidth + 29; // Add spacing between label and value
            doc.setFontSize(12);
            doc.setTextColor(0, 0, 0);
            doc.text(rentAmountValue, rentValueXPosition, paymentDetailsHeadingY + 6);
            // Rent Amount Section on Right Ends

            // Canteen Amount Section on Right Starts
            const canteenAmountLabel = "Canteen Amount: ";
            const canteenAmountValue = Math.round(totalAmountDetails?.canteenAmount)?.toString();
            doc.setFontSize(12);
            doc.setTextColor(93, 100, 129);
            doc.text(canteenAmountLabel, rightSectionX, paymentDetailsHeadingY + 12);
            const canteenAmountLabelWidth = doc.getTextDimensions(canteenAmountLabel).w;
            const canteenAmountXPosition = rightSectionX + canteenAmountLabelWidth + 23; // Add spacing between label and value
            doc.setFontSize(12);
            doc.setTextColor(0, 0, 0);
            doc.text(canteenAmountValue, canteenAmountXPosition, paymentDetailsHeadingY + 12);
            // Canteen Amount Section on Right Ends

            // Total Amount Section on Right Starts
            totalAmountLabel = "Total Amount: ";
            totalAmountValue = Math.round(totalAmountDetails?.bookingAmount + totalAmountDetails?.rentalAmount + totalAmountDetails?.canteenAmount)?.toString();
            doc.setFontSize(12);
            doc.text(totalAmountLabel, rightSectionX, paymentDetailsHeadingY + 18);
            const totalAmountLabelWidth = doc.getTextDimensions(totalAmountLabel).w;
            const totalAmountXPosition = rightSectionX + totalAmountLabelWidth + 28; // Add spacing between label and value
            doc.setFontSize(12);
            doc.setTextColor(0, 0, 0);
            doc.text(totalAmountValue, totalAmountXPosition, paymentDetailsHeadingY + 18);
            // Total Amount Section on Right Ends
        } else if (bookingType === "ONLINE" && paymentData?.status === "PENDING") {

            //Payment Method Starts//
            paymentMethodLabel = "Payment Method: ";
            const paymentMethodY = paymentDateY + 6;
            doc.setFontSize(12);
            doc.setTextColor(93, 100, 129);
            doc.text(paymentMethodLabel, 10, paymentMethodY);
            const paymentMethodLabelWidth = doc.getTextDimensions(paymentMethodLabel).w;
            paymentMethodLabelValue = paymentData?.paymentMethod
            doc.setFontSize(12);
            doc.setTextColor(0, 0, 0);
            const paymentMethodXPosition = 12 + paymentMethodLabelWidth; // Adjust the offset (30) as needed for spacing
            doc.text(paymentMethodLabelValue, paymentMethodXPosition, paymentMethodY);
            //Payment Method Ends//

            //Payment Mode Starts//
            paymentModeLabel = "Payment Mode: ";
            const paymentModeLabelY = paymentMethodY + 6;
            doc.setFontSize(12);
            doc.setTextColor(93, 100, 129);
            doc.text(paymentModeLabel, 10, paymentModeLabelY);
            const paymentModeLabelWidth = doc.getTextDimensions(paymentModeLabel).w;
            doc.setFontSize(12);
            doc.setTextColor(0, 0, 0);
            paymentModeLabelValue = paymentData?.paymentMode;
            const paymentModeXPosition = 15 + paymentModeLabelWidth;
            doc.text(paymentModeLabelValue, paymentModeXPosition, paymentMethodY + 6);
            //Payment Mode Ends//

            //Payment Status Starts
            const paymentStatusLabel = "Payment Status: ";
            paymentStatusLabelY = paymentMethodY + 12;
            doc.setFontSize(12);
            doc.setTextColor(93, 100, 129);
            doc.text(paymentStatusLabel, 10, paymentStatusLabelY);
            const paymentStatusLabelWidth = doc.getTextDimensions(paymentStatusLabel).w;
            doc.setFontSize(12);
            doc.setTextColor(0, 0, 0);
            const paymentStatusValue = paymentData?.status;
            const paymentStatusXPosition = 14 + paymentStatusLabelWidth;
            doc.text(paymentStatusValue, paymentStatusXPosition, paymentMethodY + 12);

            rightSectionX = pageWidth - 80;

            //Total Amount for Online if it is PENDING
            totalAmountLabel = "Total Amount: ";
            totalAmountValue = Math.round(paymentData?.totalAmount)?.toString();
            doc.setFontSize(12);
            doc.text(totalAmountLabel, rightSectionX, paymentDetailsHeadingY);
            const totalAmountLabelWidth = doc.getTextDimensions(totalAmountLabel).w;
            const totalAmountXPosition = rightSectionX + totalAmountLabelWidth + 28; // Add spacing between label and value
            doc.setFontSize(12);
            doc.setTextColor(0, 0, 0);
            doc.text(totalAmountValue, totalAmountXPosition, paymentDetailsHeadingY);

        } else if (bookingType === "ONLINE" && paymentData?.status === "PAID") {
            //Payment Method Starts//
            paymentMethodLabel = "Payment Method: ";
            const paymentMethodY = paymentDateY + 6;
            doc.setFontSize(12);
            doc.setTextColor(93, 100, 129);
            doc.text(paymentMethodLabel, 10, paymentMethodY);
            const paymentMethodLabelWidth = doc.getTextDimensions(paymentMethodLabel).w;
            paymentMethodLabelValue = paymentData?.paymentMethod
            doc.setFontSize(12);
            doc.setTextColor(0, 0, 0);
            const paymentMethodXPosition = 12 + paymentMethodLabelWidth; // Adjust the offset (30) as needed for spacing
            doc.text(paymentMethodLabelValue, paymentMethodXPosition, paymentMethodY);
            //Payment Method Ends//

            //Transaction ID Starts
            const transactionIDLabel = "Transaction ID: ";
            doc.setFontSize(12);
            doc.setTextColor(93, 100, 129);
            doc.text(transactionIDLabel, 10, paymentMethodY + 6);
            const transactionIDLabelWidth = doc.getTextDimensions(transactionIDLabel).w;
            const transactionIDValue = paymentData?.transactionID
            doc.setFontSize(12);
            doc.setTextColor(0, 0, 0);
            const transactionIDXPosition = 12 + transactionIDLabelWidth; // Adjust the offset (30) as needed for spacing
            doc.text(transactionIDValue, transactionIDXPosition, paymentMethodY + 6);
            //Transaction ID Ends

            //Order ID Starts
            const orderIDLabel = "Order ID: ";
            doc.setFontSize(12);
            doc.setTextColor(93, 100, 129);
            doc.text(orderIDLabel, 10, paymentMethodY + 12);
            const orderIDLabelWidth = doc.getTextDimensions(orderIDLabel).w;
            const orderIDValue = paymentData?.orderID
            doc.setFontSize(12);
            doc.setTextColor(0, 0, 0);
            orderIDY = paymentMethodY + 12
            const orderIDXPosition = 12 + orderIDLabelWidth; // Adjust the offset (30) as needed for spacing
            doc.text(orderIDValue, orderIDXPosition, orderIDY);
            //Order ID Ends

            //Total Amount For Online Right Section if it is PAID
            rightSectionX = pageWidth - 80;

            totalAmountLabel = "Total Amount: ";
            totalAmountValue = Math.round(paymentData?.totalAmount)?.toString();
            doc.setFontSize(12);
            doc.text(totalAmountLabel, rightSectionX, paymentDetailsHeadingY);
            const totalAmountLabelWidth = doc.getTextDimensions(totalAmountLabel).w;
            const totalAmountXPosition = rightSectionX + totalAmountLabelWidth + 28; // Add spacing between label and value
            doc.setFontSize(12);
            doc.setTextColor(0, 0, 0);
            doc.text(totalAmountValue, totalAmountXPosition, paymentDetailsHeadingY);
        }

        // Get the total number of pages
        const totalNumberOfPages = doc.getNumberOfPages();

        for (let i = 1; i <= totalNumberOfPages; i++) {
            doc.setPage(i);

            // Check if this is the last page
            if (i === totalNumberOfPages) {
                const footerHeight = 35;
                const topPadding = 5;  // Add Padding for top of the footer

                let lastContentY;

                if (bookingType === "ONLINE" && paymentData?.status === "PENDING") {
                    lastContentY = paymentStatusLabelY;
                } else if (bookingType === "ONLINE" && paymentData?.status === "PAID") {
                    lastContentY = orderIDY;
                } else {
                    lastContentY = paymentModeLabelY;
                }

                console.log("Last Content Y--->", lastContentY);

                // Check if there's enough space for the footer
                if ((pageHeight - lastContentY) < footerHeight) {
                    doc.addPage();
                }

                // Define the footer content
                const gamebeezWebSiteName = "www.gamebeez.in";
                const gamebeezContactInfo = "info.yevis@gmail.com / +91 8754403792";
                const yevisAddress = "No# 11, 1st floor, Mosque Street, Eswaran Nagar, Pammal Nagalkeni ink Road, Pammal, Chennai, Tamil Nadu, 600075.";

                doc.setFillColor(246, 248, 252);  // Background fill color
                doc.setFontSize(12);  // Set font size
                doc.setTextColor(77, 77, 77);  // Text color

                // Calculate the Y position for the footer with padding
                const footerY = doc.internal.pageSize.height - footerHeight + topPadding;

                // Draw the footer background rectangle
                doc.rect(0, footerY, pageWidth, footerHeight, 'F');

                // Define Y position for the text inside the footer
                const textY = footerY + 10; 

                // Add the website name on the left side
                doc.text(gamebeezWebSiteName, 10, textY);

                // Add the contact info on the right side
                doc.text(gamebeezContactInfo, pageWidth - 100, textY);

                // Break the address into multiple lines
                const addressLines = doc.splitTextToSize(yevisAddress, pageWidth - 20);  // 20px padding for left and right margins
                doc.text(addressLines, 10, textY + 10);  // Add address below contact info, with 10px space
            }
        }

        doc.save(fileName);
    } else {
        toast.error("No data Available for PDF");
    }
};

export default DownloadSpecificBooking;
