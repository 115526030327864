import React, { useState, useEffect } from 'react'
import { Outlet } from "react-router-dom";

import StaffList from '../AdminModules/staffModule/StaffList';

function Staff() {
  const [updateStaffList, setUpdateStaffList] = useState(false);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }, [])

  return (
    <div className='bg-[#E6E6E6] px-[1.5rem] py-[1rem]'>
      <div className='rounded-md bg-[#FFFFFF] flex-col justify-center items-center px-[1rem] py-[0.5rem]'>

        {/* Heading For Staff */}
        <div className=''>
          <div className="border-b border-[#EAECF0] text-[1.2rem] sm:text-[1.3rem] font-semibold pb-[0.5rem]">Staff</div>
        </div>

        <Outlet context={[setUpdateStaffList]} />

        <StaffList updateStaffList={updateStaffList} setUpdateStaffList={setUpdateStaffList} />

      </div>
    </div >
  )
}

export default Staff
