import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateClubOpeningTimings, updateLocationOperating } from '../../../store/createClubSlice';
import { Checkbox, Option, Select, ThemeProvider } from '@material-tailwind/react';
import { checkboxCustomTheme, selectCustomTheme } from '../../../helpers/constants';
import { stateDistrict } from '../../../helpers/stateDistrict';

const TIMES_DATA = [
  "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM", "12:00 PM", "12:30 PM", "1:00 PM", "1:30 PM", "2:00 PM", "2:30 PM", "3:00 PM", "3:30 PM", "4:00 PM", "4:30 PM", "5:00 PM", "5:30 PM", "6:00 PM", "6:30 PM", "7:00 PM", "7:30 PM", "8:00 PM", "8:30 PM", "9:00 PM", "9:30 PM", "10:00 PM", "10:30 PM", "11:00 PM", "11:30 PM", "12:00 AM", "12:30 AM", "1:00 AM", "1:30 AM", "2:00 AM", "2:30 AM", "3:00 AM", "3:30 AM", "4:00 AM", "4:30 AM", "5:00 AM", "5:30 AM", "6:00 AM", "6:30 AM", "7:00 AM", "7:30 AM", "8:00 AM", "8:30 AM", "9:00 AM", "9:30 AM",
]

export default function LocationOperating({ showError, setError }) {
  // const [error, setError] = useState(false);
  // const [showError, setShowError] = useState(false);
  const {
    addressLine1, addressLine2, city, state, country, pincode, selectedTimingsData, timingSlotsData
  } = useSelector((store) => store.createClub.locationOperating);
  console.log("TIME SLOTS DATA ===>", timingSlotsData);
  const initialState1 = {
    from: null,
    to: null,
  }
  const initialState2 = {
    monday: {
      from: null,
      to: null,
      holiday: false,
    },
    tuesday: {
      from: null,
      to: null,
      holiday: false,
    },
    wednesday: {
      from: null,
      to: null,
      holiday: false,
    },
    thursday: {
      from: null,
      to: null,
      holiday: false,
    },
    friday: {
      from: null,
      to: null,
      holiday: false,
    },
    saturday: {
      from: null,
      to: null,
      holiday: false,
    },
    sunday: {
      from: null,
      to: null,
      holiday: false,
    },
  }
  const [selectedTimings, setSelectedTimings] = useState(selectedTimingsData);
  const [timingSlots, setTimingSlots] = useState(timingSlotsData);
  const dispatch = useDispatch();

  // useEffect(() => {
  //    if (selectedTimings === "Every Day") {
  //       setTimingSlots(initialState1);
  //       dispatch(updateLocationOperating({ timingSlotsData: initialState1 }));
  //    } else {
  //       setTimingSlots(initialState2);
  //       dispatch(updateLocationOperating({ timingSlotsData: initialState2 }));
  //    }
  // }, [selectedTimings]);
  // useEffect(() => {
  //    dispatch(updateClubOpeningTimings(timingSlotsData))
  // }, [timingSlotsData, selectedTimings]);

  const [district, setDistrict] = useState("");

  useEffect(() => {
    if (!(addressLine1 && addressLine2 && city && state && pincode && !(isAnyFieldEmpty(timingSlotsData)))) {
      setError(true);
    } else {
      setError(false);
    }
  }, [addressLine1, addressLine2, city, state, pincode, setError, timingSlotsData]);

  console.log("selected timings", selectedTimings)
  console.log("timing slots", timingSlots);

  const timingSlotChangeHandler = (day, field, value) => {
    setTimingSlots((prevState) => {
      let updatedTimings = {
        ...prevState,
        [day]: {
          ...prevState[day],
          [field]: value,
        }
      }
      dispatch(updateLocationOperating({
        timingSlotsData: updatedTimings,
      }))
      return updatedTimings;
    })
  }

  const isAnyFieldEmpty = (state) => {
    if (Object.keys(state).length === 2) {
      if (state.from && state.to) {
        console.log("Any Field Empty STATE-1 ===> false")
        return false;
      } else {
        console.log("Any Field Empty STATE-1 ===> true")
        return true;
      }
    }
    for (const key in state) {
      const day = state[key];
      // if ((!day?.from && !day?.to) || (!day?.holiday !== true)) {
      if (((!day?.from || !day?.to) && day?.holiday === false)) {
        console.log("Any Field Empty STATE-2 ===> true")
        return true;
      }
    }
    console.log("Any Field Empty STATE-2 ===> false")
    return false;
  };

  const convertUTCToIST = (utcDateString) => {
    const utcDate = new Date(utcDateString);
    const offset = 5.5 * 60; // Offset in minutes (IST is UTC+5:30)
    const istDate = new Date(utcDate.getTime() + offset * 60 * 1000);
    return istDate.toISOString().replace('Z', '');
  };

  const selectedTimingsChangeHandler = (value) => {
    setSelectedTimings(value)
    dispatch(updateLocationOperating({ selectedTimingsData: value }));
    if (value === "Every Day") {
      setTimingSlots(initialState1);
      dispatch(updateLocationOperating({ timingSlotsData: initialState1 }));
    } else {
      setTimingSlots(initialState2);
      dispatch(updateLocationOperating({ timingSlotsData: initialState2 }));
    }
  }

  const everyDayTimeChangeHandler = (time, key) => {
    const [hours, minutes] = time.split(":");
    const date = new Date();
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));
    date.setSeconds(0);
    console.log("CUSTUMISED DATE =====>", date);
    setTimingSlots((prevState) => {
      let updatedTimings = {
        ...prevState,
        [key]: date,
      }
      console.log("UPDATED TIMINGS =====>", updatedTimings);
      dispatch(updateLocationOperating({
        timingSlotsData: updatedTimings,
      }))
      // dispatch(updateClubOpeningTimings(updatedTimings));
      return updatedTimings;
    }
    )
    isAnyFieldEmpty(timingSlotsData);
  }

  const customTimeChangeHandler = (time, key, day) => {
    const [hours, minutes] = time.split(":");
    const date = new Date();
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));
    date.setSeconds(0);
    setTimingSlots((prevState) => {
      let updatedTimings = {
        ...prevState,
        [day]: {
          ...prevState[day],
          [key]: date,
        }
      }
      dispatch(updateLocationOperating({
        timingSlotsData: updatedTimings,
      }))
      return updatedTimings;
    })
  }

  const holidayCheckHandler = (e, day) => {
    console.log(e.target.checked);
    setTimingSlots((prevState) => {
      let updatedTimings = {
        ...prevState,
        [day]: {
          ...prevState[day],
          from: null,
          to: null,
          holiday: e.target.checked,
        }
      }
      dispatch(updateLocationOperating({
        timingSlotsData: updatedTimings,
      }))
      return updatedTimings;
    })
  }

  useEffect(() => {
    setDistrict(city)
  }, [])

  console.log(timingSlotsData)

  return (
    <ThemeProvider value={selectCustomTheme}>
      <form
        className="flex flex-col gap-[2rem]">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-[1.5rem] gap-y-[1.5rem]">
          <div className='flex flex-col gap-[1.5rem] col-span-1'>
            <div className="flex flex-col gap-[0.5rem]">
              <input
                className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                type="text"
                placeholder="Address Line 1"
                value={addressLine1}
                onChange={(e) => {
                  dispatch(updateLocationOperating({ addressLine1: e.target.value }));
                }}
              />
              {showError && !addressLine1 && (
                <div className="text-sm text-[#E92215]">
                  *This Field is Required
                </div>
              )}
            </div>
            <div className="flex flex-col gap-[0.5rem]">
              <input
                className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                type="text"
                placeholder="Address Line 2"
                value={addressLine2}
                onChange={(e) => {
                  dispatch(updateLocationOperating({ addressLine2: e.target.value }));
                }}
              />
              {showError && !addressLine2 && (
                <div className="text-sm text-[#E92215]">
                  *This Field is Required
                </div>
              )}
            </div>
            <div className="flex flex-col gap-[0.5rem]">
              <Select label='State' color='blue-gray' size='lg'
                className='text-black'
                value={state}
                onChange={(value) => {
                  dispatch(updateLocationOperating({ state: value }));
                }}
              >
                {
                  stateDistrict.map((state) => (
                    <Option key={state.name} value={state.name}>{state.name}</Option>
                  ))
                }
              </Select>
              {showError && !state && (
                <div className="text-sm text-[#E92215]">
                  *This Field is Required
                </div>
              )}
            </div>
            <div className="flex flex-col gap-[0.5rem]">
              <Select label='City' color='blue-gray' size='lg' disabled={!state}
                className='text-black'
                value={district}
                onChange={(value) => {
                  dispatch(updateLocationOperating({ city: value }));
                }}
              >
                {
                  state && stateDistrict.find((stateData) => stateData.name === state).districts.map((districtName) => (
                    <Option key={districtName} value={districtName}>{districtName}</Option>
                  ))
                }
              </Select>
              {showError && !city && (
                <div className="text-sm text-[#E92215]">
                  *This Field is Required
                </div>
              )}
            </div>
            <div className="flex flex-col gap-[0.5rem]">
              <input
                className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                type="text"
                placeholder="Pincode"
                value={pincode}
                onChange={(e) => {
                  if (!(/[a-zA-Z]/.test(e.target.value))) {
                    dispatch(updateLocationOperating({ pincode: e.target.value }));
                  }
                }}
              />
              {showError && !pincode && (
                <div className="text-sm text-[#E92215]">
                  *This Field is Required
                </div>
              )}
            </div>
            <div className="flex flex-col gap-[0.5rem]">
              <input
                className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                type="text"
                placeholder="Country"
                readOnly
                value={"India"}
                // onChange={(e) => {
                //   dispatch(updateLocationOperating({ country: e.target.value }));
                // }}
              />
            </div>
          </div>
          <div className=' col-span-2'>
            {
              showError && isAnyFieldEmpty(timingSlotsData) && <p className="text-md text-[#E92215] mb-2">
                *Please entered value for all time input fields</p>
            }
            <div className={`flex ${selectedTimings !== "Every Day" && "flex-col"} gap-[1.5rem]`}>
              <div className={`w-full ${selectedTimings === "Custom" && "grid grid-cols-2"}`}>
                <Select label='Select Timings' color='blue-gray' size='lg'
                  className='text-black'
                  value={selectedTimings}
                  // onChange={(value) => {
                  //    dispatch(updateClubInfo({ businessCategory: value }));
                  // }}
                  onChange={(value) => selectedTimingsChangeHandler(value)}
                >
                  <Option value='Every Day'>Every Day</Option>
                  <Option value='Custom'>Custom</Option>
                </Select>
                {
                  selectedTimings === "Custom" && <div></div>
                }
              </div>
              {
                selectedTimings === "Every Day" && (
                  <div className='flex gap-4 w-full'>
                    {/* <Select label='' color='blue-gray' size='lg' className='text-black'
                              value={timingSlotsData?.everyDay?.from}
                              onChange={(value) => setTimingSlots((prevState) => {
                                 let updatedTimings = {
                                    ...prevState,
                                    everyDay: {
                                       ...prevState.everyDay,
                                       from: value,
                                    }
                                 }
                                 dispatch(updateLocationOperating({
                                    timingSlotsData: updatedTimings,
                                 }))
                                 return updatedTimings;
                              }
                              )}
                           >
                              {
                                 TIMES_DATA.map((timing, index) => {
                                    return (
                                       <Option key={index} value={timing}>{timing}</Option>
                                    )
                                 })
                              }
                           </Select> */}
                    <input type="time"
                      className='bg-[#F6F7FB]'
                      value={timingSlotsData.from !== null ? convertUTCToIST(timingSlotsData?.from).slice(11, 16) : null}
                      onChange={(e) => everyDayTimeChangeHandler(e.target.value, "from")}
                    />
                    <h3 className='font-medium mt-2 text-[1.1rem]'>to</h3>
                    {/* <Select label='' color='blue-gray' size='lg' className='text-black'
                              value={timingSlotsData?.everyDay?.to}
                              onChange={(value) => setTimingSlots((prevState) => {
                                 let updatedTimings = {
                                    ...prevState,
                                    everyDay: {
                                       ...prevState.everyDay,
                                       to: value,
                                    }
                                 }
                                 dispatch(updateLocationOperating({
                                    timingSlotsData: updatedTimings,
                                 }))
                                 return updatedTimings;
                              }
                              )}
                           >
                              {
                                 TIMES_DATA.map((timing, index) => {
                                    return (
                                       <Option key={index} value={timing}>{timing}</Option>
                                    )
                                 })
                              }
                           </Select> */}
                    <input type="time"
                      className='bg-[#F6F7FB]'
                      value={timingSlotsData.to ? convertUTCToIST(timingSlotsData?.to).slice(11, 16) : null}
                      onChange={(e) => everyDayTimeChangeHandler(e.target.value, "to")}
                    />
                  </div>
                )
              }
              <ThemeProvider value={checkboxCustomTheme}>
                {
                  selectedTimings === "Custom" && (
                    <div className='flex flex-col gap-6 w-full'>
                      {/* <div className='flex gap-20 justify-between items-center w-full'> */}
                      <div className='flex gap-6 justify-between items-center w-full'>
                        <h2 className='text-[#7F7F7F] text-[1.15rem] font-medium min-w-[110px]'>
                          Monday
                        </h2>
                        <div className='flex gap-4 w-full items-center'>
                          {/* <Select label='' color='blue-gray' size='lg' className='text-black w-full'
                                    value={timingSlotsData?.custom?.monday?.from}
                                    onChange={(value) => timingSlotChangeHandler("monday", "from", value)}
                                 // onChange={(value) => setTimingSlots((prevState) => (
                                 //    {
                                 //       ...prevState,
                                 //       monday: {
                                 //          ...prevState.monday,
                                 //          from: value,
                                 //       }
                                 //    }
                                 // ))}
                                 >
                                    {
                                       TIMES_DATA.map((timing, index) => {
                                          return (
                                             <Option key={index} value={timing}>{timing}</Option>
                                          )
                                       })
                                    }
                                 </Select> */}
                          <input type="time"
                            className={`bg-[#F6F7FB] ${timingSlotsData.monday.holiday && "cursor-not-allowed bg-blue-gray-50"}`}
                            disabled={timingSlotsData.monday.holiday}
                            value={timingSlotsData.monday.from ? convertUTCToIST(timingSlotsData?.monday?.from).slice(11, 16) : ""}
                            onChange={(e) => customTimeChangeHandler(e.target.value, "from", "monday")}
                          />
                          <h3 className='font-medium text-[1.1rem]'>to</h3>
                          {/* <Select label='' color='blue-gray' size='lg' className='text-black w-full'
                                    value={timingSlotsData?.custom?.monday?.to}
                                    onChange={(value) => timingSlotChangeHandler("monday", "to", value)}
                                 // onChange={(value) => setTimingSlots((prevState) => (
                                 //    {
                                 //       ...prevState,
                                 //       monday: {
                                 //          ...prevState.monday,
                                 //          to: value,
                                 //       }
                                 //    }
                                 // ))}
                                 >
                                    {
                                       TIMES_DATA.map((timing, index) => {
                                          return (
                                             <Option key={index} value={timing}>{timing}</Option>
                                          )
                                       })
                                    }
                                 </Select> */}
                          <input type="time"
                            className={`bg-[#F6F7FB] ${timingSlotsData.monday.holiday && "cursor-not-allowed bg-blue-gray-50"}`}
                            disabled={timingSlotsData.monday.holiday}
                            value={timingSlotsData.monday.to ? convertUTCToIST(timingSlotsData?.monday?.to).slice(11, 16) : ""}
                            onChange={(e) => customTimeChangeHandler(e.target.value, "to", "monday")}
                          />
                          <label htmlFor='checkbox' className='font-medium text-[1.1rem] cursor-pointer ml-8'>Holiday</label>
                          <Checkbox id='checkbox' color='deep-orange'
                            onChange={(e) => holidayCheckHandler(e, "monday")} />
                        </div>
                      </div>
                      <div className='flex gap-6 justify-between items-center w-full'>
                        <h2 className='text-[#7F7F7F] text-[1.15rem] font-medium min-w-[110px]'>Tuesday</h2>
                        <div className='flex gap-4 w-full items-center'>
                          <input type="time"
                            className={`bg-[#F6F7FB] ${timingSlotsData.tuesday.holiday && "cursor-not-allowed bg-blue-gray-50"}`}
                            disabled={timingSlotsData.tuesday.holiday}
                            value={timingSlotsData.tuesday.from ? convertUTCToIST(timingSlotsData?.tuesday?.from).slice(11, 16) : ""}
                            onChange={(e) => customTimeChangeHandler(e.target.value, "from", "tuesday")} />
                          <h3 className='font-medium text-[1.1rem]'>to</h3>
                          <input type="time"
                            className={`bg-[#F6F7FB] ${timingSlotsData.tuesday.holiday && "cursor-not-allowed bg-blue-gray-50"}`}
                            disabled={timingSlotsData.tuesday.holiday}
                            value={timingSlotsData.tuesday.to ? convertUTCToIST(timingSlotsData?.tuesday?.to).slice(11, 16) : ""}
                            onChange={(e) => customTimeChangeHandler(e.target.value, "to", "tuesday")} />
                          <label htmlFor='checkbox1' className='font-medium text-[1.1rem] cursor-pointer ml-8'>Holiday</label>
                          <Checkbox id='checkbox1' color='deep-orange'
                            onChange={(e) => holidayCheckHandler(e, "tuesday")} />
                        </div>
                      </div>
                      <div className='flex gap-6 justify-between items-center w-full'>
                        <h2 className='text-[#7F7F7F] text-[1.15rem] font-medium min-w-[110px]'>Wednesday</h2>
                        <div className='flex gap-4 w-full items-center'>
                          <input type="time"
                            className={`bg-[#F6F7FB] ${timingSlotsData.wednesday.holiday && "cursor-not-allowed bg-blue-gray-50"}`}
                            disabled={timingSlotsData.wednesday.holiday}
                            value={timingSlotsData.wednesday.from ? convertUTCToIST(timingSlotsData?.wednesday?.from).slice(11, 16) : ""}
                            onChange={(e) => customTimeChangeHandler(e.target.value, "from", "wednesday")} />
                          <h3 className='font-medium text-[1.1rem]'>to</h3>
                          <input type="time"
                            className={`bg-[#F6F7FB] ${timingSlotsData.wednesday.holiday && "cursor-not-allowed bg-blue-gray-50"}`}
                            disabled={timingSlotsData.wednesday.holiday}
                            value={timingSlotsData.wednesday.to ? convertUTCToIST(timingSlotsData?.wednesday?.to).slice(11, 16) : null}
                            onChange={(e) => customTimeChangeHandler(e.target.value, "to", "wednesday")} />
                          <label htmlFor='checkbox2' className='font-medium text-[1.1rem] cursor-pointer ml-8'>Holiday</label>
                          <Checkbox id='checkbox2' color='deep-orange'
                            onChange={(e) => holidayCheckHandler(e, "wednesday")} />
                        </div>
                      </div>
                      <div className='flex gap-6 justify-between items-center w-full'>
                        <h2 className='text-[#7F7F7F] text-[1.15rem] font-medium min-w-[110px]'>Thursday</h2>
                        <div className='flex gap-4 w-full items-center'>
                          <input type="time"
                            className={`bg-[#F6F7FB] ${timingSlotsData.thursday.holiday && "cursor-not-allowed bg-blue-gray-50"}`}
                            disabled={timingSlotsData.thursday.holiday}
                            value={timingSlotsData.thursday.from ? convertUTCToIST(timingSlotsData?.thursday?.from).slice(11, 16) : ""}
                            onChange={(e) => customTimeChangeHandler(e.target.value, "from", "thursday")} />
                          <h3 className='font-medium text-[1.1rem]'>to</h3>
                          <input type="time"
                            className={`bg-[#F6F7FB] ${timingSlotsData.thursday.holiday && "cursor-not-allowed bg-blue-gray-50"}`}
                            disabled={timingSlotsData.thursday.holiday}
                            value={timingSlotsData.thursday.to ? convertUTCToIST(timingSlotsData?.thursday?.to).slice(11, 16) : ""}
                            onChange={(e) => customTimeChangeHandler(e.target.value, "to", "thursday")} />
                          <label htmlFor='checkbox3' className='font-medium text-[1.1rem] cursor-pointer ml-8'>Holiday</label>
                          <Checkbox id='checkbox3' color='deep-orange'
                            onChange={(e) => holidayCheckHandler(e, "thursday")} />
                        </div>
                      </div>
                      <div className='flex gap-6 justify-between items-center w-full'>
                        <h2 className='text-[#7F7F7F] text-[1.15rem] font-medium min-w-[110px]'>Friday</h2>
                        <div className='flex gap-4 w-full items-center'>
                          <input type="time"
                            className={`bg-[#F6F7FB] ${timingSlotsData.friday.holiday && "cursor-not-allowed bg-blue-gray-50"}`}
                            disabled={timingSlotsData.friday.holiday}
                            value={timingSlotsData.friday.from ? convertUTCToIST(timingSlotsData?.friday?.from).slice(11, 16) : ""}
                            onChange={(e) => customTimeChangeHandler(e.target.value, "from", "friday")} />
                          <h3 className='font-medium text-[1.1rem]'>to</h3>
                          <input type="time"
                            className={`bg-[#F6F7FB] ${timingSlotsData.friday.holiday && "cursor-not-allowed bg-blue-gray-50"}`}
                            disabled={timingSlotsData.friday.holiday}
                            value={timingSlotsData.friday.to ? convertUTCToIST(timingSlotsData?.friday?.to).slice(11, 16) : ""}
                            onChange={(e) => customTimeChangeHandler(e.target.value, "to", "friday")} />
                          <label htmlFor='checkbox4' className='font-medium text-[1.1rem] cursor-pointer ml-8'>Holiday</label>
                          <Checkbox id='checkbox4' color='deep-orange'
                            onChange={(e) => holidayCheckHandler(e, "friday")} />
                        </div>
                      </div>
                      <div className='flex gap-6 justify-between items-center w-full'>
                        <h2 className='text-[#7F7F7F] text-[1.15rem] font-medium min-w-[110px]'>Saturday</h2>
                        <div className='flex gap-4 w-full items-center'>
                          <input type="time"
                            className={`bg-[#F6F7FB] ${timingSlotsData.saturday.holiday && "cursor-not-allowed bg-blue-gray-50"}`}
                            disabled={timingSlotsData.saturday.holiday}
                            value={timingSlotsData.saturday.from ? convertUTCToIST(timingSlotsData?.saturday?.from).slice(11, 16) : ""}
                            onChange={(e) => customTimeChangeHandler(e.target.value, "from", "saturday")} />
                          <h3 className='font-medium text-[1.1rem]'>to</h3>
                          <input type="time"
                            className={`bg-[#F6F7FB] ${timingSlotsData.saturday.holiday && "cursor-not-allowed bg-blue-gray-50"}`}
                            disabled={timingSlotsData.saturday.holiday}
                            value={timingSlotsData.saturday.to ? convertUTCToIST(timingSlotsData?.saturday?.to).slice(11, 16) : ""}
                            onChange={(e) => customTimeChangeHandler(e.target.value, "to", "saturday")} />
                          <label htmlFor='checkbox5' className='font-medium text-[1.1rem] cursor-pointer ml-8'>Holiday</label>
                          <Checkbox id='checkbox5' color='deep-orange'
                            onChange={(e) => holidayCheckHandler(e, "saturday")} />
                        </div>
                      </div>
                      <div className='flex gap-6 justify-between items-center w-full'>
                        <h2 className='text-[#7F7F7F] text-[1.15rem] font-medium min-w-[110px]'>Sunday</h2>
                        <div className='flex gap-4 w-full items-center'>
                          <input type="time"
                            className={`bg-[#F6F7FB] ${timingSlotsData.sunday.holiday && "cursor-not-allowed bg-blue-gray-50"}`}
                            disabled={timingSlotsData.sunday.holiday}
                            value={timingSlotsData.sunday.from ? convertUTCToIST(timingSlotsData?.sunday?.from).slice(11, 16) : ""}
                            onChange={(e) => customTimeChangeHandler(e.target.value, "from", "sunday")} />
                          <h3 className='font-medium text-[1.1rem]'>to</h3>
                          <input type="time"
                            className={`bg-[#F6F7FB] ${timingSlotsData.sunday.holiday && "cursor-not-allowed bg-blue-gray-50"}`}
                            disabled={timingSlotsData.sunday.holiday}
                            value={timingSlotsData.sunday.to ? convertUTCToIST(timingSlotsData?.sunday?.to).slice(11, 16) : ""}
                            onChange={(e) => customTimeChangeHandler(e.target.value, "to", "sunday")} />
                          <label htmlFor='checkbox6' className='font-medium text-[1.1rem] cursor-pointer ml-8'>Holiday</label>
                          <Checkbox id='checkbox6' color='deep-orange'
                            onChange={(e) => holidayCheckHandler(e, "sunday")} />
                        </div>
                      </div>
                    </div>
                  )
                }
              </ThemeProvider>
            </div>
          </div>
        </div>
      </form>
    </ThemeProvider>
  )
}
