import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { Button, IconButton, Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react';
import moment from 'moment';
import { Icon } from '@iconify/react';
import DataTable from 'react-data-table-component';
import CustomCheckbox from '../../../../helpers/CustomCheckbox';
import { APIurls } from '../../../../api/apiConstant';
import { useAuth } from '../../../../store/AuthContext';
import { useQuery } from 'react-query';

export default function ViewCOSportHTP() {

   const { getAccessToken } = useAuth();

   // const fetchClubData = async () => {
   //    const token = await getAccessToken();
   //    const response = await fetch(`${APIurls.fetchClubs}`, {
   //       method: 'GET',
   //       headers: {
   //          Authorization: `Bearer ${token}`,
   //       }
   //    })
   //    const result = await response.json();
   //    if (!response.ok) {
   //       throw new Error(result.message);
   //    }
   //    console.log("Club List", result?.response);
   //    return result?.response?.CatArray?.reverse();
   // }
   // const { data: clubs, isLoading } = useQuery('clubData', fetchClubData);

   const customStyles = {
      headRow: {
         style: {
            fontWeight: 600,
            // padding: "10px 10px",
            color: "#4D4D4D",
            fontSize: "13px",
         },
      },
      head: {
         style: {
            background: "#FFF",
         },
      },

      rows: {
         style: {
            background: "#FFF",
            color: "#565656",
            fontSize: "14px",
            fontWeight: 400,
            width: "auto",
         },
      },
      table: {
         style: {
            overflow: "visible",
            minWidth: "1100px",
         },
      },
      tableWrapper: {
         style: {
            overflow: "visible",
         },
      },
      responsiveWrapper: {
         style: {
            overflowX: "auto", // table scroll on x axis
         },
      },
   };
   const data = [
      {
         category: "category-1",
      },
      {
         category: "category-2",
      },
      {
         category: "category-3",
      },
      {
         category: "category-4",
      },
      {
         category: "category-5",
      },
      {
         category: "category-6",
      },
   ]
   const columns = [
      {
         name: "SL",
         selector: (row, index) => index + 1,
         center: true,
         maxWidth: "0.5rem",
      },
      {
         name: 'Title',
         id: "title",
         selector: row => (
            <div className='flex flex-col justify-start whitespace-break-spaces'>
               <p><span className='text-[1rem] font-semibold'>Q - </span>This is My Question</p>
               <p><span className='text-[1rem] font-semibold'>A - </span>This is My Answer</p>
            </div>
         ),
         grow: 4,
         // center: true,
      },
      {
         name: 'Category',
         id: "category",
         selector: row => row.category,
         center: true,
      },
      {
         name: "Status",
         id: "status",
         selector: (row) => (
            <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
            py-[0.4rem] w-[6rem] ${row.status !== "Active" ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"}`}>
               <span className={`${row.status !== true ? "bg-[#25BF17]" : "bg-[#FF0023]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
               <h2>{"Active"}</h2>
            </div>
         ),
         center: true,
         grow: 1,
         sortable: true,
      },
      {
         name: "Actions",
         id: "action",
         cell: (row) => (
            <div className="flex gap-[1rem] items-center">
               <Link to={``}>
                  <IconButton
                     className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
                  >
                     <Icon
                        icon="ic:baseline-edit"
                        className="text-[1.5rem] text-black"
                     />
                  </IconButton>
               </Link>
               <IconButton
                  className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
               >
                  <Icon
                     icon="material-symbols:delete-rounded"
                     className="text-[1.5rem] text-black"
                  />
               </IconButton>
            </div>
         ),
         center: true,
         grow: 1,
      },
   ];

   return (
      <div className='flex flex-col gap-[1rem]'>
         <div className='bg-white py-[0.8rem] rounded-md flex flex-col gap-[0.5rem]'>
            <div className='flex items-center justify-between'>
               <div className='flex items-center gap-[3rem]'>
                  <h2 className='text-[1.3rem] font-semibold'>
                     How To Play
                  </h2>
                  <div className="flex py-[0.4rem] px-[1rem] bg-[#FFF] border border-[#DFDFDF] rounded-md">
                     <input
                        type="text"
                        className="w-[18rem] placeholder-[#8F8F8F] text-black text-sm font-semibold outline-none"
                        placeholder="Search by Title"
                     />
                     <Icon icon="ri:search-2-fill"
                        className="p-[0.2rem] bg-primary text-white text-[1.5rem] rounded-[5px] cursor-pointer" />
                  </div>
               </div>
               <div>
                  <div className='flex items-center gap-[2rem]'>
                     <div className='flex items-center gap-[0.4rem] hover:text-primary cursor-pointer'>
                        <Icon
                           icon='material-symbols:delete'
                           className='text-[1.5rem]'
                        />
                        <span className='text-[1rem] font-medium'>Remove</span>
                     </div>
                     <div className='flex items-center gap-[0.3rem] hover:text-[#FB6108] cursor-pointer'>
                        <p className='text-[1rem] font-medium'>Change Status</p>
                        <Icon
                           icon='mingcute:down-line'
                           className='text-[1.5rem]'
                        />
                     </div>
                     <div className="flex flex-row gap-[0.5rem] xs:gap-[1rem] items-center justify-center border border-[#CBCBCB] rounded-[5px]">
                        <div className="">
                           <Menu>
                              <MenuHandler>
                                 <Button className="text-[0.8rem] sm:text-[1rem] flex w-full flex-row justify-center whitespace-nowrap capitalize text-black bg-white px-[0.4rem] xs:px-[1rem] py-[0.4rem] rounded-md items-center gap-[0.5rem] sm:gap-[0.8rem] hover:shadow-none shadow-none">
                                    <Icon
                                       icon="mdi:cloud-download"
                                       className="text-[1.5rem]"
                                    />
                                    <span className="font-[500]">Export</span>
                                 </Button>
                              </MenuHandler>
                              <MenuList>
                                 <MenuItem
                                    //  onClick={downloadPdf}
                                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                                 >
                                    {" "}
                                    <Icon
                                       icon="fa-solid:file-pdf"
                                       className="text-[#BE0A23] w-[1rem] h-auto"
                                    />
                                    Download as PDF
                                 </MenuItem>
                                 <hr className="my-[0.1rem]" />
                                 <MenuItem
                                    //  onClick={downloadCSV}
                                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                                 >
                                    <Icon
                                       icon="fa-solid:file-csv"
                                       className="text-[#29672E] w-[1rem] h-auto"
                                    />
                                    Download as CSV
                                 </MenuItem>
                              </MenuList>
                           </Menu>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div>
               <DataTable
                  columns={columns}
                  customStyles={customStyles}
                  data={data}
                  pagination
                  selectableRows
                  paginationPerPage={10}
                  // progressPending={isLoading}
                  selectableRowsComponent={CustomCheckbox}
               />
            </div>
         </div>
      </div>
   )
}

