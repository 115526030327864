
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Icon } from "@iconify/react";
import "flatpickr/dist/themes/material_green.css";
// import 'flatpickr/dist/flatpickr.min.css';
import {
   IconButton,
   Select,
   Option,
} from "@material-tailwind/react";
import CustomCheckbox from "../../../helpers/CustomCheckbox";
import moment from "moment";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";
import DownloadExcel from '../../../exportData/ExportExcel'
import pdfLogo from '../../../assets/pdfImage.png'
import { FaFileCsv } from "react-icons/fa6";
import DownloadPDF from '../../../exportData/ExportPDF'

export default function StaffList({ updateStaffList, setUpdateStaffList }) {

   const { getAccessToken, userRole, adminName } = useAuth();
   const [status, setStatus] = useState("")
   const [statusdata, setStatusData] = useState([])
   const [loading, setLoading] = useState(false);

   const fetchSubadminsData = async () => {

      const token = await getAccessToken();

      const response = await fetch(`${APIurls.fetchSubadmins}`, {
         method: "GET",
         headers: {
            Authorization: `Bearer ${token}`,
         }
      });
      if (!response.ok) {
         throw new Error("Failed to Fetch Subadmins Data");
      }
      const result = await response.json();

      console.log("SUB ADMINS............", result?.response?.subAdmins);
      setStatusData(result?.response?.subAdmins)
      return result?.response?.subAdmins.reverse();
   };

   const { data: subAdmins, error, isLoading, refetch } = useQuery('subadmins', fetchSubadminsData);

   const customStyles = {
      headRow: {
         style: {
            fontWeight: 600,
            // padding: "10px 10px",
            color: "#4D4D4D",
            fontSize: "13px",
         },
      },
      head: {
         style: {
            background: "#FFF",
         },
      },

      rows: {
         style: {
            background: "#FFF",
            color: "#565656",
            padding: "5px 0px",
            fontSize: "14px",
            fontWeight: 400,
            width: "auto",
         },
      },
      table: {
         style: {
            overflow: "visible",
            minWidth: "1100px",
         },
      },
      tableWrapper: {
         style: {
            overflow: "visible",
         },
      },
      responsiveWrapper: {
         style: {
            overflowX: "auto", // table scroll on x axis
         },
      },
   };
   const columns = [
      {
         name: "SL",
         selector: (row, index) => index + 1,
         center: true,
         maxWidth: "0.5rem",
      },
      {
         name: "Subadmin Id",
         id: "subadminsId",
         selector: (row) => row.subId,
         center: true,
         grow: 3,
      },
      {
         name: "Name",
         id: "name",
         selector: (row) => row.name,
         center: true,
         grow: 3,
      },
      {
         name: "Contact Information",
         id: "contactInformation",
         selector: (row) => (
            <h2>{row.email} <br /> {row.MobileNumber}</h2>
         ),
         grow: 3,
      },
      {
         name: "Created At",
         id: "createdAt",
         selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
         center: true,
         sortable: true,
         grow: 2,
      },
      {
         name: "Status",
         id: "status",
         selector: (row) => (
            <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold w-[6rem] 
        py-[0.4rem] ${row.status === "Inactive" ? "text-[#FF0023] bg-[#FDCAD1]" : "text-[#25BF17] bg-[#D1F0CF]"}`}>
               <span className={`${row.status === "Inactive" ? "bg-[#FF0023]" : "bg-[#25BF17]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
               <h2>{row.status}</h2>
            </div>
         ),
         center: true,
         sortable: true,
         grow: 2,
      },

      (userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("UPDATE_STAFFS")) &&
      {
         name: "Actions",
         id: "action",
         cell: (row) => (
            <div className="flex space-x-2">
               <Link to={`edit/${row.subId}`}>
                  <IconButton
                     className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656]"
                     style={{
                        borderRadius: "5.55px",
                        border: "0.925px solid #D9D9D9",
                        background: "#FFF",
                     }}
                  >
                     <Icon
                        icon="material-symbols:edit-rounded"
                        className="text-[1.5rem]"
                     />
                  </IconButton>
               </Link>
               {/*<Link to={"edit/:id"}>
                  <IconButton
                     className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656]"
                     style={{
                        borderRadius: "5.55px",
                        border: "0.925px solid #D9D9D9",
                        background: "#FFF",
                     }}
                  >
                     <Icon
                        icon="material-symbols:delete-rounded"
                        className="text-[1.5rem]"
                     />
                  </IconButton>
               </Link> */}
            </div>
         ),
         center: true,
         grow: 2,
      },
   ];

   if (updateStaffList) {
      refetch();
      setUpdateStaffList(false);
   }

   const handleSelectStatus = (value) => {
      setStatus(value)
      setStatusData(subAdmins?.filter((data) => data.status === value))
   }

   useEffect(() => {
      if (status === "clear_all") {
         setStatusData(subAdmins)
      }
   }, [status])

   const handleDownloadPDF = async () => {
      setLoading(true);
      try {
         const pdfData = subAdmins?.map((item, index) =>
            [
               index + 1,
               item?.subId,
               item?.name,
               item?.email,
               item?.MobileNumber,
               moment(item?.createdAt).format("DD-MM-YYYY"),
               item?.status,
            ]
         )

         const pdfColumns = ["SL", "Subadmin Id", "Name", "Email", "Mobile No", "Created At", "Status"]

         await DownloadPDF(pdfData, pdfColumns, "Sub Admin.pdf", adminName);
      } catch (error) {
         console.error("Error generating PDF:", error);
      } finally {
         setLoading(false);
      }
   };

   const excelData = subAdmins?.map((item, index) => [
      index + 1,
      item?.subId,
      item?.name,
      item?.email,
      item?.MobileNumber,
      moment(item?.createdAt).format("DD-MM-YYYY"),
      item?.status,
   ])

   const excelColumns = ["SL", "Subadmin Id", "Name", "Email", "Mobile No", "Created At", "Status"]

   return (
      <>
         <div className='bg-white px-[1rem] mt-5 py-[0.8rem] rounded-md flex flex-col gap-[0.5rem]'>
            <div className='flex items-center justify-between'>
               <h2 className='text-[1.3rem] font-semibold'>
                  Manage Staff
               </h2>
               <div>
                  <div className='flex items-center gap-[2rem]'>
                     <div className='flex gap-3 items-center rounded-md'>
                        {loading ? (
                           <p className="text-xs">Generating PDF...</p>
                        ) : (
                           <img
                              src={pdfLogo}
                              className='w-[25px] cursor-pointer'
                              onClick={handleDownloadPDF}
                              alt="Generate PDF"
                           />
                        )}
                        <FaFileCsv
                           className='w-[25px] h-[30px] text-green-800 cursor-pointer'
                           onClick={() => DownloadExcel({ excelData, excelColumns })}
                        />
                     </div>
                     <div className='flex items-center gap-[0.3rem] hover:text-[#FB6108] cursor-pointer'>
                        <Select label='Change Status'
                           value={status}
                           onChange={(value) => handleSelectStatus(value)}
                        >
                           <Option value='active' disabled={status === "active"}>active</Option>
                           <Option value='inactive' disabled={status === "inactive"}>inactive</Option>
                           <Option value="clear_all" disabled={status === "clear_all"}>clear all</Option>
                        </Select>
                     </div>
                  </div>
               </div>
            </div>
            <DataTable
               columns={columns}
               customStyles={customStyles}
               data={statusdata}
               selectableRows
               pagination
               paginationPerPage={10}
               progressPending={isLoading}
               selectableRowsComponent={CustomCheckbox}
            />
         </div>
      </>
   );
}