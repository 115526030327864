import React from 'react'

export default function ClubCard({ name, number }) {
  return (
    <div className='flex flex-col bg-[#FFF3EC] px-[1rem] py-[0.5rem] rounded-[4px] w-full'>
      <h2 className='font-semibold'>
        {name}
      </h2>
      <p className='text-[1.8rem] font-bold'>
        {number}
      </p>
    </div>
  )
}
