import React from 'react'

function Settings() {
  return (
    <div className='bg-[#E6E6E6] h-full'>
      Work is Under Progress Coming Soon...
    </div>
  )
}

export default Settings