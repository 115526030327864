import { useForm } from 'react-hook-form';
import { ThreeDots } from "react-loader-spinner";
import { useState } from 'react'
import { ToastContainer, toast } from "react-toastify";
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";
import { Icon } from "@iconify/react";
import { IconButton } from "@material-tailwind/react";


const CreateMainCategory = (props) => {
   const { getAccessToken } = useAuth()
   const { register, handleSubmit, formState: { errors }, control, reset } = useForm();
   const [loaderBtn, setLoaderBtn] = useState(false);
   const [selectImg, setSelectImg] = useState();
   const [showError, setShowError] = useState(false);

   const onSubmit = async (data) => {
      if (!selectImg) {
         setShowError(true);
         return;
      } else {
         setShowError(false);
      }
      const { categoryName } = data;

      const formData = new FormData();
      formData.append("categoryName", categoryName);
      formData.append("category_img", selectImg);

      const token = await getAccessToken();

      const requestOptions = {
         method: "POST",
         body: formData,
         headers: {
            Authorization: `Bearer ${token}`,
         }
      }

      try {
         setLoaderBtn(true)
         const response = await fetch(APIurls.createCategories, requestOptions)
         const result = await response.json();

         if (!response.ok) {
            setLoaderBtn(false);
            toast.error(result.message);
            // props.setPopUpScreenVisibility(false)
            throw new Error(result.message);
         }
         else {
            setLoaderBtn(false);
            console.log(result.message);;
            props.setPopUpScreenVisibility(false)
            props.setIsRefetch(true);
            toast.success(result.message);
         }
      }
      catch (error) {
         setLoaderBtn(false)
         console.log(error.message);
         // toast.error(error.message);
      }
   }

   return (
      <div className="flex items-center justify-center bg-[#000000E6] h-full w-full absolute top-0 left-0 right-0 bottom-0 z-10">
         <div className="flex flex-col w-[50%] gap-[1.5rem] py-[1.5rem] px-[2rem] bg-white rounded-lg justify-around">
            <div className="flex justify-between items-center">
               <p className="text-[1.3rem] font-semibold">Create Main Category</p>
               <IconButton onClick={() => props.setPopUpScreenVisibility(false)}
                  className="shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8]"
                  size='sm'
                  style={{
                     borderRadius: "5px",
                     border: "1px solid #FF0023",
                  }}
               >
                  <Icon
                     icon="material-symbols:close"
                     className="text-[1.5rem]"
                  />
               </IconButton>
            </div>

            <div className="flex">
               <form onSubmit={handleSubmit(onSubmit)}
                  className="w-full flex flex-col gap-[2rem]">
                  <div className="group flex flex-col gap-[0.5rem] bg-[#EFF1F999]">
                     <div className={`flex flex-row text-[1.1rem] font-[600] group-focus-within:bg-white  group-focus-within:shadow-md border  ${!errors.categoryName ? "border-[#AFBACA]" : "border-red-600"} items-center rounded-md px-[0.5rem] xs:px-[1rem] py-[0.4rem] bg-[#EFF1F999]`}>
                        <input
                           type="text"
                           name="categoryName"
                           placeholder="Main Category Name"
                           className="outline-none w-1 border-none flex-grow placeholder:text-[#858585] bg-[#EFF1F999]"
                           {...register("categoryName", {
                              required: '*This field is required.',
                           })}
                        />
                     </div>
                     {errors.categoryName?.type === "required" && (
                        <p className="text-red-600 text-sm">{errors.categoryName.message}</p>
                     )}
                  </div>
                  <div className='flex flex-col gap-[0.5rem]'>
                     <div className="flex flex-col gap-[1rem] items-center py-[2rem] px-[1rem] border-2 border-dashed
                         border-[#D9D9D9] rounded-lg">
                        {selectImg?.name ? (
                           <>
                              <img src={URL.createObjectURL(selectImg)} alt=""
                                 className='h-[100px] w-[115px] object-cover bg-center' />
                              <div className="flex gap-[1rem] items-center">
                                 <h2 className="text-[1.1rem] text-black font-[500]">
                                    {selectImg?.name.length > 30
                                       ? selectImg.name.substring(0, 30) + "..."
                                       : selectImg.name}
                                 </h2>
                                 <div className='p-[0.1rem] border border-[#D9D9D9] rounded-md hover:bg-[#3D4A5C]/[0.3]'>
                                    <Icon
                                       onClick={() => setSelectImg(null)}
                                       icon="akar-icons:cross"
                                       className="text-[1.2rem] cursor-pointer"
                                    />
                                 </div>
                              </div>
                           </>
                        ) : (
                           <>
                              <button
                                 type="button"
                                 onClick={() =>
                                    document.querySelector(".input-field").click()
                                 }
                              >
                                 <Icon
                                    icon="material-symbols:cloud-upload"
                                    className="text-[#D9D9D9] text-[4rem] mb-[0.5rem]"
                                 />
                              </button>
                              <input
                                 type="file"
                                 accept="image/*"
                                 value=""
                                 className="input-field"
                                 hidden
                                 onChange={({ target: { files } }) => {
                                    if (files[0]) {
                                       setSelectImg(files[0]);
                                    }
                                 }}
                              />
                              <p className="text-[0.9rem] text-[#D9D9D9]">
                                 Upload jpg, png. Size up to 20MB
                              </p>
                           </>
                        )}
                     </div>
                     {showError && !selectImg && (
                        <p className="text-red-600 text-sm">
                           *This field is required.
                        </p>
                     )}
                  </div>
                  <div className="flex justify-end w-full">
                     {loaderBtn ? (
                        <ThreeDots
                           height="50"
                           width="50"
                           radius="9"
                           color="#FB6108"
                           ariaLabel="three-dots-loading"
                           wrapperStyle={{}}
                           wrapperClassName=""
                           visible={true}
                        />
                     ) :
                        (

                           <button
                              type="submit"
                              className="flex items-center gap-[0.5rem] text-white text-[1.1rem] rounded-md px-[1rem] py-[0.6rem] bg-[#FB6108] font-bold"
                           >
                              <span>Create Category</span>
                              <Icon icon="material-symbols:arrow-forward-ios" className="text-[1.3rem]" />
                           </button>
                        )}
                  </div>
               </form>
            </div>

         </div>
         <ToastContainer />
      </div>
   )
}

export default CreateMainCategory;