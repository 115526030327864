import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell } from "recharts";
import "react-datepicker/dist/react-datepicker.css";
import "tailwindcss/tailwind.css";
import DataTable from "react-data-table-component";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";
import { useQuery } from "react-query";
import { Select, Option } from "@material-tailwind/react";
import DownloadExcel from "../../../exportData/ExportExcel";
import pdfLogo from "../../../assets/pdfImage.png";
import { FaFileCsv } from "react-icons/fa6";
import DownloadPDF from "../../../exportData/ExportPDF";
import { RxCross2 } from "react-icons/rx";
import { Icon } from "@iconify/react/dist/iconify.js";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Canteen = () => {
  const [customerActiveText, setCustomerActiveText] = useState("MONTH");
  const [paymentActiveText, setPaymentActiveText] = useState("MONTH");
  const [clubWiseActiveText, setClubWiseActiveText] = useState("MONTH");
  const [customerStartDate, setCustomerStartDate] = useState("");
  const [customerEndDate, setCustomerEndDate] = useState("");
  const [clubStartDate, setClubStartDate] = useState("");
  const [clubEndDate, setClubEndDate] = useState("");
  const [paymentStartDate, setPaymentStartDate] = useState("");
  const [paymentEndDate, setPaymentEndDate] = useState("");
  const [percentageType, setPercentageType] = useState("amount");
  const [loading, setLoading] = useState(false);
  const [searchCustomer, setSearchCustomer] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(searchCustomer);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { getAccessToken ,adminName} = useAuth();

  const fetchTopCustomers = async () => {
    const token = await getAccessToken();
    const response = await fetch(
      `${APIurls.fetchCustomersForCanteenReports}?${
        customerStartDate && customerEndDate
          ? `startDate=${customerStartDate}&endDate=${customerEndDate}`
          : `dateType=${customerActiveText}`
      }&page=${currentPage}&limit=${limit}&text=${debouncedSearch}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    console.log("Response for Customers", result?.response);
    return result?.response;
  };
  const {
    data: topCustomersData,
    isLoading,
    refetch,
  } = useQuery(
    [
      "topCustomers",
      customerStartDate,
      customerEndDate,
      customerActiveText,
      currentPage,
      limit,
      debouncedSearch,
    ],
    fetchTopCustomers
  );
  const fetchClubSales = async () => {
    const token = await getAccessToken();
    const response = await fetch(
      `${APIurls.fetchClubWiseCanteenSalesData}?${
        clubStartDate && clubEndDate
          ? `startDate=${clubStartDate}&endDate=${clubEndDate}`
          : `dateType=${clubWiseActiveText}`
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    console.log(
      "Club Wise Canteen Sales Data",
      result?.response?.topClubsSales
    );
    return result?.response?.topClubsSales;
  };

  const {
    data: clubWiseSalesData,
    isLoading: isclubLoading,
    refetch: clubRefetch,
  } = useQuery(
    ["clubwiseSales", clubStartDate, clubEndDate, clubWiseActiveText],
    fetchClubSales
  );

  const fetchPaymentData = async () => {
    const token = await getAccessToken();
    const response = await fetch(
      `${APIurls.fetchPaymentDataCanteenReports}?${
        paymentStartDate && paymentEndDate
          ? `startDate=${paymentStartDate}&endDate=${paymentEndDate}`
          : `dateType=${paymentActiveText}`
      }&percentageType=${percentageType}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    console.log(
      "Canteen Payment Data",
      result?.response?.paymentModePercentage
    );
    return result?.response?.paymentModePercentage;
  };

  const {
    data: paymentData,
    refetch: paymRefetch,
    isLoading: isPaymentLoading,
  } = useQuery(
    [
      "canteenPaymentData",
      paymentStartDate,
      paymentEndDate,
      paymentActiveText,
      percentageType,
    ],
    fetchPaymentData
  );

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        color: "#4D4D4D",
        fontSize: "13px",
      },
    },
    head: {
      style: {
        background: "#FFF",
      },
    },

    rows: {
      style: {
        background: "#FFF",
        color: "#00000",
        padding: "5px 0px",
        fontSize: "14px",
        fontWeight: 600,
        width: "auto",
      },
    },

    table: {
      style: {
        overflow: "visible",
        minWidth: "570px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "visible", // table scroll on x axis
      },
    },
  };

  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      grow: 1,
    },
    {
      name: "Customer ID",
      selector: (row) => <div>{row?.customerID}</div>,
      // width: ""
      grow: 2.5,
      // center: true,
    },
    {
      name: "Customer Name",
      selector: (row) => <div>{row?.name}</div>,
      // width: ""
      grow: 2.5,
      // center: true,
    },
    {
      name: "Mobile Number",
      selector: (row) => <div>{row?.mobileNumber}</div>,
      // width: ""
      grow: 2.5,
      // center: true,
    },
    {
      name: "Customer Name",
      selector: (row) => <div>{row?.name}</div>,
      // width: ""
      grow: 2.5,
      // center: true,
    },
    {
      name: "Total Purchased",
      selector: (row) => <div>{row?.totalPurchased}</div>,
      grow: 2,
    },
    {
      name: "Total Purchased Amount",
      selector: (row) => <div>{Math.round(row?.totalPurchasedAmount)}</div>,
      grow: 2.6,
    },
  ];

  const columns2 = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      // center: true,
      maxWidth: "0.3rem",
    },
    {
      name: "Club Name",
      id: "clubName",
      selector: (row) => <div>{row?.clubName}</div>,
      // center: true,
    },
    {
      name: "Total Sales",
      selector: (row) => <div>{row?.totalSales}</div>,
    },
    {
      name: "Total Selling Price",
      selector: (row) => <div>{row?.totalSellingPrice}</div>,
    },
    {
      name: "Whole Price",
      selector: (row) => <div>{row?.totalWholePrice}</div>,
    },
    {
      name: "Total Profit",
      selector: (row) => <div>{row?.totalProfit}</div>,
    },
  ];

  // Update debounced search after a delay
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchCustomer);
    }, 1000);

    // Clean up timer if the search term changes
    return () => clearTimeout(handler);
  }, [searchCustomer]);


  const handleDownloadPDF = async () => {
    setLoading(true);
    try {

      const pdfData = clubWiseSalesData?.map((ele, index) => [
        index + 1,
        ele?.clubName,
        ele?.totalSales,
        ele?.totalSellingPrice,
        ele?.totalWholePrice,
        ele?.totalProfit,
      ]);

      const pdfColumns = [
        "SL",
        "Club Name",
        "Total Sales",
        "Total Selling Price",
        "Whole Price",
        "Total Profit",
      ];

      DownloadPDF(
        pdfData,
        pdfColumns,
        "Club Wise Canteen Sales.pdf",
        adminName
      );
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setLoading(false);
    }
  };

  const excelData = clubWiseSalesData?.map((item, index) => [
    index + 1,
    item?.clubName,
    item?.totalSales,
    item?.totalSellingPrice,
    item?.totalWholePrice,
    item?.totalProfit,
  ]);

  const excelColumns = [
    "SL",
    "Club Name",
    "Total Sales",
    "Total Selling Price",
    "Whole Price",
    "Total Profit",
  ];
  const onChangePage = (direction) => {
    if (direction === "left") {
      if (currentPage === 1 || currentPage === 0) {
        return;
      }
      setCurrentPage(currentPage - 1);
    }
    if (direction === "right") {
      if (currentPage === topCustomersData?.totalPages) {
        return;
      }
      setCurrentPage(currentPage + 1);
    }
  };

  const updateTopCustomerStates = (type, value) => {
    switch (type) {
      case "start":
        setCustomerStartDate(value);
        setCustomerActiveText("");
        break;
      case "end":
        setCustomerEndDate(value);
        setCustomerActiveText("");
        break;
      case "dateType":
        setCustomerActiveText(value);
        setCustomerStartDate("");
        setCustomerEndDate("");
        break;
      default:
        console.log("no type match in top Customer");
    }
  };
  const updateTopCanteensStates = (type, value) => {
    switch (type) {
      case "start":
        setPaymentStartDate(value);
        setPaymentActiveText("");
        break;
      case "end":
        setPaymentEndDate(value);
        setPaymentActiveText("");
        break;
      case "dateType":
        setPaymentActiveText(value);
        setPaymentStartDate("");
        setPaymentEndDate("");
        break;
      default:
        console.log("no type match in top Canteen");
    }
  };
  const updateTopClubCanteensStates = (type, value) => {
    switch (type) {
      case "start":
        setClubStartDate(value);
        setClubWiseActiveText("");
        break;
      case "end":
        setClubEndDate(value);
        setClubWiseActiveText("");
        break;
      case "dateType":
        setClubWiseActiveText(value);
        setClubStartDate("");
        setClubEndDate("");
        break;
      default:
        console.log("no type match in top Club canteens");
    }
  };

  return (
    <>
      <div className="flex justify-start items-start p-6">
        <div className="flex flex-col w-full items-center border border-gray-200 p-4 pb-2">
          <div className="w-full flex justify-between items-start pb-6">
            <p className="text-slate-950 text-xl font-semibold max-lg:text-[18px]">
              Top Customers
            </p>
            <div className="flex items-center gap-3 max-lg:flex max-lg:flex-col">
              <div className="flex items-center py-[0.4rem] px-[1rem] bg-[#FFF] border border-[#DFDFDF] rounded-md">
                <input
                  type="text"
                  className="w-[18rem] placeholder-[#8F8F8F] text-black text-sm font-semibold outline-none"
                  placeholder="Search by ID"
                  value={searchCustomer}
                  onChange={(e) => setSearchCustomer(e.target.value)}
                />
                {searchCustomer !== "" && (
                  <RxCross2
                    className="text-[1.3rem] bg-primary text-white rounded-md cursor-pointer mr-1"
                    onClick={() => setSearchCustomer("")}
                  />
                )}
                <Icon
                  icon="ri:search-2-fill"
                  className="p-[0.2rem] bg-primary text-white text-[1.5rem] rounded-[5px] cursor-pointer"
                />
              </div>
              <div className="flex gap-2">
                <div className="flex items-center">
                  <input
                    type="date"
                    value={customerStartDate}
                    onChange={(e) =>
                      updateTopCustomerStates("start", e.target.value)
                    }
                    className="w-28 border rounded px-2 py-1 outline-none"
                    placeholder="From"
                  />
                </div>

                {/* To */}
                <input
                  type="date"
                  value={customerEndDate}
                  onChange={(e) =>
                    updateTopCustomerStates("end", e.target.value)
                  }
                  className="w-28 border rounded px-2 py-1 outline-none"
                  placeholder="To"
                />
              </div>
              {/* Week Month Year */}
              <div className="flex justify-center items-end mx-2">
                <div
                  className={`px-4 py-1 ${
                    customerStartDate !== "" || customerEndDate !== ""
                      ? "bg-[#e3e1e1]"
                      : customerActiveText === "DAY"
                      ? "bg-primary text-white"
                      : "bg-[#e3e1e1]"
                  }  cursor-pointer rounded-sm justify-start items-center`}
                  onClick={() => updateTopCustomerStates("dateType", "DAY")}
                  onDoubleClick={() => setCustomerActiveText("")}
                >
                  <div className="text-neutral-600 text-base font-semibold">
                    Day
                  </div>
                </div>
                <div
                  className={`px-4 py-1 ${
                    customerStartDate !== "" || customerEndDate !== ""
                      ? "bg-[#e3e1e1]"
                      : customerActiveText === "WEEK"
                      ? "bg-orange-800 text-white"
                      : "bg-[#e3e1e1]"
                  } cursor-pointer rounded-sm justify-start items-center`}
                  onClick={() => updateTopCustomerStates("dateType", "WEEK")}
                  onDoubleClick={() => setCustomerActiveText("")}
                >
                  <div className="text-neutral-600 text-base font-semibold ">
                    Week
                  </div>
                </div>
                <div
                  className={`px-4 py-1 ${
                    customerStartDate !== "" || customerEndDate !== ""
                      ? "bg-[#e3e1e1]"
                      : customerActiveText === "MONTH"
                      ? "bg-orange-800 text-white"
                      : "bg-[#e3e1e1]"
                  } cursor-pointer rounded-sm justify-start items-center`}
                  onClick={() => updateTopCustomerStates("dateType", "MONTH")}
                  onDoubleClick={() => setCustomerActiveText("")}
                >
                  <div className="text-neutral-600 text-base font-semibold ">
                    Month
                  </div>
                </div>
                <div
                  className={`px-4 py-1 ${
                    customerStartDate !== "" || customerEndDate !== ""
                      ? "bg-[#e3e1e1]"
                      : customerActiveText === "YEAR"
                      ? "bg-orange-800 text-white"
                      : "bg-[#e3e1e1]"
                  } cursor-pointer rounded-sm justify-start items-center`}
                  onClick={() => updateTopCustomerStates("dateType", "YEAR")}
                  onDoubleClick={() => setCustomerActiveText("")}
                >
                  <div className="text-neutral-600 text-base font-semibold ">
                    Year
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DataTable
            columns={columns}
            customStyles={customStyles}
            data={topCustomersData?.topCustomersCanteens}
            // pagination
            // paginationPerPage={10}
            progressPending={isLoading}
          />
          <div className="mt-2">
            <div className="flex justify-center items-center">
              <p>
                <label>Total Customers : </label>
                <span>{topCustomersData?.totalCustomers}</span>
              </p>
            </div>
            <div className="flex gap-3 justify-center items-center text-[#858585] font-medium">
              <div>
                <label className="text-[13px]">Rows per page:</label>
                <select
                  value={limit}
                  onChange={(e) => {
                    setLimit(+e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={topCustomersData?.totalCustomers}>
                    {topCustomersData?.totalCustomers}
                  </option>
                </select>
              </div>
              <div className="flex gap-1 justify-center items-center ">
                {/* left icon stop*/}
                {/* left icon */}
                <FaAngleDoubleLeft
                  size={25}
                  className={`${
                    currentPage === 1 || currentPage === 0
                      ? "cursor-not-allowed  text-blue-gray-300"
                      : "cursor-pointer active:size-6 active:text-blue-gray-700 text-black"
                  } mr-2`}
                  onClick={() => onChangePage("left")}
                />
                <span className="font-bold">{topCustomersData?.totalPages === 0 ? 0 : currentPage}</span> /{" "}
                <span className="font-bold">
                  {topCustomersData?.totalPages}
                </span>
                {/* right icon */}
                <FaAngleDoubleRight
                  size={25}
                  className={`${
                    currentPage === 1000 || currentPage === 0
                      ? "cursor-not-allowed text-blue-gray-300"
                      : " cursor-pointer active:size-6 active:text-blue-gray-700 text-black"
                  } ml-2`}
                  onClick={() => onChangePage("right")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="items-center gap-2 box-border border border-gray-200 p-4 mx-6">
        <div className="flex justify-between items-center">
          <p className="text-slate-950 text-xl font-semibold max-lg:text-[18px]">
            Payments
          </p>
          <div className="">
            {/* Dates */}
            <div className="flex items-center gap-4 max-xl:flex max-xl:flex-col">
              {/* From */}
              <div className="flex gap-2">
                <input
                  type="date"
                  value={paymentStartDate}
                  onChange={(e) =>
                    updateTopCanteensStates("start", e.target.value)
                  }
                  className="w-28 border rounded px-2 py-1 outline-none"
                  placeholder="From"
                />
                {/* To */}
                <input
                  type="date"
                  value={paymentEndDate}
                  onChange={(e) =>
                    updateTopCanteensStates("end", e.target.value)
                  }
                  className="w-28 border rounded px-2 py-1 outline-none"
                  placeholder="To"
                />
              </div>
              {/* Week Month Year */}
              <div className="flex justify-center items-end mx-2">
                <div
                  className={`${
                    paymentStartDate !== "" || paymentEndDate !== ""
                      ? "bg-[#e3e1e1]"
                      : paymentActiveText === "DAY"
                      ? "bg-orange-800 text-white"
                      : "bg-[#e3e1e1] text-black"
                  } cursor-pointer px-4 py-1 rounded-sm justify-start items-center`}
                  onClick={() => updateTopCanteensStates("dateType", "DAY")}
                  onDoubleClick={() => setPaymentActiveText("")}
                >
                  <div className="text-base font-semibold ">Day</div>
                </div>
                <div
                  className={`${
                    paymentStartDate !== "" || paymentEndDate !== ""
                      ? "bg-[#e3e1e1]"
                      : paymentActiveText === "WEEK"
                      ? "bg-orange-800 text-white"
                      : "bg-[#e3e1e1] text-black"
                  } cursor-pointer px-4 py-1 rounded-sm justify-start items-center`}
                  onClick={() => updateTopCanteensStates("dateType", "WEEK")}
                  onDoubleClick={() => setPaymentActiveText("")}
                >
                  <div className="text-base font-semibold ">Week</div>
                </div>
                <div
                  className={`${
                    paymentStartDate !== "" || paymentEndDate !== ""
                      ? "bg-[#e3e1e1]"
                      : paymentActiveText === "MONTH"
                      ? "bg-orange-800 text-white"
                      : "bg-[#e3e1e1] text-black"
                  } cursor-pointer px-4 py-1 rounded-sm justify-start items-center`}
                  onClick={() => updateTopCanteensStates("dateType", "MONTH")}
                  onDoubleClick={() => setPaymentActiveText("")}
                >
                  <div className=" text-base font-semibold ">Month</div>
                </div>
                <div
                  className={`${
                    paymentStartDate !== "" || paymentEndDate !== ""
                      ? "bg-[#e3e1e1]"
                      : paymentActiveText === "YEAR"
                      ? "bg-orange-800 text-white"
                      : "bg-[#e3e1e1] text-black"
                  } cursor-pointer px-4 py-1 rounded-sm justify-start items-center`}
                  onClick={() => updateTopCanteensStates("dateType", "YEAR")}
                  onDoubleClick={() => setPaymentActiveText("")}
                >
                  <div className=" text-base font-semibold ">Year</div>
                </div>
              </div>
              <div className="hover:text-[#FB6108] cursor-pointer">
                <Select
                  label="Percentage Type"
                  value={percentageType}
                  className=""
                  onChange={(value) => setPercentageType(value)}
                >
                  <Option value="amount" disabled={percentageType === "amount"}>
                    Amount
                  </Option>
                  <Option value="transactions">Transactions</Option>
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center">
          {paymentData?.length === 0 ? (
            <p className="py-6">No Data Available.</p>
          ) : isPaymentLoading ? (
            <p className="text-xl font-semibold py-6">Loading...</p>
          ) : (
            <PieChart width={1000} height={250}>
              <Pie
                data={paymentData}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={110}
                fill="#8884d8"
                dataKey="percentage"
              >
                {paymentData?.map((entry, index) => (
                  <Cell
                    key={`cell - ${index} `}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          )}
        </div>
        <div className="flex flex-col gap-3 w-full px-2">
          {paymentData?.map((data, i) => {
            return (
              <div className="flex justify-between items-center gap-2 w-full">
                <div className="flex items-center gap-4">
                  <div
                    className={`w-6 h-6 rounded`}
                    style={{ backgroundColor: COLORS[i % COLORS.length] }}
                  />
                  <div className="text-black text-base font-medium ">
                    {data.paymentMethod} ({data.percentage}%)
                  </div>
                </div>
                <div className="flex items-center">
                  {percentageType === "transactions" ? (
                    ""
                  ) : (
                    <FaIndianRupeeSign />
                  )}
                  <div className="text-slate-950 text-base font-semibold ">
                    {data.total}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex">
        <div className="w-full items-center border border-gray-200 p-4 m-6">
          <div className="flex justify-between items-start">
            {/* 1st Div */}
            <p className="text-slate-950 text-xl font-semibold max-lg:text-[18px]">
              Club wise Canteen Sales
            </p>
            {/* 2nd Div  */}
            {/* Dates */}
            <div className="flex flex-col items-end gap-2 p-2 max-lg:flex max-lg:flex-col">
              {/* From */}
              <div className="flex justify-center items-end mx-2">
                <div
                  className={`px-4 py-1 ${
                    clubStartDate !== "" || clubEndDate !== ""
                      ? "bg-[#e3e1e1]"
                      : clubWiseActiveText === "DAY"
                      ? "bg-orange-800 text-white"
                      : "bg-[#e3e1e1]"
                  } cursor-pointer rounded-sm justify-start items-center`}
                  onClick={() => updateTopClubCanteensStates("dateType", "DAY")}
                  onDoubleClick={() => setClubWiseActiveText("")}
                >
                  <div className="text-neutral-600 text-base font-semibold ">
                    Day
                  </div>
                </div>
                <div
                  className={`px-4 py-1 ${
                    clubStartDate !== "" || clubEndDate !== ""
                      ? "bg-[#e3e1e1]"
                      : clubWiseActiveText === "WEEK"
                      ? "bg-orange-800 text-white"
                      : "bg-[#e3e1e1]"
                  } cursor-pointer rounded-sm justify-start items-center`}
                  onClick={() =>
                    updateTopClubCanteensStates("dateType", "WEEK")
                  }
                  onDoubleClick={() => setClubWiseActiveText("")}
                >
                  <div className="text-neutral-600 text-base font-semibold ">
                    Week
                  </div>
                </div>
                <div
                  className={`px-4 py-1 ${
                    clubStartDate !== "" || clubEndDate !== ""
                      ? "bg-[#e3e1e1]"
                      : clubWiseActiveText === "MONTH"
                      ? "bg-orange-800 text-white"
                      : "bg-[#e3e1e1]"
                  } cursor-pointer rounded-sm justify-start items-center`}
                  onClick={() =>
                    updateTopClubCanteensStates("dateType", "MONTH")
                  }
                  onDoubleClick={() => setClubWiseActiveText("")}
                >
                  <div className="text-neutral-600 text-base font-semibold ">
                    Month
                  </div>
                </div>
                <div
                  className={`px-4 py-1 ${
                    clubStartDate !== "" || clubEndDate !== ""
                      ? "bg-[#e3e1e1]"
                      : clubWiseActiveText === "YEAR"
                      ? "bg-orange-800 text-white"
                      : "bg-[#e3e1e1]"
                  } cursor-pointer rounded-sm justify-start items-center`}
                  onClick={() =>
                    updateTopClubCanteensStates("dateType", "YEAR")
                  }
                  onDoubleClick={() => setClubWiseActiveText("")}
                >
                  <div className="text-neutral-600 text-base font-semibold ">
                    Year
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <div className="flex gap-3 items-center rounded-md pr-2">
                  {loading ? (
                    <p className="text-xs">Generating PDF...</p>
                  ) : (
                    <img
                      src={pdfLogo}
                      className="w-[25px] cursor-pointer"
                      onClick={handleDownloadPDF}
                      alt="Generate PDF"
                    />
                  )}
                  <FaFileCsv
                    className="w-[25px] h-[30px] text-green-800 cursor-pointer"
                    onClick={() => DownloadExcel({ excelData, excelColumns })}
                  />
                </div>
                <div className="flex gap-2">
                  <input
                    type="date"
                    value={clubStartDate}
                    onChange={(e) =>
                      updateTopClubCanteensStates("start", e.target.value)
                    }
                    className="w-28 border  rounded px-2 py-1 outline-none"
                    placeholder="From"
                  />
                  {/* To */}
                  <input
                    type="date"
                    value={clubEndDate}
                    onChange={(e) =>
                      updateTopClubCanteensStates("end", e.target.value)
                    }
                    className="w-28 border rounded px-2 py-1 outline-none"
                    placeholder="To"
                  />
                </div>
              </div>
            </div>
          </div>
          <DataTable
            columns={columns2}
            customStyles={customStyles}
            data={clubWiseSalesData}
            pagination
            paginationPerPage={10}
            progressPending={isclubLoading}
          />
        </div>
      </div>
    </>
  );
};

export default Canteen;
