import React, { useEffect, useState } from "react"
import { IconButton } from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import { PulseLoader } from "react-spinners";
import { APIurls } from "../../../../api/apiConstant";
import { useAuth } from "../../../../store/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import { LiaGreaterThanSolid } from "react-icons/lia";

export default function EditCoClubOwner({ setEditCoClubOwnerModal, coClubOwnerId, existingEmail, setRefetch }) {

    const [email, setEmail] = useState("");
    const [showError, setShowError] = useState(false);
    const [loader, setLoader] = useState(false);

    const { getAccessToken } = useAuth();

    const handleSaveCoClubOwner = async () => {

        if (!email) {
            setShowError(true);
            return;
        }

        try {
            setLoader(true);
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.updateCoClubOwner}/${coClubOwnerId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ "email": email })
            })
            const result = await response.json();
            if (result.code > 201) {
                toast.error(result?.message);
                throw new Error(result.message);
            }
            console.log("Co-Club Owner Email Updated Successfully", result?.response);
            setLoader(false);
            setRefetch(true);
            toast.success("Email Updated Successfully");
            setTimeout(() => { setEditCoClubOwnerModal(false) }, 2000);
            return;
        } catch (error) {
            toast.error(error?.message);
            console.error("Error while Updating Co-Club Owner Email", error.message)
        }
        setLoader(false);
    }

    useEffect(() => {
        setEmail(existingEmail);
    }, [])

    return (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-[#000000E6]">
            <div className="flex flex-col gap-4 bg-white rounded-sm px-6 py-8 w-[25%] relative">
                <div className="flex items-center justify-between">
                    <h3 className="text-xl font-semibold">Edit Co-Club Owner</h3>
                    <IconButton
                        onClick={() => setEditCoClubOwnerModal(false)}
                        className={"shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-sm"}
                        size="sm"
                    >
                        <Icon icon="material-symbols:close" className="text-[1.5rem]" />
                    </IconButton>
                </div>
                <div className="w-[100%]">
                    <input
                        placeholder="Enter Email"
                        className="w-[100%] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {!email && showError && (
                        <p className="text-red-500 text-xs">*This field is required</p>
                    )}
                </div>
                <div>
                    {loader ? <PulseLoader color="orange" size={8} />
                        :
                        <button
                            className="w-[90px] flex justify-center items-center gap-1 text-white bg-primary px-2 py-2 rounded-md"
                            onClick={handleSaveCoClubOwner}
                        >
                            Save
                            <LiaGreaterThanSolid />
                        </button>
                    }
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}