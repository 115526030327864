import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@material-tailwind/react';
import { selectCustomTheme } from '../../../helpers/constants';
import { useAuth } from '../../../store/AuthContext';
import { APIurls } from '../../../api/apiConstant';
import { useParams } from 'react-router-dom';
import DataTable from "react-data-table-component";
import CustomCheckbox from '../../../helpers/CustomCheckbox';
import { Icon } from "@iconify/react";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import DownloadExcel from '../../../exportData/ExportExcel'
import pdfLogo from '../../../assets/pdfImage.png'
import { FaFileCsv } from "react-icons/fa6";
import DownloadPDF from '../../../exportData/ExportPDF'

export default function ViewPayOuts() {

    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [specificPayoutData, setSpecificPayoutData] = useState([]);
    const [loading, setLoading] = useState(false);

    const { getAccessToken, adminName } = useAuth();
    const { _id } = useParams();
    const navigate = useNavigate();

    const fetchSpecificPayout = async () => {
        const token = await getAccessToken();
        setIsLoading(true);
        const response = await fetch(`${APIurls.fetchSpecificClubPayOut}/${_id}?page=${currentPage}&limit=${recordsPerPage}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        if (!response.ok) {
            const result = await response.json();
            throw new Error(result?.message);
        }
        const result = await response.json();
        console.log("Specific Club Payout fetched Successfully", result?.response)
        setSpecificPayoutData(result?.response);
        setIsLoading(false);
        return result?.response
    }

    useEffect(() => {
        fetchSpecificPayout();
    }, [])

    useEffect(() => {
        fetchSpecificPayout();
    }, [recordsPerPage, currentPage])

    const columns = [
        {
            name: "SL",
            selector: (row, i) => i + 1,
            center: true,
            width: "80px"
        },
        {
            name: "Booking ID",
            selector: row => (
                row?.BookingId
            ),
            center: true,
            grow: 1.8
        },
        {
            name: "Category",
            selector: row => (
                row?.AreanaID === null ? "-" : row?.AreanaID?.Areana_category?.categoryName
            ),
            center: true
        },
        {
            name: "Arena Name",
            selector: row => (
                row?.AreanaID === null ? "-" : row?.AreanaID?.Areana_name
            ),
            center: true
        },
        {
            name: "Discount Type",
            selector: row => (
                <div>No Discount</div>
            ),
            center: true,
            grow: 1.5
        },
        {
            name: "Discount Amount",
            selector: row => (
                <div>0</div>
            ),
            center: true,
            grow: 1.4
        },
        {
            name: "Selected Date",
            selector: row => (
                moment(row?.SelectedDate).format("MMM Do YY")
            ),
            center: true,
            grow: 1.5
        },
        {
            name: "Duration",
            selector: row => (
                <div>
                    <p>{moment(row?.TimeSlots[0]?.time).format("LT")}</p>
                    <p>{moment(row?.TimeSlots[row?.TimeSlots?.length - 1]?.time).format("LT")}</p>
                </div>
            ),
            center: true
        },
        {
            name: "Amount",
            selector: row => (
                row?.PaymentDetails?.TotalAmt
            ),
            center: true
        }
    ]

    const customStyles = {
        headRow: {
            style: {
                fontWeight: 600,
                // padding: "10px 10px",
                color: "#4D4D4D",
                fontSize: "13px",
            },
        },
        head: {
            style: {
                background: "#FFF",
            },
        },

        rows: {
            style: {
                background: "#FFF",
                color: "#565656",
                padding: "5px 0px",
                fontSize: "14px",
                fontWeight: 400,
                width: "auto",
            },
        },
        table: {
            style: {
                overflow: "visible",
                minWidth: "1100px",
                maxWidth: "1400px",
            },
        },
        tableWrapper: {
            style: {
                overflow: "visible",
            },
        },
        responsiveWrapper: {
            style: {
                overflowX: "auto", // table scroll on x axis
            },
        },
    };

    const handleDirection = (direction) => {
        if (direction === "right") {
            const currPg = currentPage + 1;
            if (currentPage === specificPayoutData?.pagination?.totalPage) {
                setCurrentPage(specificPayoutData?.pagination?.totalPage);
            } else {
                setCurrentPage(currPg);
            }
        } else if (direction === "left") {
            const currPg = currentPage - 1;
            if (currentPage === 1) {
                setCurrentPage(1);
            } else {
                setCurrentPage(currPg);
            }
        }
    };

    const handleDownloadPDF = async () => {
        setLoading(true);
        try {
            const pdfData = specificPayoutData?.booking?.map((ele, index) => [
                index + 1,
                ele?.BookingId,
                ele?.AreanaID === null ? "-" : ele?.AreanaID?.Areana_category?.categoryName,
                ele?.AreanaID === null ? "-" : ele?.AreanaID?.Areana_name,
                "No Discount",
                "0",
                moment(ele?.SelectedDate).format("MMM Do YY"),
                moment(ele?.TimeSlots[0]?.time).format("LT") + "-" + moment(ele?.TimeSlots[ele?.TimeSlots?.length - 1]?.time).format("LT"),
                ele?.PaymentDetails?.TotalAmt
            ]);

            const pdfColumns = ["SL", "Booking ID", "Category", "Arena Name", "Discount Type", "Discount Amount", "Selected Date", "Duration", "Amount"];

            await DownloadPDF(pdfData, pdfColumns, "Specific Club Payout.pdf", adminName);
        } catch (error) {
            console.error("Error generating PDF:", error);
        } finally {
            setLoading(false);
        }
    };

    const excelData = specificPayoutData?.booking?.map((item, index) => [
        index + 1,
        item?.BookingId,
        item?.AreanaID === null ? "-" : item?.AreanaID?.Areana_category?.categoryName,
        item?.AreanaID === null ? "-" : item?.AreanaID?.Areana_name,
        "No Discount",
        "0",
        moment(item?.SelectedDate).format("MMM Do YY"),
        moment(item?.TimeSlots[0]?.time).format("LT") + "-" + moment(item?.TimeSlots[item?.TimeSlots?.length - 1]?.time).format("LT"),
        item?.PaymentDetails?.TotalAmt
    ])

    const excelColumns = ["SL", "Booking ID", "Category", "Arena Name", "Discount Type", "Discount Amount", "Selected Date", "Duration", "Amount"];

    return (
        <ThemeProvider value={selectCustomTheme}>
            <div className="bg-[#E6E6E6] px-[1.5rem] py-[1rem]">
                <div className="rounded-md bg-[#FFFFFF] px-[1rem] py-[0.5rem]">
                    <button
                        className='text-primary flex items-center border border-primary rounded-md px-2 py-1 mt-2'
                        onClick={() => navigate("/payouts")}
                    >
                        <Icon icon="ic:twotone-less-than" className='text-[1.2rem]' />
                        Back
                    </button>
                    <div className='flex items-center justify-between border-b border-[#EAECF0]'>
                        <h3 className="mt-6 ml-3 text-[1.2rem] sm:text-[1.3rem] font-semibold pb-[0.5rem]">
                            Specific Club Pay Outs
                        </h3>
                        <div className='flex gap-3 items-center rounded-md'>
                            {loading ? (
                                <p className="text-xs">Generating PDF...</p>
                            ) : (
                                <img
                                    src={pdfLogo}
                                    className='w-[25px] cursor-pointer'
                                    onClick={handleDownloadPDF}
                                    alt="Generate PDF"
                                />
                            )}
                            <FaFileCsv
                                className='w-[25px] h-[30px] text-green-800 cursor-pointer'
                                onClick={() => DownloadExcel({ excelData, excelColumns })}
                            />
                        </div>
                    </div>
                    <div className='px-[2rem] py-[2.5rem]'>
                        {isLoading ? <p className='text-xl font-semibold text-center'>Loading...</p>
                            :
                            <DataTable
                                columns={columns}
                                data={specificPayoutData?.booking}
                                selectableRows
                                selectableRowsComponent={CustomCheckbox}
                                customStyles={customStyles}
                            />
                        }
                        {specificPayoutData?.pagination?.totalDocuments > 0 &&
                            <div className="flex justify-center items-center gap-2 py-2">
                                <button
                                    className={`${currentPage === 1 ? "cursor-not-allowed text-gray-500" : "cursor-pointer"} rounded-md px-2 border-2`}
                                    onClick={() => handleDirection("left")}
                                >Prev</button>
                                <span>
                                    {currentPage} / {Math.ceil(specificPayoutData?.pagination?.totalDocuments / recordsPerPage)}
                                </span>
                                <button
                                    className={`${currentPage === specificPayoutData?.pagination?.totalPage ? "cursor-not-allowed text-gray-500" : "cursor-pointer"} rounded-md px-2 border-2`}
                                    onClick={() => handleDirection("right")}
                                >Next</button>
                                <div className="flex items-center">
                                    <span>Per Page:</span>
                                    <select
                                        value={recordsPerPage}
                                        onChange={(e) => { setRecordsPerPage(+e.target.value); setCurrentPage(1); }}
                                        className="border outline-none ml-2"
                                    >
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={specificPayoutData?.pagination?.totalDocuments}>{specificPayoutData?.pagination?.totalDocuments}</option>
                                    </select>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </ThemeProvider>
    )
}
