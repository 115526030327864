import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  IconButton,
} from "@material-tailwind/react";
import moment from "moment";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import CustomCheckbox from "../../../../helpers/CustomCheckbox";
import { APIurls } from "../../../../api/apiConstant";
import { useAuth } from "../../../../store/AuthContext";
import { useQuery } from "react-query";
import { ToastContainer } from "react-toastify";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { RxCross2 } from "react-icons/rx";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";

export default function ViewCOBookings() {
  const [date, setDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const { id } = useParams();
  const { getAccessToken } = useAuth();
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalBookings, setTotalBookings] = useState(0);

  const fetchClubOwnerBookings = async () => {
    const token = await getAccessToken();
    const response = await fetch(
      `${APIurls.fetchClubOwnerBookingData}/${id}?page=${currentPage}&limit=${limit}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    if (result?.response?.currentPage !== currentPage) {
      setCurrentPage(result?.response?.currentPage);
    }
    setTotalPages(result?.response?.totalPages);
    setTotalBookings(result?.response?.totalBookings);
    console.log("View Co Bookings-->", result?.response);
    return result?.response?.bookings;
  };

  const { data: bookingsData, isLoading } = useQuery(["ClubBooking", currentPage, limit], fetchClubOwnerBookings);

  useEffect(() => {
    if (bookingsData) {
      if (date) {
        setFilteredData(
          bookingsData?.filter((booking) =>
            moment(booking?.selectedDate).isSame(moment(date), "day")
          )
        );
      } else {
        setFilteredData(bookingsData);
      }
    }
  }, [date, bookingsData]);

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        color: "#4D4D4D",
        fontSize: "13px",
      },
    },
    head: {
      style: {
        background: "#FFF",
      },
    },
    rows: {
      style: {
        background: "#FFF",
        color: "#565656",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
        padding: "5px 0px",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1100px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };

  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      maxWidth: "0.5rem",
    },
    {
      name: "Booking ID",
      selector: (row) => row.BookingId,
      center: true,
      grow: 1.5,
    },
    {
      name: "Customer Names",
      selector: (row) => (
        <div>
          {row.offline === true ? (
            <div>
              {row.customerName.map((div, i) => (
                <p key={i}>{div}</p>
              ))}
            </div>
          ) : (
            <p className="mt-2">{row.customerName}</p>
          )}
        </div>
      ),
      center: true,
      grow: 2,
    },
    {
      name: "Mobile Number",
      selector: (row) => (
        <div>
          {row.offline === true ? (
            <div>
              {row.mobileNumber.map((div, i) => (
                <p key={i}>{div}</p>
              ))}
            </div>
          ) : (
            <p className="mt-2">{row.mobileNumber}</p>
          )}
        </div>
      ),
      center: true,
      grow: 2,
    },
    {
      name: "Date of Booking",
      selector: (row) => <p>{moment(row.dateOfBooking).format("MMM Do YY")}</p>,
      center: true,
      grow: 1,
    },
    {
      name: "Selected Date of Booking",
      selector: (row) => <p>{moment(row.selectedDate).format("MMM Do YY")}</p>,
      center: true,
      grow: 1,
    },
    {
      name: "Time Slot",
      selector: (row, i) => {
        return (
          <div>
            {row.timeSlots.map((div, i) => {
              const startDate = new Date(div?.start);
              const endDate = new Date(div?.end);
              return (
                <div className="mt-2" key={i}>
                  <p>Start: {moment(startDate).format("LTS")}</p>
                  <p>End: {moment(endDate).format("LTS")}</p>
                </div>
              );
            })}
          </div>
        );
      },
      center: true,
      grow: 3,
    },
    {
      name: "Sports",
      selector: (row) => <p>{row.sport}</p>,
      center: true,
    },
    {
      name: "Total Amount",
      selector: (row) => <p>{row.totalAmount}</p>,
      center: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <div
          className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
            py-[0.4rem] w-[6.6rem] ${row.status.toLowerCase() === "paid"
              ? "text-[#25BF17] bg-[#D1F0CF]"
              : "text-[#FF0023] bg-[#FDCAD1]"
            }`}
        >
          <span
            className={`${row.status.toLowerCase() === "paid"
              ? "bg-[#25BF17]"
              : "bg-[#FF0023]"
              } h-[0.5rem] w-[0.5rem] rounded-full`}
          ></span>
          <h2>{row.status}</h2>
        </div>
      ),
      center: true,
      grow: 1.8,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex gap-[0.5rem]">
          <Link to={`view/${row.BookingId}`}>
            <IconButton className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]">
              <Icon icon="mdi:eye" className="text-[1.5rem] text-black" />
            </IconButton>
          </Link>
          <Link to={``}>
            <IconButton className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]">
              <Icon
                icon="material-symbols:print"
                className="text-[1.5rem] text-black"
              />
            </IconButton>
          </Link>
        </div>
      ),
      center: true,
      grow: 1,
    },
  ];

  const handleDateChange = (selectedDates) => {
    const newDate = selectedDates[0];
    setDate(newDate);
  };

  const onChangePage = (direction) => {
    console.log("Page changed");
    console.log("page : ", currentPage);
    if (direction === "left") {
      if (currentPage === 1 || currentPage === 0) {
        return;
      }
      setCurrentPage(currentPage - 1);
    }
    if (direction === "right") {
      if (currentPage === totalPages) {
        return;
      }
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="bg-white rounded-md flex flex-col gap-[0.5rem]">
      <div className="flex items-center justify-between">
        <h2 className="text-[1.3rem] font-semibold">Bookings</h2>
        <div>
          <div className="flex items-center gap-[2rem]">
            <div className="flex items-center border rounded px-2 py-1">
              <Flatpickr
                value={date}
                onChange={handleDateChange}
                options={{ dateFormat: "Y-m-d" }}
                className="flatpickr-input border-0 outline-none flex-grow"
                placeholder="Select Date"
              />
              {date !== "" && (
                <RxCross2
                  className="text-[1.2rem] cursor-pointer bg-red-500 text-white rounded-md"
                  onClick={() => setDate("")}
                />
              )}
            </div>
            {/* <div className='flex gap-3 items-center rounded-md'>
              {loading ? (
                <div className="loading-indicator">Generating PDF...</div>
              ) : (
                <img
                  src={pdfLogo}
                  className='w-[25px] cursor-pointer'
                  onClick={handleDownloadPDF}
                  alt="Generate PDF"
                />
              )}
              <FaFileCsv
                className='w-[25px] h-[30px] text-green-800 cursor-pointer'
                onClick={() => DownloadExcel({ excelData, excelColumns })}
              />
            </div> */}
          </div>
        </div>
      </div>
      <div>
        <DataTable
          columns={columns}
          customStyles={customStyles}
          data={filteredData}
          pagination
          selectableRows
          progressPending={isLoading}
          selectableRowsComponent={CustomCheckbox}
        />
      </div>
      <div className="">
        <div className="flex justify-center items-center">
          <p>
            <label>Total Bookings : </label>
            <span>{totalBookings}</span>
          </p>
        </div>
        <div className="flex gap-3 justify-center items-center text-[#858585] font-medium">
          <div>
            <label className="text-[13px]">Rows per page:</label>
            <select
              value={limit}
              onChange={(e) => {
                setLimit(+e.target.value);
                setCurrentPage(1);
              }}
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={50}>50</option>
              {/* <option value={100}>100</option> */}
            </select>
          </div>
          <div className="flex gap-1 justify-center items-center ">
            {/* left icon */}
            <FaAngleDoubleLeft
              size={25}
              className={`${currentPage === 1 || currentPage === 0
                ? "cursor-not-allowed  text-blue-gray-300"
                : "cursor-pointer active:size-6 active:text-blue-gray-700 text-black"
                } mr-2`}
              onClick={() => onChangePage("left")}
            />
            <span className="font-bold">{currentPage}</span> /{" "}
            <span className="font-bold">{totalPages}</span>
            {/* right icon */}
            <FaAngleDoubleRight
              size={25}
              className={`${currentPage === totalPages || currentPage === 0
                ? "cursor-not-allowed text-blue-gray-300"
                : " cursor-pointer active:size-6 active:text-blue-gray-700 text-black"
                } ml-2`}
              onClick={() => onChangePage("right")}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
