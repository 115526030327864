import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sportInfo: {
        maincategoryId: null,
        subCategoryName: "",
        subcat_img: "",
        minPlayers: "",
        maxPlayers: "",
    },
    howToPlayInfo: {
        question: "",
        answer: "",
        qna: [],
    },
    loaderBtn: false,
}

const createSubCategorySlice = createSlice({
    name: 'createSubCategory',
    initialState: initialState,
    reducers: {
        updateSportInfo(state, action) {
            state.sportInfo = { ...state.sportInfo, ...action.payload };
        },
        updateHowToPlayInfo(state, action) {
            state.howToPlayInfo = { ...state.howToPlayInfo, ...action.payload };
        },
        updateQuestionAnswer(state, action) {
            state.questionAnswer = { ...state.questionAnswer, ...action.payload };
        },
        updateLoaderBtn(state, action) {
            state.loaderBtn = action.payload;
        },
    }
})

export const { updateSportInfo, updateHowToPlayInfo, updateQuestionAnswer, updateLoaderBtn } = createSubCategorySlice.actions;

export default createSubCategorySlice.reducer;