import { RouterProvider } from "react-router-dom";
import { router } from "./routes/Routes";
import { AuthContextProvider } from "./store/AuthContext";
import { QueryClientProvider, QueryClient } from "react-query";

function App() {

  const queryClient = new QueryClient();

  return (
    <AuthContextProvider>
      <QueryClientProvider client={queryClient}>
        <div className="relative font-exo">
          <RouterProvider router={router} />
        </div>
      </QueryClientProvider>

    </AuthContextProvider>

  );
}

export default App;
