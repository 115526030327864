import React, { useEffect, useState } from 'react'
import { LiaGreaterThanSolid } from "react-icons/lia";
import { GrDown } from "react-icons/gr";
import { IconButton } from '@material-tailwind/react';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../store/AuthContext';
import { APIurls } from '../../../api/apiConstant';
import { toast } from 'react-toastify';
import { RotatingLines } from "react-loader-spinner";
import { PulseLoader } from 'react-spinners';

export default function ViewFAQ() {

    const [specificFAQData, setSpecificFAQData] = useState("")
    const [faqDataLoading, setFAQDataLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { getAccessToken } = useAuth();
    const { id } = useParams();
    const navigate = useNavigate();

    const fetchSpecificFaq = async () => {
        setFAQDataLoading(true)
        try {
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.fetchFAQs}`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            const result = await response.json();
            if (!response.ok) {
                throw new Error(result.message)
            }
            const index = result?.response.allFaq?.findIndex((faq) => faq?.faq_id === id);
            console.log("Index Matched Data------>", index)
            const specificFAQ = result?.response.allFaq[index];
            setSpecificFAQData(specificFAQ)
            console.log('Specific FAQ Data--->', specificFAQ)
        } catch (error) {
            console.log(error)
        }
        setFAQDataLoading(false)
    }

    useEffect(() => {
        fetchSpecificFaq();
    }, [])

    const handleDeleteSpecificFAQ = async (ID) => {
        // eslint-disable-next-line no-restricted-globals
        let isConfirm = confirm("Are you sure you want to Delete this FAQ?");
        if (isConfirm) {
            try {
                const token = await getAccessToken();
                setIsLoading(true)
                const response = await fetch(`${APIurls.deleteFAQ}/${ID}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                const result = await response.json();
                if (!response.ok) {
                    toast.error("Failed to Delete FAQ")
                    throw new Error(result.message)
                }
                console.log("Specific FAQ Deleted Sucessfully", result)
                setIsLoading(false)
                toast.success("FAQ Deleted Sucessfully")
                navigate("/faqs")
            } catch (error) {
                console.log(error)
            }
        }
    }

    return (
        <div className='bg-[#E6E6E6] px-[1.5rem] py-[1rem] min-h-screen'>
            <div className='rounded-md bg-[#FFFFFF] px-[1rem] py-[1rem]'>
                <div>
                    {faqDataLoading ? <div className="flex items-center justify-center h-[60%]">
                        <RotatingLines
                            visible={true}
                            height="100"
                            width="100"
                            color="gray"
                            strokeColor="#FB6108"
                            strokeWidth="5"
                            animationDuration="0.75"
                            ariaLabel="rotating-lines-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                        :
                        <div>
                            <div>
                                {isLoading && <PulseLoader size={8}/>
                                }
                            </div>
                            <div className='flex items-center gap-1 border-b border-[#EAECF0]'>
                                <h3
                                    className='text-[#808080] font-semibold text-base cursor-pointer'
                                    onClick={() => navigate("/faqs")}
                                >
                                    FAQs
                                </h3>
                                <LiaGreaterThanSolid />
                                <h3 className='font-semibold text-xl'>{specificFAQData?.faq_id}</h3>
                                {/* <GrDown /> */}
                            </div>
                            <div className='flex flex-col  border rounded-md mt-[2rem] px-[2rem] py-[2rem]'>
                                <div className='flex items-center justify-between pb-[2rem]'>
                                    <div>
                                        <label className='text-[#808080] font-medium text-[14px]'>Category</label>
                                        <p className='text-xl font-medium'>{specificFAQData?.category}</p>
                                    </div>
                                    <div>
                                        <label className='text-[#808080] font-medium text-[14px]'>Send to</label>
                                        <p className='text-xl font-medium'>{specificFAQData?.sendTo?.clubOwners === true ? "Club Owners" : specificFAQData?.sendTo?.customers === true ? "Customers" : "All"}</p>
                                    </div>
                                    <div>
                                        <label className='text-[#808080] font-medium text-[14px]'>Status</label>
                                        <div className='bg-[#ECFDF3] flex items-center rounded-lg gap-2 px-3 py-1'>
                                            <span className='bg-[#14BA6D] h-[0.5rem] w-[0.5rem] rounded-full'></span>
                                            <p className='text-[#037847]'>{specificFAQData?.status}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <label>Action</label>
                                        <div className='flex items-center gap-2'>
                                            <IconButton
                                                className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black rounded-lg border border-[#D9D9D9]"
                                                onClick={() => handleDeleteSpecificFAQ(specificFAQData?.faq_id)}
                                            >
                                                <Icon icon="material-symbols:delete-rounded" className="text-[1.5rem] text-black" />
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex items-center pb-[1rem]'>
                                        <p className='font-medium text-xl pr-2'>Q:- </p>
                                        <p>{specificFAQData?.question}</p>
                                    </div>
                                    <div className='flex'>
                                        <p className='font-medium text-xl pr-2'>A:-</p>
                                        <p className='leading-7'>{specificFAQData?.answer}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
