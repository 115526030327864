import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react';
import { Select, Option, IconButton } from '@material-tailwind/react';
import DataTable from 'react-data-table-component';
import CustomCheckbox from '../../../helpers/CustomCheckbox';
import { useAuth } from '../../../store/AuthContext';
import { APIurls } from '../../../api/apiConstant';
import { useQuery } from 'react-query';
import moment from 'moment';
import RoleEditShowModal from './RoleEditShowModal';
import { toast } from 'react-toastify';
import RoleDeleteModal from './RoleDeleteModal';

export default function ManageRoles({ updateRole, setUpdateRole }) {

  const { getAccessToken, userRole } = useAuth();

  const [status, setStatus] = useState("")
  const [filteredData, setFilteredData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState('')

  const fetchManageRoles = async () => {
    const token = await getAccessToken();
    const response = await fetch(`${APIurls.fetchRoles}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message)
    }
    return result?.response?.roles.reverse()
  }

  const { data: rolesData, isLoading, refetch } = useQuery('RolesData', fetchManageRoles)
  // console.log("Roles Data",rolesData)

  if (updateRole) {
    refetch();
    setUpdateRole(false);
  }

  const handleShowModal = (id) => {
    setModalData(id)
    setShowModal(true)
  }

  const setIsRefetch = async () => {
    refetch();
  }

  const handleUpdateStatusRole = async (id) => {
    const token = await getAccessToken();
    try {
      const response = await fetch(`${APIurls.updateStatusOfRole}/${id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      })
      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.message)
      }
      console.log("Status Updated Successfully", result)
      toast.success("Status Updated Successfully")
      refetch()
    } catch (error) {
      console.log(error)
    }
  }

  // const handleDeleteModal = async (id) => {
  //   setDeleteModalData(id)
  //   setDeleteModal(true)
  // }

  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      width: "80px",
      center: true
    },
    {
      name: "Title",
      selector: row => (
        <div>{row.roleName}</div>
      ),
      grow: 2.0,
      center: true
    },
    {
      name: "Date & Time",
      selector: row => (
        <div>
          {moment(row.updatedAt).format("MMM Do YY")}{","}{moment(row.updatedAt).format('LT')}
        </div>
      ),
      center: true
    },
    {
      name: "Status",
      selector: row => (
        <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
          py-[0.4rem] w-[6rem] ${row.status === "ACTIVE" ? "text-[#25BF17] bg-[#D1F0CF]" : "text-black bg-gray-500"}`}>
          <span className={`${row.status === "ACTIVE" ? "bg-[#25BF17]" : "bg-black"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
          <h2>{row.status}</h2>
        </div>
      ),
      center: true
    },
    {
      name: "Actions",
      selector: row => (
        <div className="flex items-center gap-[0.5rem]">

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("UPDATE_ROLES")) ?
            <IconButton
              className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black border border-[#D9D9D9] rounded-lg"
              onClick={() => handleShowModal(row?._id)}
            >
              <Icon
                icon="material-symbols:edit-rounded"
                className="text-[1.5rem]"
              />
            </IconButton>
            :
            "-"
          }

          <IconButton
            className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black border border-[#D9D9D9] rounded-lg"
            onClick={() => handleUpdateStatusRole(row?._id)}
          >
            <Icon
              icon="ph:power-fill"
              className="text-[1.5rem]"
            />
          </IconButton>
          {/* <IconButton
            className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black border border-[#D9D9D9] rounded-lg"
            onClick={() => handleDeleteModal(row?._id)}
          >
            <Icon
              icon="material-symbols:delete-rounded"
              className="text-[1.5rem]"
            />
          </IconButton> */}
        </div>
      ),
      center: true
    }
  ]

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        // padding: "10px 10px",
        color: "#4D4D4D",
        fontSize: "13px",
      },
    },
    head: {
      style: {
        background: "#FFF",
      },
    },

    rows: {
      style: {
        background: "#FFF",
        color: "#565656",
        padding: "10px 0px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1100px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };

  const handleSelectStatus = (value) => {
    setStatus(value)
  }

  useEffect(() => {
    let mapData = rolesData?.map((item) => {
      return { ...item }
    })
    if (status) {
      console.log("Status", status)
      mapData = mapData?.filter((item) => item?.status === status)
    }
    setFilteredData(mapData)
  }, [status, rolesData])

  useEffect(() => {
    if (status === "clear_all") {
      setFilteredData(rolesData)
    }
  }, [status, filteredData])

  return (
    <div>
      <div className='flex justify-between pb-[1rem]'>
        <p className="text-[1.2rem] sm:text-[1.3rem] font-semibold">Manage Roles</p>
        <div className='flex items-center gap-3'>
          {/* <div className='flex items-center gap-1 hover:text-primary'>
            <Icon icon='material-symbols:delete' className='text-[1.5rem]' />
            <p>Remove</p>
          </div> */}
          <Select label='Change Status'
            value={status}
            onChange={(value) => handleSelectStatus(value)}
          >
            <Option value="ACTIVE">ACTIVE</Option>
            <Option value='HOLD'>HOLD</Option>
            <Option value='clear_all'>CLEAR ALL</Option>
          </Select>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={filteredData}
        customStyles={customStyles}
        pagination
        paginationPerPage={10}
        selectableRows
        selectableRowsComponent={CustomCheckbox}
        progressPending={isLoading}
      />
      {
        showModal && (
          <RoleEditShowModal
            setShowModal={setShowModal}
            setIsRefetch={setIsRefetch}
            modalData={modalData}
          />
        )
      }
      {/* {
        deleteModal && (
          <RoleDeleteModal
            setDeleteModal = {setDeleteModal}
            setIsRefetch= {setIsRefetch}
            DeleteModalData = {deleteModalData}
          />
        )
      } */}
    </div>
  )
}
