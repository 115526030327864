import React, { useState } from 'react'
import { IconButton } from '@material-tailwind/react';
import { ThreeDots } from 'react-loader-spinner';
import { toast, ToastContainer } from 'react-toastify';
import { Icon } from "@iconify/react";
import { APIurls } from '../../../../api/apiConstant';
import { useAuth } from '../../../../store/AuthContext';
import { updateEditHowToPlayInfo } from '../../../../store/editSubCategorySlice';
import { useDispatch } from 'react-redux';

export default function UpdateHowToPlayModal({ setShowModal, questionForModal, setQuestionForModal, setAnswerForModal, answerForModal, modalId, setArrayData }) {
  const [showError, setShowError] = useState(false);
  const [loaderBtn, setLoaderBtn] = useState(false);
  const dispatch = useDispatch();
  const { getAccessToken } = useAuth();

  console.log("Mongo Id",modalId)

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!(questionForModal.trim() && answerForModal.trim())) {
      setShowError(true);
      return;
    } else {
      setShowError(false);
    }
    try {
      const token = await getAccessToken();
      setLoaderBtn(true);
      const response = await fetch(`${APIurls.updateSubCategoryHowToPlay}/${modalId}`, {
        method: "PUT",
        body: JSON.stringify({
          question: questionForModal,
          answer: answerForModal,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      })
      if (!response.ok) {
        if (response.status === 400) {
          const errorData = await response.json();
          console.log("Error Data:", errorData);
          toast.error(errorData.message);
          throw new Error(errorData.message);
        } else {
          toast.error("Failed to Update How To Play");
          throw new Error("Failed to Update How To Play");
        }
      }
      const result = await response.json();
      console.log("Update How To Play result", result);
      toast.success("How To Play Updated Successfully!");
      setArrayData((prevState) => {
        const updatedArray = prevState?.map((data) => {
          if (data?._id === modalId) {
            return {
              ...data,
              question: questionForModal,
              answer: answerForModal
            }
          }
        })
        dispatch(updateEditHowToPlayInfo({
          qna: updatedArray,
        }))
        return updatedArray;
      })
      setShowModal(false);
    } catch (error) {
      console.error(error.message);
    }
    setLoaderBtn(false);
  }

  return (
    <div className="flex items-center justify-center h-screen w-full absolute inset-0 z-10 ">
      <div className='fixed bg-[#000000E6] w-full h-full flex items-center justify-center'>
        <div className="flex flex-col w-[50%] gap-[1.5rem] py-[1.5rem] px-[2rem] bg-white rounded-lg justify-around">
          <div className="flex justify-between items-center">
            <p className="text-[1.3rem] font-semibold">Update How To Play</p>
            <IconButton onClick={() => setShowModal(false)}
              className="shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8]"
              size='sm'
              style={{
                borderRadius: "5px",
                border: "1px solid #FF0023",
              }}
            >
              <Icon
                icon="material-symbols:close"
                className="text-[1.5rem]"
              />
            </IconButton>
          </div>

          <div className="flex">
            <form onSubmit={onSubmit}
              className="w-full flex flex-col gap-[2rem]">
              <div className="flex flex-col gap-[0.5rem]">
                <input
                  className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                  type="text"
                  placeholder="Enter Question"
                  value={questionForModal}
                  onChange={(e) => setQuestionForModal(e.target.value)}
                />
                {showError && !questionForModal.trim() && (
                  <div className="text-sm text-[#E92215]">
                    *This Field is Required
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-[0.5rem]">
                <textarea rows={8}
                  className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                  type="text"
                  placeholder="Enter Answer"
                  value={answerForModal}
                  onChange={(e) => setAnswerForModal(e.target.value)}
                />
                {showError && !answerForModal.trim() && (
                  <div className="text-sm text-[#E92215]">
                    *This Field is Required
                  </div>
                )}
              </div>
              <div className="flex justify-end w-full">
                {loaderBtn ? (
                  <ThreeDots
                    height="50"
                    width="50"
                    radius="9"
                    color="#FB6108"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) :
                  (
                    <button
                      type="submit"
                      className="flex items-center gap-[0.5rem] text-white text-[1.1rem] rounded-md px-[1.5rem] py-[0.6rem] bg-[#FB6108] font-bold"
                    >
                      <span>Update</span>
                      <Icon icon="material-symbols:arrow-forward-ios" className="text-[1.3rem]" />
                    </button>
                  )}
              </div>
            </form>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  )
}
