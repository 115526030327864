import React, { useEffect, useState } from 'react'
import ClubCard from './ClubCard'
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Icon } from '@iconify/react';
import DataTable from 'react-data-table-component';
import CustomCheckbox from '../../../helpers/CustomCheckbox';
import { APIurls } from '../../../api/apiConstant';
import { useAuth } from '../../../store/AuthContext';
import { useQuery } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';
import DownloadPDF from '../../../exportData/ExportPDF.js'
import DownloadExcel from '../../../exportData/ExportExcel.js';
import { Select, Option } from "@material-tailwind/react";
import pdfLogo from '../../../assets/pdfImage.png'
import { FaFileCsv } from "react-icons/fa6";
import { RxCross2 } from 'react-icons/rx';

export default function ClubOwnersList() {
  const [totalClubs, setTotalClubs] = useState();
  const [loadingStates, setLoadingStates] = useState({});
  const [status, setStatus] = useState("")
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const { getAccessToken, userRole, adminName } = useAuth();

  const fetchClubData = async () => {
    const token = await getAccessToken();
    const response = await fetch(`${APIurls.fetchClubs}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    console.log("Club List", result?.response?.CatArray?.reverse());
    setTotalClubs(result?.response?.CatLength);
    return result?.response?.CatArray;
  }
  const { data: clubs, isLoading, error, refetch } = useQuery('clubData', fetchClubData);

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        // padding: "10px 10px",
        color: "#4D4D4D",
        fontSize: "13px",
      },
    },
    head: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        // fontSize: "15px",
      },
    },

    rows: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        color: "#565656",
        // padding: "10px 10px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    // pagination: {
    //    style: {
    //       boxShadow: "10px 5px 5px #ddd",
    //       marginBottom: "5px",
    //    },
    // },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1100px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };
  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      maxWidth: "0.5rem",
    },
    {
      name: 'Club ID',
      id: "culbId",
      selector: row => row.ClubID,
      center: true,
    },
    {
      name: 'Club Name',
      id: "clubName",
      selector: row => row.ClubName,
      center: true,
    },
    {
      name: 'Club Owners',
      id: "clubOwners",
      selector: row => row.OwnerData.Fullname,
      center: true,
    },
    {
      name: 'Onboarding Date',
      selector: (row) => moment(row?.createdAt).format("DD/MM/YYYY"),
      sortable: true,
      center: true,
    },
    {
      name: "Status",
      id: "status",
      selector: (row) => (
        <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
            py-[0.4rem] w-[7rem] ${row.status === "ACTIVE" ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"}`}>
          <span className={`${row.status === "ACTIVE" ? "bg-[#25BF17]" : "bg-[#FF0023]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
          <h2>{row.status}</h2>
        </div>
      ),
      center: true,
      grow: 1,
      sortable: true,
    },
    {
      name: "Actions",
      id: "action",
      cell: (row) => (
        <div className="flex gap-[1rem] items-center">
          {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("UPDATE_CLUBS")) ?
            loadingStates[row.ClubOwnerID] ? (
              <ThreeDots
                height="50"
                width="50"
                radius="9"
                color="#FB6108"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <button onClick={() => updateClubStatusHandler(row.ClubOwnerID)}>
                <Icon
                  icon="material-symbols:passkey"
                  className="text-[1.8rem] text-black"
                />
              </button>
            )
            :
            ""
          }

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("VIEW_CLUBS")) ?
            <Link to={`view/${row.ClubOwnerID}`}>
              <h2 className='text-black font-semibold border-b-2 border-black'>
                View Club
              </h2>
            </Link>
            :
            "-"
          }
        </div>
      ),
      center: true,
      grow: 1,
    },
  ];

  const updateClubStatusHandler = async (clubOwnerId) => {
    const token = await getAccessToken();
    try {
      setLoadingStates((prevState) => ({
        ...prevState,
        [clubOwnerId]: true,
      }));
      const response = await fetch(`${APIurls.updateClubStatus}/${encodeURIComponent(clubOwnerId)}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      if (!response.ok) {
        throw new Error("Error");
      }
      const result = await response.json();
      console.log("Update Status Result", result);
      toast.success("Club Status updated successfully!");
      refetch();
    } catch (error) {
      console.error("Failed to Update Club Status!");
      toast.error("Failed to Update Club Status!");
    }
    setLoadingStates((prevState) => ({
      ...prevState,
      [clubOwnerId]: false,
    }));
  }

  const handleSelectStatus = (value) => {
    setStatus(value)
  }

  const handleDownloadPDF = async () => {
    setLoading(true);
    try {
      const pdfData = clubs?.map((ele, index) => [
        index + 1,
        ele?.ClubID,
        ele?.ClubName,
        ele?.OwnerData?.Fullname,
        moment(ele?.createdAt).format("DD/MM/YYYY"),
        ele?.status
      ]);

      const pdfColumns = ['SL', 'Club ID', 'Club Name', 'Club Owners', 'Onboarding Date', 'Status'];

      await DownloadPDF(pdfData, pdfColumns, "clubOwnersPDF.pdf", adminName);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
    setLoading(false);
  };

  const excelData = clubs?.map((ele, index) => [
    index + 1,
    ele?.ClubID,
    ele?.ClubName,
    ele?.OwnerData?.Fullname,
    moment(ele?.createdAt).format("DD/MM/YYYY"),
    ele?.status
  ]);

  const excelColumns = ['SL', 'Club ID', 'Club Name', 'Club Owners', 'Onboarding Date', 'Status'];

  useEffect(() => {
    let mapData = clubs?.map((item) => {
      return { ...item };
    });
    if (searchInput !== "") {
      mapData = mapData.filter((item) => {
        return item?.ClubName?.toLowerCase().includes(searchInput.toLowerCase());
      });
    }
    if (status !== "") {
      if (status === "clear_all") {
        setFilteredData(mapData);
      }
      else if (status === "active") {
        mapData = mapData?.filter((item) => item?.status === "ACTIVE");
      } else if (status === "inactive") {
        mapData = mapData?.filter((item) => item?.status === "INACTIVE");
      }
    }
    setFilteredData(mapData);
  }, [clubs, searchInput, status]);

  return (
    <div className='flex flex-col gap-[1rem]'>
      <div className='flex gap-[1rem] justify-between'>
        <ClubCard name="Total Club Owners" number={totalClubs || "NA"} />
        <ClubCard name="Total Clubs" number={totalClubs || "NA"} />
        <ClubCard name="Top Club" number="0" />
        <ClubCard name="Highest Customer Base" number="0" />
        <ClubCard name="Lowest Customer Base" number="0" />
      </div>

      <div className='bg-white px-[1rem] py-[0.8rem] rounded-md flex flex-col gap-[0.5rem]'>
        <div className='flex items-center justify-between'>
          <h2 className='text-[1.3rem] font-semibold'>
            Clubs
          </h2>
          <div>
            <div className='flex items-center gap-[2rem]'>
              <div className="flex py-[0.5rem] px-[1rem] bg-[#FFF] border border-[#DFDFDF] rounded-md">
                <input
                  type="text"
                  className="w-[18rem] placeholder-[#8F8F8F] text-black text-sm font-semibold outline-none"
                  placeholder="Search by Club Name"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                {searchInput !== "" ?
                  <RxCross2
                    className='text-[1.5rem] bg-primary text-white rounded-md cursor-pointer'
                    onClick={() => setSearchInput("")}
                  />
                  :
                  <Icon
                    icon="ri:search-2-fill"
                    className="p-[0.2rem] bg-primary text-white text-[1.5rem] rounded-[5px] cursor-pointer"
                  />
                }
              </div>
              <div className=' hover:text-[#FB6108] cursor-pointer'>
                <Select label='Change Status'
                  value={status}
                  onChange={(value) => handleSelectStatus(value)}
                >
                  <Option value='active' disabled={status === "active"}>Active</Option>
                  <Option value='inactive' disabled={status === "inactive"}>Inactive</Option>
                  <Option value='clear_all' disabled={status === "clear_all"}>Clear All</Option>
                </Select>
              </div>
              <div className='flex gap-3 items-center rounded-md'>
                {loading ? (
                  <p className="text-xs">Generating PDF...</p>
                ) : (
                  <img
                    src={pdfLogo}
                    className='w-[25px] cursor-pointer'
                    onClick={handleDownloadPDF}
                    alt="Generate PDF"
                  />
                )}
                <FaFileCsv
                  className='w-[25px] h-[30px] text-green-800 cursor-pointer'
                  onClick={() => DownloadExcel({ excelData, excelColumns })}
                />
              </div>

              {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("CREATE_CLUBS")) &&
                <Link to={"create"}>
                  <button
                    className="text-[0.8rem] sm:text-[1rem] font-medium flex items-center justify-center max-xs:w-full gap-[0.3rem] xs:gap-[0.5rem] bg-primary text-white py-[0.5rem] px-[0.4rem] xs:px-[1rem] rounded-md">
                    <span className="whitespace-nowrap">Add New Club</span>
                    <Icon
                      icon="material-symbols:add"
                      className="text-[1.2rem] sm:text-[1.5rem]"
                    />
                  </button>
                </Link>
              }
            </div>
          </div>
        </div>
        <div>
          <DataTable
            columns={columns}
            customStyles={customStyles}
            data={filteredData}
            pagination
            selectableRows
            paginationPerPage={10}
            progressPending={isLoading}
            selectableRowsComponent={CustomCheckbox}
          />
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}
