import React, { useEffect, useState } from 'react'
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { useQuery } from 'react-query'
import { useAuth } from "../../../store/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import { APIurls } from "../../../api/apiConstant";
import { Link } from 'react-router-dom';
import CustomCheckbox from "../../../helpers/CustomCheckbox";
import 'react-toastify/dist/ReactToastify.css';
import { RxCross2 } from "react-icons/rx";
import {
   IconButton,
   Option,
   Select,
} from "@material-tailwind/react";
import DeleteSubCategoryModal from './DeleteSubCategoryModal';
import DownloadExcel from '../../../exportData/ExportExcel'
import pdfLogo from '../../../assets/pdfImage.png'
import { FaFileCsv } from "react-icons/fa6";
import DownloadPDF from '../../../exportData/ExportPDF'

export default function SubCategoryList() {

   const { getAccessToken, userRole, adminName } = useAuth();

   const [isRefetch, setIsRefetch] = useState(false);
   const [status, setStatus] = useState("")
   const [searchInput, setSearchInput] = useState("")
   const [filteredData, setFilteredData] = useState([]);
   const [deleteSubCategoryModal, setDeleteSubCategoryModal] = useState(false);
   const [subCategoryId, setSubCategoryId] = useState("");
   const [loading, setLoading] = useState(false);

   const fetchSubCategoriesData = async () => {
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.fetchSubCategories}`, {
         method: 'GET',
         headers: {
            Authorization: `Bearer ${token}`,
         }
      })
      const result = await response.json();
      if (!response.ok) {
         throw new Error(result.message);
      }
      console.log("Sub Category", result?.response?.CatArray);
      setFilteredData(result?.response?.CatArray)
      return result?.response?.CatArray?.reverse();
   }

   const { data: subCategories, isLoading, error, refetch } = useQuery('subCategoriesData', fetchSubCategoriesData);

   if (isRefetch) {
      refetch();
      setIsRefetch(false);
   }

   if (error) {
      toast.error(error.message, {
         toastId: 'error1'
      })
   }

   const handleDeleteSubCategoryModal = (id) => {
      setSubCategoryId(id);
      setDeleteSubCategoryModal(true);
   }

   const customStyles = {
      headRow: {
         style: {
            fontWeight: 600,
            // padding: "10px 10px",
            color: "#4D4D4D",
            fontSize: "13px",
         },
      },
      head: {
         style: {
            // borderRadius: "6px 6px 0px 0px",
            // borderTop: "0.824px solid #E9E9E9",
            background: "#FFF",
            // fontSize: "15px",
         },
      },

      rows: {
         style: {
            // borderRadius: "6px 6px 0px 0px",
            // borderTop: "0.824px solid #E9E9E9",
            background: "#FFF",
            color: "#565656",
            // padding: "10px 10px",
            fontSize: "14px",
            fontWeight: 400,
            width: "auto",
         },
      },
      // pagination: {
      //    style: {
      //       boxShadow: "10px 5px 5px #ddd",
      //       marginBottom: "5px",
      //    },
      // },
      table: {
         style: {
            overflow: "visible",
            minWidth: "1100px",
         },
      },
      tableWrapper: {
         style: {
            overflow: "visible",
         },
      },
      responsiveWrapper: {
         style: {
            overflowX: "auto", // table scroll on x axis
         },
      },
   };

   const columns = [
      {
         name: "SL",
         selector: (row, index) => index + 1,
         center: true,
         maxWidth: "0.5rem",
      },
      {
         name: 'Sub-Category',
         id: "sub-category",
         selector: row => row.subCategoryName,
         center: true,
      },
      {
         name: 'Main Category',
         id: "main-category",
         selector: row => row.mainCategoryID?.categoryName,
         center: true,
      },
      {
         name: 'Created Date & Time',
         selector: (row) => moment(row.updatedAt).format("DD-MM-YYYY, hh:mm A"),
         sortable: true,
         center: true,
      },
      {
         name: "Status",
         id: "status",
         selector: (row) => (
            <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
            py-[0.4rem] w-[7rem] ${row.isActive === true ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"}`}>
               <span className={`${row.isActive === true ? "bg-[#25BF17]" : "bg-[#FF0023]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
               <h2>{row.isActive === true ? "Active" : "Inactive"}</h2>
            </div>
         ),
         center: true,
         grow: 1,
         sortable: true,
      },
      {
         name: "Actions",
         id: "action",
         cell: (row) => (
            <div className="flex space-x-2">

               {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("UPDATE_SUBCATEGORIES")) &&
                  <Link to={`edit/${row.subCategoriesId}`}>
                     <IconButton
                        className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
                     >
                        <Icon
                           icon="material-symbols:edit-rounded"
                           className="text-[1.5rem] text-black"
                        />
                     </IconButton>
                  </Link>
               }

               {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("DELETE_SUBCATEGORIES")) &&
                  (row?.mainCategoryID?.subCategoriesId?.length === 1 &&
                     <div
                        onClick={() => handleDeleteSubCategoryModal(row?.subCategoriesId)}
                     >
                        <IconButton
                           className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
                        >
                           <Icon
                              icon="material-symbols:delete-rounded"
                              className="text-[1.5rem] text-black"
                           />
                        </IconButton>
                     </div>)
               }
            </div>
         ),
         // center: true,
         grow: 1,
      },
   ];

   const handleSelectStatus = (value) => {
      setStatus(value)
   }

   useEffect(() => {
      if (status === "clear_all") {
         setFilteredData(subCategories)
      }
   }, [status])

   const handleSearch = (e) => {
      setSearchInput(e.target.value)
   }

   useEffect(() => {
      let mapData = subCategories?.map((item, index) => {
         return { ...item };
      });
      if (searchInput !== "") {
         mapData = mapData.filter((item) => {
            return item?.subCategoryName?.toLowerCase().includes(searchInput.toLowerCase());
         });
      }
      if (status !== "") {
         if (status === "active") {
            mapData = mapData?.filter((item) => item?.isActive === true);
         } else if (status === "inactive") {
            mapData = mapData?.filter((item) => item?.isActive === false)
         }
      }
      setFilteredData(mapData);
   }, [searchInput, subCategories, status]);

   const handleDownloadPDF = async () => {
      setLoading(true);
      try {
         const pdfData = subCategories?.map((item, index) =>
            [
               index + 1,
               item?.subCategoryName,
               item?.mainCategoryID?.categoryName,
               moment(item?.updatedAt).format("DD-MM-YYYY, hh:mm A"),
               item?.isActive === true ? "Active" : "Inactive",
            ]
         )

         const pdfColumns = ["SL", 'Sub-Category', 'Main Category', 'Created Date & Time', "Status"]

         await DownloadPDF(pdfData, pdfColumns, "Sub Categories.pdf", adminName);
      } catch (error) {
         console.error("Error generating PDF:", error);
      } finally {
         setLoading(false);
      }
   };

   const excelData = subCategories?.map((item, index) => [
      index + 1,
      item?.subCategoryName,
      item?.mainCategoryID?.categoryName,
      moment(item?.updatedAt).format("DD-MM-YYYY, hh:mm A"),
      item?.isActive === true ? "Active" : "Inactive",
   ])

   const excelColumns = ["SL", 'Sub-Category', 'Main Category', 'Created Date & Time', "Status"]

   return (
      <div className='flex flex-col gap-[0.5rem]'>
         <div className='flex justify-between items-center px-[1rem]'>
            <h2 className='text-black text-[1.3rem] font-semibold'>Manage Sub-Categories</h2>
            <div className='flex items-center gap-[2rem]'>
               <div className='flex gap-3 items-center rounded-md'>
                  {loading ? (
                     <p className="text-xs">Generating PDF...</p>
                  ) : (
                     <img
                        src={pdfLogo}
                        className='w-[25px] cursor-pointer'
                        onClick={handleDownloadPDF}
                        alt="Generate PDF"
                     />
                  )}
                  <FaFileCsv
                     className='w-[25px] h-[30px] text-green-800 cursor-pointer'
                     onClick={() => DownloadExcel({ excelData, excelColumns })}
                  />
               </div>
               <div className="flex items-center py-[0.4rem] px-[1rem] bg-[#FFF] border border-[#DFDFDF] rounded-md">
                  <input
                     type="text"
                     className="w-[15rem] placeholder-[#8F8F8F] text-black text-sm font-semibold outline-none"
                     placeholder="Search by Name"
                     value={searchInput}
                     onChange={handleSearch}
                  />
                  {searchInput !== "" ?
                     <RxCross2
                        className='text-[1.4rem] bg-primary text-white rounded-md cursor-pointer'
                        onClick={() => setSearchInput("")}
                     />
                     :
                     <Icon
                        icon="ri:search-2-fill"
                        className="p-[0.2rem] bg-primary text-white text-[1.5rem] rounded-[5px] cursor-pointer"
                     />
                  }
               </div>
               <div className='flex items-center gap-[0.3rem] hover:text-primary cursor-pointer'>
                  <Select label='Change Status'
                     value={status}
                     onChange={(value) => handleSelectStatus(value)}
                  >
                     <Option value='active' disabled={status === "active"}>Active</Option>
                     <Option value='inactive' disabled={status === "inactive"}>Inactive</Option>
                     <Option value='clear_all' disabled={status === "clear_all"}>Clear All</Option>
                  </Select>
               </div>

               {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("CREATE_SUBCATEGORIES")) &&
                  <Link to={"create"}>
                     <button
                        className="text-[0.8rem] sm:text-[1rem] font-medium flex items-center justify-center max-xs:w-full gap-[0.3rem] xs:gap-[0.5rem] bg-primary text-white py-[0.5rem] px-[0.4rem] xs:px-[1rem] rounded-md">
                        <span className="whitespace-nowrap">Create Sub-Category</span>
                        <Icon
                           icon="material-symbols:add"
                           className="text-[1.2rem] sm:text-[1.5rem]"
                        />
                     </button>
                  </Link>
               }
            </div>
         </div>
         <div>
            <DataTable
               columns={columns}
               customStyles={customStyles}
               data={filteredData}
               pagination
               selectableRows
               paginationPerPage={10}
               progressPending={isLoading}
               selectableRowsComponent={CustomCheckbox}
            />
         </div>
         <ToastContainer />
         {deleteSubCategoryModal && (
            <DeleteSubCategoryModal
               subCategoryId={subCategoryId}
               setDeleteSubCategoryModal={setDeleteSubCategoryModal}
               refetch={refetch}
            />
         )}
      </div>
   )
}


