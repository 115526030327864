import { createSlice } from "@reduxjs/toolkit";

const initialState1 = {
   from: null,
   to: null,
}
const initialState2 = {
   monday: {
      from: null,
      to: null,
   },
   tuesday: {
      from: null,
      to: null,
   },
   wednesday: {
      from: null,
      to: null,
   },
   thursday: {
      from: null,
      to: null,
   },
   friday: {
      from: null,
      to: null,
   },
   saturday: {
      from: null,
      to: null,
   },
   sunday: {
      from: null,
      to: null,
   },
}

const initialState = {
   ownerInfo: {
      ownerName: "",
      adhaarNumber: "",
      mobileNumber: "",
      panNumber: "",
      email: "",
      dob: "",
      ownerImage: "",
   },
   clubInfo: {
      clubName: "",
      businessPanNumber: "",
      businessCategory: "Sports Club",
      businessEmail: "",
      openingDate: "",
      clubImagesArray: [],
      onlyClubImagesArray: [],
   },
   locationOperating: {
      addressLine1: "",
      addressLine2: "",
      city: null,
      state: "",
      country: "India",
      pincode: "",
      selectedTimingsData: "Every Day",
      timingSlotsData: initialState1,
   },
   sportsAmenities: {
      facilities: [],
      categoriesData: "",
   },
   clubLoaderBtn: false,
}

const createClubSlice = createSlice({
   name: 'createClub',
   initialState: initialState,
   // middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
   reducers: {
      updateOwnersInfo(state, action) {
         state.ownerInfo = { ...state.ownerInfo, ...action.payload };
      },
      updateClubInfo(state, action) {
         state.clubInfo = { ...state.clubInfo, ...action.payload };
      },
      updateLocationOperating(state, action) {
         state.locationOperating = { ...state.locationOperating, ...action.payload };
      },
      updateClubOpeningTimings(state, action) {
         state.locationOperating.timingSlotsData = action.payload === "Every Day" ? initialState1 : initialState2;
      },
      updateSportsAmenities(state, action) {
         state.sportsAmenities = { ...state.sportsAmenities, ...action.payload };
      },
      updateCatSubCatData(state, action) {
         // Correct: Create a new array
         console.log(action.payload)
         state.sportsAmenities.categoriesData = JSON.parse(action.payload);
      },

      updateClubLoaderBtn(state, action) {
         state.clubLoaderBtn = action.payload;
      },
   }
})

export const {
   updateOwnersInfo, updateClubInfo, updateLocationOperating, updateSportsAmenities, updateClubLoaderBtn, updateCatSubCatData, updateClubOpeningTimings
} = createClubSlice.actions;

export default createClubSlice.reducer;