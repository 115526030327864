import React from 'react'
import { Select, Option } from '@material-tailwind/react';
import { Switch } from "@material-tailwind/react";

export default function Rewards() {
  return (
    <div className='border border-gray-200 p-3 rounded-md'>
      <div className='flex justify-start px-4 py-2'>
        <p className='text-xl font-semibold'>Rewards</p>
      </div>
      <div className='flex gap-4'>
        <div className='flex flex-col gap-[1.5rem] items-baseline px-[2rem] py-[1rem]'>
          <h2 className='font-medium text-2xl max-lg:text-[18px]'>Prize Money Information</h2>
          {/*Prize Type*/}
          <Select label='Prize Type'
            className='bg-[#F6F7FB]'
          >
            <Option>Cash Prize</Option>
            <Option>Certificates</Option>
            <Option>Trophies</Option>
          </Select>
          <input
            type="text"
            className="w-full text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
            placeholder="Winner Prize Money"
          />
          <input
            type="text"
            className="w-full text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
            placeholder="Runner-Up Prize Money"
          />
          <input
            type="text"
            className="w-full text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
            placeholder="2nd Runner-Up Prize Money"
          />
          <input
            type="text"
            className="w-full text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
            placeholder="MVP"
          />
        </div>
        <div className='flex flex-col gap-[1.5rem] px-[2rem] py-[1rem] max-lg:px-[1rem]'>
          <h2 className='font-medium text-2xl max-lg:text-[18px]'>Trophy/Awards Information</h2>
          <div className='flex items-center justify-between'>
            <p>Winner</p>
            <Switch color="orange"
              ripple={false}
              className="h-full w-full checked:bg-primary col-span-1"
              containerProps={{
                className: "w-11 h-6",
              }}
              circleProps={{
                className: "before:hidden left-0.5 border-none",
              }}
            />
          </div>
          <div className='flex items-center justify-between'>
            <p>Runner-Up</p>
            <Switch color="orange"
              ripple={false}
              className="h-full w-full checked:bg-primary col-span-1"
              containerProps={{
                className: "w-11 h-6",
              }}
              circleProps={{
                className: "before:hidden left-0.5 border-none",
              }}
            />
          </div>
          <div className='flex items-center justify-between'>
            <p>2nd Runner-Up</p>
            <Switch color="orange"
              ripple={false}
              className="h-full w-full checked:bg-primary col-span-1"
              containerProps={{
                className: "w-11 h-6",
              }}
              circleProps={{
                className: "before:hidden left-0.5 border-none",
              }}
            />
          </div>
          <div className='flex items-center justify-between'>
            <p>MVP (All Sports)</p>
            <Switch color="orange"
              ripple={false}
              className="h-full w-full checked:bg-primary col-span-1"
              containerProps={{
                className: "w-11 h-6",
              }}
              circleProps={{
                className: "before:hidden left-0.5 border-none",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
