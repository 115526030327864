import React from 'react'
import { useOutletContext } from 'react-router-dom'

export default function ViewCOSportView() {
   const [sportsData] = useOutletContext();

   return (
      <div className='grid grid-cols-5 px-[0.6rem]'>
         <div className='col-span-2'>
            <h2 className='text-[1.15rem] font-bold my-[1rem]'>
               Info
            </h2>
            <div className='grid grid-cols-2 gap-y-[1rem] place-content-between'>
               <label className='text-[#858585] font-medium'>Arena ID</label>
               <h2 className='font-semibold'>{sportsData?.AreanaID}</h2>
               <label className='text-[#858585] font-medium'>Arena Name</label>
               <h2 className='font-semibold'>{sportsData?.Areana_name}</h2>
               <label className='text-[#858585] font-medium'>Type of Arena</label>
               <h2 className='font-semibold'>NA</h2>
               <label className='text-[#858585] font-medium'>Sub - Category</label>
               <h2 className='font-semibold'>{sportsData?.Areana_sub_category?.subCategoryName}</h2>
               <label className='text-[#858585] font-medium'>No.of Players</label>
               <h2 className='font-semibold'>{sportsData?.PlayersNO}</h2>
               <label className='text-[#858585] font-medium'>No.of Boards</label>
               <h2 className='font-semibold'>{sportsData?.Areana_boards?.length}</h2>
               <label className='text-[#858585] font-medium'>Unique ID's</label>
               <h2 className='font-semibold'>
                  {sportsData?.Areana_boards?.map((board) => board.BoardsUID + ", ")}
               </h2>
            </div>
         </div>
         <div className='col-span-2'>
            <h2 className='text-[1.15rem] font-bold my-[1rem]'>
               Pricing
            </h2>
            <h2 className='text-[1.15rem] font-bold my-[1rem]'>
               Billing
            </h2>
            <div className='grid grid-cols-2 gap-y-[1rem]'>
               <label className='text-[#858585] font-medium'>Per Game</label>
               <h2 className='font-semibold'>₹ {sportsData?.billings?.per_game}</h2>
               <label className='text-[#858585] font-medium'>Per Minute (Min: 20)</label>
               <h2 className='font-semibold'>₹ {sportsData?.billings?.per_minute}</h2>
               <label className='text-[#858585] font-medium'>Per Hour</label>
               <h2 className='font-semibold'>₹ {sportsData?.billings?.per_hour}</h2>
               <label className='text-[#858585] font-medium'>Status</label>
               <div className={`flex justify-center items-center gap-[0.5rem] text-[0.9rem] rounded-md font-semibold py-[0.2rem] w-[6.5rem] ${sportsData?.isActive === "ACTIVE" ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"}`}>
                  <span className={`${sportsData?.isActive === "ACTIVE" ? "bg-[#25BF17]" : "bg-[#FF0023]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
                  <h2>{sportsData?.isActive}</h2>
               </div>
            </div>
         </div>
         <div className='col-span-1 my-[1rem]'>
            <img src={sportsData?.Areana_image} alt="sports" className='rounded-md' />
         </div>
      </div>
   )
}
