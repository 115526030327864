import React from 'react'
import { Outlet } from 'react-router-dom'

function ClubOwners() {
   return (
      <div className='flex bg-[#E6E6E6] px-8 py-4  h-full '>
         <div className='flex flex-col h-full w-full bg-[#FFF]'>
            <Outlet />
         </div>
      </div>
   )
}

export default ClubOwners


